import { FC, ReactNode, SyntheticEvent } from 'react';
import cn from 'classnames';
import './Radio.scss';

interface Props {
  absoluteStyles?: boolean;
  isChecked: boolean;
  hideRadio?: boolean;
  label: string | ReactNode;
  value?: string | number | null;
  onChange: (e: SyntheticEvent) => void;
}

const Radio: FC<Props> = ({
  isChecked,
  label,
  value,
  absoluteStyles = false,
  hideRadio = false,
  onChange,
}) => {

  return (
    <label className={cn('radio', { checked: isChecked, 'relative-units': !absoluteStyles })}>
      <input type="radio" value={value ?? ''} checked={isChecked} onChange={onChange}/>
      {!hideRadio && <div className={cn('radio-icon new-style', { checked: isChecked })}>
        <div className="radio-icon-inner"></div>
      </div> || null}
      <div className="radio-label">{ label }</div>
    </label>
  );
};

export default Radio;