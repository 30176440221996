import { createSlice, PayloadAction, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { ProductChargify } from 'enums';
import { IHttpRequestResult, IProductPriceData, IOtherProduct } from 'interfaces';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers';
import querystring from 'query-string';

interface IGetQuickbooksProducts {
  IsOwn: boolean;
  IsPurchase: boolean;
  subscriptionId: string;
}

interface IGetProducts {
  subscriptionId: string;
}

interface INewApplication {
  product: string;
  year?: string;
  componentId?: number;
  price?: string;
  licenceCode?: string;
  productCode?: string;
  serialNumber?: string;
  userIds: string[];
  additionalInformation?: string;
}

export const getProductPrice = createAsyncThunk<any, number>(
  'products/getProductPrice',
  async (componentId: number) => {
    const response = await http.get(`/bff-ussp/catalog?ComponentId=${componentId}`);
    return response;
  },
);

// Simple form products data requests
export const getProductsInfo = createAsyncThunk<any, string>(
  'products/getProductsInfo',
  async (country: string) => {
    const query = {
      country,
      names: Object.values(ProductChargify),
    };
    const response = await http.get(`/bff/catalog/products?${querystring.stringify(query)}`);
    return response;
  },
);
export const getOtherProductsInfo = createAsyncThunk<any>(
  'products/getOtherProductsInfo',
  async () => {
    const response = await http.get('/bff/catalog/productInfos/other');
    return response;
  },
);

export const getMFAPrice = createAsyncThunk<any>(
  'products/getMFAPrice',
  async () => {
    const response = await http.get('/bff-ussp/catalog/MFA/price');
    return response;
  },
);

export const getUserHostingPrice = createAsyncThunk<any, any>(
  'products/getUserHostingPrice',
  async ({ subscriptionId }) => {
    const response = await http.get(`/bff-ussp/catalog/${subscriptionId}/userHosting/price?role=standard`);
    return response;
  },
);

export const getQuickbooksProducts = createAsyncThunk<any, IGetQuickbooksProducts>(
  'ussp/getQuickbooksProducts',
  async ({ IsOwn, IsPurchase, subscriptionId }) => {
    const query = {
      IsOwn,
      IsPurchase,
    };
    const response = await http.get(`/bff-ussp/catalog/${subscriptionId}/productInfos/quickbooks?${querystring.stringify(query)}`);
    return response;
  },
);

export const getSageProducts = createAsyncThunk<any, IGetProducts>(
  'ussp/getSageProducts',
  async ({ subscriptionId }) => {
    const response = await http.get(`/bff-ussp/catalog/${subscriptionId}/productInfos/sage`);
    return response;
  },
);

export const getOtherProducts = createAsyncThunk<any, IGetProducts>(
  'ussp/getOtherProducts',
  async ({ subscriptionId }) => {
    const response = await http.get(`/bff-ussp/catalog/${subscriptionId}/productInfos/other`);
    return response;
  },
);
export const getAdminQuickbooksProducts = createAsyncThunk<any, IGetQuickbooksProducts>(
  'ussp/getAdminQuickbooksProducts',
  async ({ IsOwn, IsPurchase, subscriptionId }) => {
    const query = {
      IsOwn,
      IsPurchase,
    };
    const response = await http.get(`/bff-ussp/admin/catalog/${subscriptionId}/productInfos/quickbooks?${querystring.stringify(query)}`);
    return response;
  },
);

export const getAdminSageProducts = createAsyncThunk<any, IGetProducts>(
  'ussp/getAdminSageProducts',
  async ({ subscriptionId }) => {
    const response = await http.get(`/bff-ussp/admin/catalog/${subscriptionId}/productInfos/sage`);
    return response;
  },
);

export const getAdminOtherProducts = createAsyncThunk<any, IGetProducts>(
  'ussp/getAdminOtherProducts',
  async ({ subscriptionId }) => {
    const response = await http.get(`/bff-ussp/admin/catalog/${subscriptionId}/productInfos/other`);
    return response;
  },
);

export const getAdminAdditionalServices = createAsyncThunk<any, IGetProducts>(
  'ussp/getAdminAdditionalServices',
  async ({ subscriptionId }) => {
    const response = await http.get(`/bff-ussp/admin/catalog/${subscriptionId}/productInfos/additional`);
    return response;
  },
);

export const addNewApplicationToCart = createAsyncThunk<any, any>(
  'ussp/addNewApplicationToCart',
  async ({ subscriptionId }, { getState }) => {
    const { newApplication } = (getState() as any).products;
    const requstBody = { ...newApplication };
    requstBody.year = Number(requstBody.year);
    delete requstBody.price;
    delete requstBody.product;
    requstBody.subscriptionId = subscriptionId;
    if (newApplication.product === 'sage-client-license') {
      delete requstBody.productCode;
      delete requstBody.licenceCode;
      delete requstBody.additionalInformation;
      const response = await http.post('/bff-ussp/cart/applications/sage', requstBody);
      return response;
    }
    if (newApplication.product === 'quickbooks-client-license') {
      delete requstBody.serialNumber;
      delete requstBody.additionalInformation;
      const response = await http.post('/bff-ussp/cart/applications/quickbooks', requstBody);
      return response;
    }
    if (['otherPerServer', 'otherPerUser'].includes(newApplication.product)) {
      const response = await http.post('/bff-ussp/cart/applications/other', requstBody);
      return response;
    }
  },
);
export const addAdminNewApplication = createAsyncThunk<any, any>(
  'ussp/addAdminNewApplication',
  async ({ subscriptionId }, { getState }) => {
    const { newApplication } = (getState() as any).products;
    const requstBody = { ...newApplication };
    requstBody.year = requstBody.year ? Number(requstBody.year) : null;
    delete requstBody.price;
    delete requstBody.product;
    if (newApplication.product === 'sage-client-license') {
      delete requstBody.productCode;
      delete requstBody.licenceCode;
      delete requstBody.additionalInformation;
      const response = await http.post(`/bff-ussp/admin/subscription/${subscriptionId}/applications/sage`, requstBody);
      return response;
    }
    if (newApplication.product === 'quickbooks-client-license') {
      delete requstBody.serialNumber;
      delete requstBody.additionalInformation;
      const response = await http.post(`/bff-ussp/admin/subscription/${subscriptionId}/applications/quickbooks`, requstBody);
      return response;
    }
    if (newApplication.product === 'additional-services') {
      delete requstBody.productCode;
      delete requstBody.licenceCode;
      delete requstBody.serialNumber;
      delete requstBody.additionalInformation;
      delete requstBody.year;
      const response = await http.post(`/bff-ussp/admin/subscription/${subscriptionId}/applications/other`, requstBody);
      return response;
    }
    if (['otherPerServer', 'otherPerUser'].includes(newApplication.product)) {
      const response = await http.post(`/bff-ussp/admin/subscription/${subscriptionId}/applications/other`, requstBody);
      return response;
    }
  },
);

interface ProductsState {
  getProductPriceRequest: IHttpRequestResult<any>;
  getProductsInfoRequest: IHttpRequestResult<any>;
  getOtherProductsInfoRequest: IHttpRequestResult<any>;
  getMFAPriceRequest: IHttpRequestResult<any>;
  getUserHostingPriceRequest: IHttpRequestResult<any>;
  getQuickbooksProductsRequest: IHttpRequestResult<any>;
  getOtherProductsRequest: IHttpRequestResult<any>;
  getSageProductsRequest: IHttpRequestResult<any>;
  getAdminQuickbooksProductsRequest: IHttpRequestResult<any>;
  getAdminOtherProductsRequest: IHttpRequestResult<any>;
  getAdminSageProductsRequest: IHttpRequestResult<any>;
  getAdminAdditionalServicesRequest: IHttpRequestResult<any>;
  products: {
    [name: string]: {
      component_id?: number;
      name: string;
      price: number;
    };
  };
  otherProductsInfo: {
    [name: string]: IOtherProduct;
  };
  mfa: IProductPriceData | null;
  userHosting: IProductPriceData | null;
  sageProducts: string[] | null;
  quickbooksProducts: string[] | null;
  otherProducts: string[] | null;
  adminSageProducts: string[] | null;
  adminQuickbooksProducts: string[] | null;
  adminOtherProducts: string[] | null;
  adminAdditionalServices: string[] | null;
  newApplication: INewApplication;
  addNewApplicationToCartRequest: IHttpRequestResult<any>;
  addAdminNewApplicationRequest: IHttpRequestResult<any>;
}

const initialState: ProductsState = {
  getProductPriceRequest: createHttpRequestInitResult(),
  getProductsInfoRequest: createHttpRequestInitResult(),
  getOtherProductsInfoRequest: createHttpRequestInitResult(),
  getMFAPriceRequest: createHttpRequestInitResult(),
  getUserHostingPriceRequest: createHttpRequestInitResult(),
  getQuickbooksProductsRequest: createHttpRequestInitResult(),
  getOtherProductsRequest: createHttpRequestInitResult(),
  getSageProductsRequest: createHttpRequestInitResult(),
  getAdminQuickbooksProductsRequest: createHttpRequestInitResult(),
  getAdminOtherProductsRequest: createHttpRequestInitResult(),
  getAdminAdditionalServicesRequest: createHttpRequestInitResult(),
  getAdminSageProductsRequest: createHttpRequestInitResult(),
  products: {},
  otherProductsInfo: {},
  mfa: null,
  userHosting: null,
  sageProducts: [],
  quickbooksProducts: [],
  otherProducts: [],
  adminSageProducts: [],
  adminQuickbooksProducts: [],
  adminOtherProducts: [],
  adminAdditionalServices: [],
  newApplication: {
    product: '',
    year: '',
    componentId: undefined,
    price: '',
    licenceCode: '',
    productCode: '',
    serialNumber: '',
    additionalInformation: '',
    userIds: [],
  },
  addNewApplicationToCartRequest: createHttpRequestInitResult(),
  addAdminNewApplicationRequest: createHttpRequestInitResult(),
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clearApplicationFormValues: (state) => {
      state.newApplication.product = '';
      state.newApplication.componentId = undefined;
      state.newApplication.price = '';
      state.newApplication.year = '';
      state.newApplication.licenceCode = '';
      state.newApplication.productCode = '';
      state.newApplication.serialNumber = '';
      state.newApplication.additionalInformation = '';
      state.newApplication.userIds = [];
    },
    setApplicationProduct: (state, action: PayloadAction<string>) => {
      state.newApplication.product = action.payload;
    },
    setApplicationComponentId: (state, action: PayloadAction<number | undefined>) => {
      state.newApplication.componentId = action.payload;
    },
    setApplicationPrice: (state, action: PayloadAction<string>) => {
      state.newApplication.price = action.payload;
    },
    setApplicationYear: (state, action: PayloadAction<string>) => {
      state.newApplication.year = action.payload;
    },
    setApplicationLicenceCode: (state, action: PayloadAction<string>) => {
      state.newApplication.licenceCode = action.payload;
    },
    setApplicationProductCode: (state, action: PayloadAction<string>) => {
      state.newApplication.productCode = action.payload;
    },
    setApplicationSerialNumber: (state, action: PayloadAction<string>) => {
      state.newApplication.serialNumber = action.payload;
    },
    setApplicationAdditionalInformation: (state, action: PayloadAction<string>) => {
      state.newApplication.additionalInformation = action.payload;
    },
    setApplicationUserIds: (state, action: PayloadAction<any>) => {
      state.newApplication.userIds = action.payload;
    },
  },
  extraReducers: {
    ...createExtraReducersForResponses(getProductPrice, 'getProductPriceRequest'),
    ...createExtraReducersForResponses(getProductsInfo, 'getProductsInfoRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.products = {};
        action.payload.products.forEach((product: any) => state.products[product.name] = product);
      },
    ),
    ...createExtraReducersForResponses(getOtherProductsInfo, 'getOtherProductsInfoRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.otherProductsInfo = {};
        const countryProductsNames = Object.keys(state.products as any[]);
        action.payload.forEach(({ version: name, ...rest }: any) => {
          if (!countryProductsNames.includes(name as string)) {
            state.otherProductsInfo[name] = {
              name,
              ...rest,
            };
          }
        });
      },
    ),
    ...createExtraReducersForResponses(getUserHostingPrice, 'getUserHostingPriceRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.userHosting = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getMFAPrice, 'getMFAPriceRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.mfa = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getOtherProducts, 'getOtherProductsRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.otherProducts = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getSageProducts, 'getSageProductsRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.sageProducts = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getQuickbooksProducts, 'getQuickbooksProductsRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.quickbooksProducts = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getAdminOtherProducts, 'getAdminOtherProductsRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.adminOtherProducts = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getAdminAdditionalServices, 'getAdminAdditionalServicesRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.adminAdditionalServices = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getAdminSageProducts, 'getAdminSageProductsRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.adminSageProducts = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getAdminQuickbooksProducts, 'getAdminQuickbooksProductsRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.adminQuickbooksProducts = action.payload;
      },
    ),
    ...createExtraReducersForResponses(addNewApplicationToCart, 'addNewApplicationToCartRequest'),
    ...createExtraReducersForResponses(addAdminNewApplication, 'addAdminNewApplicationRequest'),
  },
});

export const {
  clearApplicationFormValues,
  setApplicationProduct,
  setApplicationComponentId,
  setApplicationPrice,
  setApplicationYear,
  setApplicationLicenceCode,
  setApplicationProductCode,
  setApplicationSerialNumber,
  setApplicationAdditionalInformation,
  setApplicationUserIds,
} = productsSlice.actions;

export default productsSlice.reducer;