import './AssignUsers.scss';
import { FC, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import {
  addNewApplicationToCart,
  getAddAppAssignUsersData,
  getUsspCartData,
  setApplicationPrice,
  setApplicationUserIds,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { notify } from 'helpers';

import { SearchIcon } from 'assets/svg';

import { Checkbox, InputText, EmptyStateContainer } from 'components';
import ApplicationFormFooter from '../ApplicationFormFooter/ApplicationFormFooter';

interface IUser {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email: string;
}

interface Props {
  backHandler: () => void;
  onCancel: () => void;
}

const AssignUsers: FC<Props> = ({ backHandler, onCancel }) => {
  const { data, isLoading } = useAppSelector(state => state.ussp.getAddAppAssignUsersDataRequest);
  const { isLoading: isAddLoading } = useAppSelector(state => state.products.addNewApplicationToCartRequest);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [assignedUsersIds, setAssignedUsersIds] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();

  const fetchAddAppAssignUsersData = () => {
    if (typeof subscriptionId === 'string') {
      dispatch(getAddAppAssignUsersData({ subscriptionId })).unwrap();
    }
  };

  useEffect(() => {
    fetchAddAppAssignUsersData();
  }, []);

  const filteredUsers = useMemo(() => data?.filter(({ firstName, lastName, username, email }: { firstName: string; lastName: string; username: string; email: string }) => {
    const formatedSearchQuery = searchQuery.trim().toLowerCase();

    const formatedFirstName = firstName?.trim().toLowerCase();
    const formatedLastName = lastName?.trim().toLowerCase();
    const formatedUsername = username?.trim().toLowerCase();

    const formatedEmail = email.trim().toLowerCase();

    const isMatchFullName = formatedFirstName && formatedLastName
      && (`${formatedFirstName} ${formatedLastName}`.includes(formatedSearchQuery)
        || `${formatedLastName} ${formatedFirstName}`.includes(formatedSearchQuery));
    const isMatchUsername = formatedUsername && formatedEmail.includes(formatedUsername);
    const isMatchEmail = formatedEmail.includes(formatedSearchQuery);

    return isMatchFullName || isMatchUsername || isMatchEmail;
  }), [searchQuery, data]);

  const toggleUser = (value: boolean, id: string): void => {
    const ids: string[] = value ? [...assignedUsersIds, id] : assignedUsersIds.filter(el => el !== id);
    setAssignedUsersIds(ids);
    dispatch(setApplicationUserIds(ids));
  };

  const onSubmit = async () => {
    if (typeof subscriptionId === 'string') {
      await dispatch(addNewApplicationToCart({ subscriptionId })).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
      notify.success('Product successfully added to cart', 'Now you can proceed to payment');
      onCancel();
    }
  };

  const handleGoBack = () => {
    dispatch(setApplicationPrice('0'));
    dispatch(setApplicationUserIds([]));
    backHandler();
  };

  return (
    <div className="assign-users">
      {(isLoading && (
        <div className="dashboard-accounts-app-assign__apply-block">
          <div className="app-card disabled flex flex-align-center rel-flex-gap-24 rel-m-b-12 rel-p-l-24 rel-p-r-16 rel-p-y-12">
            <Skeleton containerClassName="app-card__icon-skeleton" height="100%" />
            <div className="app-card__content">
              <Skeleton containerClassName="app-card__name" height="100%" width="70%" />
              <Skeleton containerClassName="app-card__id" height="100%" />
            </div>
          </div>
        </div>
      )) || (!data?.length && (
        <EmptyStateContainer
          title="There are no users"
          description="This product has already been assigned to all users"
        />
      )) || (
        <>
          <InputText
            value={searchQuery}
            classes="rel-m-b-16"
            label="Assign product to"
            labelRight={`${assignedUsersIds.length} selected`}
            placeholder="Search by Email or Name..."
            theme="grey"
            icon={SearchIcon}
            onChange={setSearchQuery}
          />
          {filteredUsers?.map(({ id, username, firstName, lastName, email }: IUser) => (
            <div
              className="app-card disabled rel-m-b-12 rel-p-l-24 rel-p-r-16 rel-p-y-12"
              key={id}
            >
              <Checkbox
                isChecked={assignedUsersIds.includes(id)}
                onChange={(e) => toggleUser((e.target as HTMLInputElement).checked, id)}
              >
                <div className="app-card__content">
                  <div className="app-card__name">{username || `${firstName} ${lastName}`}</div>
                  <div className="app-card__id">{email}</div>
                </div>
              </Checkbox>
            </div>
          ))}
          {filteredUsers.length <= 0 && (
            <EmptyStateContainer
              title="There are no users"
              description={`No users matched your search: "${searchQuery}"`}
            />
          )}
        </>
      )}
      <ApplicationFormFooter
        onCancel={handleGoBack}
        onOk={onSubmit}
        isOkLoading={isAddLoading}
        isOkDisabled={assignedUsersIds.length < 1}
        submitForm
        showPrice
        cancelText="Back"
        okText="Add to Cart"
      />
    </div>
  );
};

export default AssignUsers;
