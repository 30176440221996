import { FC } from 'react';
import { ITableFilterOrdering } from 'interfaces';
import { TableSearch, TableOrder } from 'components';

interface Props {
  initialSearchQuery?: string;
  orderState?: ITableFilterOrdering | undefined;
  onSearch: (searchQuery: string) => void;
  onOrderChange: (data: ITableFilterOrdering) => void;
}

const ApplicationsTableHeadRow: FC<Props> = ({
  initialSearchQuery = '',
  orderState,
  onSearch,
  onOrderChange,
}) => (
  <tr className="applications-table-head-row">
    <th>
      <TableOrder
        fieldName="ApplicationTitle"
        currentOrderState={orderState}
        onChange={onOrderChange}
      >
        <TableSearch
          initialValue={initialSearchQuery}
          label="APPLICATION TITLE"
          searchBy="Search by title..."
          onSearch={onSearch}
        />
      </TableOrder>
    </th>
    <th>COMPONENT ID</th>
    <th>
      <TableOrder
        label="QTY OF LICENSE"
        fieldName="Quantity"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>
      <TableOrder
        label="UNIT PRICE"
        fieldName="Price"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>
      <TableOrder
        label="TOTAL MONTHLY"
        fieldName="TotalMonthlyPrice"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>ACTIONS</th>
  </tr>
);

export default ApplicationsTableHeadRow;
