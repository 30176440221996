import { LOGS } from "consts";

// Function to add a log entry
export const addLog = (name: string, data: any): void => {
  const logs = getAllLogs();
  logs.push({ name, data, timestamp: new Date().toISOString() });
  sessionStorage.setItem(LOGS, JSON.stringify(logs));
};

// Function to get all log entries
export const getAllLogs = (): { name: string, data: any, timestamp: string }[] => {
  const logs = sessionStorage.getItem(LOGS);
  return logs ? JSON.parse(logs) : [];
};

// Function to clear all log entries
export const clearAllLogs = (): void => {
  sessionStorage.removeItem(LOGS);
};