import './DashboardBillingDetails.scss';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getBillingDetailsData } from 'store';
import { setupSignalR } from 'helpers';

import { ArrowLeftSmallIcon } from 'assets/svg';

import BillingDetailsPayment from './components/BillingDetailsPayment/BillingDetailsPayment';
import BillingDetailsTableHeadRow from './components/BillingDetailsTableHeadRow';
import BillingDetailsTableBodyRow from './components/BillingDetailsTableBodyRow';
import BillingDetailsTableBodyRowLoader from './components/BillingDetailsTableBodyRowLoader';
import { Error, Pagination } from 'components';

const DashboardBillingDetails: FC = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getBillingDetailsDataRequest);
  const navigate = useNavigate();
  const { subscriptionId } = useParams();
  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
  });

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const fetchData = async () => {
    if (typeof subscriptionId === 'string') {
      const data = await dispatch(getBillingDetailsData({
        subscriptionId,
        page: queryPage,
        pageSize: queryPageSize,
        _disableErrorHandler: true,
        _isBackground: isBackgroundUpdate,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  useEffect(() => {
    const connection = setupSignalR('/billingDetails');
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('Signal', async (message: any) => {
            setIsBackgroundUpdate(true);
            await fetchData();
            setIsBackgroundUpdate(false);
            console.log('BILLING_DETAILS:SIGNAL', message);
          });

          connection.on('PaymentExpirationChanged', async (message: any) => {
            setIsBackgroundUpdate(true);
            await fetchData();
            setIsBackgroundUpdate(false);
            console.log('BILLING_DETAILS:PAYMENT_EXPIRIATION_CHANGED', message);
          });
        })
        .catch((e: any) => console.log('Connection failed [billingDetails]: ', e));
    }

    fetchData();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [query]);

  if (error) {
    if ((error as SerializedError).code === '404') {
      return <Error code={404} buttonLabel="Go to Billing Page" buttonRedirectPath="/user-portal/billing" isShowButton />;
    }
    return <Error code={500} />;
  }

  return (
    <div className="dashboard-billing-details ussp-page">
      <section className="dashboard-billing-details__header ussp-page__header">
        <button
          className="btn relative-units default dashboard-billing-details__back-btn"
          onClick={() => navigate('/user-portal/billing')}
        >
          <ArrowLeftSmallIcon />
        </button>
        <h1>Billing Details</h1>
        <button
          className="btn relative-units default new-style"
          onClick={() => navigate(`invoices`)}
        >View Invoices</button>
      </section>
      <section className="dashboard-billing-details__content">
        <table className="Billings-table">
          <thead><BillingDetailsTableHeadRow /></thead>
          <tbody>
            {isLoading && !isBackgroundUpdate
              ? <BillingDetailsTableBodyRowLoader />
              : data?.details.map((el: any, index: number) => (
                <BillingDetailsTableBodyRow key={`billing-details-${index}`} {...el} />
              ))
            }
          </tbody>
        </table>
      </section>
      <BillingDetailsPayment {...data?.paymentDetail} isLoading={isLoading} />
      <Pagination
        current={queryPage}
        entries={queryPageSize}
        totalPages={totalPages}
        onChange={onPaginationChange}
      />
    </div>
  );
};

export default DashboardBillingDetails;