import { FC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { notify, useFormFieldErrorNotification } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addUserPhoneNumber } from 'store';

import { PHONE_PATTERN_SIMPLE } from 'consts';

import {
  InputText,
  Modal,
} from 'components';

type PhoneNumberForm = {
  phoneNumber: string;
};

interface IUserData {
  id: string;
}

interface Props {
  visible: boolean;
  userData: IUserData | null;
  subscriptionId?: string;
  onCancel: () => void;
  onSuccess: () => void;
}

const schema = yup.object({
  phoneNumber: yup.string()
    .matches(PHONE_PATTERN_SIMPLE, { message: 'Please enter a valid phone' })
    .min(4, 'Phone number can\'t be shorter than 4 digits')
    .max(15, 'Phone number can\'t be longer than 15 digits'),
});

const AddPhoneNumberModal: FC<Props> = ({
  visible,
  userData,
  subscriptionId,
  onCancel,
  onSuccess,
}) => {
  const {
    register,
    handleSubmit,
    formState,
    formState: {
      touchedFields,
    },
  } = useForm<PhoneNumberForm>({ mode: 'all', resolver: yupResolver(schema) });
  useFormFieldErrorNotification(formState, ['phoneNumber']);
  const { errors, isValid } = formState;

  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(state => state.ussp.addUserPhoneNumberRequest);
  const onNextHandler: SubmitHandler<PhoneNumberForm> = async ({ phoneNumber }) => {
    if (typeof subscriptionId === 'string' && typeof userData?.id === 'string') {
      await dispatch(addUserPhoneNumber({
        subscriptionId,
        userId: userData.id,
        phoneNumber,
      })).unwrap();

      notify.success(
        'Success',
        'Phone number was changed successfully!',
      );

      onSuccess();
      onCancel();
    }
  };

  return (
    <Modal
      classes="dashboard-accounts-add-phone-number"
      title="Enter Phone Number"
      visible={visible}
      width={535}
      okText="Add"
      isOkDisabled={!isValid}
      isOkLoading={isLoading}
      onOk={handleSubmit(onNextHandler)}
      onCancel={onCancel}
    >
      <form>
        <InputText
          label="Phone number"
          placeholder="+1 (000) 0000 000"
          name="phoneNumber"
          register={register}
          shouldUnregister={false}
          isRequired
          isInvalid={!!(errors.phoneNumber && touchedFields.phoneNumber)}
        />
      </form>
    </Modal>
  );
};

export default AddPhoneNumberModal;