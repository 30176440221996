import {
  RefObject,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  type MutableRefObject,
  type DependencyList,
  type EffectCallback,
} from 'react';

export function usePrevious<T>(
  value: T,
): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const isFirst = useIsFirstRender();

  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
  }, deps);
}

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
  mouseEvent: 'click' | 'mousedown' | 'mouseup' = 'click',
  bubbling = true,
): void {
  useUpdateEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };
    document.addEventListener(mouseEvent, handleClickOutside, bubbling);
    return () => {
      document.removeEventListener(mouseEvent, handleClickOutside, bubbling);
    };
  }, [ref.current]);
}

interface IOverflow {
  ref: RefObject<HTMLDivElement>;
  isOverflowX: boolean;
  isOverflowY: boolean;
}

export function useIsOverflow(
  callback?: (hasOverflowX: boolean, hasOverflowY: boolean) => void,
): IOverflow {
  const [isOverflowX, setIsOverflowX] = useState<boolean>(false);
  const [isOverflowY, setIsOverflowY] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const hasOverflowX = current.scrollWidth > current.clientWidth;
      const hasOverflowY = current.scrollHeight > current.clientHeight;

      setIsOverflowX(hasOverflowX);
      setIsOverflowY(hasOverflowY);

      callback?.(hasOverflowX, hasOverflowY);
    }

  }, [callback, ref]);

  return { ref, isOverflowX, isOverflowY };
}
