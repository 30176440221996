import { FC, useMemo } from 'react';
import cn from 'classnames';
import Lottie from 'lottie-react';
import loaderAnimation from 'assets/lotties/button-loader.json';
import { useAppSelector } from 'store/hooks';
import { usePriceHandler, makeVw } from 'helpers';
import { IDashboardUser } from 'interfaces';
import './ApplicationFormFooter.scss';
import { Button } from 'components';

interface Props {
  isOkDisabled?: boolean;
  isOkLoading?: boolean;
  okText?: string | null;
  okWidth?: number;
  showPrice?: boolean;
  submitForm?: boolean;
  cancelText?: string | null;
  onCancel?: () => void;
  onOk?: () => void;
}

const ApplicationFormFooter: FC<Props> = ({
  onCancel,
  onOk,
  cancelText,
  okText,
  okWidth,
  showPrice = false,
  submitForm,
  isOkLoading,
  isOkDisabled,
}) => {
  const user = useAppSelector<IDashboardUser | null>(state => state.ussp.user);
  const { price, userIds } = useAppSelector(state => state.products.newApplication);
  const quantity = userIds?.length;

  const { formatPrice } = usePriceHandler();
  const formatedPrice = useMemo(
    () => formatPrice((Number(price) || 0) * quantity, user?.country),
    [price, quantity],
  );

  const okBtnWidthStyle = useMemo(
    () => okWidth ? { width: makeVw(okWidth) } : {},
    [okWidth],
  );

  return (
    <div className="application-form-footer flex flex-justify-end flex-align-center">
      {showPrice && (
        <div className="total-price-modal-footer m-r-auto flex flex-align-center">Total monthly price&nbsp;<span>{formatedPrice}</span></div>
      )}
      <button
        type="button"
        className="btn relative-units default new-style"
        onClick={onCancel}
      >
        {cancelText ? cancelText : 'Cancel'}
      </button>
      <Button
        buttonType={submitForm ? 'submit' : 'button'}
        isLoading={isOkLoading}
        style={okBtnWidthStyle}
        isDisabled={isOkDisabled}
        isNewStyle
        onClick={onOk}
      >
        {okText}
      </Button>
    </div>
  );
};
export default ApplicationFormFooter;
