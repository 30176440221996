import './CustomFieldsModal.scss';
import { FC, useEffect, useMemo, useState } from 'react';

import { CustomFieldsModalFooter, CustomFieldsPDFTemplate, EmptyStateContainer, InputText, Modal } from 'components';
import { SearchIcon } from 'assets/svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getBillingInvoicesMetafields } from 'store';
import { InvoiceStatus } from 'enums';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { pdf } from '@react-pdf/renderer';

interface IInvoiceData {
  id: string;
  number: string;
  issuedDate: string;
  dueDate: string;
  amountTotal: number;
  dueAmount: number;
  status: InvoiceStatus;
}

interface Props {
  visible: boolean;
  companyName: string | undefined;
  chargifyId: string | undefined;
  subscriptionId: string | undefined; 
  invoiceDetails: IInvoiceData | null;
  onClose: () => void;
}

const formatDate = (value: string): string => dayjs(value).format('MMM D, YYYY');

const CustomFieldsModal: FC<Props> = ({
  visible,
  companyName,
  chargifyId,
  subscriptionId,
  invoiceDetails,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { data = [], isLoading } = useAppSelector(state => state.ussp.getBillingInvoicesMetafieldsRequest);
  useEffect(() => {
    if (visible && typeof subscriptionId === 'string' && invoiceDetails) {
      dispatch(getBillingInvoicesMetafields({ subscriptionId, invoiceId: invoiceDetails.id }));
    }
  }, [visible]);

  const generatePDF = async () => {
    const pdfContent = await pdf(
      <CustomFieldsPDFTemplate
        companyName={companyName}
        chargifyId={chargifyId}
        invoiceDetails={invoiceDetails}
        customFields={data}
      />
    ).toBlob();
    const url = URL.createObjectURL(pdfContent);
    // open in new tab
    // window.open(url, '_blank');
    // download
    const link = document.createElement('a');
    link.href = url;
    link.download = `invoice_${invoiceDetails?.id}_custom_fields.pdf`;
    document.body.appendChild(link);
    link.click();
  };
  
  const filteredMetafields = useMemo(() => data.filter(({ name, value }: any) => {
    const formatedSearchQuery = searchQuery.trim().toLowerCase();
    const formatedName = name?.trim().toLowerCase();
    const formatedValue = value?.trim().toLowerCase();

    const isMatchName = formatedName.includes(formatedSearchQuery);
    const isMatchValue = formatedValue.includes(formatedSearchQuery);

    return isMatchName || isMatchValue;
  }), [searchQuery, data]);

  
  return (
    <Modal
      classes="сustom-fields-modal"
      title="Subscription Details"
      visible={visible}
      footer={
        <CustomFieldsModalFooter
          onSuccess={onClose}
          showDownload={Boolean(!isLoading && data.length)}
          onDownload={generatePDF}
        />}
      onCancel={onClose}
      width={535}
    >
      <div className="block-info rel-p-16 rel-m-b-24">
        <div className="block-info__content disabled flex flex-justify-between flex-align-center rel-flex-gap-16">
          <div className="invoice-date">Date of invoice: {formatDate(invoiceDetails?.issuedDate || '')}</div>
          <div className="invoice-name">{invoiceDetails?.number}</div>
        </div>
      </div>
      {(isLoading && (
        <>
          <div className="custom-field-item">
            <Skeleton containerClassName="app-card__id" count={2} height="100%" />
          </div>
          <div className="custom-field-item">
            <Skeleton containerClassName="app-card__id" count={2} height="100%" />
          </div>
        </>
      )) || (!data?.length && (
        <>
          <EmptyStateContainer
            title="There are no fields"
            description="No fields found"
          />
        </>
      )) || (
        <>
          <InputText
            value={searchQuery}
            classes="rel-m-b-8 rel-m-t-8"
            placeholder="Search..."
            theme="grey"
            icon={SearchIcon}
            onChange={setSearchQuery}
          />
          {filteredMetafields.map((field: any) => (
            <div className="custom-field-item">
              <span>{field.name}: </span> {field.value}
            </div>
          ))}
          {searchQuery && !filteredMetafields.length && (
            <EmptyStateContainer
              title="There are no fields"
              description={`No fields matched your search: "${searchQuery}"`}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default CustomFieldsModal;