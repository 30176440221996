import './SecureWorkspace.scss';
import { FC, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  addProductsToOrder,
  setAssignProducts,
  addUserAssignment,
  deleteUserAssignment,
} from 'store';
import { ADDITIONAL_SERVICES_PRODUCTS, EMAIL_PATTERN, PHONE_PATTERN_SIMPLE, PRODUCT_CHARGIFY_TITLES } from 'consts';
import { OrderNavigation, ProductChargify, UserRole } from 'enums';
import { IAssignProductItem, IOtherProduct } from 'interfaces';
import { useGetProductsListWithPricesFormated, usePriceHandler, useGetTotalPriceByStep, notify, useSimpleFormNavigate } from 'helpers';

import { InfoCircleIcon } from 'assets/svg';
import SentinelOneLogoImage from 'assets/images/sentinel-one-logo.png';
import TrugridLogoImage from 'assets/images/trugrid-logo.png';
import GuardicoreImage from 'assets/images/guardicore-logo.png';
import SSWLogo from 'assets/images/sww-logo-new.png';

import { Navigation, InputText, TotalPrice, Modal, Logo } from 'components';
import SecureAddUsersModalFooter from './components/SecureAddUsersModalFooter/SecureAddUsersModalFooter';
import AddUsersTableHeadRow from './components/AddUsersTableHeadRow/AddUsersTableHeadRow';
import AddUsersTableBodyRow from './components/AddUsersTableBodyRow/AddUsersTableBodyRow';
import SecureAssignAppModalFooter from './components/SecureAssignAppModalFooter/SecureAssignAppModalFooter';
import AssignAppTableHeadRow from './components/AssignAppTableHeadRow/AssignAppTableHeadRow';
import AssignAppTableBodyRow from './components/AssignAppTableBodyRow/AssignAppTableBodyRow';
import SecureWorkspaceDescription from './components/SecureWorkspaceDescription/SecureWorkspaceDescription';

interface IAssignProductsForm {
  assignProducts: IAssignProductItem[];
}

const generateAssignProducts = (quantity: number, users: any[], existedProducts: ProductChargify[]) => {
  return (new Array(quantity)).fill(null).map((el, index) => {
    const products = users?.[index]?.products?.filter((el: ProductChargify) => existedProducts.includes(el));
    return {
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        // firstName: 'Adsds', // FOR TEST
        // lastName: 'Dfsfd', // FOR TEST
        // email: 'aaa@aaa.com', // FOR TEST
        // phone: '+1 (545) 434-4343', // FOR TEST
        mfaStatus: index === 0,
        userRole: index === 0 ? UserRole.Admin : UserRole.Standard,
        ...(users?.[index]?.userInfo || {}),
      },
      products: products?.length > 0 ? products : [],
    };
  });
};

const DEFAULT_PRODUCTS: ProductChargify[] = [
  ProductChargify.SHServerUserHosting,
  ProductChargify.SSW,
];

const SecureWorkspace: FC = () => {
  const loadTime = useRef(0);
  const dispatch = useAppDispatch();
  const hostTotalQuantity = useAppSelector(state => state.order.hostProduct.quantity);
  const assignProductsState = useAppSelector(state => state.order.assignProducts);

  const otherProductsInfo: { [key: string]: IOtherProduct } = useAppSelector(
    state => state.products.otherProductsInfo,
  );
  const otherProducts = useAppSelector(state => state.order.otherProducts || []);

  const excludedSelectProducts = useMemo(() => {
    return [
      ...DEFAULT_PRODUCTS,
      ...ADDITIONAL_SERVICES_PRODUCTS,
      ...otherProducts.reduce((acc, { name }) => {
        if (otherProductsInfo[name].isSubscriptionProduct) {
          acc.push(name);
        }

        return acc;
      }, [] as string[]),
    ];
  }, [otherProducts]);

  const productsList = useGetProductsListWithPricesFormated({ excluded: excludedSelectProducts });
  const defaultAssignUsers = useMemo(() => {
    const formatedProductsList = productsList.map(el => el.name);
    return generateAssignProducts(+hostTotalQuantity, assignProductsState, formatedProductsList);
  }, []);
  const {
    control,
    register,
    trigger,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isValid, touchedFields },
  } = useForm<IAssignProductsForm>({
    mode: 'all',
    defaultValues: { assignProducts: defaultAssignUsers },
  });

  useEffect(() => {
    loadTime.current = new Date().getTime();
  }, []);

  const [notUniquePhoneIndexes, setNotUniquePhoneIndexes] = useState<number[]>([]);
  const [notUniqueEmailIndexes, setNotUniqueEmailIndexes] = useState<number[]>([]);

  const { getProductPrice } = usePriceHandler();
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const selectedProductsList = useMemo(() => {
    return getValues().assignProducts.reduce((acc, { products }) => {
      products.forEach((product) => {
        const existedProductIndex = acc.findIndex((existedProduct) => existedProduct.name === product);
        if (existedProductIndex >= 0) {
          acc[existedProductIndex].quantity += 1;
        } else {
          acc.push({ name: product, quantity: 1 });
        }
      });
      return acc;
    }, [] as { name: ProductChargify; quantity: number }[]);
  }, [watch()]);

  const filledProducts = useMemo(() => {
    return productsList.reduce((acc, { name, quantity }) => {
      const hasMaxSelectedProducts = selectedProductsList.some(el => el.name === name && +el.quantity >= +quantity);
      return { ...acc, [name]: hasMaxSelectedProducts };
    }, {} as { [key: string]: boolean });
  }, [productsList, selectedProductsList]);

  const unassignedProducts = useMemo(() => {
    return productsList.reduce((acc, { name, quantity }) => {
      const selectedProduct = selectedProductsList.find(el => el.name === name);
      const selectedProductQuantity = selectedProduct ? +selectedProduct.quantity : 0;
      if (selectedProductQuantity < +quantity) {
        acc.push({ name, quantity: +quantity - selectedProductQuantity });
      }
      return acc;

    }, [] as { name: ProductChargify; quantity: number }[]);
  }, [productsList, selectedProductsList]);

  const quantity = useMemo(() => {
    const { assignProducts } = getValues();
    return {
      total: assignProducts.length,
      ...(assignProducts.reduce((total, { userInfo }) => {
        total.mfa += userInfo.mfaStatus ? 1 : 0;
        total.admin += userInfo.userRole === UserRole.Admin ? 1 : 0;
        total.standard += userInfo.userRole === UserRole.Standard ? 1 : 0;
        return total;
      }, { mfa: 0, admin: 0, standard: 0 })),
    };
  }, [watch()]);

  const isUsersDataFilled = useMemo(() => {
    return assignProductsState.length >= +hostTotalQuantity;
  }, []);

  const STEP_PRODUCTS = useMemo(() => {
    return [
      { name: ProductChargify.SSW, quantity: '1' },
    ];
  }, [watch()]);

  const { totalPrice } = useGetTotalPriceByStep({ currentStep: OrderNavigation.SecureWorkspace, stepProducts: STEP_PRODUCTS });

  const isValidFirstUser = useMemo(() => {
    const { firstName, lastName, email, phone } = getValues()?.assignProducts?.[0]?.userInfo || {};
    const {
      firstName: firstNameInvalid,
      lastName: lastNameInvalid,
      email: emailInvalid,
      phone: phoneInvalid,
    } = errors?.assignProducts?.[0]?.userInfo || {};

    return !!firstName && !!lastName && !!email && !!phone
      && !firstNameInvalid && !lastNameInvalid && !emailInvalid && !phoneInvalid;
  }, [watch()]);

  const [isOpenAddUsersModal, setOpenAddUsersModal] = useState<boolean>(false);
  const [isOpenAssignAppModal, setOpenAssignAppModal] = useState<boolean>(false);
  const [searchUsersQuery, setSearchUsersQuery] = useState<string>('');
  const [activeAppSelectIndex, setActiveAppSelectIndex] = useState<null | number>(null);

  const showAddUsersModal = () => setOpenAddUsersModal(true);
  const hideAddUsersModal = () => setOpenAddUsersModal(false);
  const showAssignAppModal = () => setOpenAssignAppModal(true);
  const hideAssignAppModal = () => setOpenAssignAppModal(false);

  const onActiveAppSelect = (state: boolean, index: number) => {
    setActiveAppSelectIndex(state ? index : null);
  };

  const isUserMatchSearchParams = useCallback(userInfo => {
    const formatedSearchQuery = searchUsersQuery.trim().toLowerCase();
    const formatedEmail = userInfo.email.trim().toLowerCase();
    const formatedFirstName = userInfo.firstName.trim().toLowerCase();
    const formatedLastName = userInfo.lastName.trim().toLowerCase();
    const isMatchEmail = formatedEmail.includes(formatedSearchQuery);
    const isMatchName = `${formatedFirstName} ${formatedLastName}`.includes(formatedSearchQuery)
    || `${formatedLastName} ${formatedFirstName}`.includes(formatedSearchQuery);

    return isMatchEmail || isMatchName;
  }, [searchUsersQuery, watch()]);

  const filtredUsersLength = useMemo(() => getValues().assignProducts
    .filter(({ userInfo }) => isUserMatchSearchParams(userInfo)).length, [searchUsersQuery, watch()]);

  const isNextAllowed = useMemo(() => {
    return isValid && Object.values(filledProducts).every(Boolean);
  }, [isValid, filledProducts]);

  const validatePhone = (userIndex: number) => {
    const values = getValues('assignProducts');
    if (values[userIndex].userInfo.phone !== '') {
      const isUnique = values.every((el: IAssignProductItem, index: number) => {
        return values[userIndex].userInfo.phone.trim().length
          || index === userIndex
          || el.userInfo.phone !== values[userIndex].userInfo.phone;
      });
      if (!isUnique && !notUniquePhoneIndexes.includes(userIndex)) {
        notify.error('You can\'t use the same phone number twice. Please, update it.');

        setNotUniquePhoneIndexes([...notUniquePhoneIndexes, userIndex]);
      } else if (isUnique && notUniquePhoneIndexes.includes(userIndex)) {
        setNotUniquePhoneIndexes(notUniquePhoneIndexes.filter(el => el !== userIndex));
      }
      setTimeout(() => {
        trigger(`assignProducts.${userIndex}.userInfo.phone`);
      }, 0);
    }
  };

  const validateEmail = (userIndex: number) => {
    const values = getValues('assignProducts');
    const isUnique = values.every((el: IAssignProductItem, index: number) => {
      return !values[userIndex].userInfo.email.trim().length
      || index === userIndex
      || el.userInfo.email !== values[userIndex].userInfo.email;
    });
    if (!isUnique && !notUniqueEmailIndexes.includes(userIndex)) {
      notify.error('You can\'t use the same email twice. Please, update it.');
      setNotUniqueEmailIndexes([...notUniqueEmailIndexes, userIndex]);
      setValue(`assignProducts.${userIndex}.userInfo.email`, values[userIndex].userInfo.email, {
        shouldTouch: true,
        shouldValidate: true,
      });
    } else if (isUnique && notUniqueEmailIndexes.includes(userIndex)) {
      setNotUniqueEmailIndexes(notUniqueEmailIndexes.filter(el => el !== userIndex));
    }
    setTimeout(() => {
      trigger(`assignProducts.${userIndex}.userInfo.email`);
    }, 0);
  };

  const addDefaultProductsForUser = (userAssignments: IAssignProductItem[]) => userAssignments
    .map(({ userInfo, products }) => ({
      userInfo,
      products: [
        ...products.filter(el => !DEFAULT_PRODUCTS.includes(el)),
        ProductChargify.SHServerUserHosting,
      ],
    }));

  const simpleFormNavigate = useSimpleFormNavigate();
  const nextHandler: SubmitHandler<IAssignProductsForm> = async ({ assignProducts }) => {
    setIsNextLoading(true);
    try {
      await dispatch(deleteUserAssignment(null)).unwrap();
      const productsToAdd = [
        { name: ProductChargify.SSW, quantity: 1 },
        { name: ProductChargify.Deposit, quantity: Math.floor(totalPrice) },
      ];

      const formatedAssignProducts: IAssignProductItem[] = addDefaultProductsForUser(assignProducts);

      await Promise.all([
        dispatch(addProductsToOrder(productsToAdd)).unwrap(),
        dispatch(addUserAssignment(formatedAssignProducts)).unwrap(),
      ]);
      dispatch(setAssignProducts(formatedAssignProducts));
      hideAssignAppModal();
      simpleFormNavigate(OrderNavigation.Checkout);
    } finally {
      setIsNextLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="Add users"
        width={1202}
        classes="ssw-modal"
        visible={isOpenAddUsersModal}
        onCancel={hideAddUsersModal}
        footer={(
          <SecureAddUsersModalFooter
            {...quantity}
            isNextDisabled={!isValid}
            onNext={() => {
              hideAddUsersModal();
              showAssignAppModal();
            }}
            onCancel={hideAddUsersModal}
          />
        )}
      >
        <table className="secure-workspace-table users-table">
          <thead><AddUsersTableHeadRow /></thead>
          <tbody>
            {getValues().assignProducts.map(({ userInfo }, index) => (
              <AddUsersTableBodyRow
                key={`users-${index}`}
                firstName={userInfo.firstName}
                lastName={userInfo.lastName}
                email={userInfo.email}
                mfaStatus={userInfo.mfaStatus}
                userRole={userInfo.userRole}
                phone={userInfo.phone}
                control={control}
                register={register}
                trigger={trigger}
                errors={errors}
                touchedFields={touchedFields}
                userIndex={index}
                notUniquePhone={notUniquePhoneIndexes.includes(index)}
                notUniqueEmail={notUniqueEmailIndexes.includes(index)}
                onUpdateValue={setValue}
                validatePhone={validatePhone}
                validateEmail={validateEmail}
                activeSelectIndex={activeAppSelectIndex}
                onActiveAppSelect={(value: boolean) => onActiveAppSelect(value, index)} />
            ))}
          </tbody>
        </table>
      </Modal>
      <Modal
        visible={isOpenAssignAppModal}
        width={695}
        title="Assign Applications"
        classes="ssw-modal"
        onOk={handleSubmit(nextHandler)}
        onCancel={() => {
          hideAssignAppModal();
          showAddUsersModal();
          setSearchUsersQuery('');
        }}
        footer={(
          <SecureAssignAppModalFooter
            isNextLoading={isNextLoading}
            isNextDisabled={!isNextAllowed}
            onNext={handleSubmit(nextHandler)}
            onCancel={() => {
              hideAssignAppModal();
              showAddUsersModal();
              setSearchUsersQuery('');
            }}
          />
        )}
      >
        {unassignedProducts.length > 0 && (
          <h2 className="unasigned-app-title rel-m-b-12">Unassigned products</h2>
        )}
        <div className="rel-m-b-12 flex flex-wrap">
          {
            unassignedProducts.map(({ name, quantity }) => (
              <div className="label-card dark" key={`unassigned-product-${name}`}>
                {name} ( {quantity} )
              </div>
            ))
          }

        </div>
        {/* <InputText
          classes="rel-m-b-12"
          value={searchUsersQuery}
          icon={SearchIcon}
          placeholder="Search by email or name..."
          onChange={setSearchUsersQuery}
        /> */}
        {
          !!filtredUsersLength ? (
            <table className="secure-workspace-table app-table">
              <thead><AssignAppTableHeadRow totalCount={filtredUsersLength} /></thead>
              <tbody>
                {getValues().assignProducts.map(({ userInfo }, index) => isUserMatchSearchParams(userInfo) && (
                  <AssignAppTableBodyRow
                    key={`application-${index}`}
                    firstName={userInfo.firstName}
                    lastName={userInfo.lastName}
                    email={userInfo.email}
                    filledProducts={filledProducts}
                    control={control}
                    userIndex={index}
                    activeSelectIndex={activeAppSelectIndex}
                    onActiveAppSelect={(value: boolean) => onActiveAppSelect(value, index)}
                  />
                ))}
              </tbody>
            </table>
          ) : <div className="secure-workspace-not-found">Users not found...</div>
        }
      </Modal>
      <Logo />
      <div className="secure-workspace page">
        <div className="page-left">
          <img id="sw-img" src={SSWLogo} alt="Secure workspace" />
          <div className="icons">
            <img src={SentinelOneLogoImage} alt="Sentinel One" />
            <img src={TrugridLogoImage} alt="Trugrid" />
            <img src={GuardicoreImage} alt="Guardicore" />
          </div>
          <SecureWorkspaceDescription />
          <TotalPrice currentStep={OrderNavigation.SecureWorkspace} stepProducts={STEP_PRODUCTS} />
        </div>
        <div className="page-right">
          <Navigation currentStep={8} />
          <div className="page-right_content">
            <h1>{PRODUCT_CHARGIFY_TITLES[ProductChargify.SSW]}</h1>
            <p className="rel-m-b-16">SSW is required on every Summit server.
              Enter the information of the user that you want admin rights to the server.</p>
            <p className="rel-m-b-24">The user with admin rights has the ability to install applications,
              run software updates, and set file permissions.</p>
            <div className="secure-workspace_license-info rel-m-b-20">
              <div>One Required License <InfoCircleIcon /></div>
              {getProductPrice(ProductChargify.SSW)}
            </div>
            <InputText
              classes="rel-m-b-20"
              label="First name"
              placeholder="e.g. Tom"
              name="assignProducts.0.userInfo.firstName"
              register={register}
              isRequired
              isInvalid={!!errors?.assignProducts?.[0]?.userInfo?.firstName}
            />
            <InputText
              classes="rel-m-b-20"
              label="Last name"
              placeholder="e.g. Riddle"
              name="assignProducts.0.userInfo.lastName"
              register={register}
              isRequired
              isInvalid={!!errors?.assignProducts?.[0]?.userInfo?.lastName}
            />
            <InputText
              classes="rel-m-b-20"
              label="Email"
              placeholder="Enter your email"
              name="assignProducts.0.userInfo.email"
              register={register}
              validationRules={{
                setValueAs: v => v.trim(),
                pattern: EMAIL_PATTERN,
              }}
              isRequired
              isInvalid={!!(errors?.assignProducts?.[0]?.userInfo?.email
                && touchedFields?.assignProducts?.[0]?.userInfo?.email)}
            />
            <InputText
              classes="rel-m-b-56"
              label="Phone number"
              placeholder="+1 (000) 000-0000"
              name="assignProducts.0.userInfo.phone"
              validationRules={{ pattern: PHONE_PATTERN_SIMPLE, maxLength: 15 }}
              register={register}
              isRequired
              isInvalid={!!errors?.assignProducts?.[0]?.userInfo?.phone}
            />
            {/* <Controller
              name="assignProducts.0.userInfo.phone"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: PHONE_PATTERN }}
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched },
              }) => (
                <NumberFormat
                  format={PHONE_FORMAT}
                  mask={MASK_SYMBOL}
                  customInput={InputText}
                  allowEmptyFormatting
                  classes="rel-m-b-56"
                  label="Phone number"
                  placeholder="+1 (000) 000-0000"
                  value={value}
                  isInvalid={isTouched && invalid}
                  isReturnEvent
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            /> */}
            <button
              type="button"
              className="btn primary new-style full-width"
              disabled={!isValidFirstUser}
              onClick={showAddUsersModal}>
              {isUsersDataFilled ? 'Edit' : 'Add'} Users &amp; Assign Products</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecureWorkspace;