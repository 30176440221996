import './CheckboxTag.scss';
import { FC, SyntheticEvent, FormEvent, ReactNode, useMemo } from 'react';
import {
  CheckIcon,
  MinusIcon,
} from 'assets/svg';
import cn from 'classnames';
import { isBoolean } from 'lodash';

interface Props {
  isChecked: boolean;
  onlyClicked?: boolean;
  children?: ReactNode;
  isIndeterminate?: boolean;
  iconRightSmOnly?: boolean;
  dataTestId?: string;
  iconLeftSmOnly?: boolean;
  classes?: string;
  label?: string;
  icon?: FC;
  iconRight?: FC;
  isDisabled?: boolean;
  onChange?: (e: SyntheticEvent) => void;
}

const CheckboxTag: FC<Props> = ({
  onlyClicked,
  isChecked,
  isIndeterminate,
  children,
  classes,
  label,
  dataTestId,
  icon: Icon,
  iconRight: IconRight,
  iconRightSmOnly,
  iconLeftSmOnly,
  isDisabled = false,
  onChange,
}) => {
  const handleKeyDown = (event: any) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      onChange?.(event);
    }
  };
  const onChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    onChange?.(e);
  };
  const checkedValue = useMemo(() => {
    const indeterminateValue = isBoolean(isIndeterminate) ? isIndeterminate : false;
    return isChecked || indeterminateValue;
  }, [isChecked, isIndeterminate]);

  const Element = onlyClicked ? 'div' : 'label';

  return (
    <Element
      data-test-id={dataTestId}
      tabIndex={isDisabled ? -1 : 0}
      className={cn('checkbox-tag', classes, {
        checked: checkedValue,
        disabled: isDisabled,
      })}
      onClick={(e) => onlyClicked ? onChange?.(e) : undefined}
      onKeyDown={handleKeyDown}
    >
      {onlyClicked ? null : (
        <input
          type="checkbox"
          aria-checked={checkedValue}
          checked={checkedValue}
          disabled={isDisabled}
          onChange={onChangeHandler}
        />
      )}
      {Icon && <div className={cn('checkbox-tag-icon', { 'sm-only': iconLeftSmOnly })}><Icon/></div> }
      {(children || label) && (
        <div className="checkbox-label">{ children || label }</div>
      )}
      {IconRight && <div className={cn('checkbox-tag-icon-right', { 'sm-only': iconRightSmOnly })}><IconRight/></div> }
    </Element>
  );
};

export default CheckboxTag;