import './Button.scss';
import Lottie from 'lottie-react';
import cn from 'classnames';
import loaderAnimation from 'assets/lotties/button-loader.json';

const Button = ({
  absoluteStyles = false,
  buttonType = 'button',
  type = 'primary',
  isNewStyle,
  style,
  isDisabled,
  onClick,
  isLoading,
  xVersion,
  classes,
  dataTestId,
  size,
  children,
}: any) => {
  return (
    <button
      data-test-id={dataTestId}
      type={buttonType}
      className={cn(`btn ${type || ''} ${size || ''} btn-special`, { 'btn-special-loading': isLoading, 'new-style': isNewStyle && !xVersion, 'relative-units': !absoluteStyles, xVersion }, classes)}
      style={style}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading && (<div className="btn-loader"><Lottie animationData={loaderAnimation} loop /> </div>)}
      <span className="btn-text">{children}</span>
    </button>
  );
};

export default Button;