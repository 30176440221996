import { InfoCircleIcon, SearchIcon } from 'assets/svg';
import { Checkbox, EmptyStateContainer, InputText, StepModal } from 'components';
import { notify } from 'helpers';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { removeUserFromApplication } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProductData {
  id: string;
  title: string;
  name: string;
  productVersion: string;
  licenceCode: string;
  licenseCode: string;
  years: string[];
  productCode: string;
  serialNumber: string;
  price: string;
  productId: string;
  year: string;
  users: any[];
  groupName: string;
}

interface IUser {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email: string;
}

interface Props {
  visible: boolean;
  onCancel: () => void;
  productData: IProductData;
  onSuccess: () => void;
  isAdminPortal?: boolean;
}

const RemoveUserFromAppModal: FC<Props> = ({
  visible,
  onCancel,
  productData,
  onSuccess,
  isAdminPortal = false,
}) => {
  const { subscriptionId } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.ussp.removeUserFromApplicationRequest);

  const { id, name, users }: IProductData = productData;

  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredUsers = useMemo(() => users.filter(({ firstName, lastName, username, email }: IUser) => {
    const formatedSearchQuery = searchQuery.trim().toLowerCase();

    const formatedFirstName = firstName?.trim().toLowerCase();
    const formatedLastName = lastName?.trim().toLowerCase();
    const formatedUsername = username?.trim().toLowerCase();

    const formatedEmail = email.trim().toLowerCase();

    const isMatchFullName = formatedFirstName && formatedLastName
      && (`${formatedFirstName} ${formatedLastName}`.includes(formatedSearchQuery)
        || `${formatedLastName} ${formatedFirstName}`.includes(formatedSearchQuery));
    const isMatchUsername = formatedUsername && formatedEmail.includes(formatedUsername);
    const isMatchEmail = formatedEmail.includes(formatedSearchQuery);

    return isMatchFullName || isMatchUsername || isMatchEmail;
  }), [searchQuery, users]);
  
  // const changableApplications = useMemo(
  //   () => applications.filter((a: any) => !a.isReadonly),
  //   [applications]
  // );

  const [usersToRemove, setUsersToRemove] = useState<string[]>(() => {
    if (users.length > 1) {
      return [];
    }
    return [users[0].id];
  });

  const toggleApplications = (value: boolean, id: string): void => {
    const ids: string[] = value ? [...usersToRemove, id] : usersToRemove.filter(el => el !== id);
    setUsersToRemove(ids);
  };

  const onRemoveApplications = async () => {
    if (typeof subscriptionId === 'string') {
      await dispatch(removeUserFromApplication({
        subscriptionId,
        userIds: usersToRemove,
        isAdminPortal,
        applicationId: id,
      })).unwrap();
      onSuccess();
      onCancel();
      notify.success('Success', 'This user(s) has been unassign');
    }
  };

  return (
    <StepModal
      visible={visible}
      closable
      config={[{
        content: (
          <div className="dashboard-accounts-remove-application">
            <div className="info">
              {users.length > 1
                ? 'Select the users from the list below to unassign from the application.'
                : 'User listed below will be unassign from the application.'
              }
            </div>
            {users.length > 1 ? (
              <>
                <InputText
                  value={searchQuery}
                  classes="rel-m-b-16 rel-m-t-16"
                  placeholder="Search by Email or Name..."
                  theme="grey"
                  icon={SearchIcon}
                  onChange={setSearchQuery}
                />
                <ul className="list-unstyled">
                  {filteredUsers.map(({ id, firstName, lastName, username }: IUser) => (
                    <li key={id}>
                      <Checkbox
                        label={username || `${firstName} ${lastName}`}
                        isChecked={usersToRemove.includes(id)}
                        onChange={(e) => toggleApplications((e.target as HTMLInputElement).checked, id)}
                      />
                    </li>
                  ))}
                </ul>
                {filteredUsers.length <= 0 && (
                  <EmptyStateContainer
                    title="There are no users"
                    description={`No users matched your search: "${searchQuery}"`}
                    classes="flex-1"
                  />
                )}
              </>
            ) : (
              <ul className="list-unstyled">
                <li>{users[0].username || `${users[0].firstName} ${users[0].lastName}`}</li>
              </ul>
            )}
            <div className="block-info relative-units remove-application-info">
              <InfoCircleIcon />
              <div className="block-info__content remove-application-info__content">
                If you choose to click <span>Unassign</span>, selected user(s) will no longer have access to <span>{name}</span>.
              </div>
            </div>
          </div>
        ),
        title: 'Unassign User(s) From Application',
        width: 535,
        okText: 'Yes, Unassign',
        isOkDisabled: !usersToRemove.length,
      }, {
        title: 'Are you sure?',
        description: 'All associated user data will be deleted, but you will be able to subscribe them to this application again.',
        okText: 'Yes',
        isOkLoading: isLoading,
      }]}
      onFinish={onRemoveApplications}
      onCancel={onCancel}
    />
  );
};

export default RemoveUserFromAppModal;