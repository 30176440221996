import './DashboardHome.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getDashboardData } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { usePriceHandler, setupSignalR } from 'helpers';

import { ArrowRightIcon, InfoCircleOutlineIcon, WalletIcon, WorkIcon, WorkOutlineIcon } from 'assets/svg';

import { Alert, Error, StatisticsGraph, Tooltip } from 'components';
import OverviewInfo from './components/OverviewInfo/OverviewInfo';
import { CountryCode } from 'enums';

interface ICompany {
  activeUsers: number;
  companyName: string;
  chargifyId: string;
  subscriptionId: string;
  nextBillingDate: string;
  totalApplications: number;
  totalMonthlyFee: number;
  nextBillingAmountInCents: number;
  isMember: boolean | null;
  manageable: boolean;
  products: { name: string; count: number }[];
}

interface IDashboardData {
  totalMonthlyFee: number;
  totalNextBillingAmountInCents: number;
  companies: ICompany[];
}

const DashboardHome: FC = () => {
  const dispatch = useAppDispatch();
  const currentCountry = useAppSelector((state) => state.ussp.user?.country || CountryCode.USA);
  const { totalMonthlyFee, totalNextBillingAmountInCents, companies = []}: IDashboardData = useAppSelector(state =>
    state.ussp.getDashboardDataRequest.data || {});
  const { isLoading, error } = useAppSelector(state => state.ussp.getDashboardDataRequest);
  const { formatPrice } = usePriceHandler();

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);

  const fetchData = async () => {
    await dispatch(getDashboardData({
      _disableErrorHandler: true,
      _isBackground: isBackgroundUpdate,
    })).unwrap();
  };

  useEffect(() => {
    const connection = setupSignalR('/dashboard');
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('Signal', async (message: any) => {
            setIsBackgroundUpdate(true);
            await fetchData();
            setIsBackgroundUpdate(false);
            console.log('DASHBOARD:SIGNAL', message);
          });
        })
        .catch((e: any) => console.log('Connection failed [dashboard]: ', e));
    }

    fetchData();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);



  const totalNextBillingAmountFormated = useMemo(() => formatPrice((totalNextBillingAmountInCents ?? 0) / 100, currentCountry), [
    totalNextBillingAmountInCents,
    currentCountry,
  ]);

  const [currentCompanyIndex, setcurrentCompanyIndex] = useState<number>(0);

  if (error) {
    return <Error code={500} />;
  }

  return (
    <div className="dashboard-home ussp-page">
      <section className="dashboard-home__header ussp-page__header">
        <h1>Welcome to your Summit Hosting Portal</h1>
      </section>
      <section className="dashboard-home__sidebar">
        <div className="dashboard-home__total-fee total-fee rel-p-x-32 rel-p-t-32 rel-p-b-28">
          <p className="total-fee__label rel-m-b-12">Total next billing</p>
          <div className="total-fee__content flex flex-align-center rel-flex-gap-8">
            <WalletIcon />
            {isShowLoader ? (
              <Skeleton containerClassName="total-fee__skeleton" height="100%" />
            ) : totalNextBillingAmountFormated}
          </div>
        </div>
        {
          isShowLoader ? (
            <div className="dashboard-home__app-card app-card flex flex-align-center rel-flex-gap-16 rel-p-x-32 rel-p-y-24">
              <Skeleton containerClassName="app-card__icon-skeleton" height="100%" />
              <div className="app-card__content">
                <Skeleton containerClassName="app-card__name" height="100%" width="70%" />
                <Skeleton containerClassName="app-card__id" height="100%" />
              </div>
            </div>
          ) : companies.map(({ chargifyId, companyName, isMember }, index) => (
            <div
              className={cn(
                'dashboard-home__app-card app-card flex rel-flex-gap-16 rel-p-x-32 rel-p-y-24',
                { active: currentCompanyIndex === index },
              )}
              key={`account-app-${chargifyId}`}
              onClick={() => setcurrentCompanyIndex(index)}>
              {!isShowLoader && !companies[index]?.manageable && (<>
                <div className="app-card__error_icon_wrapper">
                  <Tooltip
                    classes="app-card__error_icon_wrapper__tooltip"
                    content="Your subscription is non-manageable and read-only. Please contact our customer support team if you have any questions or need assistance."
                    placement="top-start"
                  >
                    <InfoCircleOutlineIcon />
                  </Tooltip>
                </div>
              </>)}
              <div className="app-card__icon-wrapper">
                {currentCompanyIndex === index ? <WorkIcon /> : <WorkOutlineIcon />}
              </div>
              <div className="app-card__content">
                <div className="app-card__name">{companyName}</div>
                <div className="app-card__id">ID: {chargifyId}</div>
                {isMember && <div className="tag rel-m-t-8">Member</div>}
                {isMember === false && <div className="tag secondary rel-m-t-8">Primary</div>}
              </div>
              <ArrowRightIcon className="app-card__arrow" />
            </div>
          ))
        }
      </section>
      <section className="dashboard-home__main">
        {isShowLoader
          ? <Skeleton containerClassName="d-block rel-m-b-16" width="50%" />
          : <h2 className="rel-m-b-16">Overview {companies[currentCompanyIndex]?.companyName}</h2>
        }
        {!isShowLoader && !companies[currentCompanyIndex]?.manageable && (
          <Alert
            classes="rel-m-b-8"
            CustomIcon={InfoCircleOutlineIcon}
            type="error"
            theme="light"
            textFontWeight={400}
            message={
              <div>
                <span className="fw-500">Your subscription is non-manageable and read-only.</span> Please contact our customer support team if you have any questions or need assistance.
              </div>
            }
            fullWidth
          />
        )}
        <OverviewInfo
          activeUsers={companies[currentCompanyIndex]?.activeUsers}
          id={companies[currentCompanyIndex]?.subscriptionId}
          nextBillingDate={companies[currentCompanyIndex]?.nextBillingDate}
          totalApps={companies[currentCompanyIndex]?.totalApplications}
          totalMonthlyFee={companies[currentCompanyIndex]?.totalMonthlyFee}
          nextBillingAmountInCents={companies[currentCompanyIndex]?.nextBillingAmountInCents}
          isLoading={isShowLoader} />
        <StatisticsGraph data={companies[currentCompanyIndex]?.products || []} isLoading={isShowLoader}/>
      </section>
    </div>
  );
};

export default DashboardHome;
