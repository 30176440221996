import { FC } from 'react';
// import { ImportExportIcon } from 'assets/svg';

interface Props {
  isManageable: boolean;
}

const BillingInvoicesTableHeadRow: FC<Props> = ({ isManageable }) => (
  <tr className="billings-table-head-row">
    <th>NUMBER</th>
    <th>
      ISSUED
      {/* <ImportExportIcon /> */}
    </th>
    <th>
      DUE DATE
      {/* <ImportExportIcon /> */}
    </th>
    <th>TOTAL AMOUNT</th>
    <th>AMOUNT DUE</th>
    <th>STATUS</th>
    {isManageable ? null : <th>SUBSCRIPTION DETAILS</th>}
    <th>ACTIONS</th>
  </tr>
);

export default BillingInvoicesTableHeadRow;
