import './TableFilter.scss';
import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import _debounce from 'lodash/debounce';

import { useUpdateEffect } from 'helpers';

import { FilterIcon } from 'assets/svg';

import { Checkbox, Popover } from 'components';

interface IOption {
  label: string;
  value: any;
}

interface Props {
  values?: string[];
  children?: JSX.Element | string;
  label?: string;
  options: string[] | IOption[];
  showAlways?: boolean;
  onChange: (filters: string[]) => void;
}

const TableFilter: FC<Props> = ({
  children,
  values,
  label,
  options,
  showAlways,
  onChange,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const getLabel = (el: string | IOption): string => {
    return typeof el === 'string' ? el : el.label;
  };
  const getValue = (el: string | IOption): string => {
    return typeof el === 'string' ? el : el.value;
  };

  const toggleFilterValues = (el: string | IOption) => {
    const value = getValue(el);
    const selectedList = values || selectedFilters;
    const isExist = selectedList.some(el => el === value);
    if (isExist) {
      setSelectedFilters((selectedList).filter((el) => el !== value));
    } else {
      setSelectedFilters([...selectedList, value]);
    }
  };

  const debouncedOnChange = useCallback(_debounce(onChange, 500), [onChange]);

  useUpdateEffect(() => {
    debouncedOnChange(selectedFilters);
  }, [selectedFilters]);

  return options.length > 1 || showAlways ? (
    <div className="table-filter">
      <Popover
        prefix={children || label}
        trigger={<FilterIcon className={cn(
          'table-filter__icon',
          { active: (values || selectedFilters).length },
        )} />}
        placement="bottom-start"
      >
        {
          options.map(el => (
            <Checkbox
              key={`table-filter-option-${getLabel(el)}`}
              isChecked={(values || selectedFilters).includes(getValue(el))}
              classes="rel-m-t-16 rel-m-b-16 rel-m-x-16"
              theme="dark"
              onChange={() => toggleFilterValues(el)}
            >
              {getLabel(el)}
            </Checkbox>
          ))
        }
      </Popover>
    </div>
  ) : <>{children || label}</>;
};

export default TableFilter;