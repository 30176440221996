import React, { FC } from 'react';
import './SetupUsersHeadRow.scss';
import { ImportExportIcon } from 'assets/svg';

const SetupUsersHeadRow: FC = () => (
  <div className="table-row header-row">
    <div className="table-cell"></div>
    <div className="table-cell">First Name</div>
    <div className="table-cell">Last Name</div>
    <div className="table-cell">Phone Number</div>
    <div className="table-cell">Email</div>
    <div className="table-cell">Applications</div>
    <div className="table-cell web-cell">MFA Status</div>
    <div className="table-cell mobile-cell">MFA Status</div>
    <div className="table-cell">Roles</div>
  </div>
);

export default SetupUsersHeadRow;
