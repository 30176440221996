import { EmptyStateContainer, InputText, RadioButton } from 'components';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  setApplicationLicenceCode,
  setApplicationComponentId,
  setApplicationProductCode,
  setApplicationSerialNumber,
  addNewApplicationToCart,
  getUsspCartData,
  setApplicationPrice,
  getProductPrice,
} from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import ApplicationFormFooter from '../ApplicationFormFooter/ApplicationFormFooter';
import { notify, usePriceHandler } from 'helpers';
import './OtherApplication.scss';
import { SearchIcon } from 'assets/svg';
import { useParams } from 'react-router-dom';
import { IDashboardUser } from 'interfaces';
interface QuickbooksPurchaseLicenseForm {
  componentId: number;
  year: string;
  productCode: string;
  serialNumber: string;
  licenceCode: string;
  additionalInformation: string;
  price: string;
}

interface Props {
  isPerServer: boolean;
  onCancel: (reset?: boolean) => void;
  changeCurrentStep: React.Dispatch<React.SetStateAction<any>>;
}

const OtherApplication: FC<Props> = ({ onCancel, changeCurrentStep, isPerServer }) => {
  const { subscriptionId } = useParams();
  const otherProducts = useAppSelector(state => state.products.otherProducts || []);
  const application = useAppSelector(state => state.products.newApplication);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { isLoading: isAddLoading } = useAppSelector(state => state.products.addNewApplicationToCartRequest);
  const user = useAppSelector<IDashboardUser | null>(state => state.ussp.user);
  const { formatPrice } = usePriceHandler();

  const dispatch = useAppDispatch();

  const filteredOtherProducts = useMemo(
    () => otherProducts
      .filter(({ relatedToSubscription }: any) => relatedToSubscription === isPerServer)
      .filter(({ version }: any) => {
        const formatedSearchQuery = searchQuery.trim().toLowerCase();
        const formattedVersion = version.trim().toLowerCase();
        return formattedVersion.includes(formatedSearchQuery);
      }),
    [searchQuery, otherProducts],
  );

  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    resetField,
    formState: { errors, isValid, touchedFields },
  } = useForm<any>({
    defaultValues: {
      componentId: application.componentId,
      // year: application.year,
      licenceCode: application.licenceCode,
      productCode: application.productCode,
      serialNumber: application.serialNumber,
    },
  });
  const selectedComponentId = useMemo(() => getValues('componentId'), [watch()]);
  const isSelectedComponentIsServer = useCallback(
    (componentId) => otherProducts.find((p: any) => p.componentId === componentId && p.relatedToSubscription),
    [otherProducts],
  );

  const resetSelectedProduct = () => {
    dispatch(setApplicationPrice('0'));
    dispatch(setApplicationComponentId(undefined));
  };

  const handleGetProductPrice = async (componentId: number) => {
    const { price }: { price: string } = await dispatch(getProductPrice(componentId)).unwrap();
    dispatch(setApplicationPrice(price));
  };
  
  useEffect(() => {
    if (selectedComponentId) {
      resetField('licenceCode');
      resetField('productCode');
      resetField('serialNumber');
    }
  }, [selectedComponentId]);

  const onSubmit: SubmitHandler<QuickbooksPurchaseLicenseForm> = async (data) => {
    if (data.price) {
      dispatch(setApplicationPrice(data.price));
    } else {
      handleGetProductPrice(data.componentId);
    }
    dispatch(setApplicationComponentId(data.componentId));
    dispatch(setApplicationLicenceCode(data.licenceCode));
    dispatch(setApplicationProductCode(data.productCode));
    dispatch(setApplicationSerialNumber(data.serialNumber));
    // dispatch(setApplicationAdditionalInformation(data.additionalInformation));
    // dispatch(setApplicationYear(data.year));
    if (isSelectedComponentIsServer(selectedComponentId)) {
      if (typeof subscriptionId === 'string') {
        await dispatch(addNewApplicationToCart({ subscriptionId })).unwrap();
        dispatch(getUsspCartData({ _isBackground: true })).unwrap();
        notify.success('Product successfully added to cart', 'Now you can proceed to payment');
        resetSelectedProduct();
        onCancel(true);
      }

    } else {
      changeCurrentStep(2);
    }
  };

  return (
    <>
      <div>
        <form className="other-application-new" onSubmit={handleSubmit(onSubmit)}>
          <InputText
            value={searchQuery}
            classes="rel-m-b-16 rel-m-t-8"
            placeholder="Search by Name"
            theme="grey"
            icon={SearchIcon}
            onChange={setSearchQuery}
          />
          {filteredOtherProducts
            .map(({ componentId, version, price }: any) => {
              return (
                <Controller
                  key={`other-product-${componentId}`}
                  name={'componentId'}
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                  }) => (
                    <>
                      <RadioButton
                        value={componentId}
                        label={version}
                        rightLabel={formatPrice(price, user?.country)}
                        isChecked={value === componentId}
                        onChange={() => {
                          dispatch(setApplicationPrice(price));
                          dispatch(setApplicationComponentId(componentId));
                          onChange(componentId);
                        }}
                      />
                      {value === componentId && (
                        <div className="rel-p-t-8">
                          <InputText
                            classes="rel-m-b-24"
                            label="Enter product code"
                            placeholder="Enter product code"
                            name={'productCode'}
                            register={register}
                            isInvalid={!touchedFields.productCode && !!errors.productCode}
                          />
                          <InputText
                            classes="rel-m-b-24"
                            label="Enter serial number"
                            placeholder="Enter serial number"
                            name={'serialNumber'}
                            register={register}
                            isInvalid={!touchedFields.serialNumber && !!errors.serialNumber}
                          />
                          <InputText
                            classes="rel-m-b-24"
                            label="Enter license code"
                            placeholder="Enter license code"
                            name={'licenceCode'}
                            register={register}
                            isInvalid={!!touchedFields.licenceCode && !!errors.licenseCode}
                          />
                        </div>
                      )}
                    </>
                  )}
                />
              );
            })
          }
          <ApplicationFormFooter
            onCancel={() => {
              resetSelectedProduct();
              onCancel();
            }}
            submitForm
            isOkDisabled={!isValid}
            isOkLoading={isAddLoading}
            cancelText="Back"
            perServer={isPerServer}
            showPrice={isPerServer}
            okText={isSelectedComponentIsServer(selectedComponentId)
              ? 'Add'
              : 'Next'
            }
          />
        </form>
      </div>
      {filteredOtherProducts.length <= 0 && (
        <div className="empty-wrapper-other-products">
          <EmptyStateContainer
            title="There are no applications"
            description={`No applications matched your search: "${searchQuery}"`}
          />
        </div>
      )}
    </>
  );
};

export default OtherApplication;
