import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { usePriceHandler } from './priceHandler';
import { ProductChargify, MsOfficeLicenseType, OrderNavigation, DiscountType, SignUpNavigation } from 'enums';
import { ICouponValues, IProduct, IOtherProduct } from 'interfaces';

const INCLUDE_COUPON_STEPS = [
  OrderNavigation.Checkout,
];

const INCLUDE_COUPON_STEPS_SIGNUP = [
  SignUpNavigation.Summary,
];

interface Arguments {
  currentStep: OrderNavigation;
  stepProducts?: IProduct[] | IOtherProduct[];
}
interface ArgumentsSignUpSteps {
  currentStep: SignUpNavigation;
  stepProducts?: IProduct[] | IOtherProduct[];
}

const COUPON_VALUE_PROP_MAP: { [key: string]: keyof ICouponValues } = {
  [DiscountType.Amount]: 'amount',
  [DiscountType.Percent]: 'percentage',
};

const discountHandlers: {
  [key: string]: ({ value, totalPrice }: {value: number; totalPrice: number}) => number;
} = {
  [DiscountType.Amount]: ({ value, totalPrice }) => value > totalPrice ? totalPrice : value,
  [DiscountType.Percent]: ({ value, totalPrice }) => totalPrice ? (totalPrice * value) / 100 : 0,
};

const discountHandlersByCode: { [key: string]: ({ totalPrice }: {totalPrice: number}) => number } = {
  'summit15': ({ totalPrice }) => totalPrice,
};

const discountDepositHandlersByCode: { [key: string]: ({ depositPrice }: { depositPrice: number}) => number } = {
  'summit15': ({ depositPrice }) => depositPrice,
};



export const useGetSignUpTotalPriceByStep = ({ currentStep, stepProducts = []}: ArgumentsSignUpSteps) => {
  const hostProduct = useAppSelector(state => state.order.hostProduct);
  const quickbooksState = useAppSelector(state => state.order.quickbooks);
  const sageState = useAppSelector(state => state.order.sage);
  const msOfficeState = useAppSelector(state => state.order.msOffice);
  const otherProductsState = useAppSelector(state => state.order.otherProducts);
  const additionalServicesState = useAppSelector(state => state.order.additionalServices);
  const couponState = useAppSelector(state => state.order.coupon);
  const { calculateProductPrice } = usePriceHandler();

  const isProductAlignToChargify = (name: string | ProductChargify) => {
    return Object.values(ProductChargify).includes(name as ProductChargify);
  };

  const PRODUCTS_MAP: {
    stepName: SignUpNavigation;
    products: (IProduct | IOtherProduct)[];
  }[] = useMemo(() => {
    return [
      {
        stepName: SignUpNavigation.ServerUserHosting,
        products: [
          {
            name: ProductChargify.SHServerUserHosting,
            quantity: hostProduct.quantity,
          },
          {
            name: ProductChargify.SSW,
            quantity: '1'
          },
        ],
      },
      {
        stepName: SignUpNavigation.ProductsList,
        products: [
          ...(quickbooksState.data.name === null
            ? []
            : [{
              name: quickbooksState.data.name,
              quantity: quickbooksState.data.quantity,
            }]),
          ...(sageState.name === null
            ? []
            : [{ name: sageState.name, quantity: sageState.quantity }]),
          ...(msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense
            ? [{ name: ProductChargify.MSOfficeOwn, quantity: hostProduct.quantity }]
            : []),
          ...msOfficeState.products.filter(({ isChecked }) => isChecked),
          ...otherProductsState.filter(({ isChecked }) => isChecked),
        ]
      },
      {
        stepName: SignUpNavigation.AdditionalServices,
        products: additionalServicesState as IProduct[],
      },
    ];
  }, [hostProduct, quickbooksState, sageState, msOfficeState]);

  const PRODUCTS_BY_STEP: (IProduct | IOtherProduct)[] = useMemo(() => {
    const currentStepIndex: number | undefined = PRODUCTS_MAP.findIndex(el => el.stepName === currentStep);
    return PRODUCTS_MAP
      .slice(0, currentStepIndex >= 0 ? currentStepIndex : PRODUCTS_MAP.length)
      .reduce((acc, cur) => acc.concat(cur.products), [] as (IProduct | IOtherProduct)[])
      .concat(stepProducts);
  }, [PRODUCTS_MAP, currentStep, stepProducts]);

  const TOTAL_PRODUCTS_PRICE = useMemo(() => {
    return PRODUCTS_BY_STEP
      .reduce((acc, { name, quantity, price = 0 }) => {
        const stepProductPrice = isProductAlignToChargify(name)
          ? calculateProductPrice(name as ProductChargify, quantity)
          : price * +quantity as number;
        return acc += stepProductPrice ?? 0;
      }, 0);
  }, [PRODUCTS_BY_STEP, currentStep]);

  const DEPOSIT_PRICE = useMemo(() => {
    return calculateProductPrice(ProductChargify.Deposit, `${Math.floor(TOTAL_PRODUCTS_PRICE)}`);
  }, [TOTAL_PRODUCTS_PRICE]);

  const DISCOUNT_PRICE = useMemo(() => {
    let discount = 0;
    if (couponState !== null && INCLUDE_COUPON_STEPS_SIGNUP.includes(currentStep)) {
      const handler = (couponState.code && discountHandlersByCode[couponState.code.toLocaleLowerCase()])
        || (couponState.discount_type && discountHandlers[couponState.discount_type]);
      const value = couponState[COUPON_VALUE_PROP_MAP[couponState.discount_type]] || 0;

      const totalProductForDiscountPrice = PRODUCTS_BY_STEP.reduce((acc, { name, quantity, price = 0 }) => {
        const hasProductRestriction = couponState.restrictions.some(el => el.name === name);
        let stepProductPrice = 0;
        if (hasProductRestriction) {
          stepProductPrice = isProductAlignToChargify(name)
            ? calculateProductPrice(name as ProductChargify, quantity)
            : price * +quantity;
        }
        return acc += stepProductPrice ?? 0;
      }, 0);
      discount = handler ? handler({ value, totalPrice: totalProductForDiscountPrice }) : 0;
    }
    return discount;
  }, [couponState, PRODUCTS_BY_STEP]);

  const DISCOUNT_DEPOSITE_PRICE = useMemo(() => {
    let discountDeposit = 0;
    if (couponState !== null && INCLUDE_COUPON_STEPS_SIGNUP.includes(currentStep)) {
      const handler = couponState.code && discountDepositHandlersByCode[couponState.code.toLocaleLowerCase()];
      discountDeposit = handler ? handler({ depositPrice: DEPOSIT_PRICE }) : 0;
    }
    return discountDeposit;
  }, [couponState, DEPOSIT_PRICE]);

  return {
    stepProducts: PRODUCTS_BY_STEP,
    totalPrice: TOTAL_PRODUCTS_PRICE,
    deposit: DEPOSIT_PRICE ?? 0,
    discount: DISCOUNT_PRICE,
    discountDeposit: DISCOUNT_DEPOSITE_PRICE,
  };
};

export const useGetTotalPriceByStep = ({ currentStep, stepProducts = []}: Arguments) => {
  const hostProduct = useAppSelector(state => state.order.hostProduct);
  const quickbooksState = useAppSelector(state => state.order.quickbooks);
  const sageState = useAppSelector(state => state.order.sage);
  const msOfficeState = useAppSelector(state => state.order.msOffice);
  const otherProductsState = useAppSelector(state => state.order.otherProducts);
  const additionalServicesState = useAppSelector(state => state.order.additionalServices);
  const couponState = useAppSelector(state => state.order.coupon);
  const { calculateProductPrice } = usePriceHandler();

  const isProductAlignToChargify = (name: string | ProductChargify) => {
    return Object.values(ProductChargify).includes(name as ProductChargify);
  };

  const PRODUCTS_MAP: {
    stepName: OrderNavigation;
    products: (IProduct | IOtherProduct)[];
  }[] = useMemo(() => {
    return [
      {
        stepName: OrderNavigation.Products,
        products: [{
          name: ProductChargify.SHServerUserHosting,
          quantity: hostProduct.quantity,
        }],
      },
      {
        stepName: OrderNavigation.Quickbooks,
        products: quickbooksState.data.name === null
          ? []
          : [{
            name: quickbooksState.data.name,
            quantity: quickbooksState.data.quantity,
          }],
      },
      {
        stepName: OrderNavigation.Sage,
        products: sageState.name === null
          ? []
          : [{ name: sageState.name, quantity: sageState.quantity }],
      },
      {
        stepName: OrderNavigation.MsOffice,
        products: msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense
          ? [{ name: ProductChargify.MSOfficeOwn, quantity: hostProduct.quantity }]
          : [],
      },
      {
        stepName: OrderNavigation.MsOfficeLease,
        products: msOfficeState.products.filter(({ isChecked }) => isChecked),
      },
      {
        stepName: OrderNavigation.OtherProducts,
        products: otherProductsState.filter(({ isChecked }) => isChecked),
      },
      {
        stepName: OrderNavigation.AdditionalServices,
        products: additionalServicesState as IProduct[],
      },
      {
        stepName: OrderNavigation.SecureWorkspace,
        products: [
          { name: ProductChargify.SSW, quantity: '1' },
        ],
      },
    ];
  }, [hostProduct, quickbooksState, sageState, msOfficeState]);

  const PRODUCTS_BY_STEP: (IProduct | IOtherProduct)[] = useMemo(() => {
    const currentStepIndex: number | undefined = PRODUCTS_MAP.findIndex(el => el.stepName === currentStep);
    return PRODUCTS_MAP
      .slice(0, currentStepIndex >= 0 ? currentStepIndex : PRODUCTS_MAP.length)
      .reduce((acc, cur) => acc.concat(cur.products), [] as (IProduct | IOtherProduct)[])
      .concat(stepProducts);
  }, [PRODUCTS_MAP, currentStep, stepProducts]);

  const TOTAL_PRODUCTS_PRICE = useMemo(() => {
    return PRODUCTS_BY_STEP
      .reduce((acc, { name, quantity, price = 0 }) => {
        const stepProductPrice = isProductAlignToChargify(name)
          ? calculateProductPrice(name as ProductChargify, quantity)
          : price * (+quantity || 0) as number;
        return acc += stepProductPrice ?? 0;
      }, 0);
  }, [PRODUCTS_BY_STEP, currentStep]);

  const DEPOSIT_PRICE = useMemo(() => {
    return calculateProductPrice(ProductChargify.Deposit, `${Math.floor(TOTAL_PRODUCTS_PRICE)}`);
  }, [TOTAL_PRODUCTS_PRICE]);

  const DISCOUNT_PRICE = useMemo(() => {
    let discount = 0;
    if (couponState !== null && INCLUDE_COUPON_STEPS.includes(currentStep)) {
      const handler = (couponState.code && discountHandlersByCode[couponState.code.toLocaleLowerCase()])
        || (couponState.discount_type && discountHandlers[couponState.discount_type]);
      const value = couponState[COUPON_VALUE_PROP_MAP[couponState.discount_type]] || 0;

      const totalProductForDiscountPrice = PRODUCTS_BY_STEP.reduce((acc, { name, quantity, price = 0 }) => {
        const hasProductRestriction = couponState.restrictions.some(el => el.name === name);
        let stepProductPrice = 0;
        if (hasProductRestriction) {
          stepProductPrice = isProductAlignToChargify(name)
            ? calculateProductPrice(name as ProductChargify, quantity)
            : price * +quantity;
        }
        return acc += stepProductPrice ?? 0;
      }, 0);
      discount = handler ? handler({ value, totalPrice: totalProductForDiscountPrice }) : 0;
    }
    return discount;
  }, [couponState, PRODUCTS_BY_STEP]);

  const DISCOUNT_DEPOSITE_PRICE = useMemo(() => {
    let discountDeposit = 0;
    if (couponState !== null && INCLUDE_COUPON_STEPS.includes(currentStep)) {
      const handler = couponState.code && discountDepositHandlersByCode[couponState.code.toLocaleLowerCase()];
      discountDeposit = handler ? handler({ depositPrice: DEPOSIT_PRICE }) : 0;
    }
    return discountDeposit;
  }, [couponState, DEPOSIT_PRICE]);

  return {
    totalPrice: TOTAL_PRODUCTS_PRICE,
    deposit: DEPOSIT_PRICE ?? 0,
    discount: DISCOUNT_PRICE,
    discountDeposit: DISCOUNT_DEPOSITE_PRICE,
  };
};
