import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';

import { notify, setupSignalR, useCartCompleteSuccess } from 'helpers';
import {
  getAccountApplicationsData,
  addAssignedUsersToCart,
  removeApplication,
  getUsspCartData,
  canAppBeUpgraded,
  getProductInfos,
  getProductUpgradePrice,
  getProductNewestVersion,
  addSageUpgradeToCart,
  addQuickbooksUpgradeToCart,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ITableFilterOrdering } from 'interfaces';

import {
  Error,
  Pagination,
  Modal,
  SidebarModal,
  EmptyStateContainer,
  TotalPriceModalFooter,
  ReviewApplicationModal,
  AssignUsersToAppModal,
} from 'components';
import ApplicationsTableHeadRow from './components/ApplicationsTableHeadRow';
import ApplicationsTableBodyRow from './components/ApplicationsTableBodyRow';
import ApplicationsTableBodyRowLoader from './components/ApplicationsTableBodyRowLoader';
import UpgradeAppModalContent from './components/UpgradeAppModalContent/UpgradeAppModalContent';
import './AccountDetailsApplications.scss';

interface Props {
  openAddNewAppModal: () => void;
  isViewMode?: boolean;
}

const AccountDetailsApplications: FC<Props> = ({ openAddNewAppModal, isViewMode }) => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getAccountApplicationsDataRequest);
  const { isLoading: isLoadingRemove } = useAppSelector(state => state.ussp.removeApplicationRequest);

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    filter: '',
    orderings: '[]',
  });

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryFilter = useMemo(() => {
    return query.get('filter') || '';
  }, [query]);

  const queryOrderings = useMemo(() => {
    return JSON.parse(query.get('orderings') || '[]');
  }, [query]);

  useEffect(() => {
    fetchData();
  }, [subscriptionId, query]);

  const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);

  const fetchData = async (
    isBackground?: boolean,
  ) => {
    if (typeof subscriptionId === 'string') {
      const data = await dispatch(getAccountApplicationsData({
        subscriptionId,
        page: queryPage,
        pageSize: queryPageSize,
        filter: queryFilter,
        orderings: queryOrderings,
        _disableErrorHandler: true,
        _isBackground: isBackground,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };

  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(true);
    setIsBackgroundUpdate(false);
  };

  const onSearch = (searchQuery: string) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('filter', searchQuery);
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onOrderChange = (data: ITableFilterOrdering) => {
    const newOrderings = data === undefined ? [] : [data];
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('orderings', JSON.stringify(newOrderings));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  useCartCompleteSuccess(async () => {
    await backgroundFetch();
  });
  useEffect(() => {
    const connection = setupSignalR('/accountOverviewApplications');
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('Signal', async (message: any) => {
            await backgroundFetch();
            console.log('ACCOUNT_OVERVIEW_APPLICATIONS:SIGNAL', message);
          });
        })
        .catch((e: any) => console.log('Connection failed [accountOverviewApplications]: ', e));
    }

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  // useEffect(() => {
  //   tableFilterData.current.page = 1;
  //   fetchData(1, pageSize, filter, orderings);
  // }, [subscriptionId]);

  const [reviewItemData, setReviewedItemeData] = useState<any>({});
  const [appToRemove, setAppToRemove] = useState<any>(null);
  const [isShowReviewModal, setIsShowReviewModal] = useState<boolean>(false);
  const showReviewModal = () => {
    setIsShowReviewModal(true);
  };
  const hideReviewModal = () => {
    setIsShowReviewModal(false);
  };

  const reviewAppDetailsHandler = (index: number) => {
    setReviewedItemeData({
      title: data?.applications[index].title,
      ...(data?.applications[index]?.details || {}),
    });
    showReviewModal();
  };

  const [isShowRemoveModal, setIsShowRemoveModal] = useState<boolean>(false);
  const showRemoveModal = (index: number) => {
    setAppToRemove(data?.applications[index]);
    setIsShowRemoveModal(true);
  };
  const hideRemoveModal = () => {
    setAppToRemove(null);
    setIsShowRemoveModal(false);
  };
  const removeAppHandler = async () => {
    //maybe add loading to buttons
    if (appToRemove && typeof subscriptionId === 'string') {
      await dispatch(removeApplication({
        subscriptionId,
        applicationId: appToRemove.id,
      })).unwrap();
      backgroundFetch();
      notify.success('This product has been deleted', 'You cannot return this product');
    }
    hideRemoveModal();
  };

  const [currenAppData, setCurrenAppData] = useState<any>({});

  const [isShowAssignUsersModal, setIsShowAssignUsersModal] = useState<boolean>(false);
  const showAssignUsersModal = (index: number) => {
    if (typeof (data?.applications[index].id) === 'string') {
      setCurrenAppData({
        id: data?.applications[index].id,
        title: data?.applications[index].title,
        price: data?.applications[index].price,
        ...(data?.applications[index]?.details || {}),
      });
      setIsShowAssignUsersModal(true);
    }
  };
  const hideAssignUsersModal = () => {
    setIsShowAssignUsersModal(false);
  };
  const addAssignedUsersToCartHandler = async (assignedUsersIds: string[]) => {
    if (typeof subscriptionId === 'string') {
      await dispatch(addAssignedUsersToCart({
        subscriptionId,
        productId: currenAppData.id,
        userIds: assignedUsersIds,
      })).unwrap();
      notify.success(
        'Assingned users successfully added to cart',
        'Now you can proceed to payment',
      );
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
      setIsShowAssignUsersModal(false);
      setCurrenAppData({});
    }
  };

  const { price: upgradeAppPrice } = useAppSelector(
    state => state.ussp.getProductUpgradePriceRequest.data || { price: 0 },
  );
  const [isShowUpgradeModal, setIsShowUpgradeModal] = useState<boolean>(false);
  const [isUpgradeConfirmed, setIsUpgradeConfirmed] = useState<boolean>(false);
  const showUpgradeModal = async (index: number) => {
    if (data?.applications[index] && typeof subscriptionId === 'string') {
      try {
        const canBeUpgraded = await dispatch(
          canAppBeUpgraded({
            subscriptionId,
            productId: data.applications[index].id,
            _disableErrorHandler: true,
          }),
        ).unwrap();

        if (canBeUpgraded) {
          setCurrenAppData({
            id: data?.applications[index].id,
            title: data.applications[index].title,
            ...(data.applications[index].details || {}),
          });
          setIsShowUpgradeModal(true);
          Promise.all([getProductInfos, getProductNewestVersion, getProductUpgradePrice].map(
            action => dispatch(action({
              componentId: data.applications[index].componentId,
              subscriptionId,
            })).unwrap(),
          ));
        } else {
          notify.warning('Upgrade', 'The product already has the latest version');
        }
      } catch (err) {
        if ((err as SerializedError).code === '404') {
          notify.error('Error', 'The product can\'t be upgraded');
        } else if ((err as SerializedError).code === '500') {
          notify.warning('Upgrade', 'The product already has the latest version');
        } else if (typeof (err as SerializedError).message === 'string') {
          notify.error('Error', (err as SerializedError).message);
        }
      }
    }
  };
  const confirmUpgrade = (value: boolean): void => {
    setIsUpgradeConfirmed(value);
  };
  const hideUpgradeModal = () => {
    setIsShowUpgradeModal(false);
    setCurrenAppData({});
    setIsUpgradeConfirmed(false);
  };
  const { groupName: groupNameForUpgrade } = useAppSelector(
    state => state.ussp.getProductInfosRequest?.data || {},
  );
  const addUpgradeToCart = async () => {
    if (typeof subscriptionId === 'string' && groupNameForUpgrade === 'sage') {
      await dispatch(
        addSageUpgradeToCart({
          subscriptionId,
          productId: currenAppData.id,
          serialNumber: currenAppData.serialNumber,
        })).unwrap();
    } else if (typeof subscriptionId === 'string' && groupNameForUpgrade === 'quickbooks') {
      await dispatch(
        addQuickbooksUpgradeToCart({
          subscriptionId,
          productId: currenAppData.id,
          productCode: currenAppData.productCode,
          licenseCode: currenAppData.licenceCode,
        }),
      ).unwrap();
    }
    notify.success(
      'Upgrade successfully added to cart',
      'Now you can proceed to payment',
    );
    dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    hideUpgradeModal();
  };

  if (error) {
    return <section className="dashboard-account-details__content"><Error code={500} /></section>;
  }

  return (
    <>
      <section className="dashboard-account-details__content">
        <table className="dashboard-accounts-app-table">
          <thead>
            <ApplicationsTableHeadRow
              initialSearchQuery={queryFilter}
              orderState={queryOrderings?.[0]}
              onSearch={onSearch}
              onOrderChange={onOrderChange}
            />
          </thead>
          <tbody>
            {isShowLoader
              ? <ApplicationsTableBodyRowLoader />
              : data?.applications.map((el: any, index: number) => (
                <ApplicationsTableBodyRow
                  key={el.id || el.title}
                  {...el}
                  isActionsDisabled={el.isReadonly || isViewMode}
                  onAssignNewUsers={() => showAssignUsersModal(index)}
                  onUpgrade={() => showUpgradeModal(index)}
                  onReview={() => reviewAppDetailsHandler(index)}
                  onRemove={() => showRemoveModal(index)}
                />
              ))
            }
          </tbody>
        </table>
        {
          data?.applications.length === 0
            ? <EmptyStateContainer
              title="No applications found"
              description="No applications found with specified parameters"
              classes="empty-state-container-table"
            />
            : null
        }
      </section>
      {
        data?.applications.length > 0
          ? <Pagination
            current={queryPage}
            entries={queryPageSize}
            totalPages={totalPages}
            onChange={onPaginationChange}
          />
          : null
      }
      {isShowReviewModal && (
        <ReviewApplicationModal
          visible={isShowReviewModal}
          {...reviewItemData}
          onClose={hideReviewModal}
        />
      )}
      {isShowAssignUsersModal && (
        <AssignUsersToAppModal
          visible={isShowAssignUsersModal}
          productData={currenAppData}
          subscriptionId={subscriptionId ?? ''}
          onOk={addAssignedUsersToCartHandler}
          onCancel={hideAssignUsersModal}
        />
      )}
      <SidebarModal
        classes="dashboard-accounts-app-upgrade"
        title="Upgrade Application"
        visible={isShowUpgradeModal}
        onOk={addUpgradeToCart}
        isOkDisabled={!isUpgradeConfirmed}
        okText="Add to Cart"
        footerAdditonal={<TotalPriceModalFooter oneTimeFee price={upgradeAppPrice ?? 0} />}
        cancelText="Cancel"
        onCancel={hideUpgradeModal}
      >
        <UpgradeAppModalContent
          title={currenAppData.title}
          productVersion={currenAppData.productVersion}
          confirmation={isUpgradeConfirmed}
          onConfirm={confirmUpgrade}
          openAddNewAppModal={() => {
            hideUpgradeModal();
            openAddNewAppModal();
          }}
        />
      </SidebarModal>
      <Modal
        title={`Do you want to remove ${appToRemove?.title} entirely from your server?`}
        visible={isShowRemoveModal}
        onOk={removeAppHandler}
        isOkLoading={isLoadingRemove}
        okText="Yes, Remove"
        cancelText="No"
        onCancel={hideRemoveModal}
        width={535}
      />
    </>
  );
};

export default AccountDetailsApplications;
