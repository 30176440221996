import React, { FC } from 'react';
import { capitalizeFirstLetter } from 'helpers';
import { ITableFilterOrdering } from 'interfaces';
import { TableFilter, TableOrder } from 'components';

interface Props {
  orderState?: ITableFilterOrdering;
  statuses: string[];
  selectedStatuses: string[];
  terms: string[];
  managableTypes: any;
  selectedTerms: string[];
  selectedSubscriptionManageable: string[];
  onOrderChange: (data: ITableFilterOrdering) => void;
  onFilterByStatusesChange: (filters: string[]) => void;
  onFilterByTermsChange: (filters: string[]) => void;
  onFilterByManageableChange: (filters: string[]) => void;
}

const SubscriptionsTableHeadRow: FC<Props> = ({
  orderState,
  statuses = [],
  selectedStatuses,
  terms = [],
  managableTypes = [],
  selectedTerms,
  selectedSubscriptionManageable,
  onOrderChange,
  onFilterByStatusesChange,
  onFilterByTermsChange,
  onFilterByManageableChange,
}) => (
  <tr className="subscriptions-table-head-row">
    <th></th>
    <th>
      <TableFilter
        values={selectedSubscriptionManageable}
        options={managableTypes}
        onChange={onFilterByManageableChange}
      >
        <TableOrder
          label="SUBSCRIPTION"
          fieldName="CompanyName"
          currentOrderState={orderState}
          onChange={onOrderChange}
        />
      </TableFilter>
    </th>
    <th>SUBSCRIBER</th>
    <th>
      <TableFilter
        values={selectedStatuses}
        options={statuses.map((status) => ({
          label: capitalizeFirstLetter(status.replaceAll('_', ' ')),
          value: status,
        }))}
        onChange={onFilterByStatusesChange}
      >
        <TableOrder
          label="STATUS"
          fieldName="Status"
          currentOrderState={orderState}
          onChange={onOrderChange}
        />
      </TableFilter>
    </th>
    <th>
      <TableFilter
        values={selectedTerms}
        options={terms.map(({ id, name }: any) => ({
          label: name,
          value: id.toString(),
        }))}
        onChange={onFilterByTermsChange}
      >
        <TableOrder
          label="SUB. TERMS"
          fieldName="SubTerms"
          currentOrderState={orderState}
          onChange={onOrderChange}
        />
      </TableFilter>
    </th>
    <th>
      NEXT BILLING
    </th>
    <th>
      <TableOrder
        label="NEXT BILLING DATE"
        fieldName="NextBillingDate"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>COUNTRY</th>
  </tr>
);

export default SubscriptionsTableHeadRow;
