import { makeVh } from 'helpers/styles';

const iframeHeightPx = 95;

const inputFieldStyles = {
  field: {
    backgroundColor: '#ffffff',
    padding: '0px',
  },
  input: {
    backgroundColor: '#ffffff',
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: makeVh(17, iframeHeightPx),
    fontWeight: '400',
  },
  label: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: makeVh(17, iframeHeightPx),
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
const selectFieldStyles = {
  field: {
    backgroundColor: '#ffffff',
    padding: '0px',
  },
  select: {
    backgroundColor: '#ffffff',
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: makeVh(17, iframeHeightPx),
    fontWeight: '400',
  },
  input: {
    backgroundColor: '#ffffff',
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: makeVh(17, iframeHeightPx),
    fontWeight: '400',
    minHeight: makeVh(38, iframeHeightPx),
  },
  label: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: makeVh(17, iframeHeightPx),
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: makeVh(5, iframeHeightPx),
  },
};

const generalStyles = {
  field: {
    borderRadius: '5px',
    margin: '0px',
    fontWeight: '400',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  input: {
    backgroundColor: '#ffffff',
    paddingTop: '2px',
    paddingBottom: '1px',
    placeholder: { color: '#eee' },
  },
  label: {
    paddingTop: '2px',
    paddingBottom: '1px',
    marginTop: '0px',
  },
};


const inputFieldStylesXVersion = {
  field: {
    backgroundColor: '#ffffff',
    padding: '0px',
  },
  input: {
    backgroundColor: '#ffffff',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '14px',
    paddingRight: '14px',
    fontSize: '16px',
    borderRadius: '8px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#101828'
  },
  label: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
const cardInputFieldStylesXVersion = {
  field: {
    backgroundColor: '#ffffff',
    padding: '0px',
  },
  input: {
    backgroundColor: '#ffffff',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '62px',
    paddingRight: '14px',
    fontSize: '16px',
    borderRadius: '8px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#101828'
  },
  label: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
const selectFieldStylesXVersion = {
  field: {
    backgroundColor: '#ffffff',
    padding: '0px',
  },
  select: {
    backgroundColor: '#ffffff',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '14px',
    paddingRight: '14px',
    fontSize: '16px',
    borderRadius: '8px',
    lineHeight: '24px',
    fontWeight: '400',
  },
  input: {
    backgroundColor: '#ffffff',
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '16px',
    borderRadius: '8px',
    lineHeight: '24px',
    fontWeight: '400',
    minHeight: '46px',
  },
  label: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '5px',
  },
};

const generalStylesXVersion = {
  field: {
    borderRadius: '5px',
    margin: '0px',
    fontWeight: '400',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  input: {
    backgroundColor: '#ffffff',
    paddingTop: '2px',
    paddingBottom: '1px',
    placeholder: { color: '#eee' },
  },
  label: {
    paddingTop: '2px',
    paddingBottom: '1px',
    marginTop: '0px',
  },
};



export {
  inputFieldStyles,
  selectFieldStyles,
  generalStyles,
  inputFieldStylesXVersion,
  selectFieldStylesXVersion,
  cardInputFieldStylesXVersion,
  generalStylesXVersion,
};