import { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { usePriceHandler } from 'helpers';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getAdminSubscriptionInvoiceFile } from 'store';

import { CountryCode, InvoiceStatus } from 'enums';
import { INVOICE_STATUS_CLASSES } from 'consts';

import {
  DotsIcon,
  ShowOutlineIcon,
  PaperDownloadIcon,
  LinkIcon,
} from 'assets/svg';

import { Menu } from 'components';

interface Props {
  subscriptionId: string;
  id: string;
  number: string;
  issueDate: string;
  dueDate: string;
  amountTotal: number;
  dueAmount: number;
  status: InvoiceStatus;
  publicUrl: string;
  onShowPublicUrl: (publicUrl: string) => void;
}

const formatDate = (value: string): string => dayjs(value).format('MMMM D, YYYY');

const InvoicesTableBodyRow: FC<Props> = ({
  subscriptionId,
  id,
  number,
  issueDate,
  dueDate,
  amountTotal,
  dueAmount,
  status,
  publicUrl,
  onShowPublicUrl,
}) => {
  const dispatch = useAppDispatch();
  const currentCountry = useAppSelector(
    (state) => state.ussp.getAdminSubscriptionInfoRequest.data?.country as CountryCode
      || CountryCode.USA,
  );
  const { formatPrice } = usePriceHandler();

  const issueDateFormated = useMemo(() => issueDate ? formatDate(issueDate) : '-', [issueDate]);
  const dueDateFormated = useMemo(() => dueDate ? formatDate(dueDate) : '-', [dueDate]);

  const amountTotalFormated = useMemo(() => formatPrice(amountTotal || 0, currentCountry), [
    amountTotal,
    currentCountry,
  ]);
  const dueAmountFormated = useMemo(() => formatPrice(dueAmount || 0, currentCountry), [
    dueAmount,
    currentCountry,
  ]);

  const onPreviewInvoiceClick = async () => {
    const data = await dispatch(getAdminSubscriptionInvoiceFile({
      invoiceId: id,
      subscriptionId,
    })).unwrap();
    const url = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    window.open(url, '_blank');
  };
  const onDownloadInvoiceClick = async () => {
    const data = await dispatch(getAdminSubscriptionInvoiceFile({
      invoiceId: id,
      subscriptionId,
    })).unwrap();
    const url = URL.createObjectURL(new Blob([data]));
    const alink = document.createElement('a');
    alink.href = url;
    alink.download = `invoice_${id}.pdf`;
    alink.click();
  };

  return (
    <tr className="users-table-body-row">
      <td>{number}</td>
      <td>{issueDateFormated}</td>
      <td>{dueDateFormated}</td>
      <td>{amountTotalFormated}</td>
      <td>{dueAmountFormated}</td>
      <td><p className={INVOICE_STATUS_CLASSES[status]}>{status}</p></td>

      <td>
        <Menu
          trigger={<DotsIcon />}
          items={[
            <div onClick={onPreviewInvoiceClick}><ShowOutlineIcon className="menu-item-icon" />View Invoice</div>,
            <div onClick={onDownloadInvoiceClick}><PaperDownloadIcon className="menu-item-icon" />Download PDF</div>,
            <div onClick={() => onShowPublicUrl(publicUrl)}><LinkIcon className="menu-item-icon" />View Invoice Public URL</div>,
          ]}
          placement="bottom-end"
        />
      </td>
    </tr>
  );
};

export default InvoicesTableBodyRow;
