import './Payment.scss';
import { FC, useState, useMemo } from 'react';
import cn from 'classnames';
import { SerializedError } from '@reduxjs/toolkit';
import Lottie from 'lottie-react';
import ReactGA from 'react-ga4';

import { CountryCode, OrderNavigation } from 'enums';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { completeOrder, resetOrder } from 'store';
import {
  useGetProductsListWithInfo,
  makeVh,
  makeVw,
  getEnvCountryCode,
  notify,
  useGetTotalPriceByStep,
  useGetProductsListWithPrices,
} from 'helpers';

import { CreditCardIcon, BankIcon, MessageOutlineIcon } from 'assets/svg';
import doneAnimation from 'assets/lotties/done.json';
import paymentUnsuccesful from 'assets/lotties/payment-unsuccesful.json';

import ChargifyForm from './components/ChargifyForm/ChargifyForm';
import { Navigation, Modal, Logo } from 'components';

enum PaymentMethod {
  CreditCard = 'card',
  BankAccount = 'bank',
}

interface IPaymentMethod {
  type: PaymentMethod;
  title: string;
  IconComponent: FC;
}

const PAYMENT_METHODS_BY_COUNTRY: { [key: string]: IPaymentMethod[]} = {
  [CountryCode.Canada]: [
    {
      type: PaymentMethod.CreditCard,
      title: 'Credit Card',
      IconComponent: CreditCardIcon,
    },
  ],
  [CountryCode.USA]: [
    {
      type: PaymentMethod.CreditCard,
      title: 'Credit Card',
      IconComponent: CreditCardIcon,
    },
    {
      type: PaymentMethod.BankAccount,
      title: 'Bank Account',
      IconComponent: BankIcon,
    },
  ],
};

const doneAnimationStyles = {
  height: makeVh(108),
  marginTop: makeVh(50),
  marginBottom: makeVh(50),
  marginLeft: makeVw(50),
  marginRight: makeVw(50),
};
const paymentUnsuccesfulAnimationStyles = {
  height: makeVh(160),
  marginTop: makeVh(20),
  marginBottom: makeVh(0),
  marginLeft: makeVw(50),
  marginRight: makeVw(50),
};

const PAYMENT_TYPES_MAP = {
  [PaymentMethod.CreditCard]: 'credit_card',
  [PaymentMethod.BankAccount]: 'bank_account',
};

const Payment: FC = () => {
  const dispatch = useAppDispatch();
  const currentCountryCode = useAppSelector(state => state.order.country?.code ?? CountryCode.USA);
  const termsId = useAppSelector(state => state.order.termsId);
  const envCountryCode = useMemo(() => getEnvCountryCode(currentCountryCode), [currentCountryCode]);
  const couponState = useAppSelector(state => state.order.coupon);
  const orderId = useAppSelector(state => state.order.orderId);
  const [
    currentPaymentMethod,
    setCurrentPaymentMethod,
  ] = useState<PaymentMethod>(PAYMENT_METHODS_BY_COUNTRY[envCountryCode][0].type);
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [isShowErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessPayment, setIsSuccessPayment] = useState<boolean>(false);
  const products = useGetProductsListWithInfo();
  const purchaseProducts = useGetProductsListWithPrices();
  const { totalPrice } = useGetTotalPriceByStep({ currentStep: OrderNavigation.Payment });

  const showModal = () => {
    setShowModal(true);
  };
  const showErrorModal = () => {
    setShowErrorModal(true);
  };

  const sendPurchaseGtagEvent = () => {
    ReactGA.event('purchase', {
      transaction_id: orderId,
      value: totalPrice,
      currency: currentCountryCode === CountryCode.Canada ? ' CAD' : 'USD',
      items: purchaseProducts.map(i => ({
        item_id: i.id,
        item_name: i.name,
        quantity: Number(i.quantity),
        price: i.price,
      })),
      coupon: couponState && couponState.name || '',
    });
  };

  const submit = async (token: string) => {
    setIsLoading(true);
    try {
      await dispatch(completeOrder({
        token: token,
        products,
        paymentType: PAYMENT_TYPES_MAP[currentPaymentMethod],
        termsId,
        _disableErrorHandler: true,
      })).unwrap();
      showModal();
      sendPurchaseGtagEvent();
      setIsSuccessPayment(true);
      dispatch(resetOrder());
    } catch (err) {
      if ((err as SerializedError).code === '502') {
        showErrorModal();
      } else if (typeof (err as SerializedError).message === 'string') {
        notify.error(
          'Error',
          (err as SerializedError).message,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  const next = () => {
    setShowModal(false);
    window.location.href = 'https://www.summithosting.com';
  };

  return (
    <>
      <Logo type="white" />
      <div className="payment page">
        <Modal
          title={null}
          footer={null}
          closable={false}
          width={376}
          visible={isShowModal}
        >
          <div className="thanks-modal-body">
            {isShowModal && (
              <Lottie
                style={doneAnimationStyles}
                animationData={doneAnimation}
                initialSegment={[0, 129]} />
            )}
            <p className="rel-m-b-24">Thank You for Choosing Summit Hosting!</p>
            <button type="button" className="btn primary new-style" onClick={next}>Next Steps and More Information</button>
          </div>
        </Modal>
        <Modal
          title={null}
          footer={null}
          closable={false}
          width={538}
          visible={isShowErrorModal}
        >
          <div className="error-modal-body rel-p-x-20">
            {isShowErrorModal && (
              <Lottie
                style={paymentUnsuccesfulAnimationStyles}
                animationData={paymentUnsuccesful}
                initialSegment={[0, 79]} />
            )}
            <p className="title rel-m-b-8">Opps...</p>
            <p className="description rel-m-b-48">
              Your order has been received; however, we are currently facing challenges
              processing it through our payment provider. To ensure the completion of the
              processing, a team member will reach out to you at the earliest convenience.
              Thank you for your understanding and patience.
            </p>
            <p className="info rel-m-b-8">
              For immediate assistance or to inquire about your order status,
              please reach out to our Client Experience team.
            </p>
            <div className="flex flex-justify-center rel-m-b-56">
              <a
                href={`mailto:am@summithosting.com`}
                className="link relative-units without-decorations fw-400 flex flex-align-center rel-gap-4 mail-link"
              >
                <MessageOutlineIcon />
                am@summithosting.com
              </a>
            </div>
            <button type="button" className="btn primary new-style thanks-button" onClick={next}>Thank you for choosing Summit Hosting</button>
          </div>
        </Modal>
        <div className="page-left">
        </div>
        <div className="page-right">
          <Navigation currentStep={10} />
          <div className="page-right_content">
            <h1>Payment Method</h1>
            <div className="payment-methods rel-m-b-36">
              {PAYMENT_METHODS_BY_COUNTRY[envCountryCode].map(({ type, title, IconComponent }) => (
                <button
                  key={`payment-type-btn-${type}`}
                  type="button"
                  className={cn('btn btn-tab with-icon new-style', { active: currentPaymentMethod === type })}
                  onClick={() => setCurrentPaymentMethod(type)}><IconComponent /> {title}</button>
              ))}
            </div>
            <ChargifyForm
              paymentType={currentPaymentMethod}
              submit={submit}
              isLoading={isLoading}
              isDisabled={isSuccessPayment} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
