import './Popover.scss';
import React, { FC, ReactNode, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { usePopper } from 'react-popper';
import { makePxDependOnWindowHeight, useOnClickOutside } from 'helpers';

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

interface Props {
  children: ReactNode;
  trigger: JSX.Element;
  prefix?: JSX.Element | string;
  classes?: string;
  placement?: Placement;
  isDisabled?: boolean;
  isInline?: boolean;
  onStateChange?: (state: boolean) => void;
}

const Popover: FC<Props> = ({
  children,
  trigger,
  prefix,
  classes,
  placement = 'auto',
  isDisabled = false,
  isInline = false,
  onStateChange,
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const popperElementWrapper = useRef<HTMLDivElement | null>(null);
  const referenceElementWrapper = useRef<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, makePxDependOnWindowHeight(8)],
        },
      },
    ],
  });

  const toggleMenu = () => {
    if (!isDisabled) {
      setIsShow(!isShow);
    }
  };

  useEffect(() => {
    if (referenceElement !== null) {
      referenceElementWrapper.current = referenceElement;
    }
  }, [referenceElement]);

  useEffect(() => {
    if (popperElement !== null) {
      popperElementWrapper.current = popperElement;
    }
  }, [popperElement]);

  useEffect(() => {
    if (!!onStateChange) {
      onStateChange(isShow);
    }
  }, [isShow]);

  useOnClickOutside<HTMLDivElement>(referenceElementWrapper, (event) => {
    if (
      popperElementWrapper.current
      && event.target !== popperElementWrapper.current
      && !popperElementWrapper.current.contains(event.target as Node)
    ) {
      setIsShow(false);
    }
  }, undefined, false);

  return (
    <div className={cn('popover', classes, {
      'd-inline-block': isInline,
      'popover--disabled': isDisabled,
    })}>
      <div
        ref={setReferenceElement}
        className="popover__trigger-wrapper flex flex-align-center rel-gap-8"
      >
        {prefix}
        <div
          className="popover__trigger flex flex-align-center"
          onClick={toggleMenu}
        >
          {trigger}
        </div>
      </div>
      {isShow && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="popover__content"
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Popover;
