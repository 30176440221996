import './ChargifyForm.scss';
import { FC, useState, useEffect, useRef, useMemo, SyntheticEvent } from 'react';
import Lottie from 'lottie-react';

import { getFields as getCardFields, template as CardFormContent } from './config/card';
import { getFields as getBankFields, template as BankFormContent } from './config/bank';
import { generalStyles, generalStylesXVersion } from './config/styleConfig';

import loaderAnimation from 'assets/lotties/loader.json';
import { CountryCode } from 'enums';

import { useAppSelector } from 'store/hooks';

import { notify, getEnvCountryCode } from 'helpers';

import { Button, OrderNextButton } from 'components';

const CHARGIFY_SERVER_HOSTS = {
  [CountryCode.USA]: process.env.REACT_APP_CHARGIFY_HOST_US,
  [CountryCode.Canada]: process.env.REACT_APP_CHARGIFY_HOST_CA,
  [CountryCode.Other]: process.env.REACT_APP_CHARGIFY_HOST_US,
};
const CHARGIFY_PUBLIC_KEYS = {
  [CountryCode.USA]: process.env.REACT_APP_CHARGIFY_KEY_US,
  [CountryCode.Canada]: process.env.REACT_APP_CHARGIFY_KEY_CA,
  [CountryCode.Other]: process.env.REACT_APP_CHARGIFY_KEY_US,
};

declare global {
  interface Window {
    Chargify: any;
  }
}
interface Props {
  isLoading: boolean;
  isDisabled: boolean;
  xVersion?: boolean;
  paymentType?: 'card' | 'bank';
  submit: (token: string) => void;
}

const ChargifyForm: FC<Props> = ({
  isLoading,
  isDisabled,
  xVersion = false,
  paymentType = 'card',
  submit,
}) => {
  const currentCountryCode = useAppSelector(state => state.order.country?.code ?? CountryCode.USA);
  const envCountryCode = useMemo(() => getEnvCountryCode(currentCountryCode), [currentCountryCode]);
  const chargifyForm = useRef<any>();
  const chargify = useRef<any>(new window.Chargify());
  const [isFormLoading, setFormLoading] = useState<boolean>(false);
  const [isTokenLoading, setIsTokenLoading] = useState<boolean>(false);
  const [currentCardType, setCurrentCardType] = useState(undefined);
  
  const formConfig = useMemo(() => ({
    card: {
      fields: getCardFields({ xVersion })[envCountryCode],
      template: <CardFormContent hide={isFormLoading} xVersion={xVersion} cardType={currentCardType}/>,
      additionalContent: null,
    },
    bank: {
      fields: getBankFields({ xVersion }),
      template: <BankFormContent hide={isFormLoading} xVersion={xVersion}/>,
      additionalContent: null,
    },
  }), [isFormLoading, xVersion, currentCardType]);

  console.log(69, formConfig);

  const curentForm = useMemo(() => formConfig[paymentType], [paymentType, formConfig]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsTokenLoading(true);

    chargify.current.token(
      chargifyForm.current,

      (token: string) => {
        submit(token);
        setIsTokenLoading(false);
      },

      (error: any) => {
        let errorMessage = error.message || error.errors;
        let errorTitle = 'Error';
        if (!!error.invalidFields?.length) {
          errorTitle = 'Oops, It seems like some of your data is not valid.';
          errorMessage = 'Please, check if all the fields are filled correctly and resend the form.';
        }
        const message: string = typeof errorMessage === 'string'
          ? errorMessage
          : 'Something went wrong';
        notify.error(errorTitle, message, 5000);
        setIsTokenLoading(false);
      },
    );
  };

  useEffect(
    () => {
      if (envCountryCode !== null) {
        setFormLoading(true);
        chargify.current.load({
          publicKey: CHARGIFY_PUBLIC_KEYS[envCountryCode],
          type: paymentType,
          serverHost: CHARGIFY_SERVER_HOSTS[envCountryCode],
          hideCardImage: true,
          optionalLabel: '(optional)',
          ...(paymentType === 'bank' ? { gatewayHandle: process.env.REACT_APP_CHARGIFY_ACCOUNT_GATEWAY_HANDLE } : {}),
          requiredLabel: '*',
          addressDropdowns: true,
          style: xVersion ? generalStylesXVersion : generalStyles,
          fields: curentForm.fields,
        }, {
          onCardTypeDetected: (type: any) => {

            console.log(118, type);
            setCurrentCardType(type);
          }
        }).then(() => setFormLoading(false));
      }

      return () => {
        chargify.current.unload();
      };
    },
    [paymentType],
  );

  return (
    <div className="chargify-form-wrapper">
      <form ref={chargifyForm} onSubmit={handleSubmit}>
        {curentForm?.template}
        {isFormLoading
          ? <Lottie animationData={loaderAnimation}/>
          : (
            <>
              {curentForm?.additionalContent}
              <div className="flex flex-justify-center m-t-24 sm-m-t-16">
                <Button
                  buttonType="submit"
                  dataTestId="payment.next-button"
                  xVersion
                  absoluteStyles
                  isLoading={isTokenLoading || isLoading}
                  isDisabled={isDisabled}
                >
                  Pay
                </Button>
              </div>
            </>
          )}
      </form>
    </div>
  );
};

export default ChargifyForm;