import './AccountDetailsUsers.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

import { setupSignalR, useCartCompleteSuccess } from 'helpers';
import {
  getUserAvailableAssigningApps,
  getAccountUsersData,
  getUsspCartData,
  getAccountApplicationInfosData,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ITableFilterOrdering } from 'interfaces';

import {
  Error,
  Pagination,
  EmptyStateContainer,
  RemoveApplicationFromUserModal,
  AssignApplicationSidebarModal,
  EditUserModal,
  RemoveUserModal,
  FilterTags,
} from 'components';
import UsersTableHeadRow from './components/UsersTableHeadRow';
import UsersTableBodyRow from './components/UsersTableBodyRow';
import UsersTableBodyRowLoader from './components/UsersTableBodyRowLoader';

interface Props {
  isViewMode?: boolean;
}

const AccountDetailsUsers: FC<Props> = ({ isViewMode }) => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getAccountUsersDataRequest);
  const {
    data: applicationInfosData,
  } = useAppSelector(state => state.ussp.getAccountApplicationInfosDataRequest);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    filter: '',
    orderings: '[]',
    applicationIds: '[]',
  });

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryFilter = useMemo(() => {
    return query.get('filter') || '';
  }, [query]);

  const queryOrderings = useMemo(() => {
    return JSON.parse(query.get('orderings') || '[]');
  }, [query]);

  const queryApplicationIds = useMemo(() => {
    return JSON.parse(query.get('applicationIds') || '[]');
  }, [query]);

  useEffect(() => {
    fetchApplicationInfos();
  }, [subscriptionId]);

  useEffect(() => {
    fetchData();
  }, [subscriptionId, query]);

  const fetchData = async (
    isBackgroundUpdate?: boolean,
  ) => {
    if (typeof subscriptionId === 'string') {
      const data = await dispatch(getAccountUsersData({
        subscriptionId,
        page: queryPage,
        pageSize: queryPageSize,
        filter: queryFilter,
        orderings: queryOrderings,
        applicationIds: queryApplicationIds,
        _disableErrorHandler: true,
        _isBackground: isBackgroundUpdate,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };

  const fetchApplicationInfos = () => {
    if (typeof subscriptionId === 'string') {
      dispatch(getAccountApplicationInfosData({ subscriptionId })).unwrap();
    }
  };

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);
  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(true);
    fetchApplicationInfos();
    setIsBackgroundUpdate(false);
  };

  const onSearch = (searchQuery: string) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('filter', searchQuery);
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onOrderChange = (data: ITableFilterOrdering) => {
    const newOrderings = data === undefined ? [] : [data];
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('orderings', JSON.stringify(newOrderings));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onFilterByProductsChange = (filters: string[]) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('applicationIds', JSON.stringify(filters));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  const filterTagsData = useMemo(
    () => {
      const applicationIdsFilters = queryApplicationIds.map((id: string) => {
        const application = applicationInfosData?.find((app: any) => app.id === id);
        const newProductsAfterDelete = queryApplicationIds.filter((el: string) => el !== id);
        return {
          label: `${application?.name}`,
          onDelete: () => {
            onFilterByProductsChange(newProductsAfterDelete);
          }
        };
      });
      return [
        ...applicationIdsFilters,
      ];
    },
    [queryApplicationIds]
  );

  const onClearAll = () => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('applicationIds', '[]');
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  useCartCompleteSuccess(async () => {
    await backgroundFetch();
  });

  useEffect(() => {
    const connection = setupSignalR('/accountOverviewUsers');
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('Signal', async (message: any) => {
            await backgroundFetch();
            console.log('ACCOUNT_OVERVIEW_USERS:SIGNAL', message);
          });
        })
        .catch((e: any) => console.log('Connection failed [accountOverviewUsers]: ', e));
    }

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  const navigate = useNavigate();
  const openUserProfile = (id: string) => {
    navigate(id);
  };

  const [editUserIndex, setEditUserIndex] = useState<number | null>(null);
  const [isShowEditUserModal, setIsShowEditUserModal] = useState<boolean>(false);
  const openEditUserModal = (index: number) => {
    setEditUserIndex(index);
    setIsShowEditUserModal(true);
  };
  const closeEditUserModal = () => setIsShowEditUserModal(false);

  const [isShowRemoveUserModal, setIsShowRemoveUserModal] = useState<boolean>(false);
  const [removeUserModalData, setRemoveUserModalData] = useState<any>({});
  const openRemoveUserModal = (userName: string, userId: string) => {
    setRemoveUserModalData({ userName, userId });
    setIsShowRemoveUserModal(true);
  };
  const closeRemoveUserModal = () => {
    setIsShowRemoveUserModal(false);
    setRemoveUserModalData({});
  };

  const [removeApplicationsData, setRemoveApplicationsData] = useState<any>({});
  const [isShowRemoveApplicationModal, setIsShowRemoveApplicationModal] = useState<boolean>(false);
  const openRemoveApplicationModal = (id: string, userName: string, applications: string[]) => {
    setRemoveApplicationsData({ id, userName, applications });
    setIsShowRemoveApplicationModal(true);
  };
  const closeRemoveApplicationModal = () => {
    setIsShowRemoveApplicationModal(false);
    setRemoveApplicationsData({});
  };

  const [isShowAssignApplicationSidebar, setIsShowAssignApplicationSidebar] = useState<boolean>(false);
  const [assignApplicationSidebarUserId, setAssignApplicationSidebarUserId] = useState<string>('');
  const fetchAccountUserAssigningAppData = async (userId: string) => {
    if (typeof subscriptionId === 'string') {
      await dispatch(getUserAvailableAssigningApps({ subscriptionId, userId })).unwrap();
    }
  };
  const openAssignApplicationSidebar = (id: string) => {
    fetchAccountUserAssigningAppData(id);
    setAssignApplicationSidebarUserId(id);
    setIsShowAssignApplicationSidebar(true);
  };
  const closeAssignApplicationSidebar = () => {
    setIsShowAssignApplicationSidebar(false);
    setAssignApplicationSidebarUserId('');

  };
  const onSuccessAssignApplication = () => {
    dispatch(getUsspCartData({ _isBackground: true })).unwrap();
  };

  if (error) {
    return <section className="dashboard-account-details__content"><Error code={500} /></section>;
  }

  return (
    <>
      <section className="dashboard-account-details__content">
        <FilterTags tags={filterTagsData} onClearAll={onClearAll}/>
        <table className="dashboard-accounts-users-table">
          <thead>
            <UsersTableHeadRow
              applicationsIdsValue={queryApplicationIds}
              initialSearchQuery={queryFilter}
              applicationsForFilter={applicationInfosData}
              orderState={queryOrderings?.[0]}
              onSearch={onSearch}
              onOrderChange={onOrderChange}
              onFilterByApplicationsChange={onFilterByProductsChange}
            />
          </thead>
          <tbody>
            {isShowLoader
              ? <UsersTableBodyRowLoader />
              : data?.users?.map((el: any, index: number) => (
                <UsersTableBodyRow
                  key={`account-users-details-${index}`}
                  {...el}
                  isActionsDisabled={isViewMode}
                  onViewProfile={() => openUserProfile(el.id as string)}
                  onEdit={() => openEditUserModal(index)}
                  openRemoveApplicationModal={openRemoveApplicationModal}
                  onRemove={openRemoveUserModal}
                  onAssignApplication={openAssignApplicationSidebar}
                />
              ))
            }
          </tbody>
        </table>
        {
          data?.users.length === 0
            ? <EmptyStateContainer
              title="No users found"
              description="No users found with specified parameters"
              classes="empty-state-container-table"
            />
            : null
        }
      </section>
      {
        data?.users.length > 0
          ? <Pagination
            current={queryPage}
            entries={queryPageSize}
            totalPages={totalPages}
            onChange={onPaginationChange}
          />
          : null
      }
      {isShowRemoveUserModal && (
        <RemoveUserModal
          visible={isShowRemoveUserModal}
          onCancel={closeRemoveUserModal}
          modalData={removeUserModalData}
          onSuccess={backgroundFetch}
        />
      )}
      {isShowEditUserModal && (
        <EditUserModal
          visible={isShowEditUserModal}
          userData={editUserIndex === null ? null : data?.users[editUserIndex]}
          subscriptionId={subscriptionId}
          onCancel={closeEditUserModal}
          onSuccessEdit={backgroundFetch}
        />
      )}
      {isShowRemoveApplicationModal && (
        <RemoveApplicationFromUserModal
          visible={isShowRemoveApplicationModal}
          onCancel={closeRemoveApplicationModal}
          removeApplicationsData={removeApplicationsData}
          onSuccess={backgroundFetch}
        />
      )}
      {isShowAssignApplicationSidebar && (
        <AssignApplicationSidebarModal
          visible={isShowAssignApplicationSidebar}
          onCancel={closeAssignApplicationSidebar}
          userId={assignApplicationSidebarUserId}
          onSuccess={onSuccessAssignApplication}
        />
      )}
    </>
  );
};

export default AccountDetailsUsers;
