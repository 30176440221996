import './OverviewInfo.scss';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAppSelector } from 'store/hooks';
import { ArrowRightSquareIcon } from 'assets/svg';
import { usePriceHandler } from 'helpers';
import { CountryCode } from 'enums';

interface Props {
  activeUsers: number;
  id: string;
  isLoading: boolean;
  nextBillingDate: string;
  nextBillingAmountInCents: number;
  totalApps: number;
  totalMonthlyFee: number;
}

const OverviewInfo: FC<Props> = ({
  activeUsers,
  id,
  isLoading,
  nextBillingDate,
  nextBillingAmountInCents,
  totalApps,
  totalMonthlyFee,
}) => {
  const navigate = useNavigate();
  const currentCountry = useAppSelector((state) => state.ussp.user?.country || CountryCode.USA);
  const { formatPrice } = usePriceHandler();

  const nextBillingDateFormated = useMemo(() => dayjs(nextBillingDate).format('MMM D, YYYY'), [nextBillingDate]);
  const nextBillingAmountInCentsFormated = useMemo(() => formatPrice((nextBillingAmountInCents ?? 0) / 100, currentCountry), [
    nextBillingAmountInCents,
    currentCountry,
  ]);

  const goToBillingDetailsPage = () => {
    if (id) {
      navigate(`billing/${id}`);
    }
  };

  return (
    <div className="overview-info rel-p-t-8">
      {
        isLoading ? Array.from({ length: 4 }, (_, index) => (
          <Skeleton key={`overview-info-skeleton-${index}`} containerClassName="overview-info__skeleton" height="100%" />
        )) : <>
          <div className="overview-info__item c-p" onClick={goToBillingDetailsPage}>
            <div className="overview-info__title rel-m-b-8">Next monthly billed amount</div>
            <div className="overview-info__value rel-m-b-8">{nextBillingAmountInCentsFormated}</div>
            <ArrowRightSquareIcon className="overview-info__icon"/>
          </div>
          <div className="overview-info__item">
            <div className="overview-info__title rel-m-b-8">Total applications</div>
            <div className="overview-info__value rel-m-b-8">{totalApps}</div>
          </div>
          <div className="overview-info__item">
            <div className="overview-info__title rel-m-b-8">Active users</div>
            <div className="overview-info__value rel-m-b-8">{activeUsers}</div>
          </div>
          <div className="overview-info__item c-p" onClick={goToBillingDetailsPage}>
            <div className="overview-info__title rel-m-b-8">Next billing date</div>
            <div className="overview-info__value rel-m-b-8">{nextBillingDateFormated}</div>
            <ArrowRightSquareIcon className="overview-info__icon"/>
          </div>
        </>
      }
    </div>
  );
};

export default OverviewInfo;