import { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { UserRole } from 'enums';

import { ToggleSwitch } from 'components';

interface IApplication {
  id: string;
  name: string;
}

interface Props {
  id: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber: string;
  avatarUrl: string;
  createdAt: string;
  applications: IApplication[];
  role: UserRole;
  mfaStatus: boolean;
  onAddPhoneNumber: (id: string) => void;
  onAddMfa: (id: string, userName: string, email: string) => void;
  onRemoveMfa: (id: string, userName: string, email: string) => void;
}

const formatDate = (value: string): string => dayjs(value).format('MMMM D, YYYY');

const UsersMfaTableBodyRow: FC<Props> = ({
  id,
  username,
  firstName,
  lastName,
  email,
  phoneNumber,
  // avatarUrl,
  createdAt,
  role,
  mfaStatus,
  onAddPhoneNumber,
  onAddMfa,
  onRemoveMfa,
}) => {
  const createdAtFormated = useMemo(() => formatDate(createdAt), [createdAt]);

  const userName = username || `${firstName} ${lastName}`;

  const toggleMfaStatus = (e: boolean) => {
    console.log('toggleMfaStatus => ', e);
    if (e) {
      onAddMfa(id, userName, email);
    } else {
      onRemoveMfa(id, userName, email);
    }
  };

  return (
    <tr className="users-table-body-row">
      <td>
        <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
          {/* <img
            className="app-card__icon-wrapper"
            src={avatarUrl || DefaultAvatarImage}
            alt={`${username} avatar`}
          /> */}
          <div className="app-card__content">
            <div className="app-card__name flex align-items-center rel-flex-gap-4">
              {userName}
              {role === UserRole.Admin && (
                <div className="user-role-label">Admin</div>
              )}
            </div>
            <div className="app-card__id">{email}</div>
          </div>
        </div>
      </td>

      <td>{createdAtFormated}</td>
      <td>
        {phoneNumber || (
          <span
            className="btn btn-text"
            onClick={() => onAddPhoneNumber(id)}
          >
            Add phone number*
          </span>
        )}
      </td>

      <td>
        <ToggleSwitch
          isChecked={mfaStatus}
          isDisabled={role === UserRole.Admin || !phoneNumber}
          onChange={toggleMfaStatus}
        />
      </td>
    </tr>
  );
};

export default UsersMfaTableBodyRow;
