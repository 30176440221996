import './CartSection.scss';
import { FC, ReactNode, useState } from 'react';

import { ArrowDownIcon, ArrowTopIcon, CloseIcon } from 'assets/svg';

interface Props {
  title: string;
  description: string;
  totalPrice: string;
  Icon: FC;
  onRemove: () => void;
  children?: ReactNode;
}

const CartSection: FC<Props> = ({
  title,
  description,
  totalPrice,
  Icon,
  onRemove,
  children,
}) => {
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);
  const toggleShowDetails = () => {
    setIsShowDetails(!isShowDetails);
  };

  return (
    <div className="cart-section rel-p-b-4 rel-m-b-20">
      <div className="flex flex-justify-between rel-flex-gap-16 rel-m-b-16">
        <CloseIcon className="cart-section__close-icon" onClick={onRemove} />
        <div className="cart-section__icon-wrapper flex flex-justify-center flex-align-center">
          <Icon />
        </div>
        <div className="flex-1">
          <h3 className="cart-section__title">{title}</h3>
          <p className="cart-section__description rel-m-b-4">{description}</p>
          {children && <div className="cart-section__details-trigger" onClick={toggleShowDetails}>
            {isShowDetails
              ? <>Hide details <ArrowTopIcon /></>
              : <>Details <ArrowDownIcon /></>
            }
          </div>}
        </div>
        <div className="cart-section__total-price">{totalPrice}</div>
      </div>
      {isShowDetails && (
        <div className="cart-section__details rel-m-b-16">
          {children}
        </div>
      )}
    </div>
  );
};

export default CartSection;