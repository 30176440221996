import './InputText.scss';
import { FC, ReactElement, FormEvent, useMemo, Ref } from 'react';
import cn from 'classnames';
import { UseFormRegister } from 'react-hook-form';
import Lottie from 'lottie-react';
import loaderAnimation from 'assets/lotties/button-loader.json';

import { CloseOutlineIcon, InfoCircleIcon } from 'assets/svg';

import { makeVw } from 'helpers';

import Tooltip from '../Tooltip/Tooltip';

interface IconProps {
  className?: string;
}
interface IValidationRuleItem {
  value?: RegExp;
  message?: string;
}

interface IValidationRules {
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: any | IValidationRuleItem; // @todo
  valueAsNumber?: boolean;
  validate?: any;
  setValueAs?: (v: any) => any;
}

interface Props {
  xVersion?: boolean;
  absoluteStyles?: boolean;
  inpurRef?: Ref<HTMLInputElement>;
  value?: string;
  label?: string;
  labelRight?: string;
  name?: string;
  type?: 'text' | 'email';
  theme?: 'light' | 'dark' | 'grey';
  classes?: string;
  placeholder?: string;
  readOnly?: boolean;
  isRequired?: boolean;
  isOptional?: boolean;
  isInvalid?: boolean;
  errorText?: string;
  withInfo?: boolean;
  withClear?: boolean;
  showClearAlways?: boolean;
  infoContent?: string | ReactElement;
  icon?: FC<IconProps>;
  isWithButton?: boolean;
  buttonText?: string;
  buttonWidth?: number;
  dataTestId?: string;
  buttonDisabled?: boolean;
  isButtonLoading?: boolean;
  validationRules?: IValidationRules;
  isDisabled?: boolean;
  isDisabledOpacity?: boolean;
  isReturnEvent?: boolean;
  shouldUnregister?: boolean;
  isNewStyle?: boolean;
  maxLength?: number;
  register?: UseFormRegister<any>;
  buttonAction?: () => void;
  onChange?: (value: string | any) => void;
  onBlur?: (value: string | any) => void;
  onFocus?: (value: string | any) => void;
  onClear?: () => void;
}

const InputText: FC<Props> = (
  {
    xVersion,
    inpurRef,
    value,
    label,
    labelRight,
    name,
    type = 'text',
    theme = 'light',
    classes,
    placeholder,
    readOnly = false,
    isRequired = false,
    isOptional,
    withClear = false,
    showClearAlways = false,
    isInvalid = false,
    errorText = '',
    isWithButton,
    withInfo = false,
    infoContent,
    icon: IconComponent,
    buttonText,
    buttonWidth,
    buttonDisabled,
    dataTestId,
    isButtonLoading = false,
    validationRules = {},
    isDisabled = false,
    isDisabledOpacity,
    isReturnEvent,
    shouldUnregister = true,
    isNewStyle = false,
    absoluteStyles = false,
    maxLength,
    register,
    buttonAction,
    onChange,
    onBlur,
    onFocus,
    onClear,
  }) => {
  const inputProps = useMemo(() => {
    return register && name
      ? { ...register(name, {
        shouldUnregister,
        required: isRequired,
        ...validationRules,
      }) }
      : {
        ref: inpurRef,
        value: value || '',
        onChange: (e: FormEvent<HTMLInputElement>) => {
          let inputValue = (e.target as HTMLInputElement).value;
          if (maxLength && inputValue.length > maxLength) {
            inputValue = inputValue.slice(0, maxLength);
          }
          if (onChange !== undefined) {
            const value = isReturnEvent ? e : inputValue;
            onChange(value);
          }
        },
        onBlur: (e: FormEvent<HTMLInputElement>) => {
          if (onBlur !== undefined) {
            onBlur(e);
          }
        },
        onFocus: (e: FormEvent<HTMLInputElement>) => {
          if (onFocus !== undefined) {
            onFocus(e);
          }
        },
      };
  }, [register, name, value, onChange]);

  const buttonWidthStyle = useMemo(
    () => buttonWidth ? { width: makeVw(buttonWidth) } : {},
    [buttonWidth],
  );

  const showClearButton = useMemo(
    () => {
      if (!withClear) {
        return false;
      }
      return showClearAlways || value;
    },
    [showClearAlways, withClear, value]
  );

  const onClearClick = () => {
    onChange && onChange('');
    onClear && onClear();
  };

  return (
    <div className={cn('input-text', theme, classes, { 'relative-units': !absoluteStyles, xVersion, })}>
      {label && (
        <label>
          <div>
            {label}
            {isOptional && <span> (optional)</span>}
            {isRequired && <span className="req">*</span>}
            {(withInfo || infoContent) && (
              <Tooltip
                absoluteStyles={absoluteStyles}
                isDisabled={!infoContent}
                content={infoContent ?? ''}
                placement="top-start"
                isInline
              >
                <InfoCircleIcon className="input-icon" />
              </Tooltip>
            )}
          </div>
          {labelRight && <span className="input-text-label-right">{labelRight}</span>}
        </label>
      )}
      <div className={cn('input-wrapper', {
        invalid: isInvalid,
        disabled: isDisabled,
        'read-only': readOnly,
        'disabled-opacity': isDisabledOpacity,
      })}>
        {IconComponent && <IconComponent className={`input-icon ${absoluteStyles ? 'm-l-12 m-r--4': 'rel-m-l-12'}`} />}
        <input
          data-test-id={dataTestId}
          type={type}
          placeholder={placeholder}
          disabled={isDisabled}
          readOnly={readOnly}
          {...inputProps}
        />
        {showClearButton &&
          (<button className={cn("btn input-clear-button")} type="button" onClick={onClearClick}>
            <CloseOutlineIcon />
          </button>)
        }
        {isWithButton &&
            <button
              type="button"
              className={cn('btn input-btn primary small', { loading: isButtonLoading, 'new-style': isNewStyle, 'relative-units': !absoluteStyles })}
              style={buttonWidthStyle}
              disabled={!value && buttonDisabled || isButtonLoading}
              onClick={buttonAction}
            >
              {isButtonLoading ? <Lottie animationData={loaderAnimation} loop /> : buttonText }
            </button>
        }
      </div>
      {isInvalid && errorText && (
        <div className="input-error">
          {errorText}
        </div>
      )}
    </div>
  );
};

export default InputText;