import { InfoCircleIcon } from 'assets/svg';
import { Modal } from 'components';
import { notify } from 'helpers';
import { FC } from 'react';
import { useParams } from 'react-router';
import { removeUser } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IModalData {
  userName: string;
  userId: string;
}

interface Props {
  visible: boolean;
  onCancel: () => void;
  modalData: IModalData;
  onSuccess: () => void;
  isAdminPortal?: boolean;
}

const RemoveUserModal: FC<Props> = ({ visible, onCancel, modalData, onSuccess, isAdminPortal }) => {
  const { userName, userId } = modalData;

  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const { isLoading } = useAppSelector(state => state.ussp.removeUserRequest);

  const handleRemoveUser = async () => {
    if (typeof subscriptionId === 'string') {
      try {
        const data = await dispatch(removeUser({
          subscriptionId,
          isAdminPortal,
          userId,
          _disableErrorHandler: true,
        })).unwrap();
        onCancel();
        if (isAdminPortal || data) {
          notify.success('This user has been removed', 'You cannot return this user');
          onSuccess();
        } else {
          notify.error('This user can\'t be removed');
        }
      } catch (e) {
        notify.error('This user can\'t be removed');
      }
    }
  };

  return (
    <Modal
      classes="dashboard-accounts-remove-user"
      visible={visible}
      onCancel={onCancel}
      title="Remove User"
      onOk={handleRemoveUser}
      isOkLoading={isLoading}
      width={535}
      okText="Yes, Remove"
    >
      <div className="info-block">
        {isAdminPortal
          ? `The client's account will be updated with the latest changes.`
          : `Your billing will be updated based on your recent changes.`
        }
      </div>
      <div className="block-info relative-units remove-application-info">
        <InfoCircleIcon />
        <div className="block-info__content remove-application-info__content">
          If you click <strong>Remove</strong>, the user <strong>{userName}</strong> will no longer be able to login,
          your billing will be updated, and their Summit user profile will also be deleted.
        </div>
      </div>
    </Modal>
  );
};

export default RemoveUserModal;