import React, { FC } from 'react';

const BillingDetailsTableHeadRow: FC = () => (
  <tr>
    <th>PRODUCT NAME</th>
    <th>QTY OF USERS</th>
    <th>UNIT PRICE</th>
    <th>AMOUNT</th>
  </tr>
);

export default BillingDetailsTableHeadRow;
