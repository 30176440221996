import './GeneralInfo.scss';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { makeVh, notify, usePriceHandler } from 'helpers';

import {
  getAdminSubscriptionTerms,
  updateAdminSubscriptionTerms,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { BILLING_STATUS_CLASSES, COUNTRY_FLAG_BY_COUNTRY_CODE, COUNTRY_NAMES_BY_CODE } from 'consts';
import { BillingStatus, CountryCode } from 'enums';

import { Button, Modal, Select } from 'components';

interface IconProps {
  className?: string;
}
interface Props {
  classes: string;
  isLoading: boolean;
  status: BillingStatus;
  country: CountryCode;
  countryCode: CountryCode;
  countryName: string;
  balanceInCents: null | number;
  creditBalanceInCents: null | number;
  activatedAt: string;
  billingAmountInCents: null | number;
  nextBillingAmountInCents: null | number;
  nextBillingDate: string;
  proformaInvoiceUrl: string;
  term: string;
  onTermsChange: () => void;
}

const formatDate = (value: string): string => dayjs(value).format('MM/DD/YYYY');
const formatDatetime = (value: string): string => dayjs(value).format('MM/DD/YYYY hh:mmA');
const renderIcon = (IconComponent: FC<IconProps> | undefined) => {
  return IconComponent && <IconComponent />;
};

const GeneralInfo: FC<Props> = ({
  classes,
  isLoading,
  status,
  country,
  countryCode,
  countryName,
  balanceInCents,
  creditBalanceInCents,
  activatedAt,
  nextBillingAmountInCents,
  nextBillingDate,
  proformaInvoiceUrl,
  term,
  onTermsChange,
}) => {
  const { subscriptionId } = useParams();
  const dispatch = useAppDispatch();
  const { formatPrice } = usePriceHandler();
  const formatedBalance = useMemo(
    () => formatPrice((balanceInCents ?? 0) / 100, country || CountryCode.USA),
    [balanceInCents],
  );
  const formatedCreditBalance = useMemo(
    () => formatPrice((creditBalanceInCents ?? 0) / 100, country || CountryCode.USA),
    [creditBalanceInCents],
  );
  const formatedActivatedAt = useMemo(() => formatDate(activatedAt), [activatedAt]);
  const formatedNextBillingAmountInCents = useMemo(
    () => formatPrice((nextBillingAmountInCents ?? 0) / 100, country || CountryCode.USA),
    [nextBillingAmountInCents],
  );
  const formatedNextBillingDate = useMemo(() => formatDatetime(nextBillingDate), [nextBillingDate]);

  const {
    data: availableTerms = [],
    isLoading: isTermsLoading,
  } = useAppSelector(
    (state) => state.ussp.getAdminSubscriptionTermsRequest || {},
  );
  const [selectedTerms, setSelectedTerms] = useState<string>('');
  const [isLoadingUpdateTerms, setIsLoadingUpdateTerms] = useState<boolean>(false);
  const [isShowTermsModal, setIsShowTermsModal] = useState<boolean>(false);
  const openTermsModal = async () => {
    await dispatch(getAdminSubscriptionTerms()).unwrap();
    setIsShowTermsModal(true);
  };
  const hideTermsModal = () => {
    setIsShowTermsModal(false);
    setSelectedTerms('');
  };

  const statusFormated = useMemo(() => (status || '')
    .split('_')
    .map(el => `${el.charAt(0).toUpperCase()}${el.slice(1)}`)
    .join(' ')
  , [status]);

  const updateTerms = async () => {
    if (typeof subscriptionId === 'string') {
      setIsLoadingUpdateTerms(true);
      await dispatch(updateAdminSubscriptionTerms({
        subscriptionId,
        termsId: selectedTerms,
      })).unwrap();
      onTermsChange();
      hideTermsModal();
      notify.success('Success', 'Term successfully applied');
      setIsLoadingUpdateTerms(false);
    }
  };

  const previewProforma = () => {
    window.open(proformaInvoiceUrl, '_blank');
  };

  return (
    <>
      <div className={classes}>
        <div
          className="flex flex-justify-between flex-align-center rel-p-x-24 rel-p-y-16"
        >
          Status
          {isLoading ? (
            <Skeleton height="100%" width="150px" />
          ) : (
            <span className={BILLING_STATUS_CLASSES[status]}>
              {statusFormated}
            </span>
          )}
        </div>
        <div
          className="flex flex-justify-between flex-align-center rel-p-x-24 rel-p-y-16"
        >
          Country
          {isLoading ? (
            <Skeleton height={makeVh(20)} width="100px" />
          ) : (
            <div className="country-with-icon">
              <div className="option-icon">{renderIcon(COUNTRY_FLAG_BY_COUNTRY_CODE[countryCode] || COUNTRY_FLAG_BY_COUNTRY_CODE.OTHER)}</div>
              <div className="fw-600 flex flex-align-center">{COUNTRY_NAMES_BY_CODE[countryCode] || countryName}</div>
            </div>
          )}
        </div>
        <div
          className="flex flex-justify-between flex-align-center rel-p-x-24 rel-p-y-16"
        >
          Current Balance
          {isLoading ? (
            <Skeleton height="100%" width="140px" />
          ) : (
            <span className="fw-600">{formatedBalance}</span>
          )}
        </div>
        <div
          className="flex flex-justify-between flex-align-center rel-p-x-24 rel-p-y-16"
        >
          Service Credit Balance
          {isLoading ? (
            <Skeleton height="100%" width="130px" />
          ) : (
            <span className="fw-600">{formatedCreditBalance}</span>
          )}
        </div>
        <div
          className="flex flex-justify-between flex-align-center rel-p-x-24 rel-p-y-16"
        >
          Activated At
          {isLoading ? (
            <Skeleton height="100%" width="160px" />
          ) : (
            <span className="fw-600">{formatedActivatedAt}</span>
          )}
        </div>
        <div
          className="flex flex-justify-between rel-p-x-24 rel-p-y-16"
        >
          Subscription Terms
          {isLoading ? (
            <Skeleton height="100%" width="130px" />
          ) : (
            <div className="flex flex-align-end flex-column">
              {term && <p className="fw-600 text-right rel-m-b-8">{term}</p>}
              <Button
                isLoading={isTermsLoading}
                isNewStyle
                type={term ? 'default' : 'primary'}
                size="small"
                onClick={openTermsModal}
              >
                {term ? 'Change' : 'Add Terms'}
              </Button>
              {/* <button
                className={`btn ${term ? 'default' : 'primary'} small new-style`}
                disabled={isTermsLoading}
                onClick={openTermsModal}
              >
                {term ? 'Change' : 'Add Terms'}
              </button> */}
            </div>
          )}
        </div>
        <div
          className="flex flex-justify-between flex-align-start rel-p-x-24 rel-p-y-16"
        >
          Next Billing
          <div className="text-right">
            {isLoading ? (
              <Skeleton height="100%" width="200px" />
            ) : (
              <p className="fw-600">
                {formatedNextBillingAmountInCents} on&nbsp;
                {formatedNextBillingDate} EDT
              </p>
            )}
            <button
              className="btn relative-units default small rel-m-t-12 new-style"
              disabled={!proformaInvoiceUrl}
              onClick={previewProforma}
            >
              Preview Proforma
            </button>
          </div>
        </div>
      </div>
      {isShowTermsModal && (
        <Modal
          title="Subscription Terms"
          visible={isShowTermsModal}
          onOk={updateTerms}
          isOkDisabled={!selectedTerms}
          isOkLoading={isLoadingUpdateTerms}
          okText="Apply"
          onCancel={hideTermsModal}
          width={535}
        >
          <Select
            value={selectedTerms}
            classes="rel-m-b-20"
            options={availableTerms.map(({ name, id }: any = {}) => ({
              label: name,
              value: id,
              disabled: name === term,
            }))}
            label="Select subscription terms"
            placeholder="None"
            isRequired
            onChange={setSelectedTerms}
          />
        </Modal>
      )}
    </>
  );
};

export default GeneralInfo;
