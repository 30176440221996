import './TotalPriceModalFooter.scss';
import { FC, ReactElement, useMemo } from 'react';

import { usePriceHandler } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { IDashboardUser } from 'interfaces';
import { CountryCode } from 'enums';

import { InfoCircleIcon } from 'assets/svg';
import { Tooltip } from 'components';

interface Props {
  price: number;
  quantity?: number;
  withInfo?: boolean;
  infoContent?: string | ReactElement;
  country?: CountryCode;
  oneTimeFee?: boolean;
}

const TotalPriceModalFooter: FC<Props> = ({
  price,
  quantity = 1,
  withInfo = false,
  infoContent,
  country,
  oneTimeFee
}) => {
  const user = useAppSelector<IDashboardUser | null>(state => state.ussp.user);

  const { formatPrice } = usePriceHandler();

  const formatedPrice = useMemo(
    () => formatPrice((price || 0) * quantity, country || user?.country),
    [price, quantity],
  );

  return (
    <div className="total-price-modal-footer m-r-auto flex flex-align-center">
      {oneTimeFee ? 'One-Time Fee' : 'Total monthly price'}&nbsp;
      <span>{formatedPrice}</span>&nbsp;
      {withInfo && <Tooltip
        isDisabled={!infoContent}
        content={infoContent ?? ''}
        placement="top-start"
        isFlex>
        <InfoCircleIcon className="total-price-modal-footer__info-icon"/>
      </Tooltip>}
    </div>
  );
};

export default TotalPriceModalFooter;
