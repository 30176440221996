import { FC, ReactNode } from 'react';
import cn from 'classnames';
import {
  InfoCircleIcon,
  TickSquareIcon,
  CloseSquareIcon,
  CloseIcon,
} from 'assets/svg';
import './Alert.scss';

interface Props {
  absoluteStyles?: boolean;
  classes?: string;
  CustomIcon?: FC;
  iconPosition?: 'top' | 'center' | 'bottom';
  fullWidth?: boolean;
  message: string | ReactNode;
  textFontWeight?: 400 | 500 | 600;
  type?: 'info' | 'success' | 'error';
  theme?: 'light' | 'dark' | 'default';
  onClose?: () => void;
}

const FLEX_ALIGN_ACCORDANCE = {
  top: 'start',
  center: 'center',
  bottom: 'end',
};

const ICONS_BY_TYPE = {
  info: InfoCircleIcon,
  success: TickSquareIcon,
  error: CloseSquareIcon,
};

const Alert: FC<Props> = ({
  classes = '',
  CustomIcon,
  iconPosition = 'center',
  absoluteStyles = false,
  fullWidth = false,
  message,
  textFontWeight = 600,
  type = 'info',
  theme = 'default',
  onClose,
}) => {
  const Icon = CustomIcon || ICONS_BY_TYPE[type];

  const wrapperFlexClasses = `flex flex-${FLEX_ALIGN_ACCORDANCE[iconPosition]}`;

  return (
    <div className={cn('alert', wrapperFlexClasses, type, theme, classes, { 'alert--full-width': fullWidth, 'relative-units': !absoluteStyles })}>
      <div className="alert-icon">
        <Icon />
      </div>
      <div className="alert-text" style={{ fontWeight: textFontWeight }}>{ message }</div>
      {onClose && (
        <CloseIcon className="alert__close-icon" onClick={onClose} />
      )}
    </div>
  );
};

export default Alert;