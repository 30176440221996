import './UploadUserAvatar.scss';
import { FC, useState, FormEvent, useRef } from 'react';

import { notify } from 'helpers';
import { UsersIcon } from 'assets/svg';

interface Props {
  currentUserAvatar?: string;
  onUpload: (file: undefined | null | Blob) => void;
}

const UploadUserAvatar: FC<Props> = ({
  currentUserAvatar,
  onUpload,
}) => {
  const avatarFileInput = useRef<HTMLInputElement>(null);
  const [userAvatar, setUserAvatar] = useState<string>(currentUserAvatar ?? '');

  const removeUserAvatar = () => {
    if (avatarFileInput.current?.value) {
      avatarFileInput.current.value = '';
    }
    if (userAvatar) {
      setUserAvatar('');
      onUpload(currentUserAvatar ? null : undefined);
    }
  };

  const changeUserAvatar = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target?.files?.length) {
      const file = target.files[0];
      onUpload(file);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function() {
        setUserAvatar(reader?.result as string ?? '');
      };

      reader.onerror = function() {
        notify.error('Error', 'Try to upload avatar again!');
      };
    }
  };

  return (
    <div className="upload-user-avatar rel-m-b-24 flex flex-align-center rel-flex-gap-24">
      <div className="upload-user-avatar__img-container">
        {userAvatar ? (
          <img src={userAvatar} alt="user avatar" />
        ) : (
          <UsersIcon />
        )}
      </div>
      <div
        className="upload-user-avatar__actions flex flex-wrap rel-flex-gap-8"
      >
        <label
          className="btn primary"
          htmlFor="user-avatar"
        >
          Upload image
        </label>
        <input
          ref={avatarFileInput}
          type="file"
          id="user-avatar"
          name="avatar"
          accept="image/png, image/jpeg"
          hidden
          onChange={changeUserAvatar}
        />
        <button
          className="btn relative-units default"
          type="button"
          onClick={removeUserAvatar}
        >
          Remove
        </button>
        <span>PNG, JPG, 10 MB max</span>
      </div>
    </div>
  );
};

export default UploadUserAvatar;
