import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AccessTokenType, UsspRole } from 'enums';

const PublicRoute: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const accessToken = localStorage.getItem(AccessTokenType.AccessTokenUssp);
  if (accessToken) {
    const decodedToken: { role: UsspRole } = jwtDecode(accessToken)
      || { role: UsspRole.Customer };

    const pagePath = UsspRole.Admin === (decodedToken?.role as UsspRole)
      ? '/admin-portal'
      : '/user-portal';
    // logged in so redirect to admin or user portal page
    return <Navigate to={pagePath} />;
  }
  // not authorized so return child components
  return children;
};

export default PublicRoute;
