import './AssignAppTableBodyRow.scss';
import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { Controller, Control } from 'react-hook-form';

import { ProductChargify } from 'enums';
import { IOtherProduct } from 'interfaces';
import { useAppSelector } from 'store/hooks';

import { Select } from 'components';

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  filledProducts: { [key: string]: boolean };
  control: Control<any>;
  userIndex: number;
  activeSelectIndex: null | number;
  onActiveAppSelect: (value: boolean) => void;
}

const DEFAULT_USER_PRODUCTS = [
  ProductChargify.SHServerUserHosting,
];

const AssignAppTableBodyRow: FC<Props> = ({
  firstName,
  lastName,
  email,
  filledProducts,
  control,
  userIndex,
  activeSelectIndex,
  onActiveAppSelect,
}) => {
  const otherProductsInfo: { [key: string]: IOtherProduct } = useAppSelector(
    state => state.products.otherProductsInfo,
  );
  const otherProducts = useAppSelector(state => state.order.otherProducts || []);

  const excludedSelectProducts = useMemo(() => {
    return [
      ...DEFAULT_USER_PRODUCTS,
      ...otherProducts.reduce((acc, { name }) => {
        if (otherProductsInfo[name].isSubscriptionProduct) {
          acc.push(name);
        }

        return acc;
      }, [] as string[]),
    ];
  }, [otherProducts]);

  const isDisabled = useMemo(() => {
    return activeSelectIndex !== null && activeSelectIndex !== userIndex;
  }, [activeSelectIndex, userIndex]);
  return (
    <tr className={cn({ 'disabled-block': isDisabled })}>
      <td>
        <h6 className="user-name">{firstName} {lastName}</h6>
        <p className="user-email">{email}</p>
      </td>
      <td className="app-select-wrapper">
        <Controller
          name={`assignProducts.${userIndex}.products`}
          control={control}
          defaultValue=""
          render={({
            field: { onChange, value },
          }) => (
            <Select
              value={value.filter((el: ProductChargify) => !DEFAULT_USER_PRODUCTS.includes(el))}
              onChange={(e: ProductChargify[]) => onChange(DEFAULT_USER_PRODUCTS.concat(e))}
              isRequired
              dropdownHeight="250"
              placeholder="Select products"
              theme="dark"
              emptyOptionsText="No application to select..."
              isMulti
              options={Object.entries(filledProducts).reduce((acc, [key, filledProductValue]) => {
                const isFiltred = !excludedSelectProducts.includes(key as ProductChargify)
                  && (!filledProductValue || value.includes(key));
                if (isFiltred) {
                  acc.push(key);
                }
                return acc;
              }, [] as string[])}
              onChangeDropdownState={onActiveAppSelect}
            />
          )}
        />
      </td>
    </tr>
  );
};

export default AssignAppTableBodyRow;
