import { AsyncThunk, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { notify } from './notification';
import { BffTypesUrlStart, AccessTokenType, RefreshTokenType, SpecificCode, LogType } from 'enums';
import { EXPIRED_TIME, ERROR_MESSAGES_BY_SPECIFIC_CODE, INTENDED_URL, SESSION_EXPIRED } from 'consts';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { addLog } from './logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IRequestConfig<D = any> extends AxiosRequestConfig {
  _retry?: boolean;
  _disableErrorHandler?: boolean;
}
export interface IAxiosError<D = any> extends AxiosError {
  config: IRequestConfig<D>;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Set the Auth token for any request
instance.interceptors.request.use((config: any) => {
  if (config.url.startsWith(BffTypesUrlStart.SfBff)) {
    // simple form requests handle
    const token = sessionStorage.getItem(AccessTokenType.AccessTokenSf);
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  } else if (config.url.startsWith(BffTypesUrlStart.UsspBff)) {
    // ussp requests handle
    const token = localStorage.getItem(AccessTokenType.AccessTokenUssp);
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig: IRequestConfig = err.config;
    if (originalConfig && originalConfig.url && originalConfig.url.startsWith(BffTypesUrlStart.SfBff)) {
      // simple form requests handle
      const refresh_token = sessionStorage.getItem(RefreshTokenType.RefreshTokenSf);
      if (err.response.status === 401 && !originalConfig._retry && refresh_token) { // change tp 401
        originalConfig._retry = true;
        try {
          const tokenResponse: {
            data: {
              access_token: string;
              refresh_token: string;
            };
          } = await instance.post(`/bff/users/refreshToken`, {
            refreshToken: refresh_token,
          });
          if (tokenResponse && tokenResponse.data && tokenResponse.data.access_token) {
            sessionStorage.setItem(AccessTokenType.AccessTokenSf, tokenResponse.data.access_token);
          }
          if (tokenResponse && tokenResponse.data && tokenResponse.data.refresh_token) {
            sessionStorage.setItem(RefreshTokenType.RefreshTokenSf, tokenResponse.data.refresh_token);
          }
          return instance(originalConfig);
        } catch (_error) {
          // return original 401 error if refresh token invalid
          return Promise.reject(err);
          // return Promise.reject(_error);
        }
      }
    } else if (originalConfig && originalConfig.url && originalConfig.url.startsWith(BffTypesUrlStart.UsspBff)) {
      // ussp requests handle

      const refresh_token = localStorage.getItem(RefreshTokenType.RefreshTokenUssp);
      if (err.response.status === 401 && !originalConfig._retry && refresh_token) { // change tp 401
        originalConfig._retry = true;
        try {
          const tokenResponse: {
            data: {
              access_token: string;
              refresh_token: string;
            };
          } = await instance.post(`/bff-ussp/auth/refreshToken`, {
            refreshToken: refresh_token,
          });
          if (tokenResponse && tokenResponse.data && tokenResponse.data.access_token) {
            localStorage.setItem(AccessTokenType.AccessTokenUssp, tokenResponse.data.access_token);
          }
          if (tokenResponse && tokenResponse.data && tokenResponse.data.refresh_token) {
            localStorage.setItem(RefreshTokenType.RefreshTokenUssp, tokenResponse.data.refresh_token);
          }
          return instance(originalConfig);
        } catch (_error) {
          // return original 401 error if refresh token invalid
          return Promise.reject(err);
          // return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);

// const handleHttpError = (error: IAxiosError) => {
//   const genericErrorMessage = 'Something Failed. Try again?';
//   let message = genericErrorMessage;
//   if (typeof error.response?.data === 'string') {
//     message = error.response.data;
//   } else if (!!error.response?.data?.title) {
//     message = error.response.data.title;
//   }
//   const newError: SerializedError = new Error(message);
//   newError.code = `${error.response?.status}`;
//   if (error.config && error.config.url && error.config.url.startsWith(BffTypesUrlStart.SfBff)) {
//     if (newError.code == '401') {
//       newError.message = "A subscription wasn't created, please start again";
//       setTimeout(() => {
//         sessionStorage.removeItem('applicationState');
//         window.location.href = '/';
//       }, 3000);
//     }
//     if (!error.config._disableErrorHandler) {
//       notify.error(
//         newError.code == '401' ? 'Your session has expired' : 'Error',
//         newError.message,
//       );
//     }
//   } else if (error.config && error.config.url && error.config.url.startsWith(BffTypesUrlStart.UsspBff)) {
//     if (newError.code == '401') {
//       // remove localstorage tokens
//       localStorage.removeItem(AccessTokenType.AccessTokenUssp);
//       localStorage.removeItem(RefreshTokenType.RefreshTokenUssp);
//       // remove IDLE expired time
//       localStorage.removeItem(EXPIRED_TIME);
//       // remove session storage state
//       sessionStorage.removeItem('applicationState');
//       window.location.href = '/login';
//     }
//     if (!error.config._disableErrorHandler) {
//       notify.error(
//         'Error',
//         newError.message,
//       );
//     }
//   }
//   return newError;
// };
const handleHttpError = (error: IAxiosError) => {
  const genericErrorMessage = 'Something Failed. Try again?';
  let message = genericErrorMessage;
  if (typeof error.response?.data === 'string') {
    message = error.response.data;
  } else if (!!error.response?.data?.title) {
    message = error.response.data.title;
  }

  // Handle error with specific code
  const errorMessageBySpecificCode: string = error.response?.data?.code
    && ERROR_MESSAGES_BY_SPECIFIC_CODE[error.response?.data?.code as SpecificCode];

  const newError: SerializedError = new Error(
    errorMessageBySpecificCode ? errorMessageBySpecificCode : message,
  );
  newError.code = `${error.response?.status}`;
  let errorTitle = 'Error';

  if (error?.config?.url && newError.code == '401') {
    if (error.config.url.startsWith(BffTypesUrlStart.SfBff)) {
      newError.message = "A subscription wasn't created, please start again";
      setTimeout(() => {
        sessionStorage.removeItem('applicationState');
        window.location.href = '/sign-up';
      }, 3000);
      errorTitle = 'Your session has expired';
    } else if (error.config.url.startsWith(BffTypesUrlStart.UsspBff)) {
      // remove localstorage tokens
      localStorage.removeItem(AccessTokenType.AccessTokenUssp);
      localStorage.removeItem(RefreshTokenType.RefreshTokenUssp);
      // remove IDLE expired time
      localStorage.removeItem(EXPIRED_TIME);
      // remove session storage state
      sessionStorage.removeItem('applicationState');
      localStorage.setItem(SESSION_EXPIRED, 'true');
      sessionStorage.setItem(INTENDED_URL, window.location.pathname + window.location.search);
      window.location.href = '/login';
      addLog(LogType.Logout, '401 http error, redirect to logout');
    }
  }
  if (!error?.config?._disableErrorHandler) {
    notify.error(
      errorTitle,
      newError.message,
    );
  }
  return newError;
};

const makeHttpRequest = (apiCall: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await apiCall();
      resolve(response.data);
    } catch (e: any) {
      reject(handleHttpError(e as AxiosError));
    }
  });

export const http = {
  get: (url: string, options?: IRequestConfig) =>
    makeHttpRequest(() => instance.get(url, options)),
  post: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => instance.post(url, data, options)),
  put: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => instance.put(url, data, options)),
  patch: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => instance.patch(url, data, options)),
  delete: (url: string, options?: IRequestConfig) =>
    makeHttpRequest(() => instance.delete(url, options)),
};

export const createHttpRequestInitResult = () => ({
  data: undefined,
  error: undefined,
  isUninitialized: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
});

export const createExtraReducersForResponses = (
  asyncThunk: AsyncThunk<any, any, any>,
  reduxField: string,
  successCallback?: (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => void,
) => ({
  [asyncThunk.pending.type]: (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
    if (!action.meta?.arg?._isBackground) {
      state[reduxField].data = undefined;
    }
    state[reduxField].error = undefined;
    state[reduxField].isError = false;
    state[reduxField].isSuccess = false;
    state[reduxField].isLoading = true;
    state[reduxField].isUninitialized = true;
  },
  [asyncThunk.rejected.type]: (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
    state[reduxField].data = undefined;
    state[reduxField].isLoading = false;
    state[reduxField].isError = true;
    state[reduxField].error = action.error;
  },
  [asyncThunk.fulfilled.type]: (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
    state[reduxField].isLoading = false;
    state[reduxField].isSuccess = true;
    state[reduxField].data = action.payload;
    if (successCallback) {
      successCallback(state, action);
    }
  },
});

export default http;