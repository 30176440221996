import { Button, ProgressBar, Select, StepPriceDetails } from 'components';
import './ServerUserHosting.scss';
import { FC, useMemo, useState } from 'react';
import _ from 'lodash';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ProductChargify, SignUpNavigation } from 'enums';
import { useGetSignUpTotalPriceByStep, usePriceHandler, useSignUpFormNavigate } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { refreshAccessToken, resetProductsWithQuantity, setHostProduct } from 'store';

type ServerUserHostingForm = {
  usersQuantity: string;
};

const ServerUserHosting: FC = () => {
  const dispatch = useAppDispatch();
  const hostProduct = useAppSelector(state => state.order.hostProduct);
  const {
    getValues,
    formState,
    watch,
    control,
    handleSubmit,
  } = useForm<ServerUserHostingForm>({
    mode: 'all', defaultValues: {
      usersQuantity: hostProduct.quantity || '1',
    },
  });
  const { getProductPrice } = usePriceHandler();
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const simpleFormNavigate = useSignUpFormNavigate();

  const numbersRange = useMemo(() => _.range(1, 100).map(el => el.toString()), []);

  const { isValid } = formState;
  const STEP_PRODUCTS = useMemo(() => {
    return [
      { name: ProductChargify.SHServerUserHosting, quantity: (getValues())?.usersQuantity.toString() || '0' },
      { name: ProductChargify.SSW, quantity: '1' },
    ];
  }, [watch('usersQuantity')]);
  const onNextHandler: SubmitHandler<ServerUserHostingForm> = async (data) => {
    setIsNextLoading(true);
    try {
      const { usersQuantity } = data;
      if (+hostProduct.quantity > +usersQuantity) {
        dispatch(resetProductsWithQuantity());
      }
      dispatch(setHostProduct({ ...hostProduct, quantity: `${Number(usersQuantity)}` }));
      // check session
      await dispatch(refreshAccessToken(true));
    } catch (e: any) {
      //error
    } finally {
      simpleFormNavigate(SignUpNavigation.ProductsList);
      setIsNextLoading(false);
    }
  };
  return (
    <>
      <div className="container-sm">
        <ProgressBar currentStep={3} totalSteps={7}/>

        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-8">Number of Users</div>
          <div className="sign-up-form-step-description m-b-40">
            How many users will be accessing your server?
          </div>
          <div className="user-price-wrapper m-b-12">
            <div className="user-price-label">Price per user</div>
            <div className="user-price-count">{getProductPrice(ProductChargify.SHServerUserHosting)}</div>
          </div>
          <div className="fee-info m-b-24">
            A ({getProductPrice(ProductChargify.SSW)}) Summit Secure Workspace fee is added for each subscription<span className="req">*</span>.
          </div>
          <Controller
            name="usersQuantity"
            control={control}
            shouldUnregister
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { invalid },
            }) => (
              <Select
                dataTestId="server-user-hosting.quantity-select"
                xVersion
                absoluteStyles
                value={value}
                label="Number of Users"
                placeholder=""
                options={numbersRange}
                isInvalid={invalid}
                isRequired
                onChange={onChange}
                onClose={onBlur}
              />
            )}
          />
          <div className="flex flex-justify-center m-t-40">
            <Button
              dataTestId="server-user-hosting.next-step-button"
              xVersion
              absoluteStyles
              isDisabled={!isValid}
              isLoading={isNextLoading}
              onClick={handleSubmit(onNextHandler)}
            >
              Next Step
            </Button>
          </div>
          <div className="fee-info m-t-20">
            <span className="req">*</span>Summit Secure Workspace combines a variety of industry-leading cybersecurity tools and practices that we use to keep your data and applications secure. Because we want to provide the most secure hosting services possible, every one of our clients receives these benefits automatically when they join us.
          </div>
        </div>
      </div>
      <StepPriceDetails currentStep={SignUpNavigation.ServerUserHosting} stepProducts={STEP_PRODUCTS} />
    </>
  );
};

export default ServerUserHosting;