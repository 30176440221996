import { FC } from 'react';
import { ITableFilterOrdering } from 'interfaces';
import { TableSearch, TableOrder } from 'components';

interface Props {
  initialSearchQuery?: string;
  orderState?: ITableFilterOrdering;
  onSearch: (searchQuery: string) => void;
  onOrderChange: (data: ITableFilterOrdering) => void;
}

const UsersMfaTableHeadRow: FC<Props> = ({
  initialSearchQuery = '',
  orderState,
  onSearch,
  onOrderChange,
}) => (
  <tr className="users-table-head-row">
    <th>
      <TableSearch
        initialValue={initialSearchQuery}
        label="USERNAMES"
        searchBy="Search by Email or Name..."
        onSearch={onSearch}
      />
    </th>
    <th>
      <TableOrder
        label="DATE ADDED"
        fieldName="CreatedAt"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>
      PHONE NUMBER
    </th>
    <th>
      MFA STATUS
    </th>
  </tr>
);

export default UsersMfaTableHeadRow;
