export * from './filter';
export * from './http';
export * from './ussp';

export enum CountryCode {
  USA = 'US',
  Canada = 'CA',
  Other = 'OTHER',
}

export enum HostProduct {
  Quickbooks = 'quickbooks',
  Sage = 'sage',
}

export enum ProductGroup {
  Other = 'other',
  Sage = 'sage',
  Quickbooks = 'quickbooks',
  Quickbook = 'quickbook'
}

export enum SageOtherProduct {
  Sfa = 'sfa',
  St = 'st',
  Sbw = 'sbw',
}

export enum MsOfficeProduct {
  OfficeStan = 'officeStan',
  OfficeProf = 'officeProf',
  Word = 'word',
  Excel = 'excel',
  Powerpoint = 'powerpoint',
  Outlook = 'outlook',
  Access = 'access',
}

export enum ProductChargify {
  // US Quickbooks
  QuickBooksEnterpriseDiamond = 'QuickBooks Enterprise Diamond',
  QuickBooksEnterprisePlatinum = 'QuickBooks Enterprise Platinum',
  QuickBooksEnterpriseGold = 'QuickBooks Enterprise Gold',
  QuickBooksEnterpriseSilver = 'QuickBooks Enterprise Silver',
  QuickBooksPremierAccountant = 'QuickBooks Premier Accountant',
  QuickBooksPremierPremierPlus = 'QuickBooks Premier/Premier Plus',
  QuickBooksProProPlus = 'QuickBooks Pro/Pro Plus',

  QuickBooksEnterpriseDiamondPurchase = 'QuickBooks Enterprise Diamond - Purchase',
  QuickBooksEnterprisePlatinumWithAdvancedInventoryPurchase = 'QuickBooks Enterprise Platinum w/Advanced Inventory - Purchase',
  QuickBooksEnterpriseGoldPurchase = 'QuickBooks Enterprise Gold - Purchase',
  QuickBooksEnterpriseSilverPurchase = 'QuickBooks Enterprise Silver - Purchase',
  QuickBooksPremierAccountantPurchase = 'QuickBooks Premier Accountant - Purchase',
  QuickBooksPremierPlusPurchase = 'QuickBooks Premier Plus - Purchase',
  QuickBooksProPlusPurchase = 'QuickBooks Pro Plus - Purchase',

  // CAD Quickbooks
  QuickBooksPro = 'QuickBooks Pro',
  QuickBooksPremier = 'QuickBooks Premier',
  QuickBooksEnterprise = 'QuickBooks Enterprise',
  QuickBooksEnterpriseWithAdvancedInventory = 'QuickBooks Enterprise with Advanced Inventory',

  QuickBooksProPurchase = 'QuickBooks Pro - Purchase',
  QuickBooksPremierPurchase = 'QuickBooks Premier - Purchase',
  QuickBooksEnterprisePurchase = 'QuickBooks Enterprise - Purchase',
  QuickBooksEnterpriseWithAdvancedInventoryPurchase = 'QuickBooks Enterprise with Advanced Inventory - Purchase',

  Sage50Premium = 'Sage 50 Premium',
  Sage50Quantum = 'Sage 50 Quantum',
  SageFixedAssets = 'Sage Fixed Assets',
  SageTimeslips = 'Sage Timeslips',
  SageBusinessWorks = 'Sage Business Works',
  MSOfficeOwn = 'Office 365',
  OfficeStandardLease = 'Office Standard 2016 Lease',
  OfficeProfessionalLease = 'Office Professional 2016 Lease',
  WordLease = 'Word 2016 Lease',
  ExcelLease = 'Excel 2016 Lease',
  PowerpointLease = 'Powerpoint 2016 Lease',
  OutlookLease = 'Outlook 2016 Lease',
  AccessLease = 'Access 2016 Lease',
  SSW = 'Summit Secure Workspace',
  SHServerUserHosting = 'Server User Hosting',
  Deposit = 'Deposit',
  BackupRetentionIncrease1Year = 'Backup Retention Increase 100 GB - 1 Year',
  BackupRetentionIncrease2Year = 'Backup Retention Increase 100 GB - 2 Years',
  BackupRetentionIncrease3Year = 'Backup Retention Increase 100 GB - 3 Years',
  VPN = 'VPN',
  ExternalStaticIPAddress = 'External Static IP Address',
}
// export enum ProductChargify {
//   QuickBooksAccountantHosting = 'QuickBooks Accountant Hosting',
//   QuickBooksEnterpriseAccountantPurchase = 'QuickBooks Enterprise Accountant - Purchase',
//   QuickBooksEnterpriseDiamond = 'QuickBooks Enterprise Diamond',
//   QuickBooksEnterpriseDiamondPurchase = 'QuickBooks Enterprise Diamond - Purchase',
//   QuickBooksEnterpriseHosting = 'QuickBooks Enterprise Hosting',
//   QuickBooksEnterprisePlatinumPurchase = 'QuickBooks Enterprise Platinum w/Advanced Inventory - Purchase',
//   QuickBooksEnterpriseSilverGoldPurchase = 'QuickBooks Enterprise Silver/Gold - Purchase',
//   QuickBooksPremierAccountantPurchase = 'QuickBooks Premier Accountant - Purchase',
//   QuickBooksPremierHosting = 'QuickBooks Premier Hosting',
//   QuickBooksPremierPlusPurchase = 'QuickBooks Premier Plus Purchase',
//   QuickBooksProHosting = 'QuickBooks Pro Hosting',
//   QuickBooksProPlusPurchase = 'QuickBooks Pro Plus - Purchase',
//   Sage50Premium = 'Sage 50 Premium',
//   Sage50Quantum = 'Sage 50 Quantum',
//   SageFixedAssets = 'Sage Fixed Assets',
//   SageTimeslips = 'Sage Timeslips',
//   SageBusinessWorks = 'Sage Business Works',
//   MSOfficeOwn = 'Office 365',
//   OfficeStandardLease = 'Office Standard 2016 Lease',
//   OfficeProfessionalLease = 'Office Professional 2016 Lease',
//   WordLease = 'Word 2016 Lease',
//   ExcelLease = 'Excel 2016 Lease',
//   PowerpointLease = 'Powerpoint 2016 Lease',
//   OutlookLease = 'Outlook 2016 Lease',
//   AccessLease = 'Access 2016 Lease',
//   SSW = 'Summit Secure Workspace',
//   SSWAdditional = 'MFA User(s)',
//   SHServerUserHosting = 'Server User Hosting',
//   Deposit = 'Deposit',
// }

export enum MsOfficeLicenseType { // @todo move to const
  OwnLicense = 'MS Office 365 Apps for Enterprise, E3, or E5',
  LeaseLicense = 'lease-license',
}

export enum SignUpNavigation {
  UserDetails = '/',
  CompanyDetails = '/company-details',
  ServerUserHosting = '/server-user-hosting',
  ProductsList = '/products-list',
  AdditionalServices = '/additional-services',
  SetupUsers = '/setup-users',
  Summary = '/summary',
  Payment = '/payment'
}

export enum OrderNavigation {
  Products = '/sign-up/products',
  Quickbooks = '/sign-up/quickbooks',
  Sage = '/sign-up/sage',
  MsOffice = '/sign-up/ms-office',
  MsOfficeLease = '/sign-up/ms-office-lease',
  OtherProducts = '/sign-up/other-products',
  AdditionalServices = '/sign-up/additional-services',
  SecureWorkspace = '/sign-up/secure-workspace',
  Checkout = '/sign-up/checkout',
  Payment = '/sign-up/payment',
}

export enum BffTypesUrlStart {
  SfBff = '/bff/',
  UsspBff = '/bff-ussp/'
}

export enum AccessTokenType {
  AccessTokenSf = 'access_token_sf',
  AccessTokenUssp = 'access_token_ussp'
}

export enum AuthRole {
  Customer = 'Customer',
  Admin = 'Admin'
}

export enum RefreshTokenType {
  RefreshTokenSf = 'refresh_token_sf',
  RefreshTokenUssp = 'refresh_token_ussp'
}

export enum UserRole {
  Admin = 'Admin',
  Standard = 'Standard',
}

export enum DiscountType {
  Amount = 'amount',
  Percent = 'percent',
}

export enum QuickbookOption {
  Own = 'I own my QuickBooks and can provide my license and product number',
  Buy = 'I want to buy a QuickBooks license from Summit',
}

export enum USAStates {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
}

export enum CanadaProvince {
  Alberta = 'AB',
  BritishColumbia = 'BC',
  Manitoba = 'MB',
  NewBrunswick = 'NB',
  NewfoundlandAndLabrador = 'NL',
  NorthwestTerritories = 'NT',
  NovaScotia = 'NS',
  Nunavut = 'NU',
  Ontario = 'ON',
  PrinceEdwardIsland = 'PE',
  Quebec = 'QC',
  Saskatchewan = 'SK',
  YukonTerritory = 'YT',
}

export enum BillingStatus {
  Active = 'active',
  Canceled = 'canceled',
  Expired = 'expired',
  OnHold = 'on_hold',
  PastDue = 'past_due',
  SoftFailure = 'soft_failure',
  Trialing = 'trialing',
  TrialEnded = 'trial_ended',
  Unpaid = 'unpaid',
  Suspended = 'suspended',
  AwaitingSignup = 'awaiting_signup',
}

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Pending = 'pending',
  Voided = 'voided',
  Canceled = 'canceled',
}

export enum PaymentMethod {
  BankAccount = 0,
  CreditCard = 1,
}

export enum CardType {
  Visa = 'visa',
  MasterCard = 'master',
  Discover = 'discover',
  AmericanExpress = 'american_express',
  DinersClub = 'diners_club',
  Jcb = 'jcb',
  Maestro = 'maestro',
  UnionPay = 'unionpay',
}

export enum CardTypeChargify {
  Visa = 'visa',
  MasterCard = 'mastercard',
  Discover = 'discover',
  AmericanExpress = 'american-express',
  DinersClub = 'diners-club',
  Diners = 'diners',
  Jcb = 'jcb',
  Maestro = 'maestro',
}

export enum LogType {
  Logout = 'logout',
  Login = 'login',
}