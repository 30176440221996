import './BillingDetailsPayment.scss';
import React, { FC, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import dayjs from 'dayjs';

import { usePriceHandler } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { CountryCode, PaymentMethod } from 'enums';

import { WorkOutlineIcon } from 'assets/svg';

import { PaymentMethodBlock } from 'components';

interface Props {
  chargifyId: number;
  chargifyPaymentUpdateUrl: string;
  companyName: string;
  createdAt: string;
  creditCardType: string;
  isExpired: boolean;
  isLoading: boolean;
  nextBillingDate: string;
  paymentMethod: PaymentMethod;
  paymentNumber: string;
  totalAmount: number;
}
const formatDate = (value: string): string => dayjs(value).format('MMM D, YYYY');

const BillingDetailsPayment: FC<Props> = ({
  chargifyId,
  chargifyPaymentUpdateUrl,
  companyName,
  createdAt,
  creditCardType,
  isExpired,
  isLoading,
  nextBillingDate,
  paymentMethod,
  paymentNumber,
  totalAmount,
}) => {
  const currentCountry = useAppSelector((state) => state.ussp.user?.country || CountryCode.USA);
  const { formatPrice } = usePriceHandler();

  const createdAtFormated = useMemo(() => formatDate(createdAt), [createdAt]);
  const nextBillingDateFormated = useMemo(() => formatDate(nextBillingDate), [nextBillingDate]);

  const totalAmountFormated = useMemo(() => formatPrice(totalAmount || 0, currentCountry), [
    totalAmount,
    currentCountry,
  ]);

  return (
    <div className="billing-details-payment">
      <section className="p-32">
        <div className="billing-details-payment__product-logo-wrapper rel-m-b-32">
          {isLoading
            ? <Skeleton containerClassName="billing-details-payment__product-logo d-block" height="100%" />
            : <div className="billing-details-payment__default-logo"><WorkOutlineIcon /></div>
          }
        </div>
        <p className="billing-details-payment__product-name rel-m-b-4">
          {isLoading ? <Skeleton width="65%" /> : companyName}
        </p>
        <p className="billing-details-payment__product-id rel-m-b-24">
          ID: {isLoading ? <Skeleton width="75%" /> : chargifyId}
        </p>
        <div className="billing-details-payment__info rel-m-b-8">
          <span>Start date:</span>
          {isLoading ? <Skeleton width="80%" /> : <span>{createdAtFormated}</span>}
        </div>
        <div className="billing-details-payment__info rel-m-b-8">
          <span>Next billing date:</span>
          {isLoading ? <Skeleton width="80%" /> : <span>{nextBillingDateFormated}</span>}
        </div>
        <div className="billing-details-payment__info rel-m-b-8">
          <span>Total Amount:</span>
          {isLoading ? <Skeleton width="80%" /> : <span>{totalAmountFormated}</span>}
        </div>
      </section>
      <section className="rel-p-t-24 rel-p-x-32">
        {isLoading
          ? (
            <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
              <Skeleton containerClassName="app-card__icon-skeleton" height="100%" />
              <div className="app-card__content">
                <Skeleton containerClassName="app-card__name" height="100%" width="70%" />
                <Skeleton containerClassName="app-card__id" height="100%" />
              </div>
            </div>
          )
          : (
            <PaymentMethodBlock
              creditCardType={creditCardType}
              editCardUrl={chargifyPaymentUpdateUrl}
              isCardExpired={isExpired}
              paymentMethod={paymentMethod}
              paymentNumber={paymentNumber}
              alwaysShowEdit
            />
          )
        }
      </section>
    </div>
  );
};

export default BillingDetailsPayment;
