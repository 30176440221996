import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SubscriptionsTableBodyRowLoader: FC = () => (
  <tr>
    <td></td>
    <td>
      <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
        <div className="app-card__content">
          <Skeleton containerClassName="app-card__name" height="100%" width="70%" />
          <Skeleton containerClassName="app-card__id" height="100%" width="100%"/>
        </div>
      </div>
    </td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
  </tr>
);

export default SubscriptionsTableBodyRowLoader;
