import { CountryCode } from 'enums';

export const capitalizeFirstLetter = (text: string): string => `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

export const getEnvCountryCode = (code: CountryCode | string): CountryCode => {
  return [CountryCode.USA, CountryCode.Canada].includes(code as CountryCode)
    ? code as CountryCode
    : CountryCode.USA;
};

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}
