import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IHttpRequestResult } from 'interfaces';
import { http, createHttpRequestInitResult, createExtraReducersForResponses, encodeCeasar } from 'helpers';
import { AccessTokenType, RefreshTokenType } from 'enums';

export const validateEmailByCountry = createAsyncThunk<any, any>(
  'user/validateEmailByCountry',
  async ({ email, country, captchaResponse }) => {
    const response = await http.post(`/bff/users/validateByCountry`, {
      email,
      country,
      captchaResponse,
      skipCaptchaKey: null,
    });
    return response;
  },
);

export const getAccessToken = createAsyncThunk<any, any>(
  'user/getToken',
  async (email: string) => {
    const password = encodeCeasar(email);
    const response = await http.post(`/bff/users/accessToken`, {
      email,
      password,
    });
    return response;
  },
);

export const refreshAccessToken = createAsyncThunk<any, any>(
  'user/refreshAccessToken',
  async () => {
    const refresh_token = sessionStorage.getItem(RefreshTokenType.RefreshTokenSf);
    const response: any = await http.post(`/bff/users/refreshToken`, {
      refreshToken: refresh_token,
    });
    if (response && response.data && response.data.access_token) {
      sessionStorage.setItem(AccessTokenType.AccessTokenSf, response.data.access_token);
    }
    if (response && response.data && response.data.refresh_token) {
      sessionStorage.setItem(RefreshTokenType.RefreshTokenSf, response.data.refresh_token);
    }
    return response;
  },
);

export const createUser = createAsyncThunk<any, any>(
  'user/create',
  async (user) => {
    const response = await http.post(`/bff/users/create`, user);
    return response;
  },
);

export const updateUser = createAsyncThunk<any, any>(
  'user/update',
  async (user) => {
    const response = await http.patch(`/bff/User`, user);
    return response;
  },
);

interface UserState {
  createUserRequest: IHttpRequestResult<any>;
  updateUserRequest: IHttpRequestResult<any>;
  getAccessTokenRequest: IHttpRequestResult<any>;
  refreshAccessTokenRequest: IHttpRequestResult<any>;
  validateEmailByCountryRequest: IHttpRequestResult<any>;
}

const initialState: UserState = {
  createUserRequest: createHttpRequestInitResult(),
  updateUserRequest: createHttpRequestInitResult(),
  getAccessTokenRequest: createHttpRequestInitResult(),
  refreshAccessTokenRequest: createHttpRequestInitResult(),
  validateEmailByCountryRequest: createHttpRequestInitResult(),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    ...createExtraReducersForResponses(createUser, 'createUserRequest'),
    ...createExtraReducersForResponses(updateUser, 'updateUserRequest'),
    ...createExtraReducersForResponses(getAccessToken, 'getAccessTokenRequest'),
    ...createExtraReducersForResponses(refreshAccessToken, 'refreshAccessTokenRequest'),
    ...createExtraReducersForResponses(validateEmailByCountry, 'validateEmailByCountryRequest'),
  },
});

export default userSlice.reducer;