import { FC } from 'react';
import './FilterTag.scss';
import { CloseIcon } from 'assets/svg';

interface Props {
  tag: {
    label: string,
    onDelete: () => void;
  }
}

const FilterTag: FC<Props> = ({ tag }) => {

  return (
    <div className='filter-tag'>
      <span>{tag.label}</span>
      <CloseIcon onClick={tag.onDelete} />
    </div>
  );
};

export default FilterTag;