import { FC, useMemo } from 'react';
import './SageChoose.scss';
import { Button, InputText, Select, StepPriceDetails } from 'components';
import * as yup from 'yup';

import { Controller, useForm, SubmitHandler } from 'react-hook-form';

import { ALL_SAGE_PRODUCTS, PRODUCT_CHARGIFY_TITLES } from 'consts';
import { ProductChargify, SignUpNavigation } from 'enums';
import { yupResolver } from '@hookform/resolvers/yup';
import { SageLogo } from 'assets/svg';
import { useAppSelector } from 'store/hooks';
import * as _ from 'lodash';
import { usePriceHandler } from 'helpers';

interface Props {
  initData?: ISageForm | null;
  onAdd: (data: ISageForm) => void;
  currentStepProducts: any;
}

export interface ISageForm {
  name: string;
  year: string;
  quantity: string;
  serialNumber?: string;
}

const SageChoose: FC<Props> = ({ onAdd, initData, currentStepProducts }) => {
  const schema = useMemo(() => yup.object({
    name: yup.string().required('Product is required'),
    year: yup.string().required('Year is required'),
    quantity: yup.string()
      .required('Users count is required'),
    serialNumber: yup.string()
  }), []);
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { isValid },
  } = useForm<ISageForm>({ mode: 'all', resolver: yupResolver(schema), defaultValues: initData || { quantity: '1' } });
  const { getProductPrice } = usePriceHandler();
  const hostTotalQuantity = useAppSelector((state) => state.order.hostProduct.quantity);

  const numbersRange = useMemo(() => _.range(1, +(hostTotalQuantity || 0) + 1).map(el => el.toString()), [hostTotalQuantity]);

  const handleAdd: SubmitHandler<ISageForm> = (data) => {
    onAdd({ ...data });
  };

  const isDataChanged = useMemo(() => {
    const currentData = getValues();
    return !_.isEqual(currentData, initData);
  }, [watch()]);

  const STEP_PRODUCTS = useMemo(() => {
    const { name, quantity } = getValues();
    return [
      ...currentStepProducts.quickbooks,
      ...(!name ? [] : [{ name, quantity }]),
      ...currentStepProducts.msOwn,
      ...currentStepProducts.msLease,
      ...currentStepProducts.other,
    ];
  }, [currentStepProducts, watch(['quantity', 'name'])]);

  return (
    <>
      <div className="container-sm">
        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-8"><SageLogo className="step-heading-logo"/><div className="text">Sage</div></div>
          <div className="sign-up-form-step-description m-b-32">
            Choose which Sage product you would like installed.
          </div>
          <form>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid },
              }) => (
                <Select
                  dataTestId="product-list.sage.name-select"
                  xVersion
                  absoluteStyles
                  value={value}
                  classes="m-b-20 sm-m-b-12"
                  options={ALL_SAGE_PRODUCTS.map(
                    el => ({
                      label: `${PRODUCT_CHARGIFY_TITLES[el]} (${getProductPrice(PRODUCT_CHARGIFY_TITLES[el] as ProductChargify)})`,
                      value: el
                    }),
                  )}
                  label="Select Sage product"
                  placeholder=""
                  isRequired
                  isInvalid={invalid}
                  onChange={onChange}
                  onClose={onBlur}
                />
              )}
            />
            <div className="row-items flex-align-start m-b-20 sm-m-b-12">
              <Controller
                name="year"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <Select
                    dataTestId="product-list.sage.edition-select"
                    xVersion
                    absoluteStyles
                    value={value}
                    label="Sage Edition"
                    placeholder=""
                    options={[
                      '2024',
                      '2023',
                      '2022',
                      '2021',
                      '2020',
                      '2019',
                      '2018',
                      '2017',
                      '2016',
                      '2015',
                    ]}
                    isInvalid={invalid}
                    isRequired
                    onChange={onChange}
                    onClose={onBlur}
                  />
                )}
              />
              <Controller
                name="quantity"
                control={control}
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <Select
                    dataTestId="product-list.sage.quantity-select"
                    xVersion
                    absoluteStyles
                    value={value}
                    label="Number of Users"
                    placeholder=""
                    options={numbersRange}
                    isInvalid={invalid}
                    isRequired
                    onChange={onChange}
                    onClose={onBlur}
                  />
                  // <NumberFormat
                  //   allowNegative={false}
                  //   customInput={InputText}
                  //   xVersion
                  //   absoluteStyles
                  //   isRequired
                  //   label="Number of users"
                  //   placeholder=""
                  //   value={value}
                  //   isInvalid={isTouched && invalid}
                  //   errorText={error?.message}
                  //   isReturnEvent
                  //   onChange={onChange}
                  //   onBlur={onBlur}
                  // />
                )}
              />
            </div>
            <Controller
              name="serialNumber"
              control={control}
              defaultValue=""
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched, error },
              }) => (
                <InputText
                  dataTestId="product-list.quickbooks.serial-number-input"
                  classes="m-b-20 sm-m-b-12"
                  xVersion
                  absoluteStyles
                  label="Serial Number"
                  placeholder=""
                  value={value}
                  isInvalid={isTouched && invalid}
                  errorText={error?.message}
                  isReturnEvent
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </form>
          <div className="flex flex-justify-center m-t-40 sm-m-t-32">
            <Button
              dataTestId="product-list.sage.add-edit-button"
              xVersion
              absoluteStyles
              isDisabled={!isValid || !isDataChanged}
              onClick={handleSubmit(handleAdd)}
            >
              {initData ? 'Edit Application' : 'Add Application'}
            </Button>
          </div>
        </div>
      </div>
      <StepPriceDetails currentStep={SignUpNavigation.ProductsList} stepProducts={STEP_PRODUCTS} />
    </>
  );
};

export default SageChoose;