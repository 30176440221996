import React, { FC, useState, useEffect } from 'react';

import { RadioButton, SidebarModal, TotalPriceModalFooter } from 'components';

import QuickbooksClientLicense from './components/QuickbooksClientLicense/QuickbooksClientLicense';
import QuickbooksPurchaseLicense from './components/QuickbooksPurchaseLicense/QuickbooksPurchaseLicense';
import SageClientLicense from './components/SageClientLicense/SageClientLicense';
import OtherApplication from './components/OtherApplication/OtherApplication';
import { useAppDispatch } from 'store/hooks';
import './AddApplicationSidebarModal.scss';
import AssignUsers from './components/AssignUsers/AssignUsers';

import { clearApplicationFormValues, setApplicationProduct } from 'store';

interface Props {
  visible: boolean;
  isAdminPortal?: boolean;
  onCancel: () => void;
  toggleConfirmationModal: () => void;
}

const APPLICATIONS = [
  {
    id: 'quickbooks-client-license',
    name: 'QuickBooks - Client provided license',
  },
  {
    id: 'sage-client-license',
    name: 'Sage - Client provided license',
  },
  {
    id: 'otherPerUser',
    name: 'Other Products Per User',
  },
  {
    id: 'otherPerServer',
    name: 'Other Products Per Server',
  },
];

const AddApplicationSidebarModal: FC<Props> = ({ visible, onCancel, isAdminPortal, toggleConfirmationModal }) => {
  const [stepCount, setStepCount] = useState<number>(3);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [application, setApplication] = useState<string>('');
  const dispatch = useAppDispatch();

  const resetModal = () => {
    onCancel();
    setCurrentStep(0);
    dispatch(clearApplicationFormValues());
  };

  const backHandler = (reset?: boolean) => {
    if (reset === true) {
      resetModal();
    } else if (currentStep === 1) {
      setCurrentStep(0);
    } else if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      resetModal();
    }
  };

  const submitHandler = () => {
    setCurrentStep(1);
  };

  useEffect(() => {
    if (application === 'quickbooks-purchase-license') {
      setStepCount(2);
    } else {
      setStepCount(3);
    }
  }, [application]);

  useEffect(() => {
    return () => {
      dispatch(clearApplicationFormValues());
    };
  }, []);

  const APPLICATIONS_COMPONENTS: { [key: string]: any } = {
    'quickbooks-client-license': (
      <QuickbooksClientLicense
        onCancel={backHandler}
        changeCurrentStep={setCurrentStep} />
    ),
    'quickbooks-purchase-license': (
      <QuickbooksPurchaseLicense
        onCancel={backHandler}
        toggleConfirmationModal={toggleConfirmationModal} />
    ),
    'sage-client-license': (
      <SageClientLicense
        onCancel={backHandler}
        changeCurrentStep={setCurrentStep}
      />
    ),
    'otherPerUser': (
      <OtherApplication
        isPerServer={false}
        onCancel={backHandler}
        changeCurrentStep={setCurrentStep} />
    ),
    'otherPerServer': (
      <OtherApplication
        isPerServer={true}
        onCancel={backHandler}
        changeCurrentStep={setCurrentStep} />
    ),
  };

  return (
    <SidebarModal
      classes="dashboard-accounts-add-application"
      title="Add Application"
      visible={visible}
      onCancel={onCancel}
      onOk={submitHandler}
      okText="Next"
      cancelText="Cancel"
      isOkDisabled={!application}
      hideFooter={currentStep > 0}
      footerAdditonal={currentStep > 0 ? <TotalPriceModalFooter price={200} /> : null}
    >
      <div className="navigation-steps">
        {Array.from(Array(stepCount).keys()).map(i => {
          const classes = i <= currentStep ? 'active' : '';
          return (
            <div key={i} className={classes}></div>
          );
        })}
      </div>
      {currentStep === 0 && (
        <>
          <div className="dashboard-accounts-add-application__title">Select the applications you would like to host</div>
          <div className="row-items rel-m-b-20">
            <div>
              {APPLICATIONS.map(({ id, name }) => (
                <React.Fragment key={id}>
                  <RadioButton
                    isChecked={application === id}
                    onChange={() => {
                      dispatch(clearApplicationFormValues());
                      setApplication(id);
                      dispatch(setApplicationProduct(id));
                    }}
                    label={name}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
      {currentStep === 1 && (
        <div className="dashboard-accounts-add-application__wrap">{APPLICATIONS_COMPONENTS[application]}</div>
      )}
      {currentStep === 2 && (
        <AssignUsers backHandler={backHandler} onCancel={onCancel} />
      )}
    </SidebarModal>
  );
};

export default AddApplicationSidebarModal;