import { HubConnectionBuilder } from '@microsoft/signalr';
import { AccessTokenType } from 'enums';

const baseURL = `${process.env.REACT_APP_API_URL}/bff-ussp/hubs`;

interface ISetupSignalROptions {
  baseURL?: string;
  token?: string;
}

export const setupSignalR = (url: string, options?: ISetupSignalROptions) => {
  const fullUrl = `${options?.baseURL || baseURL}${url}`;
  const access_token = localStorage.getItem(AccessTokenType.AccessTokenUssp);
  const token: any = options?.token || access_token;
  const connection: any = new HubConnectionBuilder()
    .withUrl(fullUrl, { accessTokenFactory: () => token })
    .withAutomaticReconnect()
    .build();
  return connection;
};
