import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BillingDetailsTableBodyRowLoader: FC = () => (
  <tr>
    <td>
      <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
        <Skeleton containerClassName="app-card__icon-skeleton" height="100%" />
        <div className="app-card__content">
          <Skeleton containerClassName="app-card__name" height="100%" />
        </div>
      </div>
    </td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton width="90%" /></td>
  </tr>
);

export default BillingDetailsTableBodyRowLoader;
