import { Middleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import countryReducer from './slices/country';
import orderReducer from './slices/order';
import productsReducer from './slices/products';
import usspReducer from './slices/ussp';

//MIDDLEWARE FOR SYNC WITH SESSION STORAGE
const sessionStorageMiddleware: Middleware = ({ getState }) => {
  return next => action => {
    const result = next(action);
    sessionStorage?.setItem('applicationState', JSON.stringify(getState()));
    return result;
  };
};

const reHydrateStore = () => {
  const preState = sessionStorage?.getItem('applicationState');
  if (preState) {
    return JSON.parse(preState);
  }
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    country: countryReducer,
    order: orderReducer,
    products: productsReducer,
    ussp: usspReducer,
  },
  preloadedState: reHydrateStore(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(sessionStorageMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;