import './Login.scss';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import jwtDecode from 'jwt-decode';
import { SerializedError } from '@reduxjs/toolkit';
import Countdown, { zeroPad } from 'react-countdown';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Lottie from 'lottie-react';

import { sendEmailCode, validateOneTimeCode } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AccessTokenType, LogType, RefreshTokenType, UsspRole } from 'enums';
import { addLog, notify } from 'helpers';

import loaderAnimation from 'assets/lotties/button-loader.json';
import { InputText, Logo } from 'components';
import { EXPIRED_TIME, INTENDED_URL, SESSION_EXPIRED } from 'consts';
import { ErrorIcon } from 'assets/svg';

const OneTimeCodeExpireTime = 1000 * 60 * 3;

const TimerRenderer = ({ hours, minutes, seconds }: { hours: number; minutes: number; seconds: number }) => (
  <span>
    {hours > 0 && `${zeroPad(hours)}:`}{zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
);

const Login: FC = () => {
  const [emailValue, setEmailValue] = useState<string>('');
  const [codeValue, setCodeValue] = useState<string>('');
  const [isSessionExpired] = useState(localStorage.getItem(SESSION_EXPIRED) === 'true');
  const [intendedUrl] = useState(localStorage.getItem(INTENDED_URL));
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [isCodeSentOnce, setIsCodeSentOnce] = useState<boolean>(false);
  const [isLoadingSendCode, setIsLoadingSendCode] = useState<boolean>(false);
  const [isLoadingVerifyCode, setIsLoadingVerifyCode] = useState<boolean>(false);
  const [timerDate, setTimerDate] = useState<number | undefined>(0);
  const [isSuccessVerification, setIsSuccessVerification] = useState<boolean>(false);
  const isSendingEmail = useAppSelector(state => state.auth.sendEmailCodeRequest.isLoading);
  const sendEmailCodeData = useAppSelector(state => state.auth.sendEmailCodeRequest.data);
  const isValidatingOneTimeCode = useAppSelector(state => state.auth.validateOneTimeCodeRequest.isLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onValidate = async () => {
    try {
      setIsLoadingSendCode(true);
      const captcha = !!executeRecaptcha ? await executeRecaptcha('loginPage') : '';
      const response = await dispatch(sendEmailCode({ email: emailValue, captcha, _disableErrorHandler: true })).unwrap();
      if (response && response.isBlocked) {
        notify.error(
          'To many failed login attempts.',
          'Please wait one hour and try to login again.',
        );
        setTimerDate(undefined);
        setIsCodeSent(false);
        setIsCodeSentOnce(false);
        setCodeValue('');
      } else {
        notify.info(
          'Check your email for your one-time code',
          'Please cheсk your email account for the verification code we just sent you and enter that code in the box below',
        );
        setTimerDate(Date.now() + OneTimeCodeExpireTime);
        setIsCodeSent(true);
        setIsCodeSentOnce(true);
        localStorage.removeItem(EXPIRED_TIME);
      }
    } catch (err) {
      if ((err as SerializedError).code === '404') {
        notify.error(
          "The customer with this email doesn't exist",
          'Please, enter a different email',
        );
      } else if ((err as SerializedError).code === '400' && (err as SerializedError).message === 'One or more validation errors occurred.') {
        notify.error(
          'Invalid email address',
          'Please provide a valid email address',
        );
      } else if ((err as SerializedError).code === '400' && (err as SerializedError).message === 'SubscriptionNotFound') {
        notify.error(
          'Sorry, you can’t log in',
          'You don’t have a subscription',
        );
      } else if (typeof (err as SerializedError).message === 'string') {
        notify.error(
          'Error',
          (err as SerializedError).message,
        );
      }
    } finally {
      setIsLoadingSendCode(false);
    }
  };

  const afterTimeExpired = () => {
    notify.warning(
      'One-time code expired',
      'Please validate your email again',
    );
    setCodeValue('');
    setIsCodeSent(false);
    setTimerDate(undefined);
  };

  const verifyCode = async () => {
    try {
      setIsLoadingVerifyCode(true);
      const tokenResponse: {
        access_token: string;
        refresh_token: string;
      } = await dispatch(validateOneTimeCode({ email: emailValue, code: codeValue, _disableErrorHandler: true })).unwrap();
      // PLEASE CHECK IN FUTURE POSIBILITY TO SWITCH TO COOKIES INSTEAD LOCALSTORAGE FOR REMOVING TOKENS AFTER SOME TIME
      if (tokenResponse && tokenResponse.access_token) {
        localStorage.setItem(AccessTokenType.AccessTokenUssp, tokenResponse.access_token);
      }
      if (tokenResponse && tokenResponse.refresh_token) {
        localStorage.setItem(RefreshTokenType.RefreshTokenUssp, tokenResponse.refresh_token);
      }

      const decodedToken: { role: UsspRole } = jwtDecode(tokenResponse?.access_token)
        || { role: UsspRole.Customer };

      setIsSuccessVerification(true);
      setIsLoadingVerifyCode(false);
      const pagePath = UsspRole.Admin === (decodedToken?.role as UsspRole)
        ? '/admin-portal'
        : '/user-portal';
      if (intendedUrl) {
        navigate(intendedUrl);
      } else {
        navigate(pagePath);
      }
      
      addLog(LogType.Login, 'verified code, logged in');
    } catch (err) {
      if ((err as SerializedError).code === '400') {
        notify.error(
          'Invalid code',
          'Please use a valid one-time code',
        );
      } else if (typeof (err as SerializedError).message === 'string') {
        notify.error(
          'Error',
          (err as SerializedError).message,
        );
      }
      setIsLoadingVerifyCode(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem(SESSION_EXPIRED);
    sessionStorage.removeItem(INTENDED_URL);
  }, []);

  return (
    <div className="login page">
      <Logo type="white" />
      <div className="login-form-wrapper">
        <h2 className="text-center">WELCOME BACK</h2>
        <h1 className="text-center">Login to your account </h1>
        {isSessionExpired ? <div className="expired-text-wrapper"><ErrorIcon/><span>Your session has expired. Please login again.</span></div> : null}
        <form className="rel-m-b-24">
          <InputText
            classes="rel-m-b-8"
            label="Lets start with your email"
            placeholder="Enter your email"
            isRequired
            isWithButton
            isNewStyle
            value={emailValue}
            onChange={(v) => setEmailValue(v.trim())}
            buttonText={isCodeSentOnce ? 'Resend' : 'Validate'}
            buttonDisabled={!emailValue.trim() || isSendingEmail}
            buttonWidth={62}
            isButtonLoading={isLoadingSendCode}
            buttonAction={onValidate}
          />
          <div className="hiddenBlock">
            {
              isCodeSent && (
                <InputText
                  classes="login-validation-code"
                  placeholder="Check your email for your one-time code"
                  value={codeValue}
                  onChange={setCodeValue}
                />
              )
            }
            <div className="login-time-attempts">
              {isCodeSent && !isSuccessVerification && (
                <div className="login-time-remaining">
                  Time remaining {isCodeSent && !isSuccessVerification && (
                    <Countdown date={timerDate} onComplete={afterTimeExpired} renderer={TimerRenderer} />)}
                </div>
              )}
              {isCodeSent && !isSuccessVerification && sendEmailCodeData && sendEmailCodeData.attempts && (
                <div className="login-attempts">
                  {sendEmailCodeData?.attempts} attempts left
                </div>
              )}
            </div>
          </div>
          <button
            type="button"
            className={cn('btn relative-units primary full-width new-style', { loading: isLoadingVerifyCode })}
            disabled={!codeValue || isSuccessVerification || isValidatingOneTimeCode || isLoadingVerifyCode}
            onClick={verifyCode}
          >{isLoadingVerifyCode ? <Lottie animationData={loaderAnimation} loop /> : 'Login'}</button>
        </form>
        <p className="login-sign-up">
          Don't have an account?
          &#x200B;
          <span className="link relative-units" onClick={() => navigate('/sign-up')}>Sign up</span>
        </p>
      </div>
    </div>
  );
};

export default Login;