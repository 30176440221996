import { FC } from 'react';
import './ProgressBar.scss';

interface Props {
  currentStep: number;
  totalSteps: number;
}

const ProgressBar: FC<Props> = ({ currentStep, totalSteps }) => {
  const stepsLeftCount = totalSteps - currentStep;
  const stepsLeft = Array.from(Array(stepsLeftCount).keys());
  return (
    <div className="progress-bar-container">
      {currentStep === 1 ? (
        <div className="progress-bar-step completed">
          <div className="progress-bar-step-dot"></div>
        </div>
      ) : null}
      {currentStep > 1 ? (
        <div className="progress-bar-step completed">
          <div className="progress-bar-multiple-steps-dot" style={{ width: (currentStep * 4) + (8 * (currentStep - 1)) }}></div>
        </div>
      ) : null}
      {stepsLeft.map((step, index) => (
        <div key={index} className="progress-bar-step">
          <div className="progress-bar-step-dot"></div>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;