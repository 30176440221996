import './Sidebar.scss';
import { FC, useState } from 'react';
import cn from 'classnames';

import { SUPPORT_LINK } from 'consts';

import {
  ChatIcon,
  InfoCircleIcon,
  VectorLeftIcon,
  VectorRightIcon,
} from 'assets/svg';

import { CustomRouterLink, Modal, Tooltip, Logo } from 'components';

interface Props {
  gridArea?: string;
  isCollapsed: boolean;
  navigationMap: {
    name: string;
    path: string;
    Icon: FC;
    ActiveIcon: FC;
    PosfixComponent?: FC;
    matchPattern?: string;
    inDevelopment?: boolean;
  }[];
  isAdmin?: boolean;
  onToggleMenu?: () => void;
}

const Sidebar: FC<Props> = ({
  gridArea,
  isCollapsed,
  navigationMap,
  isAdmin = false,
  onToggleMenu,
}) => {
  const [isShowSupportModal, setIsShowSupportModal] = useState<boolean>(false);

  const showSupportModal = () => {
    setIsShowSupportModal(true);
  };

  const hideSupportModal = () => {
    setIsShowSupportModal(false);
  };

  const developmentText = <><p className="feature-development-title">🚧 Feature in Development 🚧</p><p>This functionality is currently inactive as we're working to bring you an enhanced version.</p></>;

  return (
    <nav className={cn('app-sidebar', { collapsed: isCollapsed })} style={{ gridArea }}>
      <div className="app-sidebar__head flex flex-justify-between flex-align-center">
        <div className="app-sidebar__logo-wrapper">
          <Logo/>
        </div>
        {isCollapsed
          ? <VectorRightIcon onClick={onToggleMenu} />
          : <VectorLeftIcon onClick={onToggleMenu} />}
      </div>
      <div className="app-sidebar__body">
        <ul className="app-sidebar__navigation">
          {navigationMap.map(({ name, path, Icon, ActiveIcon, PosfixComponent, matchPattern, inDevelopment }) => (
            <li className={cn("rel-m-b-20 flex new-style")} key={`sidebar-link-${path}`}>
              <Tooltip
                isDisabled={!isCollapsed && !inDevelopment}
                content={inDevelopment ? developmentText : name}
                placement="right-start">
                <CustomRouterLink to={path} matchPattern={matchPattern} className={cn({ disabled: inDevelopment })}>
                  <Icon />
                  <ActiveIcon />
                  <div>
                    <span>{name}</span>
                    {PosfixComponent && <PosfixComponent />}
                  </div>
                </CustomRouterLink>
              </Tooltip>
              <div style={{ flex: 1 }}></div>
            </li>
          ))}
        </ul>
        {!isAdmin &&
          <Tooltip
            classes="app-sidebar__btn-tooltip"
            isDisabled={!isCollapsed}
            content="Support Ticket"
            placement="bottom-end">
            <button
              type="button"
              className="app-sidebar__btn btn relative-units default with-icon full-width"
              onClick={showSupportModal}>
              <ChatIcon /> <span>Support Ticket</span>
            </button>
          </Tooltip>
        }
      </div>
      <Modal
        title="Support ticket"
        visible={isShowSupportModal}
        onOk={hideSupportModal}
        okText="Okay"
        cancelText={null}
        onCancel={hideSupportModal}
        width={535}
      >
        <div className="block-info relative-units support-info">
          <InfoCircleIcon />
          <div className="block-info__content support-info__content">
          To contact our support please&nbsp;
            <a
              className="link relative-units without-decorations support-info__link"
              href={SUPPORT_LINK}
              target="_blank"
            >submit a ticket here</a>,
            and our Support team will reach you out to discuss
          </div>
        </div>
      </Modal>
    </nav>
  );
};

export default Sidebar;