import './AdminSubscriptionsDetails.scss';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Routes, Route, useNavigate, useLocation, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAdminSubscriptionInfo,
} from 'store';

import { ArrowLeftSmallIcon, PlusInSquareIcon } from 'assets/svg';

import { AddUserSidebarModal, CustomRouterLink, Error } from 'components';
import AddApplicationSidebarModal from './components/AddApplicationSidebarModal/AddApplicationSidebarModal';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';

import SubscriptionsDetailsGeneral from './pages/SubscriptionsDetailsGeneral/SubscriptionsDetailsGeneral';
import SubscriptionsDetailsApplications from './pages/SubscriptionsDetailsApplications/SubscriptionsDetailsApplications';
import SubscriptionsDetailsUsers from './pages/SubscriptionsDetailsUsers/SubscriptionsDetailsUsers';
import SubscriptionsDetailsInvoices from './pages/SubscriptionsDetailsInvoices/SubscriptionsDetailsInvoices';
import SubscriptionsDetailsCustomFields from './pages/SubscriptionsDetailsCustomFields/SubscriptionsDetailsCustomFields';
import AddEditCustomFieldModal from './pages/SubscriptionsDetailsCustomFields/components/AddEditCustomFieldModal';
// import AccountDetailsUsersMfa from './pages/AccountDetailsUsersMfa/AccountDetailsUsersMfa';

enum EPageRouteName {
  General = 'general',
  Applications = 'applications',
  Users = 'users',
  Invoices = 'invoices',
  CustomFields = 'customFields',
}

const getRouteName = (fullpath: string): EPageRouteName => {
  const fullpathParts = fullpath.split('/');
  const routeName = Object.values(EPageRouteName).find(el => el === fullpathParts[fullpathParts.length - 1]);
  return routeName || EPageRouteName.General;
};

const ADD_BUTTON_LABELS = {
  [EPageRouteName.General]: undefined,
  [EPageRouteName.Applications]: 'Add Application',
  [EPageRouteName.Users]: 'Add User',
  [EPageRouteName.Invoices]: undefined,
  [EPageRouteName.CustomFields]: 'Add Custom Field',
};

const AdminSubscriptionsDetails: FC = () => {
  const childRef = useRef<any>(null);
  const { subscriptionId } = useParams();
  const { pathname, state: navigationState } = useLocation();
  const [backNavigationQuery, setBackNavigationQuery] = useState('');
  const routeName = useMemo(() => getRouteName(pathname), [pathname]);
  const navigate = useNavigate();
  const {
    data: subscriptionInfo,
    isLoading: isSubscriptionInfoLoading,
  } = useAppSelector(state => state.ussp.getAdminSubscriptionInfoRequest || {});

  const dispatch = useAppDispatch();

  const fetchSubscriptionInfo = () => {
    if (typeof subscriptionId === 'string') {
      dispatch(getAdminSubscriptionInfo({
        subscriptionId,
      })).unwrap();
    }
  };

  useEffect(() => {
    if (navigationState && navigationState.adminSubscriptionQuery) {
      setBackNavigationQuery(navigationState.adminSubscriptionQuery);
    }
    fetchSubscriptionInfo();
  }, []);

  const [isShowSidebarModal, setIsShowSidebarModal] = useState<boolean>(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState<boolean>(false);
  const showSidebarModal = () => {
    setIsShowSidebarModal(true);
  };
  const hideSidebarModal = () => {
    setIsShowSidebarModal(false);
  };
  const toggleConfirmationModal = () => {
    setIsShowConfirmationModal(!isShowConfirmationModal);
  };

  const [addedUsersCount, setAddedUsersCount] = useState<number>(0);

  const renderHeaderModal = () => {
    switch (routeName) {
      case EPageRouteName.Applications: {
        return (
          <AddApplicationSidebarModal
            isAdminPortal
            visible={isShowSidebarModal}
            onCancel={hideSidebarModal}
            toggleConfirmationModal={toggleConfirmationModal}
          />
        );
      }
      case EPageRouteName.Users: {
        return (
          <AddUserSidebarModal
            visible={isShowSidebarModal}
            isAdminPortal
            hidePrice
            onCancel={hideSidebarModal}
            onSuccess={() => setAddedUsersCount(prev => prev + 1)}
          />
        );
      }
      case EPageRouteName.CustomFields: {
        return (
          <AddEditCustomFieldModal
            subscriptionId={subscriptionId}
            customFieldData={null}
            visible={isShowSidebarModal}
            onCancel={hideSidebarModal}
            onSuccess={() => childRef.current?.backgroundFetch()}
          />
        );
      }
    }
  };

  return (
    <div className="admin-subscriptions-details ussp-page">
      <section className="ussp-page__header rel-flex-gap-12">
        <button
          className="btn relative-units default dashboard-billing-details__back-btn"
          onClick={() => navigate(`/admin-portal/subscriptions${backNavigationQuery ? `?${backNavigationQuery}` : ''}`)}
        >
          <ArrowLeftSmallIcon />
        </button>
        <h1 className="flex-1">
          Subscription {isSubscriptionInfoLoading
            ? <Skeleton containerClassName="h1-skeleton" height="100%" width="200px" />
            : <>{subscriptionInfo?.subscriptionName}<span className="rel-m-l-8">ID#{subscriptionInfo?.chargifyId}</span></>}
        </h1>
        {ADD_BUTTON_LABELS[routeName] && (
          <button
            className="btn relative-units primary medium with-icon new-style"
            onClick={showSidebarModal}
          >
            <PlusInSquareIcon />
            {ADD_BUTTON_LABELS[routeName]}
          </button>
        )}
      </section>
      <nav className="flex">
        <CustomRouterLink to="">Details</CustomRouterLink>
        <CustomRouterLink to="applications">Applications</CustomRouterLink>
        <CustomRouterLink to="users">Users</CustomRouterLink>
        <CustomRouterLink to="invoices">Invoices</CustomRouterLink>
        <CustomRouterLink to="customFields">Custom Fields</CustomRouterLink>
      </nav>
      <Routes>
        <Route path="" element={<SubscriptionsDetailsGeneral />} />
        <Route path="applications" element={<SubscriptionsDetailsApplications openAddNewAppModal={showSidebarModal} />} />
        <Route path="users" element={<SubscriptionsDetailsUsers addedUsersCount={addedUsersCount} />} />
        <Route path="invoices" element={<SubscriptionsDetailsInvoices />} />
        <Route path="customFields" element={<SubscriptionsDetailsCustomFields ref={childRef}/>} />
        <Route path="*" element={(
          <section className="dashboard-account-details__content">
            <Error
              code={404}
              buttonLabel="Go to Subscriptions Page"
              buttonRedirectPath="/admin-portal/subscriptions"
              isShowButton
            />
          </section>
        )} />
      </Routes>
      {isShowSidebarModal && renderHeaderModal()}
      {isShowConfirmationModal && (
        <ConfirmationModal
          isShowConfirmationModal={isShowConfirmationModal}
          toggleConfirmationModal={toggleConfirmationModal}
        />
      )}
    </div>
  );
};

export default AdminSubscriptionsDetails;
