import './UserDetails.scss';
import { FC, useState, useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useSignUpFormNavigate } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { UsaCircleIcon, CadCircleIcon, WorldCircleIcon } from 'assets/svg';

import { EMAIL_PATTERN } from 'consts';
import { CountryCode, SignUpNavigation } from 'enums';

import {
  RadioButton,
  InputText,
  Button,
  ProgressBar,
  RadioButtonGroup,
} from 'components';
import { resetOrder, setUserDetailsFields, validateEmailByCountry } from 'store';
import { useLocation } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface IUserDetailsFields {
  country: CountryCode;
  firstName: string;
  lastName: string;
  email: string;
}

type UserDetailsForm = {
  firstName: string;
  lastName: string;
  email: string;
};

const COUNTRY_NAMES_BY_CODE = {
  [CountryCode.USA]: 'USA',
  [CountryCode.Canada]: 'CA',
  [CountryCode.Other]: 'World',
};

const COUNTRY_ICON_BY_CODE = {
  [CountryCode.USA]: UsaCircleIcon,
  [CountryCode.Canada]: CadCircleIcon,
  [CountryCode.Other]: WorldCircleIcon,
};

const UserDetails: FC = () => {
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const location = useLocation();
  const { country: initCountry, firstName, lastName, email } = location.state
    || { country: CountryCode.USA, firstName: '', lastName: '', email: '' } as IUserDetailsFields;
  const [currentCountryInit, setCurrentCountryInit] = useState<CountryCode>(initCountry);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const schema = useMemo(() => yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().required('Email is required')
      .matches(EMAIL_PATTERN, 'Please enter a valid email'),
  }), []);

  const {
    formState,
    handleSubmit,
    register,
  } = useForm<UserDetailsForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { firstName, lastName, email },
  });
  const { errors, isValid, touchedFields } = formState;

  const dispatch = useAppDispatch();

  const simpleFormNavigate = useSignUpFormNavigate();
  const onNextHandler: SubmitHandler<UserDetailsForm> = async (data) => {
    setIsNextLoading(true);
    const { firstName, lastName, email } = data;
    const token = !!executeRecaptcha && await executeRecaptcha('userDetailsPage');
    try {
      dispatch(resetOrder());
      await dispatch(validateEmailByCountry({
        country: currentCountryInit === CountryCode.Canada ? currentCountryInit : CountryCode.USA,
        email,
        captchaResponse: token
      })).unwrap();
      dispatch(setUserDetailsFields({
        country: currentCountryInit,
        firstName,
        lastName,
        email,
      }));
      simpleFormNavigate(SignUpNavigation.CompanyDetails);
    } catch (e: any) {
      // error
    } finally {
      setIsNextLoading(false);
    }
  };
  return (
    <>
      <div className="container-sm">
        <ProgressBar currentStep={1} totalSteps={7}/>

        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-8">New Client Order Form</div>
          <div className="sign-up-form-step-description m-b-32">
            Start your journey with Summit and scale up your business!
          </div>
          <RadioButtonGroup
            xVersion2
            hideRadio
            absoluteStyles
            value={currentCountryInit}
            classes="row spacing-md m-b-20"
            onChange={({ option }: any) => setCurrentCountryInit(option.value)}
            options={[CountryCode.USA, CountryCode.Canada, CountryCode.Other].map((countryCode) => ({
              label: COUNTRY_NAMES_BY_CODE[countryCode],
              value: countryCode,
              dataTestId: `user-details.country-radio-${countryCode}`,
              leftIcon: COUNTRY_ICON_BY_CODE[countryCode],
              wrapperClasses: "col-4 spacing-md"
            }))}
          />
          <form>
            <InputText
              dataTestId="user-details.first-name-input"
              xVersion
              absoluteStyles
              isRequired
              label="First Name"
              name="firstName"
              classes="m-b-20 sm-m-b-12"
              register={register}
              isInvalid={!!errors.firstName}
              errorText={errors.firstName?.message}
            />
            <InputText
              dataTestId="user-details.last-name-input"
              xVersion
              absoluteStyles
              isRequired
              label="Last Name"
              name="lastName"
              classes="m-b-20 sm-m-b-12"
              register={register}
              isInvalid={!!errors.lastName}
              errorText={errors.lastName?.message}
            />
            <InputText
              dataTestId="user-details.email-input"
              xVersion
              isRequired
              absoluteStyles
              type="email"
              label="Email"
              name="email"
              classes="m-b-20 sm-m-b-12"
              register={register}
              isInvalid={!!(errors.email && touchedFields.email)}
              errorText={errors.email?.message}
            />
          </form>
        </div>
        <div className="flex flex-justify-center m-t-40 sm-m-t-32">
          <Button
            dataTestId="user-details.next-step-button"
            xVersion
            absoluteStyles
            isLoading={isNextLoading}
            isDisabled={!isValid}
            onClick={handleSubmit(onNextHandler)}
          >
            Next Step
          </Button>
        </div>
      </div>
    </>
  );
};

export default UserDetails;