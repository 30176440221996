import './SubscriptionsDetailsGeneral.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

// import { notify, setupSignalR } from 'helpers';
import {
  getAdminSubscriptionById,
  // getAdminSubscriptionInfo,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Error } from 'components';
import AdditionalInfo from './components/AdditionalInfo/AdditionalInfo';
import CompanyInfo from './components/CompanyInfo/CompanyInfo';
import DepositInfo from './components/DepositInfo/DepositInfo';
import GeneralInfo from './components/GeneralInfo/GeneralInfo';

const SubscriptionsDetailsGeneral: FC = () => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const {
    data: subscription,
    error: subscriptionError,
    isLoading: isSubscriptionLoading,
  } = useAppSelector(
    (state) => state.ussp.getAdminSubscriptionByIdRequest || {},
  );
  const {
    data: subscriptionAdditionalInfo,
    error: subscriptionAdditionalInfoError,
    isLoading: isSubscriptionAdditionalInfoLoading,
  } = useAppSelector(
    (state) => state.ussp.getAdminSubscriptionInfoRequest || {},
  );

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);

  const isLoading = useMemo(
    () => (isSubscriptionLoading || isSubscriptionAdditionalInfoLoading) && !isBackgroundUpdate,
    [isSubscriptionLoading, isSubscriptionAdditionalInfoLoading, isBackgroundUpdate],
  );

  // const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);

  const fetchSubscription = async (isBackground?: boolean) => {
    if (typeof subscriptionId === 'string') {
      await dispatch(getAdminSubscriptionById({
        subscriptionId,
        _isBackground: isBackground,
      })).unwrap();
    }
  };

  const backgroundFetchSubscription = async () => {
    setIsBackgroundUpdate(true);
    await fetchSubscription(true);
    setIsBackgroundUpdate(false);
  };

  // const fetchSubscriptionInfo = async (isBackground?: boolean) => {
  //   if (typeof subscriptionId === 'string') {
  //     await dispatch(getAdminSubscriptionInfo({
  //       subscriptionId,
  //       _disableErrorHandler: true,
  //       _isBackground: isBackground,
  //     })).unwrap();
  //   }
  // };

  // const backgroundFetchSubscriptionInfo = async () => {
  //   setIsBackgroundUpdate(true);
  //   await fetchSubscriptionInfo(true);
  //   setIsBackgroundUpdate(false);
  // };

  useEffect(() => {
  //   const connection = setupSignalR('/accountOverviewApplications');
  //   if (connection) {
  //     connection.start()
  //       .then(() => {
  //         connection.on('Signal', async (message: any) => {
  //           await backgroundFetch();
  //           console.log('ACCOUNT_OVERVIEW_APPLICATIONS:SIGNAL', message);
  //         });
  //       })
  //       .catch((e: any) => console.log('Connection failed [accountOverviewApplications]: ', e));
  //   }
    fetchSubscription();
  //   return () => {
  //     if (connection) {
  //       connection.stop();
  //     }
  //   };
  }, []);

  if (subscriptionError || subscriptionAdditionalInfoError) {
    return <section className="admin-subscriptions-details__content"><Error code={500} /></section>;
  }

  return (
    <>
      <section className="admin-subscriptions-details__content">
        {/* <header
          className="flex flex-align-center flex-justify-between rel-p-x-32 rel-p-y-20"
        >
          <h2>Details</h2>
          <button
            className="btn relative-units default with-icon"
          >
            <EditOutlineIcon className="small" />
            Edit
          </button>
        </header> */}
        <div
          className="admin-subscriptions-details__info-wrapper rel-m-x-32"
        >
          <AdditionalInfo
            classes="admin-subscriptions-details__additional-info"
            isLoading={isLoading}
            {...(subscription || {})}
            {...(subscriptionAdditionalInfo || {})}
          />
          <GeneralInfo
            classes="admin-subscriptions-details__general-info"
            isLoading={isLoading}
            {...(subscription || {})}
            {...(subscriptionAdditionalInfo || {})}
            onTermsChange={backgroundFetchSubscription}
          />
          <CompanyInfo
            classes="admin-subscriptions-details__company-info"
            isLoading={isLoading}
            {...(subscription || {})}
            {...(subscriptionAdditionalInfo || {})}
          />
          <DepositInfo
            classes="admin-subscriptions-details__deposit-info"
            isLoading={isLoading}
            {...(subscription || {})}
            onCouponsChange={backgroundFetchSubscription}
          />
        </div>
      </section>
    </>
  );
};

export default SubscriptionsDetailsGeneral;
