import { FC, ReactNode, SyntheticEvent, useMemo } from 'react';
import RadioButton from '../RadioButton/RadioButton';
import cn from 'classnames';
import './RadioButtonGroup.scss';

interface Props {
  xVersion?: boolean;
  xVersion2?: boolean;
  classes?: string;
  absoluteStyles?: boolean;
  hideRadio?: boolean;
  disableBold?: boolean;
  value?: string | number | null;
  options: Array<{
    label: string | ReactNode,
    dataTestId?: string,
    rightLabel?: string,
    value?: string | number | null;
    leftIcon?: FC; classes?: string;
  }>
  onChange: (data: any) => void;
}

const RadioButtonGroup: FC<Props> = ({
  onChange,
  hideRadio,
  xVersion,
  classes,
  xVersion2,
  absoluteStyles = false,
  disableBold = false,
  options,
  value
}) => {
  const onChangeHandler = (option: any) => {
    onChange({ option });
  };

  const selectedOption = useMemo(() => {
    return options.find(e => e.value === value);
  }, [options, value]);

  const selectedOptionIndex = useMemo(() => {
    return options.findIndex(e => e.value === value);
  }, [options, value]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      event.preventDefault();
      const nextOptionIndex = (selectedOptionIndex + 1) % options.length;
      onChange({ option: options[nextOptionIndex] });
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      event.preventDefault();
      const prevOptionIndex = (selectedOptionIndex - 1 + options.length) % options.length;
      onChange({ option: options[prevOptionIndex] });
    }
  };

  return (
    <div
      className={cn('radio-button-group', classes)}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {options.map((o: any) => (
        <div className={cn(o.wrapperClasses)}>
          <RadioButton
            dataTestId={o.dataTestId}
            key={o.name}
            hideRadio={hideRadio}
            classes={o.classes}
            xVersion2={xVersion2}
            xVersion={xVersion}
            label={o.label}
            LeftIcon={o.leftIcon}
            rightLabel={o.rightLabel}
            value={o.value}
            isChecked={o.value === value}
            absoluteStyles={absoluteStyles}
            disableBold={disableBold}
            onChange={() => onChangeHandler(o) }
          />
        </div>
      ))}
    </div>
  );
};

export default RadioButtonGroup;