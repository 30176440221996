import './AccountDetailsUsersMfa.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

import { setupSignalR, useCartCompleteSuccess } from 'helpers';
import {
  getAccountUsersMfaData,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ITableFilterOrdering } from 'interfaces';

import { Error, Pagination, EmptyStateContainer } from 'components';
import UsersMfaTableHeadRow from './components/UsersMfaTableHeadRow';
import UsersMfaTableBodyRow from './components/UsersMfaTableBodyRow';
import UsersMfaTableBodyRowLoader from './components/UsersMfaTableBodyRowLoader';

import AddPhoneNumberModal from './components/AddPhoneNumberModal';
import AddMfaSidebarModal from './components/AddMfaSidebarModal';
import RemoveMfaSidebarModal from './components/RemoveMfaSidebarModal';
interface Props {
  isViewMode?: boolean;
}

const AccountDetailsUsersMfa: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getAccountUsersMfaDataRequest);

  const [totalPages, setTotalPages] = useState<number>(1);
  
  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    filter: '',
    orderings: '[]',
  });

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryFilter = useMemo(() => {
    return query.get('filter') || '';
  }, [query]);

  const queryOrderings = useMemo(() => {
    return JSON.parse(query.get('orderings') || '[]');
  }, [query]);

  useEffect(() => {
    fetchData();
  }, [subscriptionId, query]);

  const fetchData = async (
    isBackgroundUpdate?: boolean,
  ) => {
    if (typeof subscriptionId === 'string') {
      const data = await dispatch(getAccountUsersMfaData({
        subscriptionId,
        page: queryPage,
        pageSize: queryPageSize,
        filter: queryFilter,
        orderings: queryOrderings,
        _disableErrorHandler: true,
        _isBackground: isBackgroundUpdate,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);
  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(true);
    setIsBackgroundUpdate(false);
  };

  const onSearch = (searchQuery: string) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('filter', searchQuery);
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onOrderChange = (data: ITableFilterOrdering) => {
    const newOrderings = data === undefined ? [] : [data];
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('orderings', JSON.stringify(newOrderings));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  useCartCompleteSuccess(async () => {
    await backgroundFetch();
  });

  useEffect(() => {
    const connection = setupSignalR('/accountOverviewUsers');
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('Signal', async (message: any) => {
            await backgroundFetch();
            console.log('ACCOUNT_OVERVIEW_USERS:SIGNAL', message);
          });
        })
        .catch((e: any) => console.log('Connection failed [accountOverviewUsers]: ', e));
    }

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  // useEffect(() => {
  //   tableFilterData.current.page = 1;
  //   fetchData(1, pageSize, filter, orderings);
  // }, [subscriptionId]);

  const [currentUserData, setCurrentUserData] = useState<any | null>(null);

  const [isShowAddPhoneNumberModal, setIsShowAddPhoneNumberModal] = useState<boolean>(false);
  const openAddPhoneNumberModal = (id: string): void => {
    setCurrentUserData({ id });
    setIsShowAddPhoneNumberModal(true);
  };
  const closeAddPhoneNumberModal = () => {
    setIsShowAddPhoneNumberModal(false);
    setCurrentUserData(null);
  };

  const [isShowAddMfaSidebar, setIsShowAddMfaSidebar] = useState<boolean>(false);
  const openAddMfaSidebar = (id: string, userName: string, email: string): void => {
    setCurrentUserData({ id, userName, email });
    setIsShowAddMfaSidebar(true);
  };
  const closeAddMfaSidebar = () => {
    setIsShowAddMfaSidebar(false);
    setCurrentUserData(null);

  };

  const [isShowRemoveMfaSidebar, setIsShowRemoveMfaSidebar] = useState<boolean>(false);
  const openRemoveMfaSidebar = (id: string, userName: string, email: string): void => {
    setCurrentUserData({ id, userName, email });
    setIsShowRemoveMfaSidebar(true);
  };
  const closeRemoveMfaSidebar = () => {
    setIsShowRemoveMfaSidebar(false);
    setCurrentUserData(null);

  };

  if (error) {
    return <section className="dashboard-account-details__content"><Error code={500} /></section>;
  }

  return (
    <>
      <section className="dashboard-account-details__content">
        <table className="dashboard-accounts-users-mfa-table">
          <thead>
            <UsersMfaTableHeadRow
              initialSearchQuery={queryFilter}
              orderState={queryOrderings?.[0]}
              onSearch={onSearch}
              onOrderChange={onOrderChange}
            />
          </thead>
          <tbody>
            {isShowLoader
              ? <UsersMfaTableBodyRowLoader />
              : data?.users?.map((el: any, index: number) => (
                <UsersMfaTableBodyRow
                  key={`account-users-details-${index}`}
                  {...el}
                  onAddPhoneNumber={openAddPhoneNumberModal}
                  onAddMfa={openAddMfaSidebar}
                  onRemoveMfa={openRemoveMfaSidebar}
                />
              ))
            }
          </tbody>
        </table>
        {
          data?.users.length === 0
            ? <EmptyStateContainer
              title="No users found"
              description="No users found with specified parameters"
              classes="empty-state-container-table"
            />
            : null
        }
      </section>
      {data?.users.length > 0 && (
        <Pagination
          current={queryPage}
          entries={queryPageSize}
          totalPages={totalPages}
          onChange={onPaginationChange}
        />
      )}
      {isShowAddPhoneNumberModal && (
        <AddPhoneNumberModal
          visible={isShowAddPhoneNumberModal}
          userData={currentUserData}
          subscriptionId={subscriptionId}
          onCancel={closeAddPhoneNumberModal}
          onSuccess={backgroundFetch}
        />
      )}
      {isShowAddMfaSidebar && (
        <AddMfaSidebarModal
          visible={isShowAddMfaSidebar}
          userData={currentUserData}
          onCancel={closeAddMfaSidebar}
          onSuccess={backgroundFetch}
        />
      )}
      {isShowRemoveMfaSidebar && (
        <RemoveMfaSidebarModal
          visible={isShowRemoveMfaSidebar}
          userData={currentUserData}
          onCancel={closeRemoveMfaSidebar}
          onSuccess={backgroundFetch}
        />
      )}
    </>
  );
};

export default AccountDetailsUsersMfa;
