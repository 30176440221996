import { FC, useCallback, useMemo, useState } from 'react';
import './StepPriceDetails.scss';
import { CountryCode, ProductChargify, SignUpNavigation } from 'enums';
import { IProduct } from 'interfaces';
import { usePriceHandler, useGetSignUpTotalPriceByStep, getEnvCountryCode } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'assets/svg';
import cn from 'classnames';
import { Button } from 'components';
interface Props {
  currentStep: SignUpNavigation;
  stepProducts?: any[];
  isIncludeDeposit?: boolean;
}

const StepPriceDetails: FC<Props> = ({ currentStep, stepProducts = [], isIncludeDeposit = false }) => {
  const [isExtended, setIsExtended] = useState(false);
  const currentCountryCode = useAppSelector(state => state.order.country?.code || CountryCode.USA);
  const envCountryCode = useMemo(() => getEnvCountryCode(currentCountryCode), [currentCountryCode]);
  const { formatPrice } = usePriceHandler();
  const { calculateProductPrice } = usePriceHandler();
  const { stepProducts: allStepProducts, totalPrice, deposit } = useGetSignUpTotalPriceByStep({ currentStep, stepProducts });

  const isProductAlignToChargify = (name: string | ProductChargify) => {
    return Object.values(ProductChargify).includes(name as ProductChargify);
  };

  const getPriceByProduct = useCallback(({ name, quantity, price }) => {
    return isProductAlignToChargify(name)
      ? calculateProductPrice(name as ProductChargify, quantity)
      : price * +quantity as number;
  }, []);

  const formatedFullPrice = useMemo(() => {
    const fullPrice = totalPrice + (isIncludeDeposit ? deposit : 0);
    return formatPrice(fullPrice, envCountryCode);
  }, [totalPrice, deposit]);

  const filteredProducts = (allStepProducts as any).filter((product: any) => +product.quantity);

  return (
    <div className="step-price-wrapper">
      <div className={cn('step-price-block', { 'block-extended': isExtended })}>
        <div className="step-price-info">
          <div className="step-price-text">
            Total monthly price <span className="fw-600 c-grey-7">{formatedFullPrice}</span>
          </div>
          <div className="step-price-extend-icon">
            <Button
              dataTestId="step-price-open-hide-button"
              type="textable"
              xVersion
              absoluteStyles
              onClick={() => setIsExtended(!isExtended)}
            >
              {isExtended ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
            </Button>
          </div>
        </div>
        {isExtended ? (
          <div className="step-extend-details">
            {filteredProducts.map((product: IProduct) => (
              <div className="product-row">
                <div className="product-row-name">{product.name}</div>
                <div className="product-row-quantity">x{product.quantity}</div>
                <div className="product-row-price">{formatPrice(getPriceByProduct(product), envCountryCode)}</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StepPriceDetails;