import { FC, useMemo } from 'react';

import { usePriceHandler } from 'helpers';
import { useAppSelector } from 'store/hooks';

import { CountryCode, ProductGroup } from 'enums';
import {
  DeleteOutlineIcon,
  DotsIcon,
  EditOutlineIcon,
  PlusInSquareIcon,
} from 'assets/svg';

import { Menu } from 'components';

interface Props {
  name: string;
  chargifyComponentId: number;
  quantity: number;
  price: number;
  users: any[];
  groupName: string;
  isAdditional: boolean;
  relatedToSubscription: boolean;
  totalMonthlyPrice: number;
  isActionsDisabled: boolean;
  onAssignNewUsers: () => void;
  onReview: () => void;
  onRemoveUserFromApp: () => void;
  onEdit: () => void;
  onRemove: () => void;
}

const ApplicationsTableBodyRow: FC<Props> = ({
  name,
  chargifyComponentId,
  quantity,
  price,
  totalMonthlyPrice,
  relatedToSubscription,
  isActionsDisabled,
  groupName,
  users,
  isAdditional,
  onAssignNewUsers,
  onRemoveUserFromApp,
  onEdit,
  onRemove,
}) => {
  const currentCountry = useAppSelector(
    (state) => state.ussp.getAdminSubscriptionInfoRequest.data?.country as CountryCode
      || CountryCode.USA,
  );
  const { formatPrice } = usePriceHandler();

  const priceFormated = useMemo(() => formatPrice(price || 0, currentCountry), [
    price,
    currentCountry,
  ]);
  const totalMonthlyPriceFormated = useMemo(() => formatPrice(totalMonthlyPrice || 0, currentCountry), [
    totalMonthlyPrice,
    currentCountry,
  ]);

  return (
    <tr className="applications-table-body-row">
      <td>{name}</td>
      <td>{chargifyComponentId}</td>
      <td>{quantity}</td>
      <td>{priceFormated}</td>
      <td>{totalMonthlyPriceFormated}</td>
      <td>
        <Menu
          trigger={<DotsIcon />}
          items={[
            ...(!(groupName === ProductGroup.Other && (isAdditional || relatedToSubscription)) ? [<div onClick={onAssignNewUsers}><PlusInSquareIcon className="menu-item-icon" />Assign New Users</div>] : []),
            ...(users.length && !(groupName === ProductGroup.Other && (isAdditional || relatedToSubscription)) ? [<div onClick={onRemoveUserFromApp}><DeleteOutlineIcon className="menu-item-icon" />Unassign User(s) From Application</div>] : []),
            ...(!(groupName === ProductGroup.Other && isAdditional) ? [<div onClick={onEdit}><EditOutlineIcon className="menu-item-icon" />Edit</div>] : []),
            <div onClick={onRemove}><DeleteOutlineIcon className="menu-item-icon" />Remove</div>,
          ]}
          isDisabled={isActionsDisabled}
          placement="bottom-end"
        />
      </td>
    </tr>
  );
};

export default ApplicationsTableBodyRow;
