import './AdminHome.scss';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

const AdminHome: FC = () => {
  return <Navigate to="subscriptions" />;
  // return (
  //   <div className="admin-home ussp-page">
  //     <section className="admin-home__header ussp-page__header">
  //       <h1>Home Page</h1>
  //     </section>
  //   </div>
  // );
};

export default AdminHome;
