import './Modal.scss';
import { FC, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { CloseIcon } from 'assets/svg';
import { makeVw } from 'helpers';
import Button from '../Button/Button';
import FocusTrap from 'focus-trap-react';
interface Props {
  visible: boolean;
  children?: ReactNode;
  closable?: boolean;
  description?: string;
  title?: string | null | ReactElement;
  footer?: ReactElement | null;
  footerAdditonal?: ReactElement | null;
  classes?: string;
  width?: string | number;
  isOkDisabled?: boolean;
  isOkLoading?: boolean;
  okText?: string | null;
  okWidth?: number;
  cancelText?: string | null;
  removeText?: string;
  showRemoveButton?: boolean;
  absoluteStyles?: boolean;
  xVersion?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  onRemove?: () => void;
}

const Modal: FC<Props> = ({
  visible,
  children,
  description,
  title,
  footer,
  footerAdditonal = null,
  classes,
  width = 495,
  closable = true,
  isOkDisabled = false,
  isOkLoading = false,
  absoluteStyles = false,
  showRemoveButton = false,
  removeText = 'Remove',
  xVersion,
  okText = 'Confirm',
  okWidth,
  cancelText = 'Cancel',
  onCancel,
  onOk,
  onRemove,
}) => {
  const okBtnWidthStyle = useMemo(
    () => okWidth ? { width: makeVw(okWidth) } : {},
    [okWidth],
  );

  useEffect(() => {
    if (visible) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    // Cleanup function to remove class if the component is unmounted
    return () => document.body.classList.remove('modal-open');
  }, [visible]);

  const onCloseKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onCancel?.();
    }
  };

  return (
    <FocusTrap
      active={visible}
      focusTrapOptions={{
        allowOutsideClick: true,
        initialFocus: false,
      }}
    >
      <div className={cn(
        'modal-wrapper',
        {
          'visible': visible,
          'relative-units': !absoluteStyles,
          xVersion,
        },
        classes
      )}>
        <div className="modal" style={{ width: absoluteStyles ? width : makeVw(width) }}>
          {closable && <CloseIcon data-test-id="modal-close-icon" onKeyDown={onCloseKeyDown} tabIndex={0} className="close-icon" onClick={onCancel} />}
          {title !== null && <div className="modal-title">{title}</div>}
          {(children || description) && (
            <div className="modal-main">
              {children || description}
            </div>
          )}
          {footer !== null && (
            <div className="modal-footer flex flex-justify-end flex-align-center">
              {footer === undefined
                ? (
                  <>
                    {footerAdditonal}
                    {showRemoveButton && removeText !== '' && (
                      <Button
                        dataTestId="modal-remove-button"
                        type="remove"
                        absoluteStyles={absoluteStyles}
                        xVersion={xVersion}
                        size={xVersion ? 'medium' : undefined}
                        isNewStyle
                        classes="m-r-auto"
                        onClick={onRemove}
                      >
                        {removeText}
                      </Button>
                    )}
                    {cancelText !== null && (
                      <Button
                        dataTestId="modal-cancel-button"
                        type="default"
                        absoluteStyles={absoluteStyles}
                        xVersion={xVersion}
                        size={xVersion ? 'medium' : undefined}
                        isNewStyle
                        onClick={onCancel}
                      >
                        {cancelText}
                      </Button>
                    )}
                    {okText !== null && (
                      <Button
                        dataTestId="modal-ok-button"
                        absoluteStyles={absoluteStyles}
                        xVersion={xVersion}
                        isLoading={isOkLoading}
                        style={okBtnWidthStyle}
                        size={xVersion ? 'medium' : undefined}
                        isDisabled={isOkDisabled}
                        isNewStyle
                        onClick={onOk}
                      >
                        {okText}
                      </Button>
                    )}
                  </>
                )
                : footer
              }
            </div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
};

export default Modal;