import './Logo.scss';
import React, { FC } from 'react';
import { SummitLogoNew, SummitLogoWhiteNew } from 'assets/svg';
import cn from 'classnames';

interface Props {
  type?: 'black' | 'white';
  absoluteStyles?: boolean;
}

const LOGO_COMPONENTS = {
  black: SummitLogoNew,
  white: SummitLogoWhiteNew,
};

const Logo: FC<Props> = ({ type = 'black', absoluteStyles = false }) => {
  const openSHPage = () => {
    window.open('https://www.summithosting.com/', '_blank');
  };

  const LogoComponent = LOGO_COMPONENTS[type];

  return (
    <LogoComponent
      className={cn("logo", { 'relative-units': !absoluteStyles })}
      onClick={openSHPage}
    />
  );
};

export default Logo;