import React, { FC, useMemo } from 'react';
import { usePriceHandler } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { CountryCode } from 'enums';
import { ThreeCubesIcon } from 'assets/svg';

interface Props {
  productName: string;
  quantity: number;
  unitPrice: number;
  amount: number;
}

const BillingDetailsTableBodyRow: FC<Props> = ({
  productName,
  quantity,
  unitPrice,
  amount,
}) => {
  const currentCountry = useAppSelector((state) => state.ussp.user?.country || CountryCode.USA);
  const { formatPrice } = usePriceHandler();

  const unitPriceFormated = useMemo(() => formatPrice(unitPrice || 0, currentCountry), [
    unitPrice,
    currentCountry,
  ]);

  return (
    <tr>
      <td>
        <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
          <div className="app-card__icon-wrapper"><ThreeCubesIcon /></div>
          <div className="app-card__content">
            <div className="app-card__name">{productName}</div>
          </div>
        </div>
      </td>
      <td>{quantity}</td>
      <td>{unitPriceFormated}/mo</td>
      <td>{amount}</td>
    </tr>
  );
};

export default BillingDetailsTableBodyRow;
