import { FC } from 'react';

import {
  DeleteOutlineIcon,
  DotsIcon,
  PlusInSquareIcon,
  StarOutlineIcon,
  // WorkIcon,
} from 'assets/svg';

import { Menu } from 'components';
import { ProductGroup } from 'enums';

interface IAplicationDetails {
  productVersion: string;
  licenceCode: string;
  productCode: string;
  serialNumber: string;
}

interface Props {
  title: string;
  quantity: number;
  details: IAplicationDetails | null;
  isActionsDisabled: boolean;
  relatedToSubscription: boolean;
  productGroup: string;
  isAdditional: boolean;
  onAssignNewUsers: () => void;
  onReview: () => void;
  onUpgrade: () => void;
  onRemove: () => void;
}

const ApplicationsTableBodyRow: FC<Props> = ({
  title,
  quantity,
  details,
  isActionsDisabled,
  relatedToSubscription,
  productGroup,
  isAdditional,
  onAssignNewUsers,
  onReview,
  onUpgrade,
  onRemove,
}) => {
  return (
    <tr className="applications-table-body-row">
      <td>
        <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
          {/* <div className="app-card__icon-wrapper"><WorkIcon /></div> */}
          <div className="app-card__content">
            <div className="app-card__name">
              {title}
            </div>
            <div className="app-card__id">{details?.productVersion}</div>
          </div>
        </div>
      </td>

      <td>{quantity}</td>

      <td>
        {details === null ? '-' : (
          <span className="btn btn-text" onClick={onReview}>
            Review
          </span>
        )}
      </td>

      <td>
        <Menu
          trigger={<DotsIcon />}
          items={[
            ...(!(productGroup.toLowerCase() === ProductGroup.Other && (isAdditional || relatedToSubscription)) ? [<div onClick={onAssignNewUsers}><PlusInSquareIcon className="menu-item-icon" />Assign New Users</div>] : []),
            ...([ProductGroup.Quickbooks as string, ProductGroup.Quickbook, ProductGroup.Sage as string].includes(productGroup.toLowerCase()) ? [<div onClick={onUpgrade}><StarOutlineIcon className="menu-item-icon" />Upgrade</div>] : []),
            <div onClick={onRemove}><DeleteOutlineIcon className="menu-item-icon" />Remove</div>,
          ]}
          isDisabled={isActionsDisabled}
          placement="bottom-end"
        />
      </td>
    </tr>
  );
};

export default ApplicationsTableBodyRow;
