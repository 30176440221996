import './SubscriptionsDetailsApplications.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { notify, setupSignalR } from 'helpers';
import {
  getAdminSubscriptionApplications,
  removeAdminSubscriptionApps,
  getAdminAdditionalServices,
  getAdminOtherProducts,
  getAdminQuickbooksProducts,
  getAdminSageProducts,
  adminAssignUsersToApp,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ITableFilterOrdering } from 'interfaces';

import {
  AssignUsersToAppModal,
  Error,
  Pagination,
  Modal,
  EmptyStateContainer,
  ReviewApplicationModal,
  EditApplicationModal,
  RemoveUserFromAppModal,
} from 'components';
import ApplicationsTableHeadRow from './components/ApplicationsTableHeadRow';
import ApplicationsTableBodyRow from './components/ApplicationsTableBodyRow';
import ApplicationsTableBodyRowLoader from './components/ApplicationsTableBodyRowLoader';
import { SortingState } from 'enums';

interface Props {
  openAddNewAppModal: () => void;
}

const SubscriptionsDetailsApplications: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getAdminSubscriptionApplicationsRequest);
  const { isLoading: isDeleteProductLoading } = useAppSelector(state => state.ussp.removeAdminSubscriptionAppsRequest);

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    filter: '',
    orderings: JSON.stringify([{ orderBy: 'ApplicationTitle', order: SortingState.ASC }]),
  });

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryFilter = useMemo(() => {
    return query.get('filter') || '';
  }, [query]);

  const queryOrderings = useMemo(() => {
    return JSON.parse(query.get('orderings') || '[]');
  }, [query]);

  useEffect(() => {
    fetchData();
  }, [subscriptionId, query]);

  const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);

  const fetchData = async (
    isBackground?: boolean,
  ) => {
    if (typeof subscriptionId === 'string') {
      const data = await dispatch(getAdminSubscriptionApplications({
        subscriptionId,
        page: queryPage,
        pageSize: queryPageSize,
        filter: queryFilter,
        orderings: queryOrderings,
        _disableErrorHandler: true,
        _isBackground: isBackground,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };

  const getAddProductsInformation = async () => {
    if (typeof subscriptionId === 'string') {
      await dispatch(getAdminSageProducts({ subscriptionId })).unwrap();
      await dispatch(getAdminOtherProducts({ subscriptionId })).unwrap();
      await dispatch(getAdminAdditionalServices({ subscriptionId })).unwrap();
      await dispatch(getAdminQuickbooksProducts({ IsOwn: true, IsPurchase: false, subscriptionId })).unwrap();
    }
  };

  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(true);
    setIsBackgroundUpdate(false);
  };

  const onSearch = (searchQuery: string) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('filter', searchQuery);
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onOrderChange = (data: ITableFilterOrdering) => {
    const newOrderings = data === undefined ? [] : [data];
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('orderings', JSON.stringify(newOrderings));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  // useEffect(() => {
  //   const connection = setupSignalR('/accountOverviewApplications');
  //   if (connection) {
  //     connection.start()
  //       .then(() => {
  //         connection.on('Signal', async (message: any) => {
  //           await backgroundFetch();
  //           console.log('ACCOUNT_OVERVIEW_APPLICATIONS:SIGNAL', message);
  //         });
  //       })
  //       .catch((e: any) => console.log('Connection failed [accountOverviewApplications]: ', e));
  //   }

  //   return () => {
  //     if (connection) {
  //       connection.stop();
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   tableFilterData.current.page = 1;
  //   fetchData(1, pageSize, filter, orderings);
  // }, [subscriptionId]);

  const [reviewItemData, setReviewedItemeData] = useState<any>({});
  const [isShowReviewModal, setIsShowReviewModal] = useState<boolean>(false);
  const [isShowEditApplicationModal, setIsShowEditApplicationModal] = useState<boolean>(false);
  const showReviewModal = () => {
    setIsShowReviewModal(true);
  };
  const hideReviewModal = () => {
    setIsShowReviewModal(false);
  };

  useEffect(() => {
    getAddProductsInformation();
  }, []);

  const showEditApplicationModal = (index: number) => {
    if (typeof (data?.applications[index].id) === 'string') {
      setCurrentAppData({
        ...data?.applications[index],
      });
      setIsShowEditApplicationModal(true);
    }
  };
  const hideEditApplicationModal = () => {
    setIsShowEditApplicationModal(false);
    setCurrentAppData({});
  };

  const reviewAppDetailsHandler = (index: number) => {
    setReviewedItemeData({
      title: data?.applications[index].title,
      ...(data?.applications[index]?.details || {}),
    });
    showReviewModal();
  };

  const [isShowRemoveModal, setIsShowRemoveModal] = useState<boolean>(false);
  const [appToRemove, setAppToRemove] = useState<any>(null);
  const showRemoveModal = (index: number) => {
    setAppToRemove(data?.applications[index]);
    setIsShowRemoveModal(true);
  };
  const hideRemoveModal = () => {
    setAppToRemove(null);
    setIsShowRemoveModal(false);
  };
  const removeAppHandler = async () => {
    //maybe add loading to buttons
    if (appToRemove && typeof subscriptionId === 'string') {
      await dispatch(removeAdminSubscriptionApps({
        subscriptionId,
        applicationIds: [appToRemove.id],
      })).unwrap();
      backgroundFetch();
      getAddProductsInformation();
      notify.success('This product has been deleted', 'You cannot return this product');
    }
    hideRemoveModal();
  };

  const [currentAppData, setCurrentAppData] = useState<any>({});
  const [isShowAssignUsersModal, setIsShowAssignUsersModal] = useState<boolean>(false);
  const [isShowRemoveUserFromAppModal, setIsRemoveUserFromAppModal] = useState<boolean>(false);

  const showAssignUsersModal = (index: number) => {
    if (typeof (data?.applications[index].id) === 'string') {
      setCurrentAppData({
        ...data?.applications[index],
      });
      setIsShowAssignUsersModal(true);
    }
  };
  const hideAssignUsersModal = () => {
    setIsShowAssignUsersModal(false);
    setCurrentAppData({});
  };
  const showRemoveUserFromAppModal = (index: number) => {
    if (typeof (data?.applications[index].id) === 'string') {
      setCurrentAppData({
        ...data?.applications[index],
      });
      setIsRemoveUserFromAppModal(true);
    }
  };
  const hideRemoveUserFromAppModal = () => {
    setIsRemoveUserFromAppModal(false);
    setCurrentAppData({});
  };
  const addAssignedUsersToCartHandler = async (assignedUsersIds: string[]) => {
    if (typeof subscriptionId === 'string') {
      await dispatch(adminAssignUsersToApp({
        subscriptionId,
        applicationId: currentAppData.id,
        userIds: assignedUsersIds,
      })).unwrap();
      notify.success(
        'Success',
        'Users successfully assigned',
      );
      backgroundFetch();
      setIsShowAssignUsersModal(false);
      setCurrentAppData({});
    }
  };

  if (error) {
    return <section className="dashboard-account-details__content"><Error code={500} /></section>;
  }

  return (
    <>
      {/* <div className="admin-subscription-info flex rel-gap-64 rel-p-x-32 rel-p-y-24">
        <div className="flex-column rel-gap-12">
          <span>Status:</span>
          {'todo'}
        </div>
        <div className="flex-column rel-gap-12">
          <span>Next Billing:</span>
          {'todo'}
        </div>
        <div className="flex-column rel-gap-12">
          <span>Activated on:</span>
          {'todo'}
        </div>
      </div> */}
      <section className="admin-subscriptions-details__content">
        <table className="admin-subscriptions-app-table">
          <thead>
            <ApplicationsTableHeadRow
              initialSearchQuery={queryFilter}
              orderState={queryOrderings?.[0]}
              onSearch={onSearch}
              onOrderChange={onOrderChange}
            />
          </thead>
          <tbody>
            {isShowLoader
              ? <ApplicationsTableBodyRowLoader />
              : data?.applications.map((el: any, index: number) => (
                <ApplicationsTableBodyRow
                  key={el.id || el.title}
                  {...el}
                  isActionsDisabled={el.isReadonly}
                  onAssignNewUsers={() => showAssignUsersModal(index)}
                  onEdit={() => showEditApplicationModal(index)}
                  onRemove={() => showRemoveModal(index)}
                  onRemoveUserFromApp={() => showRemoveUserFromAppModal(index)}
                />
              ))
            }
          </tbody>
        </table>
        {
          data?.applications.length === 0
            ? <EmptyStateContainer
              title="No applications found"
              description="No applications found with specified parameters"
              classes="empty-state-container-table"
            />
            : null
        }
      </section>
      {
        data?.applications.length > 0
          ? <Pagination
            current={queryPage}
            entries={queryPageSize}
            totalPages={totalPages}
            onChange={onPaginationChange}
          />
          : null
      }
      {isShowReviewModal && (
        <ReviewApplicationModal
          visible={isShowReviewModal}
          {...reviewItemData}
          onClose={hideReviewModal}
        />
      )}
      {isShowEditApplicationModal && (
        <EditApplicationModal
          visible={isShowEditApplicationModal}
          productData={currentAppData}
          subscriptionId={subscriptionId}
          onCancel={hideEditApplicationModal}
          onSuccessEdit={backgroundFetch}
        />
      )}
      {isShowAssignUsersModal && (
        <AssignUsersToAppModal
          isAdminPortal
          visible={isShowAssignUsersModal}
          productData={currentAppData}
          subscriptionId={subscriptionId || ''}
          onOk={addAssignedUsersToCartHandler}
          onCancel={hideAssignUsersModal}
        />
      )}
      {isShowRemoveUserFromAppModal && (
        <RemoveUserFromAppModal
          isAdminPortal
          visible={isShowRemoveUserFromAppModal}
          productData={currentAppData}
          onSuccess={backgroundFetch}
          onCancel={hideRemoveUserFromAppModal}
        />
      )}
      <Modal
        title={`Do you want to remove ${appToRemove?.title} entirely from your server?`}
        visible={isShowRemoveModal}
        onOk={removeAppHandler}
        isOkLoading={isDeleteProductLoading}
        okText="Yes, Remove"
        cancelText="No"
        onCancel={hideRemoveModal}
        width={535}
      />
    </>
  );
};

export default SubscriptionsDetailsApplications;
