import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import querystring from 'query-string';
import { IHttpRequestResult, IDashboardUser, ITableFilter } from 'interfaces';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers';
import { COUNTRY_NUMBER_BY_CODE } from 'consts';
import { CountryCode } from 'enums';

interface IRequestOptions {
  isAdminPortal?: boolean;
  _disableErrorHandler?: boolean;
  _isBackground?: boolean;
}

interface IRequestWithSubscription extends IRequestOptions {
  subscriptionId: string;
}

interface IUserRequestWithSubscription extends IRequestWithSubscription {
  userId: string;
}

interface IUserApplicationsRequestPayload extends IUserRequestWithSubscription, ITableFilter {}

interface IBillingsDataRequestPayload extends ITableFilter, IRequestOptions {
  statuses?: string[];
}

interface IBillingInfoRequestPayload extends ITableFilter, IRequestWithSubscription {}

interface IInvoiceMetafieldsPayload {
  invoiceId: string;
  subscriptionId: string;
}
interface IInvoiceFileRequestPayload {
  invoiceId: string;
  subscriptionId: string;
}

interface IAccountsDataRequestPayload extends ITableFilter, IRequestOptions {}

interface IAccountDetailsRequestPayload extends ITableFilter, IRequestWithSubscription {
  applicationIds?: string[];
}

interface IAccountAppAssigningUsersRequestPayload extends IRequestWithSubscription {
  productId: string;
}

interface IAccountAppRemoveRequestPayload extends IRequestWithSubscription {
  applicationId: string;
}

interface IRemoveApplicationFromUserRequestPayload extends IUserRequestWithSubscription {
  applicationIds: string[];
}
interface IRemoveUsersFromApplicationRequestPayload extends IRequestWithSubscription {
  userIds: string[];
  applicationId: string;
}

interface IAddAssignedUsersToCartRequestPayload extends IRequestWithSubscription {
  productId: string;
  userIds: string[];
}
interface IAddAdminAssignedUsersToAppRequestPayload extends IRequestWithSubscription {
  applicationId: string;
  userIds: string[];
}

interface ISendQuickbooksPurchase extends IRequestWithSubscription {
  message: string;
}

interface IRemoveAssignFromCartRequestPayload extends IRequestOptions {
  productId: string;
  userId?: string;
}

interface IRemoveApplicationFromCartRequestPayload extends IRequestWithSubscription {
  componentId: number;
  userId?: string;
}

interface IEditApplicationRequestPayload extends IRequestWithSubscription {
  id: string;
  name: string;
  year: string;
  productCode: string;
  serialNumber: string;
  licenceCode: string;
}

interface IEditUserRequestPayload extends IRequestWithSubscription {
  formData: any;
}
interface IIsUserLastAdminRequestPayload extends IRequestWithSubscription {
  userId: string;
}

interface IUpdateDomainNameRequestPayload extends IRequestWithSubscription {
  domainName: string;
}

interface ICompleteCartRequestPayload extends IRequestOptions {
  eSign: string;
}

interface ICanAppBeUpgradedRequestPayload extends IRequestWithSubscription {
  productId: string;
}

interface IGetCatalogDataRequestPayload extends IRequestOptions {
  componentId: number;
  subscriptionId?: string;
}

interface IAddSageUpgradeToCartRequestPayload extends IRequestWithSubscription {
  productId: string;
  serialNumber: string;
}

interface IAddQuickbooksUpgradeToCartRequestPayload extends IRequestWithSubscription {
  productId: string;
  productCode: string;
  licenseCode: string;
}

interface IRemoveUpgradeProductFromCartRequestPayload extends IRequestWithSubscription {
  productId: string;
}

interface ICheckIsAllowedUserEmailRequestPayload extends IRequestWithSubscription {
  email: string;
}

interface IAddNewUserToCartRequestPayload extends IRequestWithSubscription {
  formData: any;
}

interface IassignApplicationToUser extends IUserRequestWithSubscription {
  productIds: string[];
}

interface IRemoveNewUserFromCartRequestPayload extends IRequestOptions {
  email: string;
}

interface IRemoveNewUserAssignAppFromCartRequestPayload extends IRequestWithSubscription {
  productId: string;
  email: string;
}

interface IRemoveUserAssignedAppFromCartRequestPayload extends IUserRequestWithSubscription {
  productId: string;
}

interface IAddUserPhoneNumberRequestPayload extends IRequestWithSubscription {
  userId: string;
  phoneNumber: string;
}

interface IUserMfaRequestPayload extends IRequestWithSubscription {
  userIds: string[];
}

interface IAdminSubscriptionTermsRequestPayload extends IRequestWithSubscription {
  termsId: string;
}

interface IAdminSubscriptionsRequestPayload extends ITableFilter, IRequestOptions {
  statuses?: string[];
  terms?: string[];
  manageables?: string[];
  country?: CountryCode | 'All';
}

interface IAdminSubscriptionUsersRequestPayload extends ITableFilter, IRequestWithSubscription {
  applicationIds?: string[];
}
interface IAdminSubscriptionApplicationsRequestPayload extends ITableFilter, IRequestWithSubscription {}

interface IRemoveAdminSubscriptionAppsRequestPayload extends IRequestWithSubscription {
  applicationIds: string[];
}

interface IAdminSubscriptionCustomFieldsRequestPayload extends ITableFilter, IRequestWithSubscription {}

interface IAdminSubscriptionInvoicesRequestPayload extends ITableFilter, IRequestWithSubscription {}
interface IAdminCouponRequestPayload extends IRequestWithSubscription {
  code: string;
}
interface IAdminCustomFieldPayload extends IRequestWithSubscription {
  id?: string;
  name: string;
  value: string;
}
interface IAdminRemoveCustomFieldPayload extends IRequestWithSubscription {
  id: string;
}

export const getDashboardData = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/getDashboardData',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.get('/bff-ussp/dashboard', { _disableErrorHandler });
    return response;
  },
);

export const getDashboardUser = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/getDashboardUser',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.get('/bff-ussp/user/info', { _disableErrorHandler });
    return response;
  },
);

export const getBillingsData = createAsyncThunk<any, IBillingsDataRequestPayload>(
  'ussp/getBillingsData',
  async ({
    page,
    pageSize,
    statuses,
    orderings,
    filter,
    filterBy = 'CompanyName',
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      '/bff-ussp/billing',
      {
        page,
        pageSize,
        orderings,
        filter,
        filterBy,
        statuses,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getBillingDetailsData = createAsyncThunk<any, IBillingInfoRequestPayload>(
  'ussp/getBillingDetailsData',
  async ({ subscriptionId, page, pageSize, _disableErrorHandler = false }) => {
    const query = querystring.stringify({ page, pageSize });
    const response = await http.get(`/bff-ussp/billing/${subscriptionId}?${query}`, { _disableErrorHandler });
    return response;
  },
);

export const getBillingSla = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getBillingSla',
  async ({ subscriptionId, _disableErrorHandler = false }) => {
    const response = await http.get(`/bff-ussp/billing/${subscriptionId}/sla`, {
      responseType: 'blob',
      _disableErrorHandler,
    });
    return response;
  },
);

export const getBillingInvoicesData = createAsyncThunk<any, IBillingInfoRequestPayload>(
  'ussp/getBillingInvoicesData',
  async ({ subscriptionId, page, pageSize, _disableErrorHandler = false }) => {
    const query = querystring.stringify({ page, pageSize });
    const response = await http.get(`/bff-ussp/billing/${subscriptionId}/invoices?${query}`, { _disableErrorHandler });
    return response;
  },
);

export const getBillingInvoicesMetafields = createAsyncThunk<any, IInvoiceMetafieldsPayload>(
  'ussp/getBillingInvoicesMetafields',
  async ({ subscriptionId, invoiceId }) => {
    const response = await http.get(
      `/bff-ussp/billing/subscriptions/${subscriptionId}/invoices/${invoiceId}/metadata`,
    );
    return response;
  },
);

export const getBillingInvoiceFile = createAsyncThunk<any, IInvoiceFileRequestPayload>(
  'ussp/getBillingInvoiceFile',
  async ({ invoiceId, subscriptionId }) => {
    const response = await http.get(
      `/bff-ussp/billing/subscriptions/${subscriptionId}/invoices/${invoiceId}`,
      { responseType: 'blob' },
    );
    return response;
  },
);

export const getAccountsData = createAsyncThunk<any, IAccountsDataRequestPayload>(
  'ussp/getAccountsData',
  async ({ page, pageSize, _disableErrorHandler = false }) => {
    const query = querystring.stringify({ page, pageSize });
    const response = await http.get(`/bff-ussp/accountOverview?${query}`, { _disableErrorHandler });
    return response;
  },
);

export const getAccountApplicationsData = createAsyncThunk<any, IAccountDetailsRequestPayload>(
  'ussp/getAccountApplicationsData',
  async ({
    subscriptionId,
    page,
    pageSize,
    filter,
    filterBy = 'Name',
    orderings,
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/applications`,
      {
        page,
        pageSize,
        orderings,
        filter,
        filterBy,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAccountUsersData = createAsyncThunk<any, IAccountDetailsRequestPayload>(
  'ussp/getAccountUsersData',
  async ({
    subscriptionId,
    page,
    pageSize,
    filter,
    filterBy = 'Username',
    orderings,
    applicationIds,
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/users`,
      {
        page,
        pageSize,
        orderings,
        filter,
        filterBy,
        applicationIds,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAccountUsersMfaData = createAsyncThunk<any, IAccountDetailsRequestPayload>(
  'ussp/getAccountUsersMfaData',
  async ({
    subscriptionId,
    page,
    pageSize,
    filter,
    filterBy = 'Username',
    orderings,
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/users/mfa`,
      {
        page,
        pageSize,
        orderings,
        filter,
        filterBy,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const addUserPhoneNumber = createAsyncThunk<any, IAddUserPhoneNumberRequestPayload>(
  'ussp/addUserPhoneNumber',
  async ({
    subscriptionId,
    userId,
    phoneNumber,
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/users/${userId}/phoneNumber`,
      {
        phoneNumber,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const canUserMfaBeRemoved = createAsyncThunk<any, IUserMfaRequestPayload>(
  'ussp/canUserMfaBeRemoved',
  async ({ subscriptionId, userIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/users/canMfaBeRemoved`,
      {
        userIds,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const addUserMfa = createAsyncThunk<any, IUserMfaRequestPayload>(
  'ussp/addUserMfa',
  async ({ subscriptionId, userIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/users/addMfa`,
      {
        userIds,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeUserMfa = createAsyncThunk<any, IUserMfaRequestPayload>(
  'ussp/removeUserMfa',
  async ({ subscriptionId, userIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/users/removeMfa`,
      {
        userIds,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAccountApplicationInfosData = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getAccountApplicationInfosData',
  async ({ subscriptionId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/accountOverview/${subscriptionId}/applicationInfos`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getBillingStatusesData = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/getBillingStatusesData',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.get(
      '/bff-ussp/accountOverview/statuses',
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAccountAppAssigningUsersData = createAsyncThunk<any, IAccountAppAssigningUsersRequestPayload>(
  'ussp/getAccountAppAssigningUsersData',
  async ({ subscriptionId, productId, isAdminPortal, _disableErrorHandler = false }) => {
    const response = await http.get(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/applications/${productId}/assigningUsers`
        : `/bff-ussp/accountOverview/${subscriptionId}/assigningUsers/${productId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAddAppAssignUsersData = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getAddAppAssignUsersData',
  async ({ subscriptionId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/accountOverview/${subscriptionId}/assigningUsers`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminAddAppAssignUsersData = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getAdminAddAppAssignUsersData',
  async ({ subscriptionId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/admin/subscription/${subscriptionId}/assigningUsers`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getUsspCartData = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/getUsspCartData',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.get('/bff-ussp/cart', { _disableErrorHandler });
    return response;
  },
);

export const getSubscriptionContractInfo = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getSubscriptionContractInfo',
  async ({ subscriptionId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/accountOverview/${subscriptionId}/contractInfo`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const completeUsspCart = createAsyncThunk<any, ICompleteCartRequestPayload>(
  'ussp/completeUsspCart',
  async ({ eSign, _disableErrorHandler = false }) => {
    const response = await http.post('/bff-ussp/cart/complete', { eSign }, { _disableErrorHandler });
    return response;
  },
);

export const clearUsspCart = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/clearUsspCart',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.post('/bff-ussp/cart/clear', null, { _disableErrorHandler });
    return response;
  },
);

export const addAssignedUsersToCart = createAsyncThunk<any, IAddAssignedUsersToCartRequestPayload>(
  'ussp/addAssignedUsersToCart',
  async ({ subscriptionId, productId, userIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      '/bff-ussp/cart/assignUsers',
      { subscriptionId, productId, userIds },
      { _disableErrorHandler },
    );
    return response;
  },
);
export const adminAssignUsersToApp = createAsyncThunk<any, IAddAdminAssignedUsersToAppRequestPayload>(
  'ussp/adminAssignUsersToApp',
  async ({ subscriptionId, applicationId, userIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/applications/${applicationId}/assignUsers`,
      { userIds },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const sendQuickbooksPurchase = createAsyncThunk<any, ISendQuickbooksPurchase>(
  'ussp/quickbooksPurchaseRequest',
  async ({ subscriptionId, message, _disableErrorHandler = false }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/quickbooksPurchaseRequest`,
      { message },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeAssignedUserFromCart = createAsyncThunk<any, IRemoveAssignFromCartRequestPayload>(
  'ussp/removeAssignedUserFromCart',
  async ({ productId, userId, _disableErrorHandler = false }) => {
    const response = await http.delete(
      `/bff-ussp/cart/assignUsers/${productId}/${userId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeAssignedUsersFromCart = createAsyncThunk<any, IRemoveAssignFromCartRequestPayload>(
  'ussp/removeAssignedUsersFromCart',
  async ({ productId, _disableErrorHandler = false }) => {
    const response = await http.delete(
      `/bff-ussp/cart/assignUsers/${productId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeApplicationFromCart = createAsyncThunk<any, IRemoveApplicationFromCartRequestPayload>(
  'ussp/removeApplicationFromCart',
  async ({ subscriptionId, componentId, _disableErrorHandler = false }) => {
    const response = await http.delete(
      `/bff-ussp/cart/${subscriptionId}/applications/${componentId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeApplicationUserFromCart = createAsyncThunk<any, IRemoveApplicationFromCartRequestPayload>(
  'ussp/removeApplicationUserFromCart',
  async ({ subscriptionId, componentId, userId, _disableErrorHandler = false }) => {
    const response = await http.delete(
      `/bff-ussp/cart/${subscriptionId}/applications/${componentId}/users/${userId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeApplication = createAsyncThunk<any, IAccountAppRemoveRequestPayload>(
  'ussp/removeApplication',
  async ({ subscriptionId, applicationId, _disableErrorHandler = false }) => {
    const response = await http.delete(
      `/bff-ussp/accountOverview/${subscriptionId}/application/${applicationId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeApplicationFromUser = createAsyncThunk<any, IRemoveApplicationFromUserRequestPayload>(
  'ussp/removeApplicationFromUser',
  async ({ subscriptionId, userId, isAdminPortal, applicationIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/users/${userId}/unassignProducts`
        : `/bff-ussp/accountOverview/${subscriptionId}/users/${userId}/applications/remove`,
      isAdminPortal ? applicationIds : { applicationIds },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeUserFromApplication = createAsyncThunk<any, IRemoveUsersFromApplicationRequestPayload>(
  'ussp/removeUserFromApplication',
  async ({ subscriptionId, applicationId, isAdminPortal, userIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/applications/${applicationId}/unassignUsers`
        : `/bff-ussp/admin/subscription/${subscriptionId}/applications/${applicationId}/unassignUsers`, //change on ussp
      userIds,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const updateDomainName = createAsyncThunk<any, IUpdateDomainNameRequestPayload>(
  'ussp/updateDomainName',
  async ({ subscriptionId, domainName, _disableErrorHandler = false }) => {
    const response = await http.put(
      `/bff-ussp/accountOverview/${subscriptionId}/domainName`,
      { domainName },
      { _disableErrorHandler },
    );
    return response;
  },
);
export const canAppBeUpgraded = createAsyncThunk<any, ICanAppBeUpgradedRequestPayload>(
  'ussp/canAppBeUpgraded',
  async ({ subscriptionId, productId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/accountOverview/${subscriptionId}/applications/${productId}/canBeUpgraded`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getProductInfos = createAsyncThunk<any, IGetCatalogDataRequestPayload>(
  'ussp/getProductInfos',
  async ({ componentId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/catalog/productInfos/${componentId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getProductUpgradePrice = createAsyncThunk<any, IGetCatalogDataRequestPayload>(
  'ussp/getProductUpgradePrice',
  async ({ componentId, subscriptionId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/catalog/${subscriptionId}/components/${componentId}/upgrade/price`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getProductNewestVersion = createAsyncThunk<any, IGetCatalogDataRequestPayload>(
  'ussp/getProductNewestVersion',
  async ({ componentId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/catalog/${componentId}/newestVersion`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const addSageUpgradeToCart = createAsyncThunk<any, IAddSageUpgradeToCartRequestPayload>(
  'ussp/addSageUpgradeToCart',
  async ({ subscriptionId, productId, serialNumber, _disableErrorHandler = false }) => {
    const response = await http.post(
      '/bff-ussp/cart/applications/sage/upgrade',
      { subscriptionId, productId, serialNumber },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const addQuickbooksUpgradeToCart = createAsyncThunk<any, IAddQuickbooksUpgradeToCartRequestPayload>(
  'ussp/addQuickbooksUpgradeToCart',
  async ({ subscriptionId, productId, productCode, licenseCode, _disableErrorHandler = false }) => {
    const response = await http.post(
      '/bff-ussp/cart/applications/quickbooks/upgrade',
      { subscriptionId, productId, productCode, licenseCode },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeUpgradeProductFromCart = createAsyncThunk<any, IRemoveUpgradeProductFromCartRequestPayload>(
  'ussp/removeUpgradeProductFromCart',
  async ({ subscriptionId, productId, _disableErrorHandler = false }) => {
    const response = await http.delete(
      `/bff-ussp/cart/${subscriptionId}/applications/${productId}/upgrade`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAllAssigningApplications = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getAllAssigningApplications',
  async ({ subscriptionId, isAdminPortal, _disableErrorHandler = false }) => {
    const response = await http.get(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/assigningApplication`
        : `/bff-ussp/accountOverview/${subscriptionId}/assigningApplication`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const checkIsAllowedUserEmail = createAsyncThunk<any, ICheckIsAllowedUserEmailRequestPayload>(
  'ussp/checkIsAllowedUserEmail',
  async ({ subscriptionId, email, isAdminPortal, _disableErrorHandler = false }) => {
    const response = await http.post(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/email`
        : `/bff-ussp/accountOverview/${subscriptionId}/email`,
      { email },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const addNewUserToCart = createAsyncThunk<any, IAddNewUserToCartRequestPayload>(
  'ussp/addNewUserToCart',
  async ({
    subscriptionId,
    formData,
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/user`,
      formData,
      {
        _disableErrorHandler,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },
);

export const removeNewUserFromCart = createAsyncThunk<any, IRemoveNewUserFromCartRequestPayload>(
  'ussp/removeNewUserFromCart',
  async ({ email, _disableErrorHandler = false }) => {
    const response = await http.delete(`/bff-ussp/cart/newUser/${email}`, { _disableErrorHandler });
    return response;
  },
);

export const removeNewUserAssignAppFromCart = createAsyncThunk<any, IRemoveNewUserAssignAppFromCartRequestPayload>(
  'ussp/removeNewUserAssignAppFromCart',
  async ({ subscriptionId, productId, email, _disableErrorHandler = false }) => {
    const response = await http.put(
      '/bff-ussp/cart/removeAssignProductFromNewUser',
      { subscriptionId, productId, email },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const editUser = createAsyncThunk<any, IEditUserRequestPayload>(
  'ussp/editUser',
  async ({
    subscriptionId,
    formData,
    isAdminPortal,
    _disableErrorHandler = false,
  }) => {
    const response = await http.put(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/user`
        : `/bff-ussp/accountOverview/${subscriptionId}/user`,
      formData,
      { _disableErrorHandler,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },
);

export const isUserLastAdmin = createAsyncThunk<any, IIsUserLastAdminRequestPayload>(
  'ussp/isUserLastAdmin',
  async ({
    subscriptionId,
    userId,
    isAdminPortal,
    _disableErrorHandler = false,
  }) => {
    const response = await http.get(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/userIsLastAdmin/${userId}`
        : `/bff-ussp/accountOverview/${subscriptionId}/userIsLastAdmin/${userId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeUserAvatar = createAsyncThunk<any, IUserRequestWithSubscription>(
  'ussp/removeUserAvatar',
  async ({
    subscriptionId,
    userId,
    _disableErrorHandler = false,
  }) => {
    const response = await http.delete(
      `/bff-ussp/accountOverview/${subscriptionId}/user/${userId}/avatar`,
      { _disableErrorHandler,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },
);

export const removeEditUserFromCart = createAsyncThunk<any, IUserRequestWithSubscription>(
  'ussp/removeEditUserFromCart',
  async ({ subscriptionId, userId, _disableErrorHandler = false }) => {
    const response = await http.put(
      '/bff-ussp/cart/removeEditUser',
      { subscriptionId, userId },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeEditUsersFromCart = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/removeEditUsersFromCart',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.put(
      '/bff-ussp/cart/removeEditUsers',
      null,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getUserAvailableAssigningApps = createAsyncThunk<any, IUserRequestWithSubscription>(
  'ussp/getUserAvailableAssigningApps',
  async ({ subscriptionId, userId, isAdminPortal, _disableErrorHandler = false }) => {
    const response = await http.get(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/users/${userId}/assigningProducts`
        : `/bff-ussp/accountOverview/${subscriptionId}/assigningApplication/${userId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeUser = createAsyncThunk<any, IUserRequestWithSubscription>(
  'ussp/removeUser',
  async ({ subscriptionId, userId, isAdminPortal, _disableErrorHandler = false }) => {
    const response = await http.delete(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/user/${userId}`
        : `/bff-ussp/accountOverview/${subscriptionId}/users/${userId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const assignApplicationToUser = createAsyncThunk<any, IassignApplicationToUser>(
  'ussp/assignApplicationToUser',
  async ({ subscriptionId, userId, isAdminPortal, productIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/users/${userId}/assignProducts`
        : `/bff-ussp/cart/${subscriptionId}/users/${userId}/assignedApplications`,
      isAdminPortal ? productIds : { productIds },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeUserAssignedAppsFromCart = createAsyncThunk<any, IUserRequestWithSubscription>(
  'ussp/removeUserAssignedAppsFromCart',
  async ({ subscriptionId, userId, _disableErrorHandler = false }) => {
    const response = await http.delete(
      `/bff-ussp/cart/${subscriptionId}/users/${userId}/assignedApplications`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeUserAssignedAppFromCart = createAsyncThunk<any, IRemoveUserAssignedAppFromCartRequestPayload>(
  'ussp/removeUserAssignedAppFromCart',
  async ({ subscriptionId, userId, productId, _disableErrorHandler = false }) => {
    const response = await http.delete(
      `/bff-ussp/cart/${subscriptionId}/users/${userId}/assignedApplications/${productId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAccountOverviewUser = createAsyncThunk<any, IUserRequestWithSubscription>(
  'ussp/getAccountOverviewUser',
  async ({ subscriptionId, userId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/accountOverview/${subscriptionId}/user/${userId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAccountOverviewUserApp = createAsyncThunk<any, IUserApplicationsRequestPayload>(
  'ussp/getAccountOverviewUserApp',
  async ({
    userId,
    subscriptionId,
    page,
    pageSize,
    orderings,
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/accountOverview/${subscriptionId}/user/${userId}/applications`,
      {
        userId,
        subscriptionId,
        page,
        pageSize,
        orderings,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getFilterProducts = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getFilterProducts',
  async ({ subscriptionId, isAdminPortal, _disableErrorHandler = false }) => {
    const response = await http.get(
      isAdminPortal
        ? `/bff-ussp/admin/subscription/${subscriptionId}/filterProducts`
        : `/bff-ussp/accountOverview/${subscriptionId}/filterProducts`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminUser = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/getAdminUser',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.get('/bff-ussp/admin/info', { _disableErrorHandler });
    return response;
  },
);

export const getAdminSubscriptions = createAsyncThunk<any, IAdminSubscriptionsRequestPayload>(
  'ussp/getAdminSubscriptions',
  async ({
    page,
    pageSize,
    filter,
    filterBy = 'Subscriber',
    orderings,
    statuses,
    terms,
    manageables,
    country,
    _disableErrorHandler = false,
  }) => {
    const getFilterByComposit = () => {
      if (statuses?.length) return 'Status';
      if (terms?.length) return 'SubTerms';
      if (manageables?.length) return 'Manageable';
      return undefined;
    };

    const compositFilteringInfo = {
      values: [
        ...(statuses || []),
        ...(terms || []),
        ...(manageables || []),
      ],
      filterBy: getFilterByComposit(),
    };

    const response = await http.post(
      '/bff-ussp/admin/subscription',
      {
        page,
        pageSize,
        orderings,
        filter,
        filterBy,
        compositFilteringInfo: compositFilteringInfo.filterBy
          ? compositFilteringInfo
          : undefined,
        countryFiltering: country && country !== 'All' ? COUNTRY_NUMBER_BY_CODE[country] : undefined,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminSubscriptionInfo = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getAdminSubscriptionInfo',
  async ({ subscriptionId, _disableErrorHandler = false }) => {
    const response = await http.get(
      `/bff-ussp/admin/subscription/${subscriptionId}/info`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminSubscriptionStatuses = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/getAdminSubscriptionStatuses',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.get(
      '/bff-ussp/admin/subscription/statuses',
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminSubscriptionTerms = createAsyncThunk<any, IRequestOptions | undefined>(
  'ussp/getAdminSubscriptionTerms',
  async ({ _disableErrorHandler = false } = {}) => {
    const response = await http.get(
      '/bff-ussp/admin/subscription/terms',
      { _disableErrorHandler },
    );
    return response;
  },
);

export const updateAdminSubscriptionTerms = createAsyncThunk<any, IAdminSubscriptionTermsRequestPayload>(
  'ussp/updateAdminSubscriptionTerms',
  async ({ subscriptionId, termsId, _disableErrorHandler = false }) => {
    const response = await http.put(
      `/bff-ussp/admin/subscription/${subscriptionId}/terms/${termsId}`,
      null,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminSubscriptionById = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getAdminSubscriptionById',
  async ({
    subscriptionId,
    _disableErrorHandler = false,
  }) => {
    const response = await http.get(
      `/bff-ussp/admin/subscription/${subscriptionId}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminSubscriptionApplications = createAsyncThunk<any, IAdminSubscriptionApplicationsRequestPayload>(
  'ussp/getAdminSubscriptionApplications',
  async ({
    subscriptionId,
    page,
    pageSize,
    filter,
    filterBy = 'ApplicationTitle',
    orderings,
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/applications`,
      {
        page,
        pageSize,
        orderings,
        filter,
        filterBy,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeAdminSubscriptionApps = createAsyncThunk<any, IRemoveAdminSubscriptionAppsRequestPayload>(
  'ussp/removeAdminSubscriptionApps',
  async ({ subscriptionId, applicationIds, _disableErrorHandler = false }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/removeApplications`,
      { ids: applicationIds },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminSubscriptionUsers = createAsyncThunk<any, IAdminSubscriptionUsersRequestPayload>(
  'ussp/getAdminSubscriptionUsers',
  async ({
    subscriptionId,
    page,
    pageSize,
    filter,
    filterBy = 'Username',
    orderings,
    applicationIds = [],
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/users`,
      {
        page,
        pageSize,
        orderings,
        filter,
        filterBy,
        filteringByApplicationIds: applicationIds,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);
export const getAdminSubscriptionCustomFields = createAsyncThunk<any, IAdminSubscriptionCustomFieldsRequestPayload>(
  'ussp/getAdminSubscriptionCustomFields',
  async ({
    subscriptionId,
    page,
    pageSize,
    filter,
    orderings,
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/metadata/items`,
      {
        page,
        pageSize,
        orderings,
        filter,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const addAdminCustomField = createAsyncThunk<any, IAdminCustomFieldPayload>(
  'ussp/addAdminCustomField',
  async ({ subscriptionId, name, value, _disableErrorHandler }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/metadata`,
      { name, value },
      { _disableErrorHandler },
    );
    return response;
  },
);
export const editAdminCustomField = createAsyncThunk<any, IAdminCustomFieldPayload>(
  'ussp/editAdminCustomField',
  async ({ id, name, value, subscriptionId, _disableErrorHandler }) => {
    const response = await http.put(
      `/bff-ussp/admin/subscription/${subscriptionId}/metadata/${id}`,
      { value },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeCustomField = createAsyncThunk<any, IAdminRemoveCustomFieldPayload>(
  'ussp/removeAdminCoupon',
  async ({ id, subscriptionId, _disableErrorHandler }) => {
    const response = await http.delete(
      `/bff-ussp/admin/subscription/${subscriptionId}/metadata/${id}`,
      { _disableErrorHandler },
    );
    return response;
  },
);


export const adminAddUser = createAsyncThunk<any, IAddNewUserToCartRequestPayload>(
  'ussp/adminAddUser',
  async ({ subscriptionId, formData, _disableErrorHandler = false }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/user`,
      formData,
      {
        _disableErrorHandler,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },
);

export const getAdminSubscriptionInvoices = createAsyncThunk<any, IAdminSubscriptionInvoicesRequestPayload>(
  'ussp/getAdminSubscriptionInvoices',
  async ({
    subscriptionId,
    page,
    pageSize,
    orderings = [],
    _disableErrorHandler = false,
  }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/invoices`,
      {
        page,
        pageSize,
        ordering: orderings[0],
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const getAdminSubscriptionInvoiceFile = createAsyncThunk<any, IInvoiceFileRequestPayload>(
  'ussp/getAdminSubscriptionInvoiceFile',
  async ({ invoiceId, subscriptionId }) => {
    const response = await http.get(
      `/bff-ussp/admin/subscription/${subscriptionId}/invoices/${invoiceId}`,
      { responseType: 'blob' },
    );
    return response;
  },
);

export const getAdminAvailableCoupons = createAsyncThunk<any, IRequestWithSubscription>(
  'ussp/getAdminAvailableCoupons',
  async ({ subscriptionId, _disableErrorHandler }) => {
    const response = await http.get(
      `/bff-ussp/admin/subscription/${subscriptionId}/coupons/available`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const addAdminCoupon = createAsyncThunk<any, IAdminCouponRequestPayload>(
  'ussp/addAdminCoupon',
  async ({ subscriptionId, code, _disableErrorHandler }) => {
    const response = await http.post(
      `/bff-ussp/admin/subscription/${subscriptionId}/coupons`,
      { code },
      { _disableErrorHandler },
    );
    return response;
  },
);

export const removeAdminCoupon = createAsyncThunk<any, IAdminCouponRequestPayload>(
  'ussp/removeAdminCoupon',
  async ({ subscriptionId, code, _disableErrorHandler }) => {
    const response = await http.delete(
      `/bff-ussp/admin/subscription/${subscriptionId}/coupons/${code}`,
      { _disableErrorHandler },
    );
    return response;
  },
);

export const editApplicationInfo = createAsyncThunk<any, IEditApplicationRequestPayload>(
  'ussp/editApplicationInfo',
  async ({
    subscriptionId,
    id,
    name,
    year,
    isAdminPortal,
    productCode,
    serialNumber,
    licenceCode,
    _disableErrorHandler = false,
  }) => {
    const response = await http.put(
      `/bff-ussp/admin/subscription/${subscriptionId}/applications/${id}`,
      {
        name,
        year,
        productCode,
        serialNumber,
        licenceCode,
      },
      { _disableErrorHandler },
    );
    return response;
  },
);

interface UsspState {
  getDashboardDataRequest: IHttpRequestResult<any>;
  getDashboardUserRequest: IHttpRequestResult<any>;
  getBillingsDataRequest: IHttpRequestResult<any>;
  getBillingDetailsDataRequest: IHttpRequestResult<any>;
  getBillingSlaRequest: IHttpRequestResult<any>;
  getBillingInvoicesDataRequest: IHttpRequestResult<any>;
  getBillingInvoicesMetafieldsRequest: IHttpRequestResult<any>;
  getBillingInvoiceFileRequest: IHttpRequestResult<any>;
  getAccountsDataRequest: IHttpRequestResult<any>;
  getAccountApplicationsDataRequest: IHttpRequestResult<any>;
  getAccountUsersDataRequest: IHttpRequestResult<any>;
  getAccountUsersMfaDataRequest: IHttpRequestResult<any>;
  addUserPhoneNumberRequest: IHttpRequestResult<any>;
  addUserMfaRequest: IHttpRequestResult<any>;
  canUserMfaBeRemovedRequest: IHttpRequestResult<any>;
  removeUserMfaRequest: IHttpRequestResult<any>;
  getAccountApplicationInfosDataRequest: IHttpRequestResult<any>;
  getBillingStatusesDataRequest: IHttpRequestResult<any>;
  getAccountAppAssigningUsersDataRequest: IHttpRequestResult<any>;
  getAddAppAssignUsersDataRequest: IHttpRequestResult<any>;
  getAdminAddAppAssignUsersDataRequest: IHttpRequestResult<any>;
  getUsspCartDataRequest: IHttpRequestResult<any>;
  getSubscriptionContractInfoRequest: IHttpRequestResult<any>;
  completeUsspCartRequest: IHttpRequestResult<any>;
  clearUsspCartRequest: IHttpRequestResult<any>;
  addAssignedUsersToCartRequest: IHttpRequestResult<any>;
  adminAssignUsersToAppRequest: IHttpRequestResult<any>;
  sendQuickbooksPurchaseRequest: IHttpRequestResult<any>;
  removeAssignedUserFromCartRequest: IHttpRequestResult<any>;
  removeAssignedUsersFromCartRequest: IHttpRequestResult<any>;
  removeApplicationFromCartRequest: IHttpRequestResult<any>;
  removeApplicationUserFromCartRequest: IHttpRequestResult<any>;
  removeApplicationRequest: IHttpRequestResult<any>;
  removeApplicationFromUserRequest: IHttpRequestResult<any>;
  removeUserFromApplicationRequest: IHttpRequestResult<any>;
  updateDomainNameRequest: IHttpRequestResult<any>;
  canAppBeUpgradedRequest: IHttpRequestResult<any>;
  getProductInfosRequest: IHttpRequestResult<any>;
  getProductUpgradePriceRequest: IHttpRequestResult<any>;
  getProductNewestVersionRequest: IHttpRequestResult<any>;
  addSageUpgradeToCartRequest: IHttpRequestResult<any>;
  addQuickbooksUpgradeToCartRequest: IHttpRequestResult<any>;
  removeUpgradeProductFromCartRequest: IHttpRequestResult<any>;
  getAllAssigningApplicationsRequest: IHttpRequestResult<any>;
  getUserAvailableAssigningAppsRequest: IHttpRequestResult<any>;
  assignApplicationToUserRequest: IHttpRequestResult<any>;
  checkIsAllowedUserEmailRequest: IHttpRequestResult<any>;
  addNewUserToCartRequest: IHttpRequestResult<any>;
  removeNewUserFromCartRequest: IHttpRequestResult<any>;
  removeNewUserAssignAppFromCartRequest: IHttpRequestResult<any>;
  editUserRequest: IHttpRequestResult<any>;
  isUserLastAdminRequest: IHttpRequestResult<any>;
  removeUserAvatarRequest: IHttpRequestResult<any>;
  removeEditUserFromCartRequest: IHttpRequestResult<any>;
  removeEditUsersFromCartRequest: IHttpRequestResult<any>;
  removeUserRequest: IHttpRequestResult<any>;
  removeUserAssignedAppsFromCartRequest: IHttpRequestResult<any>;
  removeUserAssignedAppFromCartRequest: IHttpRequestResult<any>;
  getAccountOverviewUserRequest: IHttpRequestResult<any>;
  getAccountOverviewUserAppRequest: IHttpRequestResult<any>;
  getFilterProductsRequest: IHttpRequestResult<any>;
  getAdminUserRequest: IHttpRequestResult<any>;
  getAdminSubscriptionsRequest: IHttpRequestResult<any>;
  getAdminSubscriptionInfoRequest: IHttpRequestResult<any>;
  getAdminSubscriptionStatusesRequest: IHttpRequestResult<any>;
  getAdminSubscriptionTermsRequest: IHttpRequestResult<any>;
  updateAdminSubscriptionTermsRequest: IHttpRequestResult<any>;
  getAdminSubscriptionByIdRequest: IHttpRequestResult<any>;
  getAdminSubscriptionApplicationsRequest: IHttpRequestResult<any>;
  removeAdminSubscriptionAppsRequest: IHttpRequestResult<any>;
  getAdminSubscriptionUsersRequest: IHttpRequestResult<any>;
  getAdminSubscriptionCustomFieldsRequest: IHttpRequestResult<any>;
  addAdminCustomFieldRequest: IHttpRequestResult<any>;
  editAdminCustomFieldRequest: IHttpRequestResult<any>;
  removeCustomFieldRequest: IHttpRequestResult<any>;
  adminAddUserRequest: IHttpRequestResult<any>;
  editApplicationInfoRequest: IHttpRequestResult<any>;
  getAdminSubscriptionInvoicesRequest: IHttpRequestResult<any>;
  getAdminSubscriptionInvoiceFileRequest: IHttpRequestResult<any>;
  getAdminAvailableCouponsRequest: IHttpRequestResult<any>;
  addAdminCouponRequest: IHttpRequestResult<any>;
  removeAdminCouponRequest: IHttpRequestResult<any>;
  user: IDashboardUser | null;
}

const initialState: UsspState = {
  getDashboardDataRequest: createHttpRequestInitResult(),
  getDashboardUserRequest: createHttpRequestInitResult(),
  getBillingsDataRequest: createHttpRequestInitResult(),
  getBillingDetailsDataRequest: createHttpRequestInitResult(),
  getBillingSlaRequest: createHttpRequestInitResult(),
  getBillingInvoicesDataRequest: createHttpRequestInitResult(),
  getBillingInvoicesMetafieldsRequest: createHttpRequestInitResult(),
  getBillingInvoiceFileRequest: createHttpRequestInitResult(),
  getAccountsDataRequest: createHttpRequestInitResult(),
  getAccountApplicationsDataRequest: createHttpRequestInitResult(),
  getAccountUsersDataRequest: createHttpRequestInitResult(),
  getAccountUsersMfaDataRequest: createHttpRequestInitResult(),
  addUserPhoneNumberRequest: createHttpRequestInitResult(),
  addUserMfaRequest: createHttpRequestInitResult(),
  canUserMfaBeRemovedRequest: createHttpRequestInitResult(),
  removeUserMfaRequest: createHttpRequestInitResult(),
  getAccountApplicationInfosDataRequest: createHttpRequestInitResult(),
  getBillingStatusesDataRequest: createHttpRequestInitResult(),
  getAccountAppAssigningUsersDataRequest: createHttpRequestInitResult(),
  getAddAppAssignUsersDataRequest: createHttpRequestInitResult(),
  getAdminAddAppAssignUsersDataRequest: createHttpRequestInitResult(),
  getUsspCartDataRequest: createHttpRequestInitResult(),
  getSubscriptionContractInfoRequest: createHttpRequestInitResult(),
  completeUsspCartRequest: createHttpRequestInitResult(),
  clearUsspCartRequest: createHttpRequestInitResult(),
  addAssignedUsersToCartRequest: createHttpRequestInitResult(),
  adminAssignUsersToAppRequest: createHttpRequestInitResult(),
  sendQuickbooksPurchaseRequest: createHttpRequestInitResult(),
  removeAssignedUserFromCartRequest: createHttpRequestInitResult(),
  removeAssignedUsersFromCartRequest: createHttpRequestInitResult(),
  removeApplicationFromCartRequest: createHttpRequestInitResult(),
  removeApplicationUserFromCartRequest: createHttpRequestInitResult(),
  removeApplicationRequest: createHttpRequestInitResult(),
  removeApplicationFromUserRequest: createHttpRequestInitResult(),
  removeUserFromApplicationRequest: createHttpRequestInitResult(),
  updateDomainNameRequest: createHttpRequestInitResult(),
  canAppBeUpgradedRequest: createHttpRequestInitResult(),
  getProductInfosRequest: createHttpRequestInitResult(),
  getProductUpgradePriceRequest: createHttpRequestInitResult(),
  getProductNewestVersionRequest: createHttpRequestInitResult(),
  addSageUpgradeToCartRequest: createHttpRequestInitResult(),
  addQuickbooksUpgradeToCartRequest: createHttpRequestInitResult(),
  removeUpgradeProductFromCartRequest: createHttpRequestInitResult(),
  getAllAssigningApplicationsRequest: createHttpRequestInitResult(),
  getUserAvailableAssigningAppsRequest: createHttpRequestInitResult(),
  assignApplicationToUserRequest: createHttpRequestInitResult(),
  checkIsAllowedUserEmailRequest: createHttpRequestInitResult(),
  addNewUserToCartRequest: createHttpRequestInitResult(),
  removeNewUserFromCartRequest: createHttpRequestInitResult(),
  removeNewUserAssignAppFromCartRequest: createHttpRequestInitResult(),
  editUserRequest: createHttpRequestInitResult(),
  isUserLastAdminRequest: createHttpRequestInitResult(),
  removeUserAvatarRequest: createHttpRequestInitResult(),
  removeEditUserFromCartRequest: createHttpRequestInitResult(),
  removeEditUsersFromCartRequest: createHttpRequestInitResult(),
  removeUserRequest: createHttpRequestInitResult(),
  removeUserAssignedAppsFromCartRequest: createHttpRequestInitResult(),
  removeUserAssignedAppFromCartRequest: createHttpRequestInitResult(),
  getAccountOverviewUserRequest: createHttpRequestInitResult(),
  getAccountOverviewUserAppRequest: createHttpRequestInitResult(),
  getFilterProductsRequest: createHttpRequestInitResult(),
  getAdminUserRequest: createHttpRequestInitResult(),
  getAdminSubscriptionsRequest: createHttpRequestInitResult(),
  getAdminSubscriptionInfoRequest: createHttpRequestInitResult(),
  getAdminSubscriptionStatusesRequest: createHttpRequestInitResult(),
  getAdminSubscriptionTermsRequest: createHttpRequestInitResult(),
  updateAdminSubscriptionTermsRequest: createHttpRequestInitResult(),
  getAdminSubscriptionByIdRequest: createHttpRequestInitResult(),
  getAdminSubscriptionApplicationsRequest: createHttpRequestInitResult(),
  removeAdminSubscriptionAppsRequest: createHttpRequestInitResult(),
  getAdminSubscriptionUsersRequest: createHttpRequestInitResult(),
  getAdminSubscriptionCustomFieldsRequest: createHttpRequestInitResult(),
  addAdminCustomFieldRequest: createHttpRequestInitResult(),
  editAdminCustomFieldRequest: createHttpRequestInitResult(),
  removeCustomFieldRequest: createHttpRequestInitResult(),
  adminAddUserRequest: createHttpRequestInitResult(),
  editApplicationInfoRequest: createHttpRequestInitResult(),
  getAdminSubscriptionInvoicesRequest: createHttpRequestInitResult(),
  getAdminSubscriptionInvoiceFileRequest: createHttpRequestInitResult(),
  getAdminAvailableCouponsRequest: createHttpRequestInitResult(),
  addAdminCouponRequest: createHttpRequestInitResult(),
  removeAdminCouponRequest: createHttpRequestInitResult(),
  user: null,
};

export const usspSlice = createSlice({
  name: 'ussp',
  initialState,
  reducers: {
    setDashboardUser(state, action: PayloadAction<IDashboardUser | null>) {
      state.user = action.payload === null ? null : { ...action.payload };
    },
  },
  extraReducers: {
    ...createExtraReducersForResponses(getDashboardData, 'getDashboardDataRequest'),
    ...createExtraReducersForResponses(getDashboardUser, 'getDashboardUserRequest'),
    ...createExtraReducersForResponses(getBillingsData, 'getBillingsDataRequest'),
    ...createExtraReducersForResponses(getBillingDetailsData, 'getBillingDetailsDataRequest'),
    ...createExtraReducersForResponses(getBillingSla, 'getBillingSlaRequest'),
    ...createExtraReducersForResponses(getBillingInvoicesData, 'getBillingInvoicesDataRequest'),
    ...createExtraReducersForResponses(getBillingInvoicesMetafields, 'getBillingInvoicesMetafieldsRequest'),
    ...createExtraReducersForResponses(getBillingInvoiceFile, 'getBillingInvoiceFileRequest'),
    ...createExtraReducersForResponses(getAccountsData, 'getAccountsDataRequest'),
    ...createExtraReducersForResponses(getAccountApplicationsData, 'getAccountApplicationsDataRequest'),
    ...createExtraReducersForResponses(getAccountUsersData, 'getAccountUsersDataRequest'),
    ...createExtraReducersForResponses(getAccountUsersMfaData, 'getAccountUsersMfaDataRequest'),
    ...createExtraReducersForResponses(addUserPhoneNumber, 'addUserPhoneNumberRequest'),
    ...createExtraReducersForResponses(addUserMfa, 'addUserMfaRequest'),
    ...createExtraReducersForResponses(canUserMfaBeRemoved, 'canUserMfaBeRemovedRequest'),
    ...createExtraReducersForResponses(removeUserMfa, 'removeUserMfaRequest'),
    ...createExtraReducersForResponses(getAccountApplicationInfosData, 'getAccountApplicationInfosDataRequest'),
    ...createExtraReducersForResponses(getBillingStatusesData, 'getBillingStatusesDataRequest'),
    ...createExtraReducersForResponses(getAccountAppAssigningUsersData, 'getAccountAppAssigningUsersDataRequest'),
    ...createExtraReducersForResponses(getAddAppAssignUsersData, 'getAddAppAssignUsersDataRequest'),
    ...createExtraReducersForResponses(getAdminAddAppAssignUsersData, 'getAdminAddAppAssignUsersDataRequest'),
    ...createExtraReducersForResponses(getUsspCartData, 'getUsspCartDataRequest'),
    ...createExtraReducersForResponses(getSubscriptionContractInfo, 'getSubscriptionContractInfoRequest'),
    ...createExtraReducersForResponses(completeUsspCart, 'completeUsspCartRequest'),
    ...createExtraReducersForResponses(clearUsspCart, 'clearUsspCartRequest'),
    ...createExtraReducersForResponses(addAssignedUsersToCart, 'addAssignedUsersToCartRequest'),
    ...createExtraReducersForResponses(adminAssignUsersToApp, 'adminAssignUsersToAppRequest'),
    ...createExtraReducersForResponses(sendQuickbooksPurchase, 'sendQuickbooksPurchaseRequest'),
    ...createExtraReducersForResponses(removeAssignedUserFromCart, 'removeAssignedUserFromCartRequest'),
    ...createExtraReducersForResponses(removeAssignedUsersFromCart, 'removeAssignedUsersFromCartRequest'),
    ...createExtraReducersForResponses(removeApplicationFromCart, 'removeApplicationFromCartRequest'),
    ...createExtraReducersForResponses(removeApplicationUserFromCart, 'removeApplicationUserFromCartRequest'),
    ...createExtraReducersForResponses(removeApplication, 'removeApplicationRequest'),
    ...createExtraReducersForResponses(removeApplicationFromUser, 'removeApplicationFromUserRequest'),
    ...createExtraReducersForResponses(removeUserFromApplication, 'removeUserFromApplicationRequest'),
    ...createExtraReducersForResponses(updateDomainName, 'updateDomainNameRequest'),
    ...createExtraReducersForResponses(canAppBeUpgraded, 'canAppBeUpgradedRequest'),
    ...createExtraReducersForResponses(getProductInfos, 'getProductInfosRequest'),
    ...createExtraReducersForResponses(getProductUpgradePrice, 'getProductUpgradePriceRequest'),
    ...createExtraReducersForResponses(getProductNewestVersion, 'getProductNewestVersionRequest'),
    ...createExtraReducersForResponses(addSageUpgradeToCart, 'addSageUpgradeToCartRequest'),
    ...createExtraReducersForResponses(addQuickbooksUpgradeToCart, 'addQuickbooksUpgradeToCartRequest'),
    ...createExtraReducersForResponses(removeUpgradeProductFromCart, 'removeUpgradeProductFromCartRequest'),
    ...createExtraReducersForResponses(getAllAssigningApplications, 'getAllAssigningApplicationsRequest'),
    ...createExtraReducersForResponses(getUserAvailableAssigningApps, 'getUserAvailableAssigningAppsRequest'),
    ...createExtraReducersForResponses(assignApplicationToUser, 'assignApplicationToUserRequest'),
    ...createExtraReducersForResponses(checkIsAllowedUserEmail, 'checkIsAllowedUserEmailRequest'),
    ...createExtraReducersForResponses(addNewUserToCart, 'addNewUserToCartRequest'),
    ...createExtraReducersForResponses(removeNewUserFromCart, 'removeNewUserFromCartRequest'),
    ...createExtraReducersForResponses(removeNewUserAssignAppFromCart, 'removeNewUserAssignAppFromCartRequest'),
    ...createExtraReducersForResponses(editUser, 'editUserRequest'),
    ...createExtraReducersForResponses(isUserLastAdmin, 'isUserLastAdminRequest'),
    ...createExtraReducersForResponses(removeUserAvatar, 'removeUserAvatarRequest'),
    ...createExtraReducersForResponses(removeEditUserFromCart, 'removeEditUserFromCartRequest'),
    ...createExtraReducersForResponses(removeEditUsersFromCart, 'removeEditUsersFromCartRequest'),
    ...createExtraReducersForResponses(removeUser, 'removeUserRequest'),
    ...createExtraReducersForResponses(removeUserAssignedAppsFromCart, 'removeUserAssignedAppsFromCartRequest'),
    ...createExtraReducersForResponses(removeUserAssignedAppFromCart, 'removeUserAssignedAppFromCartRequest'),
    ...createExtraReducersForResponses(getAccountOverviewUser, 'getAccountOverviewUserRequest'),
    ...createExtraReducersForResponses(getAccountOverviewUserApp, 'getAccountOverviewUserAppRequest'),
    ...createExtraReducersForResponses(getFilterProducts, 'getFilterProductsRequest'),
    ...createExtraReducersForResponses(getAdminUser, 'getAdminUserRequest',
      (state: { [field: string]: any }, action: PayloadAction<any, string, any, SerializedError>) => {
        state.user = action.payload;
      },
    ),
    ...createExtraReducersForResponses(getAdminSubscriptions, 'getAdminSubscriptionsRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionInfo, 'getAdminSubscriptionInfoRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionStatuses, 'getAdminSubscriptionStatusesRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionTerms, 'getAdminSubscriptionTermsRequest'),
    ...createExtraReducersForResponses(updateAdminSubscriptionTerms, 'updateAdminSubscriptionTermsRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionById, 'getAdminSubscriptionByIdRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionApplications, 'getAdminSubscriptionApplicationsRequest'),
    ...createExtraReducersForResponses(removeAdminSubscriptionApps, 'removeAdminSubscriptionAppsRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionUsers, 'getAdminSubscriptionUsersRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionCustomFields, 'getAdminSubscriptionCustomFieldsRequest'),
    ...createExtraReducersForResponses(addAdminCustomField, 'addAdminCustomFieldRequest'),
    ...createExtraReducersForResponses(editAdminCustomField, 'editAdminCustomFieldRequest'),
    ...createExtraReducersForResponses(removeCustomField, 'removeCustomFieldRequest'),
    ...createExtraReducersForResponses(adminAddUser, 'adminAddUserRequest'),
    ...createExtraReducersForResponses(editApplicationInfo, 'editApplicationInfoRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionInvoices, 'getAdminSubscriptionInvoicesRequest'),
    ...createExtraReducersForResponses(getAdminSubscriptionInvoiceFile, 'getAdminSubscriptionInvoiceFileRequest'),
    ...createExtraReducersForResponses(getAdminAvailableCoupons, 'getAdminAvailableCouponsRequest'),
    ...createExtraReducersForResponses(addAdminCoupon, 'addAdminCouponRequest'),
    ...createExtraReducersForResponses(removeAdminCoupon, 'removeAdminCouponRequest'),
  },
});

export const { setDashboardUser } = usspSlice.actions;

export default usspSlice.reducer;