import './ReviewApplicationModal.scss';
import { FC } from 'react';

import { Modal } from 'components';

interface Props {
  visible: boolean;
  title: string;
  productVersion: string;
  licenceCode: string;
  productCode: string;
  serialNumber: string;
  licenseOwnership: string;
  onClose: () => void;
}

const ReviewApplicationModal: FC<Props> = ({
  visible,
  title,
  productVersion,
  licenceCode,
  productCode,
  serialNumber,
  licenseOwnership,
  onClose,
}) => (
  <Modal
    classes="dashboard-accounts-app-review-modal"
    title="Application Details"
    visible={visible}
    onOk={onClose}
    okText="Okay"
    cancelText={null}
    onCancel={onClose}
    width={535}
  >
    <div className="app-card disabled flex rel-flex-gap-24">
      <div className="app-card__content">
        <div className="app-card__name rel-m-b-4">{title}</div>
        <div className="app-card__id rel-m-b-16">Product Version: <span>{productVersion || '-'}</span></div>
      </div>
    </div>
    <div className="licence-info-item rel-m-t-16">License Ownership: <span>{licenseOwnership || '-'}</span></div>
    {productCode && (
      <div className="licence-info-item rel-m-t-8">
        Product code: <span>{productCode}</span>
      </div>
    )}
    {licenceCode && (
      <div className="licence-info-item rel-m-t-8">
        Licence code: <span>{licenceCode}</span>
      </div>
    )}
    {serialNumber && (
      <div className="licence-info-item rel-m-t-8">
        Serial number: <span>{serialNumber}</span>
      </div>
    )}
  </Modal>
);

export default ReviewApplicationModal;