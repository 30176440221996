import './Menu.scss';
import React, { FC, ReactNode, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { usePopper } from 'react-popper';
import { makePxDependOnWindowHeight, useOnClickOutside } from 'helpers';

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

interface Props {
  trigger: JSX.Element;
  items: ReactNode[];
  classes?: string;
  placement?: Placement;
  isDisabled?: boolean;
  isInline?: boolean;
  onStateChange?: (state: boolean) => void;
}

const Menu: FC<Props> = ({
  trigger,
  items,
  classes,
  placement = 'auto',
  isDisabled = false,
  isInline = false,
  onStateChange,
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const popperElementWrapper = useRef<HTMLDivElement | null>(null);
  const referenceElementWrapper = useRef<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, makePxDependOnWindowHeight(8)],
        },
      },
    ],
  });

  const toggleMenu = () => {
    if (!isDisabled) {
      setIsShow(!isShow);
    }
  };

  useEffect(() => {
    if (referenceElement !== null) {
      referenceElementWrapper.current = referenceElement;
    }
  }, [referenceElement]);

  useEffect(() => {
    if (popperElement !== null) {
      popperElementWrapper.current = popperElement;
    }
  }, [popperElement]);

  useEffect(() => {
    if (!!onStateChange) {
      onStateChange(isShow);
    }
  }, [isShow]);

  useOnClickOutside<HTMLDivElement>(referenceElementWrapper, (event) => {
    if (event.target !== popperElementWrapper.current) {
      setIsShow(false);
    }
  }, undefined, false);

  return (
    <div className={cn('menu', classes, {
      'd-inline-block': isInline,
      'menu--disabled': isDisabled,
    })}>
      <div
        ref={setReferenceElement}
        className={cn('menu__trigger', { 'd-inline-block': isInline })}
        onClick={toggleMenu}>
        {trigger}
      </div>
      {isShow && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="menu__content"
        >
          {items.map((el, index) => {
            if (typeof el === 'string') return <div key={`menu-item-${index}`}>{el}</div>;
            return <React.Fragment key={`menu-item-${index}`}>{el}</React.Fragment>;
          })}</div>
      )}
    </div>
  );
};

export default Menu;
