import { Button, ProgressBar, SignUpBackButton, StepPriceDetails } from 'components';
import './SetupUsers.scss';
import { notify, useGetProductsListWithPricesFormated, usePriceHandler, useSignUpFormNavigate } from 'helpers';
import { ProductChargify, SignUpNavigation, UserRole } from 'enums';
import { IAssignProductItem, IOtherProduct } from 'interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ADDITIONAL_SERVICES_PRODUCTS } from 'consts';
import { SubmitHandler, useForm } from 'react-hook-form';
import SetupUsersBodyRow from './components/SetupUsersBodyRow/SetupUsersBodyRow';
import SetupUsersHeadRow from './components/SetupUsersHeadRow/SetupUsersHeadRow';
import { setAssignProducts } from 'store';

interface IAssignProductsForm {
  assignProducts: IAssignProductItem[];
}

const generateAssignProducts = (quantity: number, users: any[], existedProducts: ProductChargify[]) => {
  return (new Array(quantity)).fill(null).map((el, index) => {
    const products = users?.[index]?.products?.filter((el: ProductChargify) => existedProducts.includes(el));
    return {
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        mfaStatus: index === 0,
        superAdmin: index === 0,
        userRole: index === 0 ? UserRole.Admin : UserRole.Standard,
        ...(users?.[index]?.userInfo || {}),
      },
      products: products?.length > 0 ? products : [],
    };
  });
};

const DEFAULT_PRODUCTS: ProductChargify[] = [
  ProductChargify.SHServerUserHosting,
  ProductChargify.SSW,
];

const SetupUsers = () => {
  const dispatch = useAppDispatch();
  const simpleFormNavigate = useSignUpFormNavigate();
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const hostTotalQuantity = useAppSelector(state => state.order.hostProduct.quantity);
  const assignProductsState = useAppSelector(state => state.order.assignProducts);

  const [activeAppSelectIndex, setActiveAppSelectIndex] = useState<null | number>(null);
  const onActiveAppSelect = (state: boolean, index: number) => {
    setActiveAppSelectIndex(state ? index : null);
  };

  const otherProductsInfo: { [key: string]: IOtherProduct } = useAppSelector(
    state => state.products.otherProductsInfo,
  );
  const otherProducts = useAppSelector(state => state.order.otherProducts || []);

  const excludedSelectProducts = useMemo(() => {
    return [
      ...DEFAULT_PRODUCTS,
      ...ADDITIONAL_SERVICES_PRODUCTS,
      ...otherProducts.reduce((acc, { name }) => {
        if (otherProductsInfo[name].isSubscriptionProduct) {
          acc.push(name);
        }

        return acc;
      }, [] as string[]),
    ];
  }, [otherProducts]);
  const productsList = useGetProductsListWithPricesFormated({ excluded: excludedSelectProducts });
  const defaultAssignUsers = useMemo(() => {
    const formatedProductsList = productsList.map(el => el.name);
    return generateAssignProducts(+hostTotalQuantity, assignProductsState, formatedProductsList);
  }, []);
  const {
    control,
    register,
    trigger,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isValid, touchedFields },
  } = useForm<IAssignProductsForm>({
    mode: 'all',
    defaultValues: { assignProducts: defaultAssignUsers },
  });

  const [notUniquePhoneIndexes, setNotUniquePhoneIndexes] = useState<number[]>([]);
  const [notUniqueEmailIndexes, setNotUniqueEmailIndexes] = useState<number[]>([]);

  const { getProductPrice } = usePriceHandler();

  const addDefaultProductsForUser = (userAssignments: IAssignProductItem[]) => userAssignments
    .map(({ userInfo, products }) => ({
      userInfo,
      products: [
        ...products.filter(el => !DEFAULT_PRODUCTS.includes(el)),
        ProductChargify.SHServerUserHosting,
      ],
    }));

  const selectedProductsList = useMemo(() => {
    return getValues().assignProducts.reduce((acc, { products }) => {
      products.forEach((product) => {
        const existedProductIndex = acc.findIndex((existedProduct) => existedProduct.name === product);
        if (existedProductIndex >= 0) {
          acc[existedProductIndex].quantity += 1;
        } else {
          acc.push({ name: product, quantity: 1 });
        }
      });
      return acc;
    }, [] as { name: ProductChargify; quantity: number }[]);
  }, [watch()]);

  const filledProducts = useMemo(() => {
    return productsList.reduce((acc, { name, quantity }) => {
      const hasMaxSelectedProducts = selectedProductsList.some(el => el.name === name && +el.quantity >= +quantity);
      return { ...acc, [name]: hasMaxSelectedProducts };
    }, {} as { [key: string]: boolean });
  }, [productsList, selectedProductsList]);

  const unassignedProducts = useMemo(() => {
    return productsList.reduce((acc, { name, quantity }) => {
      const selectedProduct = selectedProductsList.find(el => el.name === name);
      const selectedProductQuantity = selectedProduct ? +selectedProduct.quantity : 0;
      if (selectedProductQuantity < +quantity) {
        acc.push({ name, quantity: +quantity - selectedProductQuantity });
      }
      return acc;

    }, [] as { name: ProductChargify; quantity: number }[]);
  }, [productsList, selectedProductsList]);

  const quantity = useMemo(() => {
    const { assignProducts } = getValues();
    return {
      total: assignProducts.length,
      ...(assignProducts.reduce((total, { userInfo }) => {
        total.mfa += userInfo.mfaStatus ? 1 : 0;
        total.admin += userInfo.userRole === UserRole.Admin ? 1 : 0;
        total.standard += userInfo.userRole === UserRole.Standard ? 1 : 0;
        return total;
      }, { mfa: 0, admin: 0, standard: 0 })),
    };
  }, [watch()]);

  const validatePhone = (userIndex: number) => {
    const values = getValues('assignProducts');
    if (values[userIndex].userInfo.phone !== '') {
      const isUnique = values.every((el: IAssignProductItem, index: number) => {
        return index === userIndex
          || !values[userIndex].userInfo.phone.trim().length
          || el.userInfo.phone !== values[userIndex].userInfo.phone;
      });
      if (!isUnique && !notUniquePhoneIndexes.includes(userIndex)) {
        notify.error('You can\'t use the same phone number twice. Please, update it.');
        setNotUniquePhoneIndexes([...notUniquePhoneIndexes, userIndex]);
      } else if (isUnique && notUniquePhoneIndexes.includes(userIndex)) {
        setNotUniquePhoneIndexes(notUniquePhoneIndexes.filter(el => el !== userIndex));
      }
      setTimeout(() => {
        trigger(`assignProducts.${userIndex}.userInfo.phone`);
      }, 0);
    }
  };

  const validateEmail = (userIndex: number) => {
    const values = getValues('assignProducts');
    const isUnique = values.every((el: IAssignProductItem, index: number) => {
      return !values[userIndex].userInfo.email.trim().length
        || index === userIndex
        || el.userInfo.email !== values[userIndex].userInfo.email;
    });
    if (!isUnique && !notUniqueEmailIndexes.includes(userIndex)) {
      notify.error('You can\'t use the same email twice. Please, update it.');
      setNotUniqueEmailIndexes([...notUniqueEmailIndexes, userIndex]);
      setValue(`assignProducts.${userIndex}.userInfo.email`, values[userIndex].userInfo.email, {
        shouldTouch: true,
        shouldValidate: true,
      });
    } else if (isUnique && notUniqueEmailIndexes.includes(userIndex)) {
      setNotUniqueEmailIndexes(notUniqueEmailIndexes.filter(el => el !== userIndex));
    }
    setTimeout(() => {
      trigger(`assignProducts.${userIndex}.userInfo.email`);
    }, 0);
  };

  useEffect(() => {
    const value = getValues();
  }, [watch()]);

  const nextHandler: SubmitHandler<IAssignProductsForm> = ({ assignProducts }) => {
    setIsNextLoading(true);
    try {
      const formatedAssignProducts: IAssignProductItem[] = addDefaultProductsForUser(assignProducts);
      dispatch(setAssignProducts(formatedAssignProducts));
      simpleFormNavigate(SignUpNavigation.Summary);
    } finally {
      setIsNextLoading(false);
    }
  };

  const backHandler = () => {
    simpleFormNavigate(SignUpNavigation.AdditionalServices);
  };
  return (
    <>
      <SignUpBackButton onClick={backHandler} />
      <div className="container-xl">
        <ProgressBar currentStep={6} totalSteps={7} />
        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-8">Setup your users</div>
          <form>
            <div className="setup-users-table-wrapper">
              <div className="setup-users-table">
                <SetupUsersHeadRow />
                <div>
                  {getValues().assignProducts.map(({ userInfo }, index) => (
                    <SetupUsersBodyRow
                      superAdmin={userInfo.superAdmin}
                      key={`users-${index}`}
                      firstName={userInfo.firstName}
                      lastName={userInfo.lastName}
                      email={userInfo.email}
                      mfaStatus={userInfo.mfaStatus}
                      userRole={userInfo.userRole}
                      phone={userInfo.phone}
                      control={control}
                      register={register}
                      trigger={trigger}
                      errors={errors}
                      touchedFields={touchedFields}
                      filledProducts={filledProducts}
                      userIndex={index}
                      notUniquePhone={notUniquePhoneIndexes.includes(index)}
                      notUniqueEmail={notUniqueEmailIndexes.includes(index)}
                      onUpdateValue={setValue}
                      validatePhone={validatePhone}
                      validateEmail={validateEmail}
                      activeSelectIndex={activeAppSelectIndex}
                      onActiveAppSelect={(value: boolean) => onActiveAppSelect(value, index)} />
                  ))}
                </div>
              </div>
            </div>
          </form>
          <div className="setup-users-quanitities-wrapper m-t-16 sm-m-t-4">
            <div className="setup-users-quantities-block">
              <div className="quantities-part"><span className="quantities-label">Total:</span> {quantity.total} users</div>
              <div className="quantities-part"><span className="quantities-label">MFA:</span> {quantity.mfa} users</div>
              <div className="quantities-part"><span className="quantities-label">Admin Role:</span> {quantity.admin} users</div>
              <div className="quantities-part"><span className="quantities-label">Standard Role:</span> {quantity.standard} users</div>
            </div>
          </div>
          <div className="flex flex-justify-center m-t-40 sm-m-t-24">
            <Button
              dataTestId="setup-users.next-step-button"
              xVersion
              absoluteStyles
              isDisabled={!isValid || unassignedProducts.length}
              isLoading={isNextLoading}
              onClick={handleSubmit(nextHandler)}
            >
              Next Step
            </Button>
          </div>
        </div>
      </div>
      <StepPriceDetails currentStep={SignUpNavigation.SetupUsers} stepProducts={[]} />
    </>
  );
};

export default SetupUsers;