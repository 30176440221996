import React, { FC, ReactElement, useState, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import cn from 'classnames';
import {
  ArrowDownIcon,
  ArrowTopIcon,
  CloseCircleIcon,
  CheckIcon,
  InfoCircleIcon,
  CloseIcon,
  PlusDarkCircleIcon,
  MinusCircleIcon,
} from 'assets/svg';
import './Select.scss';
import { usePrevious, makePxDependOnWindowHeight, useUpdateEffect, makeVh } from 'helpers';
import Tooltip from '../Tooltip/Tooltip';
import { Placement } from '@popperjs/core';

const scrollEvent = new Event('scroll');

type TSelectSize = 'large' | 'small';

interface IOption {
  label: string;
  value?: string | number;
  rightLabel?: string;
  maxCount?: number;
  disableCountButtons?: boolean;
  disabled?: boolean;
  icon?: FC<IconProps>;
}

interface ISelectCountValueItem {
  value: string | number;
  count?: number;
}

interface IconProps {
  className?: string;
}

type OptionType = IOption | string | number;

interface Props {
  xVersion?: boolean;
  absoluteStyles?: boolean;
  errorMessage?: string;
  value?: number | string | Array<number | string | ISelectCountValueItem>;
  theme?: 'light' | 'dark';
  classes?: string;
  fullWidth?: boolean;
  label?: string;
  dataTestId?: string;
  isMulti?: boolean;
  withCount?: boolean; // only with isMulti
  icon?: FC<IconProps>;
  placeholder?: string;
  isRequired?: boolean;
  withInfo?: boolean;
  infoContent?: string | ReactElement;
  options?: Array<OptionType>;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isDisabledOpacity?: boolean;
  dropdownHeight?: string | number;
  emptyOptionsText?: string;
  size?: TSelectSize;
  centeredOptions?: boolean;
  optionsWithIcons?: boolean;
  readOnly?: boolean;
  dropdownWidthDifferent?: boolean;
  dropdownWidth?: number;
  dropdownPlacement?: Placement;
  onChange?: (value: any) => void;
  onClose?: (value?: any) => void;
  onChangeDropdownState?: (value: boolean) => void;
}

const Select: FC<Props> = ({
  xVersion,
  errorMessage,
  label,
  theme = 'light',
  classes,
  withInfo = false,
  infoContent,
  withCount,
  icon: IconComponent,
  placeholder = 'Select',
  isMulti = false,
  isRequired,
  options = [],
  fullWidth = true,
  value = '',
  isInvalid = false,
  isDisabled = false,
  isDisabledOpacity = false,
  dataTestId,
  dropdownHeight = '300',
  emptyOptionsText = 'Options list is empty...',
  size = 'large',
  centeredOptions = false,
  optionsWithIcons = false,
  readOnly = false,
  dropdownWidthDifferent = false,
  dropdownWidth = 140,
  dropdownPlacement = 'bottom',
  absoluteStyles = false,
  onChange,
  onClose,
  onChangeDropdownState,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [rootElement, setRootElement] = useState<Element | null>(null);
  const popperElementRef = useRef<any>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [lastKey, setLastKey] = useState('');
  const [searchResults, setSearchResults] = useState<any>([]);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: dropdownPlacement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, makePxDependOnWindowHeight(4)],
        },
      },
    ],
  });

  const setPopperElementBoth = (element: any) => {
    setPopperElement(element);
    popperElementRef.current = element;
  };

  const [isOpen, setOpen] = useState(false);
  const previousOpen = usePrevious(isOpen);

  const dropdownStyles = useMemo(() => {
    return {
      ...styles.popper,
      maxHeight: absoluteStyles ? dropdownHeight + 'px' : makeVh(dropdownHeight),
      width: dropdownWidthDifferent && dropdownWidth
        ? absoluteStyles ? dropdownWidth + 'px' : makePxDependOnWindowHeight(dropdownWidth)
        : referenceElement !== null ? referenceElement.clientWidth : 'auto',
    };
  }, [dropdownHeight, styles, isOpen]);

  useEffect(() => {
    setRootElement(document.querySelector('#root'));
  }, []);

  const scrollToOption = (optionRef: any) => {
    const optionRect = optionRef?.getBoundingClientRect();
    const dropdownRect = popperElementRef?.current?.getBoundingClientRect();
    if (optionRect && dropdownRect && popperElementRef && popperElementRef.current) {
      const optionOffsetTop = optionRect.top - dropdownRect.top + popperElementRef.current.scrollTop;
      const optionHeight = optionRect.height;
      const dropdownHeight = dropdownRect.height;
      const scrollTop = optionOffsetTop - (dropdownHeight / 2) + (optionHeight / 2);
      popperElementRef.current.scrollTop = scrollTop;
    }
  };

  const scrollOptionIfNeeded = (optionRef: any) => {
    const dropdown = popperElementRef.current;
    const dropdownRect = dropdown.getBoundingClientRect();
    const elementRect = optionRef.getBoundingClientRect();

    if (elementRect.top < dropdownRect.top) {
      dropdown.scrollTop -= (dropdownRect.top - elementRect.top);
    } else if (elementRect.bottom > dropdownRect.bottom) {
      dropdown.scrollTop += (elementRect.bottom - dropdownRect.bottom);
    }
  };

  useEffect(() => {
    if (previousOpen && !isOpen) {
      onClose && onClose();
      setHighlightedIndex(null);
    }
    if (!previousOpen && isOpen) {
      if (isMulti && Array.isArray(value)) {
        const selectedOptions = value.map(v => options.find(e => getValue(e) === v));
        const selectedOptionsIndexes = selectedOptions.map(s => getOptionIndex(s as OptionType));
        selectedOptionsIndexes.sort((a, b) => a > b ? 1 : -1);
        if (selectedOptionsIndexes.length) {
          scrollToOption(optionRefs.current[selectedOptionsIndexes[0]]);
        }
      } else {
        const selectedOption = options.find(e => getValue(e) === value);
        const optionIndex = selectedOption ? getOptionIndex(selectedOption) : 0;
        scrollToOption(optionRefs.current[optionIndex]);
      }
    }
    if (onChangeDropdownState !== undefined) {
      onChangeDropdownState(isOpen);
    }
  }, [isOpen, onClose, previousOpen]);

  useEffect(() => {
    if (highlightedIndex !== null && optionRefs.current[highlightedIndex]) {
      scrollOptionIfNeeded(optionRefs.current[highlightedIndex]);
    }
  }, [highlightedIndex]);


  const open = () => {
    if (!isDisabled && !readOnly) {
      setOpen(true);
    }
  };

  const toggleOpenState = () => {
    if (!isDisabled) {
      setOpen(!isOpen);
    }
  };

  const getOptionIndex = (option: OptionType) => {
    return options.findIndex(e => getValue(e) === getValue(option));
  };

  const isSelected = (option: OptionType) => {
    const optionValue = getValue(option);
    if (!value) return false;
    if (isMulti && Array.isArray(value)) {
      if (withCount) {
        return value.find(i => typeof i === 'object' ? i.value === optionValue : i === optionValue);
      }
      return typeof value === 'number' ? value === optionValue : value.includes(optionValue);
    } else {
      return value === optionValue;
    }
  };

  const isOptionDisabled = (option: OptionType) => {
    return typeof option === 'object' && option.disabled;
  };

  const minusCount = (option: OptionType) => {
    const optionValue = getValue(option);
    if (Array.isArray(value) && isMulti && withCount) {
      let newValue = [...value];
      const selectedValueIndex = newValue.findIndex(i => typeof i === 'object' ? i.value === optionValue : i === optionValue);
      if (selectedValueIndex !== -1) {
        const selectedValue = newValue[selectedValueIndex];
        if (typeof selectedValue === 'object') {
          if (selectedValue.count && selectedValue.count > 1) {
            selectedValue.count--;
          } else {
            newValue = value.filter(i => typeof i === 'object' ? i.value !== optionValue : i !== optionValue);
          }
        } else {
          newValue = value.filter(i => typeof i === 'object' ? i.value !== optionValue : i !== optionValue);
        }
        if (onChange !== undefined) {
          onChange(newValue);
        }
      }
    }
  };
  const plusCount = (option: OptionType) => {
    const optionValue = getValue(option);
    if (Array.isArray(value) && isMulti && withCount) {
      const newValue = [...value];
      const selectedValueIndex = newValue.findIndex(i => typeof i === 'object' ? i.value === optionValue : i === optionValue);
      if (selectedValueIndex !== -1) {
        const selectedValue = newValue[selectedValueIndex];
        if (typeof selectedValue === 'object') {
          if (selectedValue.count) {
            selectedValue.count++;
          } else {
            selectedValue.count = 2;
          }
        } else {
          newValue[selectedValueIndex] = {
            value: optionValue,
            count: 2,
          };
        }
      } else {
        newValue.push({
          value: optionValue,
          count: 1,
        });
      }
      if (onChange !== undefined) {
        onChange(newValue);
      }
    }
  };

  const getValue = (option: OptionType) => {
    return typeof option === 'object' ? (option.value || option.label) : option;
  };

  const removeValue = (item: number | string | ISelectCountValueItem) => {
    if (Array.isArray(value)) {
      const newValue = value.filter(v => v !== item);
      if (onChange !== undefined) {
        onChange(newValue);
      }
      window.dispatchEvent(scrollEvent);
    }
  };

  const onLabelClick = (event: any, option: OptionType) => {
    event.stopPropagation();
    const selectedValue = getValue(option);
    if (isMulti) {
      let newValue;
      if (Array.isArray(value) && value.find(i => typeof i === 'object' ? i.value === selectedValue : i === selectedValue)) {
        newValue = value.filter(i => typeof i === 'object' ? i.value !== selectedValue : i !== selectedValue);
      } else if (Array.isArray(value)) {
        newValue = [...value, withCount ? {
          value: selectedValue,
          count: 1,
        } : selectedValue];
      } else {
        newValue = [withCount ? {
          value: selectedValue,
          count: 1,
        } : selectedValue];
      }
      if (onChange !== undefined) {
        onChange(newValue);
      }
      window.dispatchEvent(scrollEvent);
    } else {
      if (onChange !== undefined) {
        onChange(selectedValue);
      }
      setOpen(false);
    }
  };

  const getOptionCount = (option: OptionType) => {
    const optionValue = getValue(option);
    if (Array.isArray(value) && isMulti && withCount) {
      const selectedValue = value.find(i => typeof i === 'object' ? i.value === optionValue : i === optionValue);
      if (selectedValue) {
        return typeof selectedValue === 'object' && selectedValue.count || 1;
      }
    }
    return 0;
  };

  const isValueEmpty = useMemo(() => {
    const isEmpty = Array.isArray(value) ? !value.length : value === undefined || value === '';
    return isEmpty || options.every(el => {
      const elValue = typeof el === 'object' ? el.value || el.label : el;
      const isOptionSelected = Array.isArray(value) ? value.includes(elValue) : elValue === value;
      return !isOptionSelected;
    });
  }, [value, options]);

  const findLabelByValue = (value: number | string | ISelectCountValueItem) => {
    const opt = options.find(option => {
      const curValue = getValue(option);
      return curValue === (value !== null && typeof value === 'object' ? value.value : value);
    });
    return typeof opt === 'object' ? opt.label : opt;
  };
  const findIconByValue = (value: number | string | ISelectCountValueItem): FC<IconProps> | undefined => {
    const opt = options.find(option => {
      const curValue = getValue(option);
      return curValue === (value !== null && typeof value === 'object' ? value.value : value);
    });
    return typeof opt === 'object' ? opt.icon : undefined;
  };

  const getSingleValueIcon = useMemo(() => {
    return !Array.isArray(value) ? findIconByValue(value) : undefined;
  }, [value, options]);
  
  const getSingleValueLabel = useMemo(() => {
    return Array.isArray(value) ? value : findLabelByValue(value);
  }, [value, options]);

  useUpdateEffect(() => {
    const handleClickOutside = (event: any) => {
      const canClose = referenceElement !== null
        && popperElement !== null
        && !(referenceElement.contains(event.target as Node)
        || popperElement.contains(event.target as Node));
      if (canClose) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [referenceElement, popperElement]);

  const renderIcon = (IconComponent: FC<IconProps> | undefined) => {
    return IconComponent && <IconComponent />;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // check is multi
    if (!isOpen) {
      if (!isMulti && event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey && /^[a-zA-Z0-9]$/.test(event.key)) {
        event.preventDefault();
        const key = event.key.toLowerCase();
        const matches = options.filter(option => {
          const optionLabel = typeof option === 'object' ? option.label : option;
          return optionLabel.toString().toLowerCase().startsWith(key);
        });
        if (matches.length) {
          onLabelClick(event, options[getOptionIndex(matches[0])]);
        }
      } else if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
        event.preventDefault();
        if (isMulti && Array.isArray(value)) {
          const selectedOptions = value.map(v => options.find(e => getValue(e) === v));
          const selectedOptionsIndexes = selectedOptions.map(s => getOptionIndex(s as OptionType));
          selectedOptionsIndexes.sort((a, b) => a > b ? 1 : -1);
          setHighlightedIndex(selectedOptionsIndexes[0] || 0);
        } else {
          const selectedOption = options.find(e => getValue(e) === value);
          const optionIndex = selectedOption ? getOptionIndex(selectedOption) : 0;
          setHighlightedIndex(optionIndex);
        }
        open();
      }
    } else {
      if (event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey && /^[a-zA-Z0-9]$/.test(event.key)) {
        event.preventDefault();
        const key = event.key.toLowerCase();
        if (key === lastKey) {
          const currentOption = options[highlightedIndex || 0];
          const currentSearchResultsIndex = searchResults.findIndex((e: any) => getValue(e) === getValue(currentOption));
          const newIndex = ((currentSearchResultsIndex || 0) + 1) % searchResults.length;
          setHighlightedIndex(getOptionIndex(searchResults[newIndex]));
        } else {
          const matches = options.filter(option => {
            const optionLabel = typeof option === 'object' ? option.label : option;
            return optionLabel.toString().toLowerCase().startsWith(key);
          });
          setSearchResults(matches);
          setHighlightedIndex(getOptionIndex(matches[0]));
        }
        setLastKey(key);
      } else {
        const getCurrentPossibleHighlighedIndex = () => {
          if (isMulti && Array.isArray(value)) {
            const selectedOptions = value.map(v => options.find(e => getValue(e) === v));
            const selectedOptionsIndexes = selectedOptions.map(s => getOptionIndex(s as OptionType));
            selectedOptionsIndexes.sort((a, b) => a > b ? 1 : -1);
            return selectedOptionsIndexes[0] || 0;
          } else {
            const selectedOption = options.find(e => getValue(e) === value);
            const optionIndex = selectedOption ? getOptionIndex(selectedOption) : 0;
            return optionIndex;
          }
        };
        const currentPossibleHighlighedIndex = getCurrentPossibleHighlighedIndex();
        switch (event.key) {
          case 'ArrowDown':
            setHighlightedIndex(prevIndex => {
              if (prevIndex === null) {
                if (currentPossibleHighlighedIndex > 0) {
                  return (currentPossibleHighlighedIndex + 1) % options.length;
                }
                return 0;
              }
              return (prevIndex + 1) % options.length;
            });
            event.preventDefault();
            break;
          case 'ArrowUp':
            setHighlightedIndex(prevIndex => {
              if (prevIndex === null) {
                if (currentPossibleHighlighedIndex > 0) {
                  return (currentPossibleHighlighedIndex - 1 + options.length) % options.length;
                }
                return options.length - 1;
              }
              return (prevIndex - 1 + options.length) % options.length;
            });
            event.preventDefault();
            break;
          case 'Enter':
            if (highlightedIndex !== null) {
              onLabelClick(event, options[highlightedIndex]);
            }
            break;
          case 'Escape':
          case 'Tab':
            setOpen(false);
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <div className={cn('select-wrapper', classes, { 'relative-units': !absoluteStyles, xVersion, fullWidth })}>
      {label && (
        <label>
          { label }
          { isRequired && <span className="req">*</span> }
          { withInfo && <Tooltip
            absoluteStyles={absoluteStyles}
            isDisabled={!infoContent}
            content={infoContent ?? ''}
            placement="top-start"
            isInline>
            <InfoCircleIcon />
          </Tooltip>}
        </label>
      )
      }
      <div
        ref={setReferenceElement}
        className={cn('select', size, theme, {
          invalid: isInvalid,
          disabled: isDisabled,
          'disabled-opacity': isDisabledOpacity,
          'read-only': readOnly,
        })}
        data-test-id={dataTestId}
        onClick={open}
        tabIndex={isDisabled ? -1 : 0}
        onKeyDown={handleKeyDown}
      >
        {IconComponent && <IconComponent className="select-icon" />}
        <div className="select-content">
          {isValueEmpty && <div className="placeholder">{placeholder}</div>}
          {value && !isMulti && <div className="value">
            {optionsWithIcons && (<div className="option-icon">{renderIcon(getSingleValueIcon)}</div>)}
            <div className={cn("text-wrapper", { withOptions: optionsWithIcons })}><div>{getSingleValueLabel}</div></div>
          </div>}
          {value && isMulti && Array.isArray(value) && value.map(i => (
            <div className="value-item" key={`selected-value-${findLabelByValue(i)}`}>
              <span>{findLabelByValue(i)}</span>
              {xVersion ? (
                <CloseIcon onClick={() => removeValue(i)} />
              )
                : (
                  <CloseCircleIcon onClick={() => removeValue(i)} />
                )
              }
            </div>
          ),
          )}
        </div>
        <div className={cn('right-icon', { 'open': isOpen })} onClick={(e) => {
          toggleOpenState(); e.stopPropagation();
        }}>
          {isOpen ? <ArrowTopIcon /> : <ArrowDownIcon/>}
        </div>
        {rootElement !== null && isOpen && (ReactDOM.createPortal(
          <div
            ref={setPopperElementBoth}
            style={dropdownStyles}
            {...attributes.popper}
            className={cn('select-source', theme, size, { multi: isMulti, 'relative-units': !absoluteStyles, xVersion })}>
            {options.length > 0 && options.map((option, optionIndex) => (
              <div
                key={`select-option-${optionIndex}-${typeof option === 'object' ? option.label : option}`}
                className={cn('option', {
                  selected: isSelected(option),
                  centered: centeredOptions,
                  disabled: isOptionDisabled(option),
                  leftAlign: dropdownWidthDifferent,
                  highlighted: optionIndex === highlightedIndex
                })}
                ref={el => optionRefs.current[optionIndex] = el}
                onClick={(e) => onLabelClick(e, option)}
              >
                <div className="label">
                  {isMulti && (
                    <div className={cn('checkbox-icon new-style')}>
                      <CheckIcon />
                    </div>
                  )}
                  {optionsWithIcons && typeof option === 'object' && (
                    <div className={cn('option-icon')}>
                      {renderIcon(option.icon)}
                    </div>
                  )}
                  <span>{typeof option === 'object' ? option.label : option}</span>
                </div>
                {typeof option === 'object' && option.rightLabel && !(isMulti && withCount) && (
                  <div className="right-label">
                    {option.rightLabel}
                  </div>
                )}
                {isMulti && withCount && (
                  <div className="count" onClick={e => e.stopPropagation()}>
                    {typeof option === 'object' && option.disableCountButtons ? null : <MinusCircleIcon onClick={() => minusCount(option)} />}
                    <span>{getOptionCount(option)}</span>
                    {typeof option === 'object' && option.disableCountButtons ? null : <PlusDarkCircleIcon className={cn({ disabled: option && typeof option === 'object' && option.maxCount && getOptionCount(option) >= option.maxCount })} onClick={() => plusCount(option)}/>}
                  </div>
                )}
              </div>
            ))}
            {options.length === 0 && (
              <div className="empty-text">{emptyOptionsText}</div>
            )}
          </div>,
          rootElement,
        ))}
      </div>
      {isInvalid && !!errorMessage && <span className="error-message">{errorMessage}</span>}
    </div>
  );
};

export default Select;