import './ToggleSwitch.scss';
import { FC } from 'react';
import cn from 'classnames';

interface Props {
  name?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  small?: boolean;
  onChange: (e: boolean) => void;
}

const ToggleSwitch: FC<Props> = ({
  name,
  isChecked,
  isDisabled,
  small,
  onChange,
}) => {
  const handleKeyPress = (e: any) => {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!isChecked);
  };

  return (
    <div className={cn('toggle-switch', {
      'toggle-switch--small': small,
      'toggle-switch--checked': isChecked,
    })}>
      <label
        className="toggle-switch-label"
        tabIndex={isDisabled ? -1 : 1}
        onKeyDown={e => handleKeyPress(e)}
      >
        <span
          className={cn('toggle-switch-inner', { 'toggle-switch-disabled': isDisabled })}
          tabIndex={-1}
        />
        <span
          className={cn('toggle-switch-switch', { 'toggle-switch-disabled': isDisabled })}
          tabIndex={-1}
        />
        <input
          type="checkbox"
          name={name}
          className="toggle-switch-checkbox"
          checked={isChecked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={isDisabled}
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
