import React, { FC, Fragment, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ApplicationFormFooter from '../ApplicationFormFooter/ApplicationFormFooter';
import { useFormFieldErrorNotification } from 'helpers';
import { DropdownButton, InputText, RadioButton, Select } from 'components';
import { ThreeCubesIcon } from 'assets/svg';
import { setApplicationSerialNumber, setApplicationComponentId, setApplicationYear, getProductPrice, setApplicationPrice } from 'store';

import './SageClientLicense.scss';

interface Props {
  onCancel: () => void;
  changeCurrentStep: React.Dispatch<React.SetStateAction<any>>;
}

interface SageClientLicenseForm {
  componentId: number;
  year: string;
  serialNumber: string;
}

const SageClientLicense: FC<Props> = ({ onCancel, changeCurrentStep }) => {
  const sageProducts = useAppSelector(state => state.products.sageProducts || []);
  const application = useAppSelector(state => state.products.newApplication);
  const { isLoading } = useAppSelector(state => state.products.getProductPriceRequest);

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    register,
    formState,
    formState: { errors, isValid, touchedFields },
  } = useForm<SageClientLicenseForm>({
    mode: 'all',
    defaultValues: {
      componentId: application.componentId,
      year: application.year,
      serialNumber: application.serialNumber,
    },
  });
  useFormFieldErrorNotification(formState, ['year', 'serialNumber']);

  const handleGetProductPrice = async (componentId: number) => {
    const { price }: { price: string } = await dispatch(getProductPrice(componentId)).unwrap();
    dispatch(setApplicationPrice(price));
  };

  const onSubmit: SubmitHandler<SageClientLicenseForm> = (data) => {
    handleGetProductPrice(data.componentId);

    dispatch(setApplicationComponentId(data.componentId));
    dispatch(setApplicationYear(data.year));
    dispatch(setApplicationSerialNumber(data.serialNumber));
    changeCurrentStep(2);
  };

  const isNotDropdownItem = (version: string): boolean => ['Sage 50 Premium', 'Sage 50 Quantum'].includes(version);

  const componentId = useMemo(() => getValues('componentId'), [watch()]);

  const PRODUCTS_MAP = useMemo(() => [
    {
      id: 0,
      isDropdownItems: false,
      additionalContent: sageProducts.filter((item: any) => {
        if (isNotDropdownItem(item.version as string)) {
          return item;
        }
      }),
    },
    {
      id: 1, title: 'Other Sage Products', isDropdownItems: true, additionalContent: (
        sageProducts.filter((item: any) => {
          if (!isNotDropdownItem(item.version as string)) {
            return item;
          }
        })
      ),
    },
  ], [watch()]);

  return (
    <form className="sage-client-license" onSubmit={handleSubmit(onSubmit)}>
      <div className="quickbooks-client-license__title">
        Which version of QuickBooks do you currently own?
      </div>
      {PRODUCTS_MAP.map((item: any) => (
        <Fragment key={item.id}>
          {!item.isDropdownItems ? (
            <>
              {item.additionalContent.map((item: any) => (
                <Fragment key={item.id}>
                  <Controller
                    name="componentId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <RadioButton
                        value={value}
                        isChecked={componentId === item.componentId}
                        onChange={() => {
                          onChange(item.componentId);
                          setValue('year', '');
                          setValue('serialNumber', '');
                          dispatch(setApplicationPrice(''));
                        }}
                        label={item.version}
                      />
                    )}
                  />
                  {componentId === item.componentId && (
                    <div className="quickbooks-client-license__info">
                      <Controller
                        name="year"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            classes="rel-m-b-16"
                            value={value}
                            onClose={onBlur}
                            onChange={onChange}
                            label="Choose year"
                            isRequired
                            placeholder="Please choose year"
                            options={item.years.map(String)}
                            isInvalid={!!(errors.year && touchedFields.year)}
                          />
                        )}
                      />
                      <InputText
                        classes="rel-m-b-32"
                        label="Serial number"
                        placeholder="Enter serial number"
                        name="serialNumber"
                        register={register}
                        isInvalid={!!(errors.serialNumber && touchedFields.serialNumber)}
                      />
                    </div>
                  )}
                </Fragment>
              ))}
            </>
          ) : (
            <DropdownButton
              icon={<ThreeCubesIcon />}
              label={item.title}
              source={
                <>
                  {item.additionalContent.map((item: any) => (
                    <Fragment key={item.id}>
                      <Controller
                        name="componentId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <RadioButton
                            value={value}
                            isChecked={componentId === item.componentId}
                            onChange={() => {
                              onChange(item.componentId);
                              setValue('year', '');
                              setValue('serialNumber', '');
                              dispatch(setApplicationPrice(''));
                            }}
                            label={item.version}
                          />
                        )}
                      />
                      {componentId === item.componentId && (
                        <div className="quickbooks-client-license__info">
                          <Controller
                            name="year"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <Select
                                classes="rel-m-b-16"
                                value={value}
                                onClose={onBlur}
                                onChange={onChange}
                                label="Choose year"
                                isRequired
                                placeholder="Please choose year"
                                options={item.years}
                                isInvalid={!!(errors.year && touchedFields.year)}
                              />
                            )}
                          />
                          <InputText
                            classes="rel-m-b-32"
                            label="Serial number"
                            placeholder="Enter serial number"
                            name="serialNumber"
                            register={register}
                            isInvalid={!!(errors.serialNumber && touchedFields.serialNumber)}
                          />
                        </div>
                      )}
                    </Fragment>
                  ))}
                </>
              }
            />
          )}
        </Fragment>
      ))}
      <ApplicationFormFooter
        onCancel={onCancel}
        submitForm
        isOkDisabled={!isValid}
        isOkLoading={isLoading}
        cancelText="Back"
        okText="Next"
      />
    </form>
  );
};

export default SageClientLicense;
