import { FC } from 'react';
import './FilterTags.scss';
import { FilterTag } from 'components';
import { CloseIcon } from 'assets/svg';

interface Props {
  tags: Array<{
    label: string,
    onDelete: () => void;
  }>,
  onClearAll: () => void;
}

const FilterTags: FC<Props> = ({ tags, onClearAll }) => {
  return tags.length ? (
    <div className='filter-tags'>
      {tags.map((tag) => (
        <FilterTag key={tag.label} tag={tag}/>
      ))}
      <button
        className="rel-m-l-8 btn relative-units ghost with-icon clear-button"
        onClick={onClearAll}
      >
        <CloseIcon />
        Clear All
      </button>
    </div>
  ) : null;
};

export default FilterTags;