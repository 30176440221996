import { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { usePriceHandler } from 'helpers';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getAdminSubscriptionInvoiceFile } from 'store';

import { CountryCode, InvoiceStatus } from 'enums';
import { INVOICE_STATUS_CLASSES } from 'consts';

import {
  DotsIcon,
  ShowOutlineIcon,
  PaperDownloadIcon,
  LinkIcon,
  EditOutlineIcon,
  DeleteOutlineIcon,
} from 'assets/svg';

import { Menu } from 'components';

interface Props {
  subscriptionId: string;
  id: string;
  name: string;
  value: string;
  updatedAt: string;
  onEdit: () => void
  onRemove: () => void
}

const formatDatetime = (value: string): string => dayjs(value).format('MM/DD/YYYY hh:mmA');

const InvoicesTableBodyRow: FC<Props> = ({
  name,
  value,
  updatedAt,
  onEdit,
  onRemove,
}) => {
  const updatedAtFormated = useMemo(() => updatedAt ? formatDatetime(updatedAt) : '-', [updatedAt]);

  return (
    <tr className="users-table-body-row">
      <td>{name}</td>
      <td>{value}</td>
      <td>{updatedAtFormated}</td>
      <td>
        <Menu
          trigger={<DotsIcon />}
          items={[
            <div onClick={onEdit}><EditOutlineIcon className="menu-item-icon" />Edit</div>,
            <div onClick={onRemove}><DeleteOutlineIcon className="menu-item-icon" />Remove</div>,
          ]}
          placement="bottom-end"
        />
      </td>
    </tr>
  );
};

export default InvoicesTableBodyRow;
