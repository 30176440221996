import './DashboardAccountUserProfile.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAccountOverviewUser,
  getAccountOverviewUserApp,
  getUserAvailableAssigningApps,
  getUsspCartData,
} from 'store';
// import { setupSignalR } from 'helpers';
import { useCartCompleteSuccess } from 'helpers';

import { ITableFilterOrdering } from 'interfaces';
import { UserRole } from 'enums';

import {
  PlusInSquareIcon,
  UsersOutlineIcon,
  WorkIcon,
  ArrowLeftSmallIcon,
} from 'assets/svg';

import UserProfileTableHeadRow from './components/UserProfileTableHeadRow';
import UserProfileTableBodyRow from './components/UserProfileTableBodyRow';
import UserProfileTableBodyRowLoader from './components/UserProfileTableBodyRowLoader';
import {
  Error,
  Pagination,
  EmptyStateContainer,
  ReviewApplicationModal,
  RemoveApplicationFromUserModal,
  AssignApplicationSidebarModal,
  EditUserModal,
} from 'components';
import { useSearchParams } from 'react-router-dom';

const DashboardAccountUserProfile: FC = () => {
  const navigate = useNavigate();
  const { subscriptionId, userId } = useParams();
  const dispatch = useAppDispatch();
  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useAppSelector(state => state.ussp.getAccountOverviewUserRequest);
  const formatedUsername = useMemo(() => user
    ? user.username || `${user.firstName} ${user.lastName}`
    : '', [user]);

  const fetchUser = () => {
    if (typeof subscriptionId === 'string' && typeof userId === 'string') {
      dispatch(getAccountOverviewUser({
        subscriptionId,
        userId,
      })).unwrap();
    }
  };

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    orderings: '[]',
  });

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryOrderings = useMemo(() => {
    return JSON.parse(query.get('orderings') || '[]');
  }, [query]);

  const {
    data: userApplications,
    isLoading: isUserApplicationsLoading,
  } = useAppSelector(state => state.ussp.getAccountOverviewUserAppRequest);
  const fetchUserApplications = async (
    isBackgroundUpdate?: boolean,
  ) => {
    if (typeof subscriptionId === 'string' && typeof userId === 'string') {
      const data = await dispatch(getAccountOverviewUserApp({
        subscriptionId,
        userId,
        page: queryPage,
        pageSize: queryPageSize,
        orderings: queryOrderings,
        _disableErrorHandler: true,
        _isBackground: isBackgroundUpdate,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };
  const backgroundFetchUserApplications = async () => {
    setIsBackgroundUpdate(true);
    await fetchUserApplications(true);
    setIsBackgroundUpdate(false);
  };

  const onOrderChange = (data: ITableFilterOrdering) => {
    const newOrderings = data === undefined ? [] : [data];
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('orderings', JSON.stringify(newOrderings));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  const [reviewItemData, setReviewedItemeData] = useState<any>({});
  const [isShowReviewModal, setIsShowReviewModal] = useState<boolean>(false);
  const showReviewModal = () => {
    setIsShowReviewModal(true);
  };
  const hideReviewModal = () => {
    setIsShowReviewModal(false);
  };
  const reviewAppDetailsHandler = (index: number) => {
    setReviewedItemeData({
      title: userApplications?.items[index].name,
      ...(userApplications?.items[index]?.details || {}),
    });
    showReviewModal();
  };

  const [removeApplicationsData, setRemoveApplicationsData] = useState<any>({});
  const [isShowRemoveApplicationModal, setIsShowRemoveApplicationModal] = useState<boolean>(false);
  const openRemoveApplicationModal = (application: any) => {
    setRemoveApplicationsData({
      id: user.id,
      userName: formatedUsername,
      applications: [application],
    });
    setIsShowRemoveApplicationModal(true);
  };
  const closeRemoveApplicationModal = () => {
    setIsShowRemoveApplicationModal(false);
    setRemoveApplicationsData({});
  };

  const [isShowAssignApplicationSidebar, setIsShowAssignApplicationSidebar] = useState<boolean>(false);
  const [assignApplicationSidebarUserId, setAssignApplicationSidebarUserId] = useState<string>('');
  const fetchAccountUserAssigningAppData = async (userId: string) => {
    if (typeof subscriptionId === 'string') {
      await dispatch(getUserAvailableAssigningApps({ subscriptionId, userId })).unwrap();
    }
  };
  const openAssignApplicationSidebar = () => {
    if (typeof userId === 'string') {
      fetchAccountUserAssigningAppData(userId);
      setAssignApplicationSidebarUserId(userId);
      setIsShowAssignApplicationSidebar(true);
    }
  };
  const closeAssignApplicationSidebar = () => {
    setIsShowAssignApplicationSidebar(false);
    setAssignApplicationSidebarUserId('');

  };
  const onSuccessAssignApplication = () => {
    dispatch(getUsspCartData({ _isBackground: true })).unwrap();
  };
  useCartCompleteSuccess(async () => {
    await backgroundFetchUserApplications();
  });

  const [isShowEditUserModal, setIsShowEditUserModal] = useState<boolean>(false);

  useEffect(() => {
    //   const connection = setupSignalR('/billing');
    //   if (connection) {
    //     connection.start()
    //       .then(() => {
    //         connection.on('Signal', async (message: any) => {
    //           setIsBackgroundUpdate(true);
    //           await fetchData(currentPage, entriesCount);
    //           setIsBackgroundUpdate(false);
    //           console.log('BILLING:SIGNAL', message);
    //         });

    //         connection.on('PaymentExpirationChanged', async (message: any) => {
    //           setIsBackgroundUpdate(true);
    //           await fetchData(currentPage, entriesCount);
    //           setIsBackgroundUpdate(false);
    //           console.log('BILLING:PAYMENT_EXPIRIATION_CHANGED', message);
    //         });
    //       })
    //       .catch((e: any) => console.log('Connection failed [billing]: ', e));
    //   }
    Promise.all([
      fetchUser(),
      fetchUserApplications(),
    ]);
    //   dispatch(getBillingStatusesData()).unwrap();

    //   return () => {
    //     if (connection) {
    //       connection.stop();
    //     }
    //   };
  }, []);

  if (userError) {
    return <Error code={500} />;
  }

  return (
    <div className="dashboard-acc-user-profile ussp-page">
      <section className="dashboard-acc-user-profile__header ussp-page__header">
        <button
          className="btn relative-units default dashboard-billing-details__back-btn"
          onClick={() => navigate(`/user-portal/account-overview/${subscriptionId}/users`)}
        >
          <ArrowLeftSmallIcon />
        </button>
        <h1 className="flex-1">User Profile</h1>
        <button
          className="btn relative-units primary medium with-icon new-style"
          onClick={openAssignApplicationSidebar}
        >
          <PlusInSquareIcon />
          Assign applications
        </button>
      </section>
      <section className="dashboard-acc-user-profile__info-wrapper user-profile flex">
        {isUserLoading && (
          <div className="user-profile__icon-wrapper"><Skeleton height="100%" /></div>
        ) || user?.avatar && (
          <img src={user.avatar} className="user-profile__icon-wrapper" />
        ) || (
          <div className="user-profile__icon-wrapper"><UsersOutlineIcon /></div>
        )}
        <div className="rel-m-l-32 flex-1">
          <div className="user-profile__main-info rel-p-b-24 flex flex-align-start rel-flex-gap-8">
            {isUserLoading ? (
              <div className="app-card disabled flex flex-1">
                <div className="app-card__content">
                  <Skeleton containerClassName="app-card__name rel-m-b-8 d-block" width="70%" />
                  <Skeleton containerClassName="app-card__id rel-m-b-8 d-block" width="50%" />
                  <Skeleton containerClassName="app-card__id" width="60%" />
                </div>
              </div>
            ) : (
              <div className="app-card disabled flex flex-1">
                <div className="app-card__content">
                  <div className="app-card__name rel-m-b-8 flex flex-align-center rel-flex-gap-8">
                    <span>{formatedUsername}</span>
                    {user?.role === UserRole.Admin && (
                      <div className="user-role-label">Admin</div>
                    )}
                    {user?.hasMfa && (
                      <div className="user-role-label mfa">MFA</div>
                    )}
                  </div>
                  <div className="app-card__id rel-m-b-8">{user?.email}</div>
                  <div className="app-card__id">{user?.phone || ''}</div>
                </div>
              </div>
            )}
            {/* <button className="btn negative outline">Suspend User</button> */}
            <button
              className="btn relative-units default medium new-style"
              disabled={isUserLoading}
              onClick={() => setIsShowEditUserModal(true)}
            >
              Edit Profile
            </button>
          </div>
          <div className="user-profile__company-info rel-p-t-24">
            {isUserLoading ? (
              <div className="app-card disabled flex rel-flex-gap-16">
                <Skeleton containerClassName="app-card__icon-skeleton" height="100%" />
                <div className="app-card__content">
                  <Skeleton containerClassName="app-card__name rel-m-b-4 d-block" width="70%" />
                  <Skeleton containerClassName="app-card__id" width="50%" />
                </div>
              </div>
            ) : (
              <div className="app-card disabled flex rel-flex-gap-16">
                <div className="app-card__icon-wrapper"><WorkIcon /></div>
                <div className="app-card__content">
                  <div className="app-card__name rel-m-b-4">{user?.companyName}</div>
                  <div className="app-card__id">ID: {user?.companyChargifyId}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="dashboard-acc-user-profile__content">
        <table className="user-profile-table">
          <thead>
            <UserProfileTableHeadRow
              orderings={queryOrderings}
              onOrderChange={onOrderChange}
            />
          </thead>
          <tbody>
            {isUserApplicationsLoading && !isBackgroundUpdate
              ? <UserProfileTableBodyRowLoader />
              : userApplications?.items.map((el: any, index: number) => (
                <UserProfileTableBodyRow
                  key={`user-applications-${index}`}
                  {...el}
                  onReview={() => reviewAppDetailsHandler(index)}
                  onRemove={() => openRemoveApplicationModal(el)}
                />
              ))
            }
          </tbody>
        </table>
        {
          userApplications?.items.length === 0
            ? <EmptyStateContainer
              title="No applications found"
              description="No applications found assigned to user"
              classes="empty-state-container-table"
            />
            : null
        }
      </section>
      {userApplications?.items.length > 0 && (
        <Pagination
          current={queryPage}
          entries={queryPageSize}
          totalPages={totalPages}
          onChange={onPaginationChange}
        />
      )}
      {isShowReviewModal && (
        <ReviewApplicationModal
          visible={isShowReviewModal}
          {...reviewItemData}
          onClose={hideReviewModal}
        />
      )}
      {isShowRemoveApplicationModal && (
        <RemoveApplicationFromUserModal
          visible={isShowRemoveApplicationModal}
          onCancel={closeRemoveApplicationModal}
          removeApplicationsData={removeApplicationsData}
          onSuccess={backgroundFetchUserApplications}
        />
      )}
      {isShowAssignApplicationSidebar && (
        <AssignApplicationSidebarModal
          visible={isShowAssignApplicationSidebar}
          onCancel={closeAssignApplicationSidebar}
          userId={assignApplicationSidebarUserId}
          onSuccess={onSuccessAssignApplication}
        />
      )}
      {isShowEditUserModal && (
        <EditUserModal
          visible={isShowEditUserModal}
          userData={user}
          subscriptionId={subscriptionId}
          onCancel={() => setIsShowEditUserModal(false)}
          onSuccessEdit={fetchUser}
        />
      )}
    </div>
  );
};

export default DashboardAccountUserProfile;