import './Checkout.scss';
import { FC, useEffect, useMemo, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import cn from 'classnames';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { checkCoupon, resetCoupon, setCoupon, setSignature, setTermsId } from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  OrderNavigation,
  MsOfficeLicenseType,
  ProductChargify,
  CountryCode,
} from 'enums';
import { PRODUCT_CHARGIFY_TITLES, QUICKBOOK_BUY_MAP_BY_COUNTRY } from 'consts';
import { ICoupon } from 'interfaces';
import {
  usePriceHandler,
  useGetTotalPriceByStep,
  notify,
  useSimpleFormNavigate,
  getEnvCountryCode,
} from 'helpers';

import NotebookNew from 'assets/images/notebook-new.png';
import { PromocodeIcon, InfoCircleIcon, TaxIcon } from 'assets/svg';
import {
  Navigation,
  InputText,
  Alert,
  Checkbox,
  Logo,
  Tooltip,
  Signature,
  Select,
} from 'components';

dayjs.extend(advancedFormat);

enum PromocodeState {
  Default = 'default',
  Error = 'error',
  Success = 'success',
}

interface IPromocodeConfigItem {
  btnLabel: string;
  alertType?: 'info' | 'error' | 'success';
  alertMessage?: string;
}
interface IPromocodeConfig {
  [PromocodeState.Default]: IPromocodeConfigItem;
  [PromocodeState.Error]: IPromocodeConfigItem;
  [PromocodeState.Success]: IPromocodeConfigItem;
}

const DEPOSIT_DESCRIPTION = 'Your deposit is equivalent to one month of service.  Should you choose to terminate your subscription with Summit, your deposit is fully refundable upon request within 30 days of cancellation.';

const SLA_LINK = 'https://www.summithosting.com/services-agreement/';

const PROMOCODE_CONFIG: IPromocodeConfig = {
  [PromocodeState.Default]: {
    btnLabel: 'Apply',
  },
  [PromocodeState.Error]: {
    btnLabel: 'Try Again',
    alertType: 'error',
    alertMessage: 'The coupon code is invalid.',
  },
  [PromocodeState.Success]: {
    btnLabel: 'Remove Code',
    alertType: 'success',
    alertMessage: 'Your coupon was successfuly applied.',
  },
};
// const CONTRACT_TYPE_LABELS: { [key: string]: string } = {
//   [ContractType.Anual]: 'Annual agreement, billed monthly',
//   [ContractType.Monthly]: 'Monthly agreement',
// };
// const CONTRACT_TYPES_MAP: { label: string; value: ContractType }[] = [
//   {
//     label: CONTRACT_TYPE_LABELS[ContractType.Anual],
//     value: ContractType.Anual,
//   },
//   {
//     label: CONTRACT_TYPE_LABELS[ContractType.Monthly],
//     value: ContractType.Monthly,
//   },
// ];

const GET_CUSTOM_PROMOCODE_TEXTS: {
  [key: string]: ({ formatedTotalPrice, formatedDeposit }: { formatedTotalPrice: string; formatedDeposit :string }) => string;
} = {
  'summit15': () => {
    const startingDateToPay = dayjs().add(15, 'day').format('MMMM D, YYYY');
    return `Summit Hosting is pleased to offer you 15 days of free service as a new customer.
      Starting from ${startingDateToPay}, your recurring monthly hosting invoice will be paid with the payment method on file.
      Also, you will pay a one month nontaxable Summit Hosting deposit`;
  },
};

const Checkout: FC = () => {
  const dispatch = useAppDispatch();
  const currentCountryCode = useAppSelector(state => state.order.country?.code || CountryCode.USA);
  const hostProduct = useAppSelector(state => state.order.hostProduct);
  const quickbooksState = useAppSelector(state => state.order.quickbooks);
  const sageState = useAppSelector(state => state.order.sage);
  const msOfficeState = useAppSelector(state => state.order.msOffice);
  const otherProductsState = useAppSelector(state => state.order.otherProducts);
  const additionalServicesState = useAppSelector(state => state.order.additionalServices);
  const couponState = useAppSelector(state => state.order.coupon);
  const signatureState = useAppSelector(state => state.order.signature);
  const termsIdState: number | undefined = useAppSelector(state => state.order.termsId);
  const { data: terms = []} = useAppSelector(state => state.order.getSubscriptionTermsRequest);

  const [signatureValue, setSignatureValue] = useState<string>('');

  const { formatPrice, getProductPrice } = usePriceHandler();
  const { totalPrice, deposit, discount, discountDeposit } = useGetTotalPriceByStep({ currentStep: OrderNavigation.Checkout });
  const formatedTotalPrice = useMemo(() => {
    return formatPrice(totalPrice, currentCountryCode);
  }, [totalPrice]);
  const formatedTotalPriceWithDeposit = useMemo(() => {
    return formatPrice(totalPrice + deposit, currentCountryCode);
  }, [totalPrice, deposit]);
  const formatedTotalPriceWithDiscount = useMemo(() => {
    return formatPrice(totalPrice - discount, currentCountryCode);
  }, [totalPrice, discount]);
  const formatedDeposit = useMemo(() => {
    return formatPrice(deposit, currentCountryCode);
  }, [deposit]);
  const formatedDepositWithDiscount = useMemo(() => {
    return formatPrice(deposit - discountDeposit, currentCountryCode);
  }, [deposit, discountDeposit]);
  const discountedPriceWithDeposit = useMemo(() => {
    return formatPrice(totalPrice - discount + deposit - discountDeposit, currentCountryCode);
  }, [totalPrice, discount, deposit, discountDeposit]);

  const freeMonthsQuantity = useMemo(() => {
    return (couponState?.duration_period_count ?? 0) + 1;
  }, [couponState]);

  const nextPaymentDate = useMemo(() => {
    return dayjs().add(freeMonthsQuantity, 'month').format('MMMM D, YYYY');
  }, [freeMonthsQuantity]);

  const contractStartDateText = useMemo(() => {
    return ` Your contract starts on ${dayjs().format('MMMM	D, YYYY')}`;
  }, []);

  const promocodeChargeText = useMemo(() => {
    const customText = !!couponState?.code
      && GET_CUSTOM_PROMOCODE_TEXTS?.[couponState.code.toLowerCase()]?.({ formatedTotalPrice, formatedDeposit });
    const monthText = freeMonthsQuantity === 1 ? 'month' : 'months';
    const defaultText = `
      Summit Hosting is pleased to offer you ${freeMonthsQuantity} ${monthText}
      of free service as a new customer. Starting ${nextPaymentDate}, your recurring
      monthly hosting invoice will be paid with the payment method on file.
    `;
    return customText || defaultText;
  }, [freeMonthsQuantity, couponState]);

  const [promocode, setPromocode] = useState<string>('');
  const [promocodeState, setPromocodeState] = useState<PromocodeState>(PromocodeState.Default);
  const [isContractChecked, setIsContractChecked] = useState<boolean>(false);
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState<boolean>(false);
  const [isPromocodeLoading, setIsPromocodeLoading] = useState<boolean>(false);
  const [selectedTermsId, setSelectedTermsId] = useState<number | undefined>(termsIdState);

  const getTermNameById = useMemo(() => terms.find((t: any) => t.id === selectedTermsId)?.name, [selectedTermsId]);

  useEffect(() => {
    if (couponState !== null) {
      setPromocode(couponState.code);
      setPromocodeState(PromocodeState.Success);
    }
    setSignatureValue(signatureState ?? '');
    setIsTermsAndConditionsChecked(Boolean(signatureState));
    setIsContractChecked(Boolean(signatureState));
  }, []);

  const applyPromocode = async () => {
    setIsPromocodeLoading(true);
    try {
      const couponPayload: ICoupon = await dispatch(checkCoupon({ code: promocode, _disableErrorHandler: true })).unwrap();
      dispatch(setCoupon(couponPayload));
      setPromocodeState(PromocodeState.Success);
    } catch (err) {
      if ((err as SerializedError).code === '400') {
        setPromocodeState(PromocodeState.Error);
      } else if (typeof (err as SerializedError).message === 'string') {
        notify.error(
          'Error',
          (err as SerializedError).message,
        );
      }
    } finally {
      setIsPromocodeLoading(false);
    }
  };

  const removePromocode = async () => {
    setIsPromocodeLoading(true);
    try {
      await dispatch(resetCoupon()).unwrap();
      dispatch(setCoupon(null));
      setPromocode('');
      setPromocodeState(PromocodeState.Default);
    } finally {
      setIsPromocodeLoading(false);
    }
  };

  const PROMOCODE_BTN_HANDLERS = {
    [PromocodeState.Default]: applyPromocode,
    [PromocodeState.Error]: applyPromocode,
    [PromocodeState.Success]: removePromocode,
  };

  const CHECKOUT_PRODUCTS_MAP: { name: ProductChargify; quantity: number; price: string }[] = useMemo(() => {
    return [
      {
        name: ProductChargify.SHServerUserHosting,
        quantity: hostProduct.quantity,
        price: getProductPrice(ProductChargify.SHServerUserHosting, hostProduct.quantity),
      },
      ...(quickbooksState.data.name !== null ? [{
        name: quickbooksState.data.name,
        quantity: quickbooksState.data.quantity,
        price: getProductPrice(quickbooksState.data.name, quickbooksState.data.quantity),
      }] : []),
      ...(sageState.name !== null ? [{
        name: sageState.name,
        quantity: sageState.quantity,
        price: getProductPrice(sageState.name, sageState.quantity),
      }] : []),
      ...(msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense ? [{
        name: ProductChargify.MSOfficeOwn,
        quantity: hostProduct.quantity,
        price: getProductPrice(ProductChargify.MSOfficeOwn, hostProduct.quantity),
      }] : []),
      ...msOfficeState.products.reduce((acc, { isChecked, name, quantity }) => {
        if (isChecked) acc.push({
          name,
          quantity,
          price: getProductPrice(name, quantity),
        });
        return acc;
      }, [] as any[]),
      ...otherProductsState.reduce((acc, { isChecked, name, quantity, price }) => {
        if (isChecked) acc.push({
          name,
          quantity,
          price: formatPrice(price * +quantity, currentCountryCode),
        });
        return acc;
      }, [] as any[]),
      ...additionalServicesState.map(({ name, quantity }) => ({
        name,
        quantity,
        price: getProductPrice(name as ProductChargify),
      })),
      {
        name: ProductChargify.SSW,
        quantity: 1,
        price: getProductPrice(ProductChargify.SSW),
      },
    ];
  }, [hostProduct, quickbooksState, sageState, msOfficeState]);

  const isShowPromocodeAlert = useMemo(() =>
    promocodeState === PromocodeState.Error || promocodeState === PromocodeState.Success, [promocodeState]);

  const isShowContractCheckbox = useMemo(() => {
    return couponState === null || couponState.code.toLocaleLowerCase() !== 'summit15';
  }, [couponState]);

  const isValid = useMemo(() => {
    const isContractCheckboxValid = isShowContractCheckbox ? isContractChecked : true;
    return isTermsAndConditionsChecked && !!selectedTermsId && isContractCheckboxValid && !!signatureValue;
  }, [isTermsAndConditionsChecked, selectedTermsId, isContractChecked, signatureValue, isShowContractCheckbox]);

  const simpleFormNavigate = useSimpleFormNavigate();
  const nextHandler = () => {
    dispatch(setSignature(signatureValue));
    dispatch(setTermsId(selectedTermsId));
    simpleFormNavigate(OrderNavigation.Payment);
  };

  return (
    <>
      <Logo />
      <div className="checkout page">
        <div className="page-left">
          <div className="checkout-img">
            <img src={NotebookNew} alt="Checkout image" />
          </div>
        </div>
        <div className="page-right">
          <Navigation currentStep={9} />

          <div className="page-right_content">
            <h1>Please Review Your Products</h1>
            <table>
              <thead>
                <tr>
                  <th className="text-left">Products</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-right">Price</th>
                </tr>
              </thead>
              <tbody>
                {CHECKOUT_PRODUCTS_MAP.map(checkotProduct => (
                  <tr key={checkotProduct.name}>
                    <td>{checkotProduct.name}</td>
                    <td className="text-center">x{checkotProduct.quantity}</td>
                    <td className="text-right no-wrap">{
                      QUICKBOOK_BUY_MAP_BY_COUNTRY[getEnvCountryCode(currentCountryCode)].some(
                        ({ name }) => checkotProduct.name === name,
                      )
                        ? 'TBD'
                        : checkotProduct.price
                    }</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="checkout-price deposit rel-m-b-32">
              {PRODUCT_CHARGIFY_TITLES[ProductChargify.Deposit]}
              <span>
                {promocodeState === PromocodeState.Success && discountDeposit > 0
                  ? <del>{formatedDeposit}</del>
                  : formatedDeposit}
              </span>
            </div>
            <div
              className="checkout-price rel-m-b-16">
              Total
              <span>
                {promocodeState === PromocodeState.Success
                  ? <del>{formatedTotalPriceWithDeposit}</del>
                  : formatedTotalPriceWithDeposit}
              </span>
            </div>
            {
              promocodeState === PromocodeState.Success ? (
                <div className="checkout-price rel-m-b-16">
                  Discounted Price
                  <span>{discountedPriceWithDeposit}</span>
                </div>
              ) : null
            }
            <div className={cn('block-info relative-units', {
              'rel-m-b-24': promocodeState === PromocodeState.Success,
              'rel-m-b-8': currentCountryCode == CountryCode.Canada && promocodeState !== PromocodeState.Success,
              'rel-m-b-28': currentCountryCode !== CountryCode.Canada && promocodeState !== PromocodeState.Success,
            })}>
              <InfoCircleIcon />
              <div className="block-info__content">
                <div>
                  Today you will pay <b>{formatedTotalPriceWithDiscount}</b> for monthly hosting.
                </div>
                <div>
                  Today you will pay <b>{formatedDepositWithDiscount}</b> for your nontaxable Summit Hosting &#x200B;
                  <Tooltip
                    content={DEPOSIT_DESCRIPTION}
                    placement="bottom-end"
                    isInline
                  ><span className="link relative-units font-weight-400">deposit</span></Tooltip>
                  .
                </div>
              </div>
            </div>
            {promocodeState === PromocodeState.Success && (
              <>
                {/* <div className="checkout-price rel-m-b-24">Today you Pay<span>{formatedTotalPriceWithDiscount}</span></div> */}
                <p className={cn('coupon-charge', {
                  'rel-m-b-16': currentCountryCode === CountryCode.Canada,
                  'rel-m-b-28': currentCountryCode !== CountryCode.Canada,
                })}>
                  {promocodeChargeText}
                </p>
              </>
            )}
            {currentCountryCode === CountryCode.Canada && (
              <div className="block-info rel-m-b-28">
                <TaxIcon className="rel-m-r-20" />
                Taxes will be applied after
              </div>
            )}
            <InputText
              classes={promocodeState === PromocodeState.Default ? 'rel-m-b-40' : 'rel-m-b-12'}
              label="Promotion code"
              placeholder="Enter code"
              icon={PromocodeIcon}
              readOnly={promocodeState === PromocodeState.Success}
              isWithButton
              value={promocode}
              onChange={setPromocode}
              buttonText={PROMOCODE_CONFIG[promocodeState].btnLabel}
              buttonDisabled={promocode.length === 0}
              buttonAction={PROMOCODE_BTN_HANDLERS[promocodeState]}
              isButtonLoading={isPromocodeLoading}
              isNewStyle
            />
            {isShowPromocodeAlert && (
              <Alert
                classes="rel-m-b-32"
                type={PROMOCODE_CONFIG[promocodeState]?.alertType}
                message={PROMOCODE_CONFIG[promocodeState]?.alertMessage}
                fullWidth
              />
            )}
            <Select
              options={terms.map(({ id, name }: any) => ({ label: name, value: id }))}
              value={selectedTermsId}
              classes="rel-m-b-24"
              label="Choose agreement length"
              placeholder="Select agreement length"
              isRequired
              onChange={setSelectedTermsId}
            />
            {isShowContractCheckbox && (
              <Checkbox
                classes="rel-m-b-24"
                isChecked={isContractChecked}
                onChange={(e) => setIsContractChecked((e.target as HTMLInputElement).checked)}
              >
                You are agreeing to a {getTermNameById} contract term that will be billed monthly. &#x200B;
                <span className="font-weight-600">{contractStartDateText}</span>
              </Checkbox>
            )}
            <Checkbox
              isChecked={isTermsAndConditionsChecked}
              classes="rel-m-b-32"
              onChange={(e) => setIsTermsAndConditionsChecked((e.target as HTMLInputElement).checked)}
            >
              <span className="font-weight-600">I agree with Terms and Conditions under the</span> &#x200B;
              <a className="link relative-units sla-link" href={SLA_LINK} target="_blank">Service Level Agreement (SLA)</a>
            </Checkbox>
            <Signature value={signatureValue} isNewStyle onChange={setSignatureValue} />
            <button
              className="btn primary new-style next-button"
              disabled={!isValid}
              onClick={nextHandler}>Next</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
