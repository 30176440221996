import { FC, ReactElement, useState } from 'react';
import cn from 'classnames';
import { ArrowDownIcon, ArrowTopIcon } from 'assets/svg';
import './DropdownButton.scss';

interface Props {
  label: string;
  source: ReactElement;
  icon?: ReactElement;
  defaultState?: boolean;
  state?: boolean;
}

const DropdownButton: FC<Props> = ({ label, source, icon, defaultState = false, state }) => {
  const [isOpen, setOpen] = useState(defaultState);

  return (
    <div className={cn('dropdown-button-wrapper')}>
      <div className={cn('dropdown-button')} onClick={() => setOpen(!isOpen)}>
        <div className={cn('dropdown-content', { 'with-icon': icon })}>
          {icon && <div className="dropdown-icon">{icon}</div>}
          <div className="dropdown-label">{label}</div>
        </div>
        <div className={cn('right-label', { 'open': isOpen })}>
          {state ?? isOpen ? <ArrowTopIcon /> : <ArrowDownIcon/>}
        </div>
      </div>
      {isOpen && <div className="dropdown-source">{source}</div>}
    </div>
  );
};

export default DropdownButton;