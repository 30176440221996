import React, { FC } from 'react';
import { capitalizeFirstLetter } from 'helpers';
// import { ImportExportIcon } from 'assets/svg';
import { TableFilter } from 'components';

interface Props {
  statusesValue: string[];
  statuses: string[];
  onFilterByStatusesChange: (filters: string[]) => void;
}

const BillingsTableHeadRow: FC<Props> = ({
  statusesValue = [],
  statuses = [],
  onFilterByStatusesChange,
}) => (
  <tr className="billings-table-head-row">
    <th>
      SUBSCRIPTION
      {/* <ImportExportIcon /> */}
    </th>
    <th>CLIENT SINCE</th>
    <th>
      NEXT BILL ON
      {/* <ImportExportIcon /> */}
    </th>
    <th>
      SUBMITTED BY
      {/* <ImportExportIcon /> */}
    </th>
    <th>
      <TableFilter
        values={statusesValue}
        label="STATUS"
        options={statuses.map((status) => ({
          label: capitalizeFirstLetter(status.replaceAll('_', ' ')),
          value: status,
        }))}
        onChange={onFilterByStatusesChange}
      />
    </th>
    <th>PAYMENT METHOD</th>
    <th>ACTIONS</th>
  </tr>
);

export default BillingsTableHeadRow;
