import './TableSearch.scss';
import { FC, useCallback, useRef, useState } from 'react';
import _debounce from 'lodash/debounce';

import { useOnClickOutside } from 'helpers';

import { CloseOutlineIcon, SearchIcon } from 'assets/svg';

import InputText from 'components/common/InputText/InputText';

interface Props {
  initialValue?: string;
  label: string;
  searchBy: string;
  onSearch: (searchQuery: string) => void;
}

const TableSearch: FC<Props> = ({ initialValue, label, searchBy, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState<string>(initialValue || '');
  const [isVisible, setIsVisible] = useState<boolean>(Boolean(initialValue?.length));
  const searchInputNode = useRef<HTMLDivElement | null>(null);

  const debounceFn = useCallback(_debounce((value: string) => onSearch(value), 1000), [onSearch]);

  const handleChangeSearch = (value: string) => {
    setSearchQuery(value);
    debounceFn(value);
  };

  const openSearchInput = () => {
    setIsVisible(true);
  };

  const closeSearchInput = () => {
    setIsVisible(false);
    if (searchQuery) {
      setSearchQuery('');
      debounceFn('');
    }
  };

  useOnClickOutside<HTMLDivElement>(searchInputNode, () => {
    if (!searchQuery && isVisible) {
      setIsVisible(false);
    }
  });

  return (
    <div className="table-search">
      {!isVisible ? (
        <>
          {label}
          <button className="btn relative-units" type="button" onClick={openSearchInput}><SearchIcon className="table-search__icon" /></button>
        </>
      ) : (
        <div ref={searchInputNode}>
          <InputText
            value={searchQuery}
            classes="table-search__input"
            placeholder={searchBy}
            theme="grey"
            icon={SearchIcon}
            withClear
            showClearAlways
            onClear={closeSearchInput}
            onChange={handleChangeSearch}
          />
        </div>
      )}
    </div>
  );
};

export default TableSearch;