import './CompanyInfo.scss';
import { FC, useMemo } from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { BillingStatus } from 'enums';

import { PhoneOutlineIcon, MessageOutlineIcon } from 'assets/svg';
import { BILLING_STATUS_CLASSES } from 'consts';

interface Props {
  classes: string;
  isLoading: boolean;
  organization: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  chargifyId: number;
  status: BillingStatus;
}

const CompanyInfo: FC<Props> = ({
  classes,
  isLoading,
  organization,
  firstName,
  lastName,
  email,
  phone,
  chargifyId,
  status,
}) => {
  const statusFormated = useMemo(() => (status || '')
    .split('_')
    .map(el => `${el.charAt(0).toUpperCase()}${el.slice(1)}`)
    .join(' ')
  , [status]);

  return (
    <div className={cn('company-info', classes)}>
      <div className="flex flex-justify-between flex-align-center rel-m-b-8 rel-m-t-24 rel-m-x24">
        {isLoading ? (
          <Skeleton height="100%" width="100px" />
        ) : (
          organization
        )}
      </div>
      <p className="font-weight-600 rel-m-x24 rel-m-b-32">
        {isLoading ? (
          <Skeleton height="100%" width="50%" />
        ) : (
          `${firstName} ${lastName}`
        )}
      </p>
      {/* <p className="rel-m-x24 rel-m-b-32">(Summit Hosting monthly) Summit Hosting annual</p> */}
      <p className="rel-m-x24 rel-m-b-16 flex flex-justify-between">
        {isLoading ? (
          <Skeleton height="100%" width="70%" />
        ) : (
          <>
            <a
              href={`mailto:${email}`}
              className="link relative-units without-decorations fw-400 flex flex-align-center rel-gap-4"
            >
              <MessageOutlineIcon />
              {email}
            </a>
            {phone && (
              <a
                href={`tel:${phone}`}
                className="company-info__phone flex flex-align-center rel-gap-4"
              >
                <PhoneOutlineIcon />
                {phone}
              </a>
            )}
          </>
        )}
      </p>
      <div className="flex flex-justify-between rel-m-t-16 rel-m-b-20">
        <div className="rel-m-l-24 rel-m-t-16">
          Subscription ID:&nbsp;
          {isLoading ? (
            <Skeleton height="100%" width="130px" />
          ) : (
            <span>{chargifyId}</span>
          )}
        </div>
        <div className="flex rel-m-r-24 rel-m-t-16">
          Status:&nbsp;
          {isLoading ? (
            <Skeleton height="100%" width="100px" />
          ) : (
            <p className={BILLING_STATUS_CLASSES[status]}>
              {statusFormated}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
