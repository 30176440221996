import './AssignUsersToAppModal.scss';
import { FC, useState, useMemo, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getAccountAppAssigningUsersData } from 'store';

import { SearchIcon } from 'assets/svg';

import {
  InputText,
  Checkbox,
  EmptyStateContainer,
  SidebarModal,
  TotalPriceModalFooter,
} from 'components';

interface IUser {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email: string;
}

interface IProductData {
  id: string;
  title: string;
  name: string;
  productVersion: string;
  licenceCode: string;
  licenseCode: string;
  years: string[];
  productCode: string;
  serialNumber: string;
  price: string;
  productId: string;
  year: string;
  groupName: string;
}

interface Props {
  visible: boolean;
  productData: IProductData;
  subscriptionId: string;
  isAdminPortal?: boolean;
  onOk: (assignedUsersIds: string[]) => void;
  onCancel: () => void;
}

const AssignUsersToAppModal: FC<Props> = ({
  visible,
  productData,
  subscriptionId,
  isAdminPortal,
  onOk,
  onCancel,
}) => {
  const {
    id,
    title,
    name, // different names on be
    productVersion,
    licenceCode,
    licenseCode, // issue on backend
    productCode,
    serialNumber,
    price,
    productId,
  } = productData;
  const { isLoading: isLoadingAssign } = useAppSelector(state => isAdminPortal
    ? state.ussp.adminAssignUsersToAppRequest
    : state.ussp.addAssignedUsersToCartRequest,
  );
  const { data = [], isLoading = false } = useAppSelector(state =>
    state.ussp.getAccountAppAssigningUsersDataRequest as { data: IUser[]; isLoading: boolean });
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAccountAppAssigningUsersData({
      subscriptionId,
      productId: id,
      isAdminPortal,
    })).unwrap();
  }, []);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredUsers = useMemo(() => data.filter(({ firstName, lastName, username, email }) => {
    const formatedSearchQuery = searchQuery.trim().toLowerCase();

    const formatedFirstName = firstName?.trim().toLowerCase();
    const formatedLastName = lastName?.trim().toLowerCase();
    const formatedUsername = username?.trim().toLowerCase();

    const formatedEmail = email.trim().toLowerCase();

    const isMatchFullName = formatedFirstName && formatedLastName
      && (`${formatedFirstName} ${formatedLastName}`.includes(formatedSearchQuery)
        || `${formatedLastName} ${formatedFirstName}`.includes(formatedSearchQuery));
    const isMatchUsername = formatedUsername && formatedEmail.includes(formatedUsername);
    const isMatchEmail = formatedEmail.includes(formatedSearchQuery);

    return isMatchFullName || isMatchUsername || isMatchEmail;
  }), [searchQuery, data]);

  const [assignedUsersIds, setAssignedUsersIds] = useState<string[]>([]);

  const toggleAll = (value: boolean): void => {
    const ids: string[] = value ? data.map(({ id }) => id) : [];
    setAssignedUsersIds(ids);
  };

  const toggleUser = (value: boolean, id: string): void => {
    const ids: string[] = value ? [...assignedUsersIds, id] : assignedUsersIds.filter(el => el !== id);
    setAssignedUsersIds(ids);
  };

  return (
    <SidebarModal
      classes="assign-users-to-app-modal"
      title="Assign New Users"
      visible={visible}
      isOkLoading={isLoadingAssign}
      isOkDisabled={assignedUsersIds.length === 0}
      onOk={() => onOk(assignedUsersIds)}
      okText={isAdminPortal ? 'Add' : 'Add to Cart'}
      footerAdditonal={
        !isAdminPortal
          ? <TotalPriceModalFooter price={+price} quantity={assignedUsersIds.length} />
          : null
      }
      cancelText="Cancel"
      onCancel={onCancel}
    >
      <div className="block-info relative-units rel-p-16 rel-m-b-24">
        <div className="block-info__content app-card disabled flex flex-align-center rel-flex-gap-16">
          <div className="app-card__content">
            <div className="app-card__name">
              {title || name}
            </div>
            <div className="app-card__id">{productVersion}</div>
          </div>
        </div>
      </div>
      {(licenceCode || licenseCode) && <div className="dashboard-accounts-app-assign__detail">
        Licence code: <span>{licenceCode || licenseCode}</span>
      </div>}
      {productCode && <div className="dashboard-accounts-app-assign__detail">
        Product code: <span>{productCode}</span>
      </div>}
      {serialNumber && <div className="dashboard-accounts-app-assign__detail">
        Serial number: <span>{serialNumber}</span>
      </div>}
      <hr className="dashboard-accounts-app-assign__divider" />
      <div className="dashboard-accounts-app-assign__apply-block flex-column">
        {
          (isLoading && (
            <div className="app-card disabled flex flex-align-center rel-flex-gap-24 rel-m-b-12 rel-p-l-24 rel-p-r-16 rel-p-y-12">
              <Skeleton containerClassName="app-card__icon-skeleton" height="100%" />
              <div className="app-card__content">
                <Skeleton containerClassName="app-card__name" height="100%" width="70%" />
                <Skeleton containerClassName="app-card__id" height="100%" />
              </div>
            </div>
          )) || (!data?.length && (
            <EmptyStateContainer
              title="There are no users"
              description="This product has already been assigned to all users"
              classes="flex-1"
            />
          )) || (
            <>
              <InputText
                value={searchQuery}
                classes="rel-m-b-16"
                label="Assign product to"
                labelRight={`${assignedUsersIds.length} selected`}
                placeholder="Search by Email or Name..."
                theme="grey"
                icon={SearchIcon}
                onChange={setSearchQuery}
              />
              {searchQuery === '' && (
                <div className="app-card disabled flex flex-align-center rel-flex-gap-24 rel-m-b-12 rel-p-l-24 rel-p-r-16 rel-p-y-12">
                  <Checkbox
                    isChecked={data.length > 0 && assignedUsersIds.length === data.length}
                    isIndeterminate={data.length > 0 && assignedUsersIds.length > 0 && assignedUsersIds.length < data.length}
                    onChange={(e) => toggleAll((e.target as HTMLInputElement).checked)}
                  />
                  <div className="app-card__content">
                    <div className="app-card__name flex flex-justify-between">
                      Apply to all users
                      <span>{data?.length || 0} users</span>
                    </div>
                  </div>
                </div>
              )}
              {filteredUsers.map(({ id, username, firstName, lastName, email }: IUser) => (
                <div
                  className="app-card disabled flex flex-align-center rel-flex-gap-24 rel-m-b-12 rel-p-l-24 rel-p-r-16 rel-p-y-12"
                  key={id}
                >
                  <Checkbox
                    isChecked={assignedUsersIds.includes(id)}
                    onChange={(e) => toggleUser((e.target as HTMLInputElement).checked, id)}
                  />
                  <div className="app-card__content">
                    <div className="app-card__name">{username || `${firstName} ${lastName}`}</div>
                    <div className="app-card__id">{email}</div>
                  </div>
                </div>
              ))}
              {filteredUsers.length <= 0 && (
                <EmptyStateContainer
                  title="There are no users"
                  description={`No users matched your search: "${searchQuery}"`}
                  classes="flex-1"
                />
              )}
            </>
          )
        }
      </div>
    </SidebarModal>
  );
};

export default AssignUsersToAppModal;
