import './Quickbooks.scss';
import React, { FC, useState, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
// import QuickbooksLeaseLicense from './components/QuickbooksLeaseLicense/QuickbooksLeaseLicense';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setQuickbooks,
  resetBreadCrumbsFrom,
  addProductsToOrder,
  deleteProductsFromOrder,
  resetMsOffice,
} from 'store';

import { usePriceHandler, notify, useSimpleFormNavigate, getEnvCountryCode, makeVh, makeVw } from 'helpers';

import { PRODUCT_CHARGIFY_TITLES } from 'consts';
import { ProductChargify, OrderNavigation, QuickbookOption, CountryCode } from 'enums';
import { IQuickbooks } from 'interfaces';

import { QbLogoNewIcon } from 'assets/svg';

import QuickbooksWithLicense from './components/QuickbooksWithLicense/QuickbooksWithLicense';
// import QuickbooksBuyLicense from './components/QuickbooksBuyLicense/QuickbooksBuyLicense';
import {
  Navigation,
  TotalPrice,
  RadioButton,
  MsOfficeQuestion,
  Logo,
} from 'components';

interface IQbookOptionItem {
  title: QuickbookOption;
  label: string | ReactNode;
  form?: ReactElement;
}

const PRODUCT_PRICE_FOR_OWN_OPTION_BY_COUNTRY = {
  [CountryCode.USA]: ProductChargify.QuickBooksEnterpriseDiamond,
  [CountryCode.Canada]: ProductChargify.QuickBooksPro,
  [CountryCode.Other]: ProductChargify.QuickBooksEnterpriseDiamond,
};

const Quickbooks: FC = () => {
  const dispatch = useAppDispatch();
  const currentCountryCode = useAppSelector(state => state.order.country?.code || CountryCode.USA);
  const envCountryCode = useMemo(() => getEnvCountryCode(currentCountryCode), [currentCountryCode]);
  const hostTotalQuantity = useAppSelector(state => state.order.hostProduct.quantity);
  const quickbooks = useAppSelector(state => state.order.quickbooks);
  const { getProductPrice } = usePriceHandler();
  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<IQuickbooks>({ mode: 'all', defaultValues: {
    ...quickbooks,
    option: QuickbookOption.Own,
  } });

  const [isAcceptLoading, setIsAcceptLoading] = useState<boolean>(false);
  const [isNotAcceptLoading, setIsNotAcceptLoading] = useState<boolean>(false);

  const isUserCountValid = useMemo(() => {
    return +(getValues())?.data?.quantity <= +hostTotalQuantity;
  }, [watch('data.quantity')]);

  useEffect(() => {
    if (!isUserCountValid) {
      notify.error('Sorry', `You can sign up to ${hostTotalQuantity} users`);
    }
  }, [isUserCountValid]);
  const QUICK_BOOKS_OPTIONS_CONFIG: IQbookOptionItem[] = useMemo(() => [
    {
      title: QuickbookOption.Own,
      label: (
        <div className="flex flex-justify-between">
          {PRODUCT_CHARGIFY_TITLES[QuickbookOption.Own]}
          <span className="fw-600 c-black no-wrap rel-m-l-4">
            {getProductPrice(PRODUCT_PRICE_FOR_OWN_OPTION_BY_COUNTRY[envCountryCode])}
          </span>
        </div>
      ),
      form: (
        <QuickbooksWithLicense
          control={control}
          register={register}
          errors={errors}
          isLimitUsersError={!isUserCountValid} />
      ),
    },
    // REMOVED FOR NOW
    // {
    //   title: QuickbookOption.Buy,
    //   label: (
    //     <div className="flex flex-justify-between">
    //       {PRODUCT_CHARGIFY_TITLES[QuickbookOption.Buy]}
    //       <span className="fw-600 c-black no-wrap rel-m-l-4">TBD</span>
    //     </div>
    //   ),
    //   form: (
    //     <QuickbooksBuyLicense
    //       control={control}
    //       register={register}
    //       errors={errors}
    //       isLimitUsersError={!isUserCountValid} />
    //   ),
    // },
    // {
    //   title: QuickbooksOption.Lease, // Set to Chargify products
    //   label: 'I want to lease a QuickBooks license from Summit',
    //   form: (
    //     <QuickbooksLeaseLicense
    //       control={control}
    //       register={register}
    //       errors={errors}
    //       isLimitUsersError={!isUserCountValid} />
    //   ),
    // },
  ], [watch()]);

  const STEP_PRODUCTS = useMemo(() => {
    const { data: { name, quantity } } = getValues();
    return name === null ? [] : [{ name, quantity }];
  }, [watch('data.quantity')]);

  const nextHandler = (msOfficeQuestionHandler: () => void, setLoaderHandler: (state: boolean) => void) => {
    const formHandler: SubmitHandler<IQuickbooks> = async data => {
      setLoaderHandler(true);
      try {
        const { data: { name, quantity, ...restData } } = data;
        const productData = {
          name,
          quantity: Number(quantity),
          customInfo: {
            ...restData,
            provideMetafields: 'true',
          },
        };
        if (quickbooks.data.name !== null && quickbooks.data.name !== name) {
          await dispatch(deleteProductsFromOrder([quickbooks.data.name])).unwrap();
        }
        await dispatch(addProductsToOrder([productData])).unwrap();
        dispatch(setQuickbooks({
          ...data,
          data: {
            name,
            quantity: `${Number(quantity)}`,
            ...restData,
          },
        }));
        msOfficeQuestionHandler();
      } finally {
        setLoaderHandler(true);
      }
    };
    return formHandler;
  };
  const simpleFormNavigate = useSimpleFormNavigate();
  const acceptHandler = () => {
    dispatch(resetBreadCrumbsFrom(OrderNavigation.OtherProducts));
    simpleFormNavigate(OrderNavigation.MsOffice);
  };
  const notAcceptHandler = () => {
    dispatch(resetMsOffice());
    dispatch(resetBreadCrumbsFrom(OrderNavigation.OtherProducts));
    simpleFormNavigate(OrderNavigation.OtherProducts);
  };

  return (
    <>
      <Logo />
      <div className="quickbooks page">
        <div className="page-left">
          <QbLogoNewIcon width={makeVw(452)} height={makeVh(254)} />
          <TotalPrice currentStep={OrderNavigation.Quickbooks} stepProducts={STEP_PRODUCTS} />
        </div>
        <div className="page-right">
          <Navigation currentStep={3} />

          <div className="page-right_content">
            <h1>QuickBooks</h1>
            <h4>The applications will be installed on your new Summit Hosting server.</h4>
            <form>
              <Controller
                name="option"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                shouldUnregister
                render={({
                  field: { value },
                }) => (
                  <>
                    {QUICK_BOOKS_OPTIONS_CONFIG.map((option, optionIndex) => (
                      <React.Fragment key={`q-book-option-${optionIndex}-${option.title}`}>
                        <RadioButton
                          value={option.title}
                          label={option.label}
                          isChecked={value === option.title}
                          onChange={() => {
                            reset({
                              option: option.title,
                              data: {
                                name: null,
                                quantity: '',
                                year: '',
                              },
                            });
                          }}
                        />
                        {value === option.title && option.form}
                      </React.Fragment>
                    ))}
                    {value !== null && (
                      <MsOfficeQuestion
                        isDisabled={!isValid || !isUserCountValid}
                        isAcceptLoading={isAcceptLoading}
                        isNotAcceptLoading={isNotAcceptLoading}
                        onAccept={handleSubmit(nextHandler(acceptHandler, setIsAcceptLoading))}
                        notAccept={handleSubmit(nextHandler(notAcceptHandler, setIsNotAcceptLoading))}
                      />
                    )}
                  </>
                )}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quickbooks;