import { FC } from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { InvoiceStatus } from "enums";

import SummitLogoNew from 'assets/images/summit-hosting-logo-new.png';

import dayjs from "dayjs";

interface IInvoiceData {
  id: string;
  number: string;
  issuedDate: string;
  dueDate: string;
  amountTotal: number;
  dueAmount: number;
  status: InvoiceStatus;
}

interface Props {
  companyName: string | undefined;
  chargifyId: string | undefined;
  invoiceDetails: IInvoiceData | null;
  customFields: any[];
}

const formatDate = (value: string): string => dayjs(value).format('MMM D, YYYY');

Font.register({
  family: 'Matter',
  src: '/fonts/Matter-Regular.ttf',
  fontWeight: 400,
});
Font.register({
  family: 'Matter',
  src: '/fonts/Matter-SemiBold.ttf',
  fontWeight: 600,
});

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Matter',
    padding: 35,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  nameWrapper: {
    width: 390,
  },
  logo: {
    width: 124,
    height: 30,
  },
  companyName: {
    fontSize: 28,
    fontWeight: 600,
    color: '#21272B',
  },
  companyId: {
    fontSize: 15,
    color: '#878D96',
  },
  blockInfo: {
    padding: 20,
    backgroundColor: 'rgba(242, 244, 248, 0.6)',
  },
  invoiceDate: {
    fontSize: 13,
    color: '#878D96',
  },
  invoiceName: {
    fontWeight: 600,
    color: '#21272B',
    fontSize: 15,
  },
  customFieldsList: {
    marginTop: 36,
  },
  customFieldLastItem: {
    borderBottom: 0,
  },
  customFieldItem: {
    borderBottom: 1,
    borderColor: '#EEF0F2',
    fontSize: 15,
    paddingVertical: 20,
  },
  customFieldName: {
    fontWeight: 600,
    color: '#21272B',
    fontSize: 15,
  },
  customFieldValue: {
    fontSize: 13,
    color: '#878D96',
  }
});

const CustomFieldsPDFTemplate: FC<Props> = ({
  invoiceDetails,
  companyName,
  chargifyId,
  customFields
}) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.header}>
        <View style={styles.nameWrapper}>
          <Text style={styles.companyName}>{companyName}</Text>
          <Text style={styles.companyId}>ID#{chargifyId}</Text>
        </View>
        <Image style={styles.logo} src={SummitLogoNew} />
      </View>
      <View style={styles.blockInfo}>
        <Text style={styles.invoiceName}>{invoiceDetails?.number}</Text>
        <Text style={styles.invoiceDate}>
          Date of invoice: {formatDate(invoiceDetails?.issuedDate || '')}
        </Text>
      </View>
      <View style={styles.customFieldsList}>
        {customFields.map((field, index) => (
          <View
            style={[
              styles.customFieldItem,
              ...(index === customFields.length - 1 ? [styles.customFieldLastItem] : [])
            ]}
            key={field.name}
          >
            <Text style={styles.customFieldValue}><Text style={styles.customFieldName}>{field.name}: </Text>{field.value}</Text>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default CustomFieldsPDFTemplate;