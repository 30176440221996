import './SetupUsersBodyRow.scss';
import React, { FC, useEffect, FormEvent, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { Controller, Control, UseFormRegister } from 'react-hook-form';
import { InputText, CheckboxButton, Select } from 'components';
import { WarningIcon, CheckCircleIcon } from 'assets/svg';
import { PHONE_PATTERN, PHONE_PATTERN_SIMPLE, EMAIL_PATTERN } from 'consts';
import { ProductChargify, UserRole } from 'enums';
import { useIsFirstRender } from 'helpers';
import { IOtherProduct } from 'interfaces';
import { useAppSelector } from 'store/hooks';

interface Props {
  superAdmin?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  mfaStatus: boolean;
  userRole: string | UserRole;
  phone: string;
  register: UseFormRegister<any>;
  trigger: any; // @todo change type
  control: Control<any>;
  errors: any;
  filledProducts: { [key: string]: boolean };
  touchedFields: any; // @todo change type
  userIndex: number;
  notUniquePhone: boolean;
  notUniqueEmail: boolean;
  activeSelectIndex: null | number;
  validatePhone: (userIndex: number) => void;
  validateEmail: (userIndex: number) => void;
  onUpdateValue: (name: any, value: any, config?: any) => void;
  onActiveAppSelect: (value: boolean) => void;
}

const DEFAULT_USER_PRODUCTS = [
  ProductChargify.SHServerUserHosting,
];

const SetupUsersBodyRow: FC<Props> = ({
  superAdmin = false,
  firstName,
  lastName,
  email,
  mfaStatus,
  userRole,
  phone,
  filledProducts,
  register,
  trigger,
  control,
  errors,
  touchedFields,
  userIndex,
  notUniquePhone,
  notUniqueEmail,
  activeSelectIndex,
  validatePhone,
  validateEmail,
  onUpdateValue,
  onActiveAppSelect,
}) => {
  const VALIDATION_RULES: { [key: string]: { pattern: RegExp; validate?: any; maxLength?: number } } = {
    email: {
      pattern: EMAIL_PATTERN,
      validate: {
        isUnique: () => {
          validateEmail(userIndex);
          return !notUniqueEmail;
        },
      },
    },
    phone: { pattern: PHONE_PATTERN, maxLength: 15 },
  };

  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (isFirstRender && userIndex > 0) {
      trigger(`assignProducts.${userIndex}.userInfo.firstName`);
      trigger(`assignProducts.${userIndex}.userInfo.lastName`);
      trigger(`assignProducts.${userIndex}.userInfo.email`);
    }
    if (!isFirstRender) {
      onUpdateValue(`assignProducts.${userIndex}.userInfo.phone`, phone, {
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [mfaStatus]);

  const otherProductsInfo: { [key: string]: IOtherProduct } = useAppSelector(
    state => state.products.otherProductsInfo,
  );
  const otherProducts = useAppSelector(state => state.order.otherProducts || []);

  const excludedSelectProducts = useMemo(() => {
    return [
      ...DEFAULT_USER_PRODUCTS,
      ...otherProducts.reduce((acc, { name }) => {
        if (otherProductsInfo[name].isSubscriptionProduct) {
          acc.push(name);
        }

        return acc;
      }, [] as string[]),
    ];
  }, [otherProducts]);

  const isDisabled = useMemo(() => {
    return activeSelectIndex !== null
    && activeSelectIndex !== userIndex;
  }, [activeSelectIndex, userIndex]);

  const getSelectOptions = useCallback((value) => {
    return Object.entries(filledProducts).reduce((acc, [key, filledProductValue]) => {
      const isFiltered = !excludedSelectProducts.includes(key as ProductChargify)
        && (!filledProductValue || value.includes(key));
      if (isFiltered) {
        acc.push(key);
      }
      return acc;
    }, [] as string[]);
  }, [filledProducts]);

  return (
    <div className={cn('table-row', { 'main-block': superAdmin, 'disabled-block': isDisabled })}>
      <div className="table-cell">
        <div className="user-count">{userIndex + 1}</div>
        <div className="user-count-sm">User {userIndex + 1}</div>
        {superAdmin ? (<div className="ssw-admin-role">Role: SSW Admin</div>) : null}
      </div>
      <div className="table-cell">
        <div className="table-mobile-label">First Name</div>
        <Controller
          name={`assignProducts.${userIndex}.userInfo.firstName`}
          control={control}
          rules={{
            required: true,
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, isTouched },
          }) => (
            <InputText
              dataTestId={`setup-users.first-name-input.row-${userIndex}`}
              xVersion
              absoluteStyles
              maxLength={58}
              placeholder=""
              isDisabledOpacity={isDisabled}
              value={value}
              isRequired
              isInvalid={isTouched && invalid}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>
      <div className="table-cell">
        <div className="table-mobile-label">Last Name</div>
        <Controller
          name={`assignProducts.${userIndex}.userInfo.lastName`}
          control={control}
          rules={{
            required: true,
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, isTouched },
          }) => (
            <InputText
              dataTestId={`setup-users.last-name-input.row-${userIndex}`}
              xVersion
              absoluteStyles
              maxLength={58}
              placeholder=""
              isDisabledOpacity={isDisabled}
              value={value}
              isRequired
              isInvalid={isTouched && invalid}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>
      <div className="table-cell">
        <div className="table-mobile-label">Phone Number</div>
        <Controller
          name={`assignProducts.${userIndex}.userInfo.phone`}
          control={control}
          defaultValue={''}
          rules={{
            required: mfaStatus,
            pattern: PHONE_PATTERN_SIMPLE,
            maxLength: 15,
            validate: {
              isUnique: () => !notUniquePhone,
            },
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid },
          }) => (
            <InputText
              dataTestId={`setup-users.phone-input.row-${userIndex}`}
              absoluteStyles
              xVersion
              placeholder={mfaStatus ? '' : '(Optional)'}
              isDisabledOpacity={isDisabled}
              value={value}
              isInvalid={invalid}
              onChange={(e: FormEvent<HTMLInputElement>) => {
                onChange(e);
                validatePhone(userIndex);
              }}
              onBlur={onBlur}
            />
            // <NumberFormat
            //   format={PHONE_FORMAT}
            //   mask={MASK_SYMBOL}
            //   customInput={InputText}
            //   placeholder={mfaStatus ? 'Required' : '(Optional)'}
            //   readOnly={userIndex === 0}
            //   isDisabledOpacity={isDisabled}
            //   value={value}
            //   isInvalid={isTouched && invalid}
            //   isReturnEvent
            //   onChange={(e: FormEvent<HTMLInputElement>) => {
            //     onChange(e);
            //     validatePhone(userIndex);
            //   }}
            //   onBlur={onBlur}
            // />
          )}
        />
      </div>
      <div className="table-cell">
        <div className="table-mobile-label">Email</div>
        <Controller
          name={`assignProducts.${userIndex}.userInfo.email`}
          control={control}
          defaultValue={userIndex === 0 ? email : ''}
          rules={{
            required: true,
            pattern: EMAIL_PATTERN,
            validate: {
              isUnique: () => !notUniqueEmail,
            },
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, isTouched },
          }) => (
            <InputText
              dataTestId={`setup-users.email-input.row-${userIndex}`}
              xVersion
              absoluteStyles
              placeholder=""
              maxLength={255}
              isDisabledOpacity={isDisabled}
              value={value}
              isRequired
              isInvalid={isTouched && invalid}
              onChange={(e) => {
                onChange(e.trim());
                validateEmail(userIndex);
              }}
              onBlur={onBlur}
            />
          )}
        />
      </div>
      <div className="table-cell">
        <div className="table-mobile-label">Applications</div>
        <Controller
          name={`assignProducts.${userIndex}.products`}
          control={control}
          defaultValue=""
          shouldUnregister
          render={({
            field: { onChange, value },
          }) => (
            <Select
              dataTestId={`setup-users.applications-select.row-${userIndex}`}
              absoluteStyles
              xVersion
              value={value}
              placeholder=""
              isDisabledOpacity={isDisabled}
              isRequired
              isMulti
              options={getSelectOptions(value)}
              onChange={(e) => {
                if (e === UserRole.Admin) {
                  onUpdateValue(`assignProducts.${userIndex}.userInfo.mfaStatus`, true);
                }
                onChange(e);
              }}
              onChangeDropdownState={onActiveAppSelect}
            />
          )}
        />
      </div>
      <div className="table-cell web-cell">
        <div className="table-mobile-label">MFA Status</div>
        <Controller
          name={`assignProducts.${userIndex}.userInfo.mfaStatus`}
          control={control}
          defaultValue={false}
          render={({
            field: { onChange, value },
          }) => (
            <CheckboxButton
              dataTestId={`setup-users.mfa-status-checkbox.row-${userIndex}`}
              absoluteStyles
              xVersion
              size="small"
              whiteReadOnly={superAdmin}
              noCheckedBorderColor={!superAdmin}
              label="MFA"
              isDisabled={userRole === UserRole.Admin}
              isDisabledOpacity={isDisabled}
              isChecked={value}
              onChange={(e: any) => onChange(e.target.checked)}
            />
          )}
        />
      </div>
      <div className="table-cell mobile-cell">
        <div className="table-mobile-label">MFA Status</div>
        <Controller
          name={`assignProducts.${userIndex}.userInfo.mfaStatus`}
          control={control}
          defaultValue={false}
          render={({
            field: { onChange, value },
          }) => (
            <CheckboxButton
              dataTestId={`setup-users.mfa-status-checkbox.row-${userIndex}`}
              absoluteStyles
              xVersion
              size="small"
              noCheckedBorderColor
              label="MFA"
              isDisabled={userRole === UserRole.Admin}
              isDisabledOpacity={isDisabled}
              isChecked={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="table-cell">
        <div className="table-mobile-label">Roles</div>
        {superAdmin ? (
          <div className="ssw-admin-role">SSW Admin</div>
        ) : (
          <Controller
            name={`assignProducts.${userIndex}.userInfo.userRole`}
            control={control}
            defaultValue=""
            rules={{ required: true }}
            shouldUnregister
            render={({
              field: { onChange, value },
            }) => (
              <Select
                dataTestId={`setup-users.role-select.row-${userIndex}`}
                absoluteStyles
                xVersion
                value={value}
                isDisabled={superAdmin}
                isDisabledOpacity={isDisabled}
                isRequired
                options={Object.values(UserRole)}
                onChange={(e) => {
                  if (e === UserRole.Admin) {
                    onUpdateValue(`assignProducts.${userIndex}.userInfo.mfaStatus`, true);
                  }
                  onChange(e);
                }}
                onChangeDropdownState={onActiveAppSelect}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default SetupUsersBodyRow;
