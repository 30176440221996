import './DashboardRouting.scss';
import { FC, useEffect, useMemo, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import cn from 'classnames';

import {
  getDashboardUser,
  setDashboardUser,
  getUsspCartData,
  getSubscriptionContractInfo,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setupSignalR } from 'helpers';

import { IDashboardUser } from 'interfaces';

import {
  BuyIcon,
  CategoryIcon,
  CategoryOutlineIcon,
  InfoCircleOutlineIcon,
  StarIcon,
  StarOutlineIcon,
  WalletIcon,
  WalletOutlineIcon,
} from 'assets/svg';

import Cart from '../Cart/Cart';

import { Error, Header, Sidebar } from 'components';
import {
  DashboardHome,
  DashboardAccount,
  DashboardAccountDetails,
  DashboardAccountUserProfile,
  DashboardBilling,
  DashboardBillingInvoices,
  DashboardBillingDetails,
} from 'pages';

interface INavigation {
  name: string;
  path: string;
  Icon: FC;
  ActiveIcon: FC;
  matchPattern?: string;
}
interface IRoute {
  path: string;
  Element?: FC;
  children?: IRoute[];
}

const DashboardRouting: FC = () => {
  const user = useAppSelector<IDashboardUser | null>(state => state.ussp.user);
  const isUserLoading = useAppSelector((state) => state.ussp.getDashboardUserRequest?.isLoading || false);

  const NAVIGATIONS: INavigation[] = [
    {
      name: 'Dashboard',
      path: '',
      Icon: CategoryOutlineIcon,
      ActiveIcon: CategoryIcon,
    },
    ...(process.env.REACT_APP_DISABLE_ACCOUNT_OVERVIEW !== 'true' ?
      [{
        name: 'Account overview',
        path: 'account-overview',
        Icon: StarOutlineIcon,
        ActiveIcon: StarIcon,
        matchPattern: 'user-portal/account-overview/*',
      }] : []),
    {
      name: 'Billing',
      path: 'billing',
      Icon: WalletOutlineIcon,
      ActiveIcon: WalletIcon,
      matchPattern: 'user-portal/billing/*',
      ...(user?.hasExpiredPayment
        ? { PosfixComponent: () => <InfoCircleOutlineIcon className="red-1 m-l-auto" /> }
        : {}
      ),
    },
  ];

  const ROUTES: IRoute[] = useMemo(() => [
    {
      path: '',
      Element: DashboardHome,
    },
    ...(process.env.REACT_APP_DISABLE_ACCOUNT_OVERVIEW !== 'true' ?
      [{
        path: 'account-overview',
        children: [
          {
            path: '',
            Element: DashboardAccount,
          },
          {
            path: ':subscriptionId/*',
            Element: DashboardAccountDetails,
          },
          {
            path: ':subscriptionId/users/:userId',
            Element: DashboardAccountUserProfile,
          },
        ],
      }] : []),
    {
      path: 'billing',
      children: [
        {
          path: '',
          Element: DashboardBilling,
        },
        {
          path: ':subscriptionId',
          Element: DashboardBillingDetails,
        },
        {
          path: ':subscriptionId/invoices',
          Element: DashboardBillingInvoices,
        },
      ],
    },
  ], []);
  const dispatch = useAppDispatch();

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);

  const getDashboardUserHandler = async () => {
    const data: IDashboardUser = await dispatch(
      getDashboardUser({ _isBackground: isBackgroundUpdate }),
    ).unwrap();
    dispatch(setDashboardUser(data));
  };

  useEffect(() => {
    const connection = setupSignalR('/userInfo');
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('Signal', async (message: any) => {
            setIsBackgroundUpdate(true);
            await getDashboardUserHandler();
            setIsBackgroundUpdate(false);
            console.log('USER_INFO:SIGNAL', message);
          });
          connection.on('PaymentExpirationChanged', async (message: any) => {
            setIsBackgroundUpdate(true);
            await getDashboardUserHandler();
            setIsBackgroundUpdate(false);
            console.log('USER_INFO:PAYMENT_EXPIRIATION_CHANGED', message);
          });
        })
        .catch((e: any) => console.log('Connection failed [userInfo]: ', e));
    }

    getDashboardUserHandler();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const { data: cartData } = useAppSelector(state => state.ussp.getUsspCartDataRequest || {});
  useEffect(() => {
    dispatch(getUsspCartData()).unwrap();
  }, []);
  const cartItemsCount = useMemo(() => {
    const userAssignmentsCount = cartData?.userAssignments?.length ?? 0;
    const addedApplicationsCount = cartData?.addedApplications?.length ?? 0;
    const newUsersCount = cartData?.newUsers?.length ?? 0;
    const editUsersCount = cartData?.editUsers?.length ?? 0;
    const applicationAssignmentsCount = cartData?.applicationAssignments?.length ?? 0;
    const upgradedApplicationsCount = cartData?.upgradedApplications?.length ?? 0;
    return userAssignmentsCount
      + addedApplicationsCount
      + newUsersCount
      + editUsersCount
      + applicationAssignmentsCount
      + upgradedApplicationsCount;
  }, [
    cartData?.userAssignments?.length,
    cartData?.addedApplications?.length,
    cartData?.newUsers,
    cartData?.editUsers,
    cartData?.applicationAssignments,
    cartData?.upgradedApplications?.length,
  ]);
  const [isShowCart, setIsShowCart] = useState<boolean>(false);
  const openCart = () => {
    setIsShowCart(true);
  };
  const closeCart = () => {
    setIsShowCart(false);
  };
  useEffect(() => {
    if (cartItemsCount === 0 && isShowCart) {
      closeCart();
    }
  }, [cartItemsCount]);

  useEffect(() => {
    if (cartData?.subscriptionId) {
      dispatch(getSubscriptionContractInfo({
        subscriptionId: cartData?.subscriptionId,
      })).unwrap();
    } else {
      closeCart();
    }
  }, [cartData?.subscriptionId]);

  return (
    <div className={cn('dashboard-layout', { 'collapsed-sidebar': isSidebarCollapsed })}>
      <Sidebar
        gridArea="app-sidebar"
        isCollapsed={isSidebarCollapsed}
        navigationMap={NAVIGATIONS}
        onToggleMenu={toggleSidebar}
      />
      <Header gridArea="app-header" isLoading={isUserLoading && !isBackgroundUpdate} />
      <main>
        <Routes>
          {ROUTES.map(({ path, Element, children }) => (
            <Route key={`dashboard-route-${path}`} path={path} element={Element && <Element />}>
              {children?.map(({ path, Element: ChildElement }) => (
                <Route key={`dashboard-child-route-${path}`} path={path} element={ChildElement && <ChildElement />} />
              ))}
            </Route>
          ))}
          <Route path="*" element={(
            <Error
              code={404}
              buttonLabel="Go to Homepage"
              buttonRedirectPath="/dashboard"
              isShowButton
            />
          )} />
        </Routes>
      </main>
      {cartData?.subscriptionId && cartItemsCount > 0 && (
        <div
          className="cart-icon-wrapper flex flex-align-center flex-justify-center"
          onClick={openCart}
        >
          <BuyIcon />
          <div
            className="cart-icon-wrapper__items-count flex flex-align-center flex-justify-center"
          >{cartItemsCount}</div>
        </div>
      )}
      <Cart visible={isShowCart} onCancel={closeCart} />
    </div>
  );
};

export default DashboardRouting;