import { FC, ReactNode, SyntheticEvent } from 'react';
import cn from 'classnames';
import { Radio } from 'components';
import './RadioButton.scss';

interface Props {
  xVersion?: boolean;
  xVersion2?: boolean;
  absoluteStyles?: boolean;
  isChecked: boolean;
  hideRadio?: boolean;
  disableBold?: boolean;
  label: string | ReactNode;
  LeftIcon?: FC;
  value?: string | number | null;
  rightLabel?: string;
  dataTestId?: string;
  classes?: string;
  onChange: (e: SyntheticEvent) => void;
}

const RadioButton: FC<Props> = ({
  isChecked,
  hideRadio,
  label,
  value,
  dataTestId,
  onChange,
  rightLabel,
  classes,
  LeftIcon,
  xVersion,
  xVersion2,
  disableBold = false,
  absoluteStyles = false,
}) => {
  const labelClasses = {
    checked: isChecked,
    'with-rl': rightLabel,
  };

  return (
    <label
      data-test-id={dataTestId}
      className={cn(
        'radio-button',
        classes,
        labelClasses,
        {
          'new-style': !xVersion && !xVersion2,
          'relative-units': !absoluteStyles,
          'no-bold': disableBold,
          'xVersion': xVersion,
          'xVersion2': xVersion2
        }
      )}
    >
      {hideRadio && LeftIcon && <div className="left-radio-icon"><LeftIcon/></div>}
      <Radio
        hideRadio={hideRadio}
        absoluteStyles={absoluteStyles}
        isChecked={isChecked}
        label={label}
        value={value}
        onChange={onChange}
      />
      {rightLabel && <div className="right-label">{rightLabel}</div>}
    </label>
  );
};

export default RadioButton;