import './PaymentMethodBlock.scss';
import { FC, useMemo } from 'react';
import cn from 'classnames';

import {
  BankIcon,
  VisaIcon,
  MasterCardIcon,
  AmericanExpressIcon,
  DiscoverIcon,
  DinersClubIcon,
  MaestroIcon,
  JcbIcon,
  UnionPayIcon,
} from 'assets/svg';

import { CardType, PaymentMethod } from 'enums';

export const PAYMENT_CARD_TITLES: { [key: string]: string } = {
  [CardType.MasterCard]: 'MasterCard',
  [CardType.Visa]: 'Visa',
  [CardType.AmericanExpress]: 'American Express',
  [CardType.Discover]: 'Discover',
  [CardType.DinersClub]: 'Diners Club',
  [CardType.Jcb]: 'Jcb',
  [CardType.Maestro]: 'Maestro',
  [CardType.UnionPay]: 'UnionPay',
};

export const PAYMENT_CARD_ICONS: { [key: string]: FC } = {
  [CardType.MasterCard]: MasterCardIcon,
  [CardType.Visa]: VisaIcon,
  [CardType.AmericanExpress]: AmericanExpressIcon,
  [CardType.Discover]: DiscoverIcon,
  [CardType.DinersClub]: DinersClubIcon,
  [CardType.Jcb]: JcbIcon,
  [CardType.Maestro]: MaestroIcon,
  [CardType.UnionPay]: UnionPayIcon,
};

interface Props {
  alwaysShowEdit?: boolean;
  creditCardType: string;
  editCardUrl: string;
  isCardExpired: boolean;
  paymentMethod: PaymentMethod;
  paymentNumber: string;
}

const PaymentMethodBlock: FC<Props> = ({
  alwaysShowEdit,
  creditCardType,
  editCardUrl,
  isCardExpired,
  paymentMethod,
  paymentNumber,
}) => {
  if (paymentMethod === null) {
    return null;
  }
  const imageComponentsByPaymentMethod = useMemo(() => ({
    [PaymentMethod.BankAccount]: () => <BankIcon className="payment-method-block__bank-icon" />,
    [PaymentMethod.CreditCard]: PAYMENT_CARD_ICONS[creditCardType],
  }), [creditCardType]);

  const namesByPaymentMethod = useMemo(() => ({
    [PaymentMethod.BankAccount]: 'Bank Account',
    [PaymentMethod.CreditCard]: PAYMENT_CARD_TITLES[creditCardType],
  }), [creditCardType]);

  const Image = useMemo(() => imageComponentsByPaymentMethod[paymentMethod], [paymentMethod]);

  const isShowEdit = useMemo(() => alwaysShowEdit || isCardExpired, [alwaysShowEdit, isCardExpired]);

  const onEditClick = () => {
    window.open(editCardUrl, '_blank');
  };

  return (
    <div className={cn(
      'payment-method-block app-card disabled flex flex-align-center rel-flex-gap-16',
      { 'error': isCardExpired },
    )}>
      <div className="payment-method-block__icon-wrapper">{Image && <Image />}</div>
      <div className="app-card__content">
        <div className="app-card__name">{namesByPaymentMethod[paymentMethod]}</div>
        <div className="app-card__id">**** {paymentNumber?.slice(paymentNumber.length - 4)}</div>
      </div>
      {isShowEdit && (
        <button
          className={cn('btn relative-units small new-style', { negative: isCardExpired, primary: !isCardExpired })}
          onClick={onEditClick}
        >Edit</button>
      )}
    </div>
  );
};

export default PaymentMethodBlock;