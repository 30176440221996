import React, { FC } from 'react';
import { ITableFilterOrdering } from 'interfaces';
import { TableOrder } from 'components';

interface Props {
  orderState?: ITableFilterOrdering;
  onOrderChange: (data: ITableFilterOrdering) => void;
}

const InvoicesTableHeadRow: FC<Props> = ({
  orderState,
  onOrderChange,
}) => (
  <tr className="users-table-head-row">
    <th>NUMBER</th>
    <th>
      <TableOrder
        label="ISSUED"
        fieldName="IssueDate"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>
      <TableOrder
        label="DUE DATE"
        fieldName="DueDate"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>TOTAL AMOUNT</th>
    <th>AMOUNT DUE</th>
    <th>STATUS</th>
    <th>ACTIONS</th>
  </tr>
);

export default InvoicesTableHeadRow;
