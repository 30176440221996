import { FC } from 'react';
import './Textarea.scss';
import { InfoCircleIcon } from 'assets/svg';

interface Props {
  label?: string;
  classes?: string;
  placeholder: string;
  isRequired?: boolean;
  withInfo?: boolean;
  rows?: number;
  value: string;
  isInvalid?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const Textarea: FC<Props> = (
  {
    label,
    classes,
    placeholder,
    isRequired,
    withInfo,
    rows = 2,
    value,
    isInvalid,
    onChange,
    onBlur,
  }) => (
  <div className={`textarea ${isInvalid ? 'invalid' : ''} ${classes ? classes : ''}`}>
    {label && (
      <label>
        { label }
        { isRequired && <span className="req">*</span> }
        { withInfo && <InfoCircleIcon /> }
      </label>
    )
    }
    <textarea
      rows={rows}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
    ></textarea>
  </div>
);

export default Textarea;