import './AdditionalServices.scss';
import { FC, FormEvent, useState, useMemo, Fragment } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAdditionalServices, addProductsToOrder, deleteProductsFromOrder } from 'store';

import { useSimpleFormNavigate, usePriceHandler } from 'helpers';
import { PRODUCT_CHARGIFY_TITLES } from 'consts';
import { OrderNavigation, ProductChargify } from 'enums';

import CloudImage from 'assets/images/cloud.png';

import {
  CheckboxButton,
  Navigation,
  TotalPrice,
  RadioButton,
  OrderNextButton,
  Logo,
} from 'components';

const BACKUP_RETENTION_PRODUCTS: ProductChargify[] = [
  ProductChargify.BackupRetentionIncrease1Year,
  ProductChargify.BackupRetentionIncrease2Year,
  ProductChargify.BackupRetentionIncrease3Year,
];

const GROUPED_ADDITIONAL_SERVICES = [
  BACKUP_RETENTION_PRODUCTS,
  ProductChargify.VPN,
  ProductChargify.ExternalStaticIPAddress,
];

const getGroupTitle = (productNames: ProductChargify[]): string => {
  if (productNames.some(name => BACKUP_RETENTION_PRODUCTS.includes(name as ProductChargify))) {
    return 'Extends Backup Retention for up to 100 GB of data';
  }

  return 'Group of products';
};

const AdditionalServices: FC = () => {
  const dispatch = useAppDispatch();

  const { getProductPrice } = usePriceHandler();

  const additionalServicesState = useAppSelector(state => state.order.additionalServices);
  const additionalServicesStateNames = useMemo(
    () => additionalServicesState.map(({ name }) => name as ProductChargify),
    [additionalServicesState],
  );
  const [selectedServices, setSelectedServices] = useState<ProductChargify[]>(additionalServicesStateNames);
  const toggleServices = (newValue: ProductChargify | null, valuesToRemove: ProductChargify[]): void => {
    if (valuesToRemove.length) {
      setSelectedServices(selectedServices.filter(name => !valuesToRemove.includes(name)));
    }

    if (newValue !== null) {
      setSelectedServices(prevValue => [...prevValue, newValue]);
    }
  };
  const STEP_PRODUCTS = useMemo(
    () => selectedServices.map(name => ({ name, quantity: '1' })),
    [selectedServices],
  );
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const simpleFormNavigate = useSimpleFormNavigate();
  const nextHandler = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setIsNextLoading(true);
    try {
      const deletedServices = additionalServicesStateNames.filter(
        name => !selectedServices.includes(name as ProductChargify),
      );
      if (deletedServices.length > 0) {
        await dispatch(deleteProductsFromOrder(deletedServices)).unwrap();
      }
      const newServices = selectedServices.filter(
        name => !additionalServicesStateNames.includes(name as ProductChargify),
      );
      if (newServices.length > 0) {
        await dispatch(addProductsToOrder(
          newServices.map(name => ({ name, quantity: 1 })),
        )).unwrap();
      }
      dispatch(setAdditionalServices(selectedServices));
      simpleFormNavigate(OrderNavigation.SecureWorkspace);
    } finally {
      setIsNextLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <div className="additional-services page">
        <div className="page-left">
          <img src={CloudImage} alt="cloud" />
          <TotalPrice
            currentStep={OrderNavigation.AdditionalServices}
            stepProducts={STEP_PRODUCTS}
          />
        </div>
        <div className="page-right">
          <Navigation currentStep={7} />

          <div className="page-right_content">
            <h1>Additional Services</h1>
            <p>
              Meet your compliance or data retention requirements by extending your backup retention.
              This feature lets you keep your vital data for a longer time, ensuring more peace of mind
              and thorough data protection.
            </p>
            <form onSubmit={nextHandler}>
              {GROUPED_ADDITIONAL_SERVICES.map((value, index) => {
                const isGroup = Array.isArray(value);
                const isChecked = selectedServices.some(
                  name => isGroup ? value.includes(name) : value === name,
                );
                return (
                  <Fragment key={index}>
                    <CheckboxButton
                      isChecked={isChecked}
                      label={isGroup
                        ? getGroupTitle(value)
                        : `${PRODUCT_CHARGIFY_TITLES[value]} (${getProductPrice(value)}/month)`
                      }
                      classes="rel-m-b-16"
                      onChange={() => toggleServices(
                        isChecked ? null : isGroup ? value[0] : value,
                        isGroup ? value : [value],
                      )}
                    />
                    {isGroup && isChecked && value.map((name, index) => (
                      <RadioButton
                        value={name}
                        key={name}
                        label={`${PRODUCT_CHARGIFY_TITLES[name]} (${getProductPrice(name)}/month)`}
                        isChecked={selectedServices.includes(name)}
                        classes={index + 1 === value.length ? 'rel-m-b-32' : ''}
                        onChange={() => toggleServices(name, value)}
                      />
                    ))}
                  </Fragment>
                );
              })}
              <OrderNextButton
                isLoading={isNextLoading}
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalServices;