import './StatisticsGraph.scss';
import React, { FC, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  data: { name: string; count: number }[];
  isLoading?: boolean;
}

const LOADERS_PARAMS = [
  [70, 70],
  [54, 50],
  [84, 100],
  [55, 68],
  [100, 24],
  [85, 100],
  [45, 48],
  [55, 59],
];

const StatisticsGraph: FC<Props> = ({ data, isLoading = false }) => {
  const maxValue = useMemo(() => Math.max(...data.map(({ count }) => count)), [data]);
  const calculatePercentage = (currentValue: number): number => currentValue * 100 / maxValue;
  const calculateOpacity = (index: number): number => (index < 6 ? 1 - index / 10 : 0.4);

  return (
    <div className="statistic-graph">
      {
        isLoading ? LOADERS_PARAMS.map(([nameWidth, countWidth], index) => (
          <React.Fragment key={`statistic-graph-skeleton-${index}`}>
            <Skeleton containerClassName="statistic-graph__name statistic-graph__name-skeleton" height="100%" width={`${nameWidth}%`} />
            <Skeleton containerClassName="statistic-graph__progress-skeleton" height="100%" width={`${countWidth}%`} />
          </React.Fragment>
        )) : data.map((dataItem, index) => (
          <React.Fragment key={`statistic-graph-${index}`}>
            <span className="statistic-graph__name">{dataItem.name}</span>
            <div className="statistic-graph__progress">
              <div style={{
                width: `${calculatePercentage(dataItem.count)}%`,
                opacity: calculateOpacity(index),
              }}></div>
              <span>{dataItem.count}</span>
            </div>
          </React.Fragment>
        ))
      }
    </div>
  );
};

export default StatisticsGraph;
