import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addUserMfa } from 'store';

import { notify } from 'helpers';

import { InfoCircleOutlineIcon } from 'assets/svg';

import { SidebarModal } from 'components';

interface Props {
  visible: boolean;
  userData: { id: string; userName: string; email: string };
  onCancel: () => void;
  onSuccess: () => void;
}

const AddMfaSidebarModal: FC<Props> = ({
  visible,
  userData,
  onCancel,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.ussp.assignApplicationToUserRequest);

  const { subscriptionId } = useParams();

  const submitHandler = async () => {
    if (typeof subscriptionId === 'string' && typeof userData?.id === 'string') {
      await dispatch(addUserMfa({
        subscriptionId,
        userIds: [userData.id],
      })).unwrap();
      notify.success('Success', 'MFA successfully added to user');
      onCancel();
      onSuccess();
    }
  };

  return (
    <SidebarModal
      title="Add MFA"
      visible={visible}
      onCancel={onCancel}
      onOk={submitHandler}
      okText="Add"
      cancelText="Cancel"
      isOkLoading={isLoading}
      contentClasses="flex-column"
    >
      <div className="block-info relative-units block-info--small rel-m-b-16 app-card">
        <div className="app-card__content">
          <div className="app-card__name app-card__name--small">{userData?.userName}</div>
          <div className="app-card__id">{userData?.email}</div>
        </div>
      </div>
      <div className="block-info relative-units block-info--small">
        <InfoCircleOutlineIcon />
        <p className="block-info__content assign-application-info__content">
          By adding MFA, you are adding admin rights to the server for this user.&nbsp;
          <strong>Are you sure want to continue?</strong>
        </p>
      </div>
    </SidebarModal>
  );
};

export default AddMfaSidebarModal;