import './DashboardBilling.scss';
import { FC, useState, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getBillingsData, getBillingStatusesData } from 'store';
import { setupSignalR } from 'helpers';

import { SUPPORT_LINK } from 'consts';

import { InfoCircleIcon, InfoCircleOutlineIcon } from 'assets/svg';

import BillingsTableHeadRow from './components/BillingsTableHeadRow';
import BillingsTableBodyRow from './components/BillingsTableBodyRow';
import BillingsTableBodyRowLoader from './components/BillingsTableBodyRowLoader';
import { Alert, Error, Modal, Pagination, EmptyStateContainer } from 'components';
import { useSearchParams } from 'react-router-dom';

const DashboardBilling: FC = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getBillingsDataRequest);
  const {
    data: statusesInfosData,
  } = useAppSelector(state => state.ussp.getBillingStatusesDataRequest);
  const user = useAppSelector(state => state.ussp.user);
  const [isShowCancellationModal, setIsShowCancellationModal] = useState<boolean>(false);
  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    statuses: '[]',
  });

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryStatuses = useMemo(() => {
    return JSON.parse(query.get('statuses') || '[]');
  }, [query]);

  const showCancellationModal = () => {
    setIsShowCancellationModal(true);
  };

  const hideCancellationModal = () => {
    setIsShowCancellationModal(false);
  };

  const fetchData = async (
    isBackgroundUpdate?: boolean,
  ) => {
    const data = await dispatch(getBillingsData({
      page: queryPage,
      pageSize: queryPageSize,
      statuses: queryStatuses,
      _disableErrorHandler: true,
      _isBackground: isBackgroundUpdate,
    })).unwrap();
    setTotalPages((data?.totalPages as number) || 1);
  };
  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(true);
    setIsBackgroundUpdate(false);
  };

  const onFilterByStatusesChange = (filters: string[]) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('statuses', JSON.stringify(filters));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  useEffect(() => {
    const connection = setupSignalR('/billing');
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('Signal', async (message: any) => {
            await backgroundFetch();
            console.log('BILLING:SIGNAL', message);
          });

          connection.on('PaymentExpirationChanged', async (message: any) => {
            await backgroundFetch();
            console.log('BILLING:PAYMENT_EXPIRIATION_CHANGED', message);
          });
        })
        .catch((e: any) => console.log('Connection failed [billing]: ', e));
    }

    dispatch(getBillingStatusesData()).unwrap();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [query]);

  if (error) {
    return <Error code={500} />;
  }

  return (
    <div className="dashboard-billing ussp-page">
      <section className="dashboard-billing__header ussp-page__header">
        <h1>Billing</h1>
        <button
          className="btn relative-units primary medium new-style"
          onClick={showCancellationModal}
        >
          Cancellation Information
        </button>
      </section>
      <section className="dashboard-billing__content">
        {user?.hasExpiredPayment && (
          <Alert
            classes="rel-m-t-20 rel-m-b-24 rel-m-x-32"
            CustomIcon={InfoCircleOutlineIcon}
            type="error"
            message="Your current Credit Card(s) has expired. Please add a valid Credit Card(s)."
          />
        )}
        <table className="Billings-table">
          <thead>
            <BillingsTableHeadRow
              statusesValue={queryStatuses}
              statuses={statusesInfosData}
              onFilterByStatusesChange={onFilterByStatusesChange}
            />
          </thead>
          <tbody>
            {isLoading && !isBackgroundUpdate
              ? <BillingsTableBodyRowLoader />
              : data?.billings.map((el: any, index: number) => (
                <BillingsTableBodyRow key={`Billings-${index}`} {...el} />
              ))
            }
          </tbody>
        </table>
        {
          data?.billings.length === 0
            ? <EmptyStateContainer
              title="No billings found"
              description="No billings found with specified parameters"
              classes="empty-state-container-table"
            />
            : null
        }
      </section>
      {
        data?.billings.length > 0
          ? <Pagination
            current={queryPage}
            entries={queryPageSize}
            totalPages={totalPages}
            onChange={onPaginationChange}
          />
          : null
      }
      <Modal
        title="Cancellation Information"
        visible={isShowCancellationModal}
        onOk={hideCancellationModal}
        okText="Okay"
        cancelText={null}
        onCancel={hideCancellationModal}
        width={535}
      >
        <div className="block-info relative-units cancellation-info">
          <InfoCircleIcon />
          <div className="block-info__content cancellation-info__content">
            If you would like to cancel you account,&nbsp;
            <a
              className="link relative-units without-decorations cancellation-info__link"
              href={SUPPORT_LINK}
              target="_blank"
            >submit a ticket here</a>,
            and a member of our Account Management Team will reach out to discuss
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DashboardBilling;