import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IHttpRequestResult } from 'interfaces';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers';

export const getCountries = createAsyncThunk<any>(
  'order/getCountries',
  async () => {
    const response = await http.get('/bff/users/countries');
    return response;
  },
);

export const getCountryStates = createAsyncThunk<any, { countryCode: string }>(
  'order/getCountryStates',
  async ({ countryCode }) => {
    const response = await http.get(`/bff/users/countries/${countryCode}/states`);
    return response;
  },
);

interface CountryState {
  getCountriesRequest: IHttpRequestResult<any>;
  getCountryStatesRequest: IHttpRequestResult<any>;
}

const initialState: CountryState = {
  getCountriesRequest: createHttpRequestInitResult(),
  getCountryStatesRequest: createHttpRequestInitResult(),
};

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers: {
    ...createExtraReducersForResponses(getCountries, 'getCountriesRequest'),
    ...createExtraReducersForResponses(getCountryStates, 'getCountryStatesRequest'),
  },
});

export default countrySlice.reducer;
