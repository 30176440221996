import './Cart.scss';
import { FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { makeVw, notify, usePriceHandler } from 'helpers';
import {
  completeUsspCart,
  clearUsspCart,
  getUsspCartData,
  removeAssignedUserFromCart,
  removeAssignedUsersFromCart,
  removeApplicationFromCart,
  removeApplicationUserFromCart,
  removeUpgradeProductFromCart,
  removeNewUserFromCart,
  removeNewUserAssignAppFromCart,
  removeUserAssignedAppsFromCart,
  removeUserAssignedAppFromCart,
  removeEditUserFromCart,
  removeEditUsersFromCart,
  getSageProducts,
  getOtherProducts,
  getQuickbooksProducts,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IDashboardUser } from 'interfaces';
import { CountryCode, ProductChargify } from 'enums';

import {
  AddUserIcon,
  ArrowsUpIcon,
  CubeIcon,
  SwitchHorizontalIcon,
  UserCheckIcon,
  ThreeCubesIcon,
} from 'assets/svg';

import {
  Checkbox,
  SidebarModal,
  Signature,
} from 'components';

import CartSection from '../CartSection/CartSection';
import CartSectionItem from '../CartSectionItem/CartSectionItem';

const SLA_LINK = 'https://www.summithosting.com/services-agreement/';

interface IUserData {
  email: string;
  userId: string;
}

interface IUserAssignment {
  name: ProductChargify;
  productId: string;
  productPrice: number;
  users: IUserData[];
}

interface IAddedApplications {
  componentId: number;
  name: string;
  price: number;
  quantity: number;
  year: number;
  users: IUserData[];
}

interface INewUsers {
  email: string;
  products: {
    id: string;
    name: string;
    price: number;
  }[];
}

interface IEditUsers {
  userId: string;
  email: string;
  price: number;
}

interface IApplicationAssignments {
  userId: string;
  email: string;
  price: number;
  applications: {
    id: string;
    name: string;
    price: number;
  }[];
}

interface IUpgradedApplications {
  productId: string;
  productName: string;
  productPrice: number;
}

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const Cart: FC<Props> = ({
  visible,
  onCancel,
}) => {
  const user = useAppSelector<IDashboardUser | null>(state => state.ussp.user);
  const { data } = useAppSelector(state => state.ussp.getUsspCartDataRequest || {});
  const { isLoading } = useAppSelector(state => state.ussp.completeUsspCartRequest || { isLoading: false });
  const { data: contractData, isLoading: isLoadingContractData } = useAppSelector(
    state => state.ussp.getSubscriptionContractInfoRequest || {},
  );
  const { formatPrice } = usePriceHandler();

  const contractStartDateText = dayjs().format('MMM D, YYYY');
  const [signatureValue, setSignatureValue] = useState<string>('');
  const [isContractChecked, setIsContractChecked] = useState<boolean>(false);
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState<boolean>(false);
  const isValid = useMemo(
    () => Boolean(signatureValue) && isContractChecked && isTermsAndConditionsChecked,
    [signatureValue, isContractChecked, isTermsAndConditionsChecked],
  );
  const isDisabledSubmitButton = useMemo(
    () => isLoadingContractData || (!contractData?.isActive && !isValid),
    [isLoadingContractData, contractData?.isActive, isValid],
  );

  const isShowSignatureBlock = useMemo(
    () => !isLoadingContractData && !contractData?.isActive,
    [isLoadingContractData, contractData?.isActive],
  );

  const closeCartHandler = () => {
    setSignatureValue('');
    setIsContractChecked(false);
    setIsTermsAndConditionsChecked(false);
    onCancel();
  };

  const dispatch = useAppDispatch();

  const getProductsInformation = () => { // Get products for add application modal
    const { subscriptionId } = data || {};
    if (typeof subscriptionId === 'string') {
      dispatch(getSageProducts({ subscriptionId })).unwrap();
      dispatch(getOtherProducts({ subscriptionId })).unwrap();
      dispatch(getQuickbooksProducts({ IsOwn: true, IsPurchase: false, subscriptionId })).unwrap();
    }
  };

  const completeCartHandler = async () => {
    await dispatch(completeUsspCart({
      eSign: contractData?.isActive ? contractData.eSign : signatureValue,
    })).unwrap();
    notify.success('Your order was successful now', 'Thank You for Choosing Summit Hosting!');

    // After successfully completing cart actions
    if (data?.addedApplications?.length > 0) {
      getProductsInformation();
    }

    dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    closeCartHandler();
  };

  const clearCartHandler = async () => {
    await dispatch(clearUsspCart()).unwrap();
    dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    closeCartHandler();
  };

  const removeAssignedUserHandler = async (productId: string, userId: string) => {
    await dispatch(removeAssignedUserFromCart({ productId, userId })).unwrap();
    dispatch(getUsspCartData({ _isBackground: true })).unwrap();
  };

  const removeAssignedUsersHandler = async (productId: string) => {
    await dispatch(removeAssignedUsersFromCart({ productId })).unwrap();
    dispatch(getUsspCartData({ _isBackground: true })).unwrap();
  };

  const removeApplicationHandler = async (componentId: number) => {
    if (typeof data?.subscriptionId === 'string') {
      await dispatch(removeApplicationFromCart({
        subscriptionId: data?.subscriptionId,
        componentId,
      })).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    }
  };

  const removeApplicationUserHandler = async (componentId: number, userId: string) => {
    if (typeof data?.subscriptionId === 'string') {
      await dispatch(removeApplicationUserFromCart({
        subscriptionId: data?.subscriptionId,
        componentId,
        userId,
      })).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    }
  };

  const removeUpgradeProductHandler = async (productId: string) => {
    if (typeof data?.subscriptionId === 'string') {
      await dispatch(removeUpgradeProductFromCart({
        subscriptionId: data?.subscriptionId,
        productId,
      })).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    }
  };

  const removeNewUserHandler = async (email: string) => {
    await dispatch(removeNewUserFromCart({ email })).unwrap();
    dispatch(getUsspCartData({ _isBackground: true })).unwrap();
  };

  const removeNewUserAssignAppHandler = async (productId: string, email: string) => {
    if (typeof data?.subscriptionId === 'string') {
      await dispatch(removeNewUserAssignAppFromCart({
        subscriptionId: data?.subscriptionId,
        productId,
        email,
      })).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    }
  };

  const removeUserAssignedAppsHandler = async (userId: string) => {
    if (typeof data?.subscriptionId === 'string') {
      await dispatch(removeUserAssignedAppsFromCart({
        subscriptionId: data?.subscriptionId,
        userId,
      })).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    }
  };

  const removeUserAssignedAppHandler = async (userId: string, productId: string) => {
    if (typeof data?.subscriptionId === 'string') {
      await dispatch(removeUserAssignedAppFromCart({
        subscriptionId: data?.subscriptionId,
        userId,
        productId,
      })).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    }
  };

  const removeEditUserHandler = async (userId: string) => {
    if (typeof data?.subscriptionId === 'string') {
      await dispatch(removeEditUserFromCart({
        subscriptionId: data?.subscriptionId,
        userId,
      })).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    }
  };

  const removeEditUsersHandler = async () => {
    if (typeof data?.subscriptionId === 'string') {
      await dispatch(removeEditUsersFromCart()).unwrap();
      dispatch(getUsspCartData({ _isBackground: true })).unwrap();
    }
  };

  const editUsersTotalPrice = useMemo<number>(
    () => (data?.editUsers || []).reduce(
      (acc: number, cur: IEditUsers): number => acc + cur.price,
      0,
    ),
    [data?.editUsers],
  );
  const formatedTotalPrice = useMemo(() => {
    const userAssignmentsPrice: number = (data?.userAssignments || [])
      .reduce((acc: number, cur: IUserAssignment): number => acc + cur.productPrice * cur.users.length, 0);

    const addedApplicationsPrice: number = (data?.addedApplications || [])
      .reduce((acc: number, cur: IAddedApplications): number => acc + cur.price, 0);

    const newUsersPrice: number = (data?.newUsers || [])
      .reduce((acc: number, cur: INewUsers): number => {
        const productsTotalPrice = cur.products
          .reduce((productsAcc, curProduct) => productsAcc + curProduct.price, 0);
        return acc + productsTotalPrice;
      }, 0);

    const applicationAssignmentsPrice: number = (data?.applicationAssignments || [])
      .reduce((acc: number, cur: IApplicationAssignments): number => acc + cur.price, 0);

    const upgradedApplicationsPrice: number = (data?.upgradedApplications || [])
      .reduce((acc: number, cur: IUpgradedApplications): number => acc + cur.productPrice, 0);

    const totalPrice: number = editUsersTotalPrice
      + userAssignmentsPrice
      + addedApplicationsPrice
      + newUsersPrice
      + applicationAssignmentsPrice
      + upgradedApplicationsPrice;

    return formatPrice(totalPrice, user?.country || CountryCode.USA);
  }, [
    editUsersTotalPrice,
    data?.userAssignments,
    data?.addedApplications,
    data?.newUsers,
    data?.applicationAssignments,
    data?.upgradedApplications,
    user?.country,
  ]);

  return (
    <SidebarModal
      classes="app-cart"
      title="Cart"
      description="Please review your products"
      visible={visible}
      onOk={completeCartHandler}
      okText="Submit"
      isOkDisabled={isDisabledSubmitButton}
      isOkLoading={isLoading}
      onCancel={closeCartHandler}
      cancelText={null}
      footerAdditonal={
        <button
          type="button"
          className="btn relative-units default m-r-auto new-style"
          onClick={clearCartHandler}
        >Empty Cart</button>
      }
    >
      {
        (data?.userAssignments || []).map(({ productId, name, users, productPrice }: IUserAssignment) => (
          <CartSection
            key={`u-a-${name}`}
            title="Assign user(s)"
            description={`x${users.length} ${name}`}
            totalPrice={formatPrice(productPrice * users.length, user?.country || CountryCode.USA)}
            Icon={UserCheckIcon}
            onRemove={() => removeAssignedUsersHandler(productId)}
          >
            {users.length > 0 && users.map(({ email, userId }) => (
              <CartSectionItem
                key={`u-a-${name}-${email}`}
                title={email}
                price={formatPrice(productPrice, user?.country || CountryCode.USA)}
                onRemove={() => removeAssignedUserHandler(productId, userId)}
              />
            ))}
          </CartSection>
        ))
      }
      {
        (data?.addedApplications || []).map(({ componentId, name, users, price, quantity }: IAddedApplications) => (
          <CartSection
            key={`u-a-${name}`}
            title="Add application"
            description={`x${quantity} ${name}`}
            totalPrice={formatPrice(price, user?.country || CountryCode.USA)}
            Icon={CubeIcon}
            onRemove={() => removeApplicationHandler(componentId)}
          >
            {users.length > 0 && users.map(({ email, userId }) => (
              <CartSectionItem
                key={`u-a-${name}-${email}`}
                title={email}
                price={formatPrice(price / quantity, user?.country || CountryCode.USA)}
                onRemove={() => removeApplicationUserHandler(componentId, userId)}
              />
            ))}
          </CartSection>
        ))
      }
      {
        (data?.newUsers || []).map(({ email, products }: INewUsers) => (
          <CartSection
            key={`n-u-${email}`}
            title="Add user"
            description={email}
            totalPrice={formatPrice(
              products?.reduce((total, cur) => total + cur.price, 0) || 0,
              user?.country || CountryCode.USA,
            )}
            Icon={AddUserIcon}
            onRemove={() => removeNewUserHandler(email)}
          >
            {products?.length > 0 && products.map(({ id, name, price }) => (
              <CartSectionItem
                key={`n-u-${email}-${id}`}
                title={name}
                price={formatPrice(price, user?.country || CountryCode.USA)}
                isDisabled={id === null}
                onRemove={() => removeNewUserAssignAppHandler(id, email)}
              />
            ))}
          </CartSection>
        ))
      }
      {
        data?.editUsers?.length && (
          <CartSection
            title="Change role"
            description={`x${data.editUsers.length} MFA`}
            totalPrice={formatPrice(editUsersTotalPrice, user?.country || CountryCode.USA)}
            Icon={SwitchHorizontalIcon}
            onRemove={removeEditUsersHandler}
          >
            {
              data?.editUsers.map(({ userId, email, price }: IEditUsers) => (
                <CartSectionItem
                  key={`e-u-${userId}`}
                  title={email}
                  price={formatPrice(price, user?.country || CountryCode.USA)}
                  onRemove={() => removeEditUserHandler(userId)}
                />
              ))
            }
          </CartSection>
        )
      }
      {
        (data?.applicationAssignments || []).map(({ userId, email, price, applications }: IApplicationAssignments) => (
          <CartSection
            key={`a-a-${userId}`}
            title="Assign product(s)"
            description={email}
            totalPrice={formatPrice(price, user?.country || CountryCode.USA)}
            Icon={ThreeCubesIcon}
            onRemove={() => removeUserAssignedAppsHandler(userId)}
          >
            {applications.length > 0 && applications.map(({ id, name, price }) => (
              <CartSectionItem
                key={`a-a-${email}-${id}`}
                title={name}
                price={formatPrice(price, user?.country || CountryCode.USA)}
                onRemove={() => removeUserAssignedAppHandler(userId, id)}
              />
            ))}
          </CartSection>
        ))
      }
      {
        (data?.upgradedApplications || []).map(({ productId, productName, productPrice }: IUpgradedApplications) => (
          <CartSection
            key={`u-a-${productName}`}
            title="Upgrade application"
            description={productName}
            totalPrice={formatPrice(productPrice, user?.country || CountryCode.USA)}
            Icon={ArrowsUpIcon}
            onRemove={() => removeUpgradeProductHandler(productId)}
          />
        ))
      }
      <div className="app-cart__total-price flex flex-justify-between font-weight-600 rel-m-b-48 flex-1">
        Total<span>{formatedTotalPrice}</span>
      </div>
      {isShowSignatureBlock && (
        <>
          <Signature bodyClasses="rel-m-b-40" value={signatureValue} onChange={setSignatureValue} />
          <Checkbox
            isChecked={isTermsAndConditionsChecked}
            classes="rel-m-b-32"
            onChange={(e) => setIsTermsAndConditionsChecked((e.target as HTMLInputElement).checked)}
          >
            I agree with Terms and Conditions under the&nbsp;
            <a className="link relative-units app-cart__sla-link" href={SLA_LINK} target="_blank">Service Level Agreement (SLA)</a>
          </Checkbox>
          <Checkbox
            isChecked={isContractChecked}
            classes="rel-m-b-16"
            onChange={(e) => setIsContractChecked((e.target as HTMLInputElement).checked)}
          >
            Please sign 1 year contract billed monthly. Your contract
            starts on {contractStartDateText}
          </Checkbox>
        </>
      )}
    </SidebarModal>
  );
};

export default Cart;