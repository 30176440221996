import './DashboardBillingInvoices.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getBillingInvoicesData } from 'store';

import { ArrowLeftSmallIcon } from 'assets/svg';

import BillingInvoicesTableHeadRow from './components/BillingInvoicesTableHeadRow';
import BillingInvoicesTableBodyRow from './components/BillingInvoicesTableBodyRow';
import BillingInvoicesTableBodyRowLoader from './components/BillingInvoicesTableBodyRowLoader';
import { Error, Pagination, CustomFieldsModal } from 'components';

const DashboardBillingInvoices: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { subscriptionId } = useParams();
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getBillingInvoicesDataRequest);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false);
  const [currentSelectedInvoice, setCurrentSelectedInvoice] = useState(null);

  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
  });

  const onViewInvoices = (invoice: any) => {
    setShowCustomFieldsModal(true);
    setCurrentSelectedInvoice(invoice);
  };

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const fetchData = async () => {
    if (typeof subscriptionId === 'string') {
      const data = await dispatch(getBillingInvoicesData({
        subscriptionId,
        page: queryPage,
        pageSize: queryPageSize,
        _disableErrorHandler: true,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  useEffect(() => {
    fetchData();
  }, [query]);

  if (error) {
    if ((error as SerializedError).code === '404') {
      return <Error code={404} buttonLabel="Go to Billing Page" buttonRedirectPath="/user-portal/billing" isShowButton />;
    }
    return <Error code={500} />;
  }

  return (
    <div className="dashboard-billing-invoices ussp-page">
      <section className="dashboard-billing-invoices__header ussp-page__header">
        <button
          className="btn relative-units default dashboard-billing-details__back-btn"
          onClick={() => navigate('/user-portal/billing')}
        >
          <ArrowLeftSmallIcon />
        </button>
        {isLoading
          ? <Skeleton containerClassName="rel-rel-m-l-12 header-skeleton" />
          : <>
            <h1 className="rel-m-l-12 rel-m-r-8">{ data?.companyName }</h1>
            <p className="rel-p-t-8">ID#{ data?.chargifyId }</p>
          </>}
      </section>
      <section className="dashboard-billing__content">
        <table className="Billings-table">
          <thead><BillingInvoicesTableHeadRow isManageable={data?.manageable}/></thead>
          <tbody>
            {isLoading
              ? <BillingInvoicesTableBodyRowLoader />
              : data?.invoices.map((el: any, index: number) => (
                <BillingInvoicesTableBodyRow
                  key={`Billing-invoices-${index}`}
                  {...el}
                  isManageable={data?.manageable}
                  onViewInvoices={() => onViewInvoices(el)}
                  subscriptionId={subscriptionId}
                />
              ))
            }
          </tbody>
        </table>
      </section>
      <Pagination
        current={queryPage}
        entries={queryPageSize}
        totalPages={totalPages}
        onChange={onPaginationChange}
      />
      <CustomFieldsModal
        companyName={data?.companyName}
        chargifyId={data?.chargifyId}
        subscriptionId={subscriptionId}
        invoiceDetails={currentSelectedInvoice}
        visible={showCustomFieldsModal}
        onClose={() => setShowCustomFieldsModal(false)}
      />
    </div>
  );
};

export default DashboardBillingInvoices;