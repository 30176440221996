import './DashboardAccount.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { setupSignalR } from 'helpers';
import { getAccountsData, updateDomainName } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { EMAIL_PATTERN } from 'consts';

import {
  ArrowRightFullIcon,
  EditIcon,
  WorkIcon,
  ShowIcon,
  InfoCircleOutlineIcon,
  WarningIcon,
  InfoCircleIcon,
} from 'assets/svg';
import { Alert, InputText, Error, Pagination, Tooltip, Modal } from 'components';

interface IAccountStatusInfo {
  chargifyPaymentUpdateUrl: string;
  hasPaymentErrors: boolean;
  isActive: boolean;
  status: string; // @todo create enum "active"
}

interface IAccount {
  activeUsers: number;
  companyName: string;
  id: string;
  chargifyId: string;
  clientSinceDate: string;
  contractEndDate: string;
  nextBillingDate: string;
  domainName: string;
  isMember: boolean | null;
  statusInfo: IAccountStatusInfo;
  manageable: boolean;
}

interface IAccountsData {
  totalPages: number;
  page: number;
  count: number;
  accounts: IAccount[];
}
const formatDate = (value: string): string => dayjs(value).format('MMM D, YYYY');

const DashboardAccount: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: cartData } = useAppSelector(state => state.ussp.getUsspCartDataRequest || {});
  const { accounts = []}: IAccountsData = useAppSelector(state =>
    state.ussp.getAccountsDataRequest.data || {});
  const { isLoading, error } = useAppSelector(state => state.ussp.getAccountsDataRequest);

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined);
  const [entriesCount, setEntriesCount] = useState<number | undefined>(undefined);
  const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);

  const fetchData = async (page?: number, pageSize?: number) => {
    const data = await dispatch(getAccountsData({
      page,
      pageSize,
      _disableErrorHandler: true,
      _isBackground: isBackgroundUpdate,
    })).unwrap();
    setTotalPages((data?.totalPages as number) || 1);
  };

  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(currentPage, entriesCount);
    setIsBackgroundUpdate(false);
  };

  useEffect(() => {
    const connection = setupSignalR('/accountOverview');
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('Signal', async (message: any) => {
            await backgroundFetch();
            console.log('ACCOUNT_OVERVIEW:SIGNAL', message);
          });
        })
        .catch((e: any) => console.log('Connection failed [accountOverview]: ', e));
    }

    fetchData();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  const { isLoading: isUpdatingDomainName } = useAppSelector(state => state.ussp.updateDomainNameRequest);
  const [currentAppId, setCurrentAppId] = useState<string>('');
  const [newDomainName, setNewDomainName] = useState<string>('');
  const [isNewDomainNameInputTouched, setIsNewDomainNameInputTouched] = useState<boolean>(false);
  const isValidNewDomainName = useMemo(() => {
    return newDomainName.trim() !== '' && EMAIL_PATTERN.test(newDomainName);
  }, [newDomainName]);
  const [isShowDomainNameModal, setIsShowDomainNameModal] = useState<boolean>(false);
  const openDomainNameModal = (id: string): void => {
    setCurrentAppId(id);
    setIsShowDomainNameModal(true);
  };
  const hideDomainNameModal = (): void => {
    setIsShowDomainNameModal(false);
    setNewDomainName('');
    setCurrentAppId('');
    setIsNewDomainNameInputTouched(false);
  };
  const updateDomainNameHandler = async () => {
    await dispatch(updateDomainName({
      subscriptionId: currentAppId,
      domainName: newDomainName,
    })).unwrap();
    await backgroundFetch();
    hideDomainNameModal();
  };

  if (error) {
    return <Error code={500} />;
  }

  return (
    <div className="dashboard-accounts ussp-page">
      <section className="ussp-page__header">
        <h1>Summit Accounts Overview</h1>
      </section>
      <section className="dashboard-accounts__content">
        {isShowLoader
          ? (
            <div className="dashboard-accounts-card">
              <div className="app-card disabled flex flex-align-center rel-flex-gap-24 rel-p-x-24 rel-p-y-16">
                <Skeleton containerClassName="app-card__icon-skeleton" height="100%" />
                <div className="app-card__content">
                  <Skeleton containerClassName="app-card__name" height="100%" width="70%" />
                  <Skeleton containerClassName="app-card__id" height="100%" width="50%" />
                </div>
              </div>
              <div className="dashboard-accounts-card__info rel-p-24"></div>
            </div>
          )
          : accounts.map(({
            id,
            chargifyId,
            companyName,
            activeUsers,
            clientSinceDate,
            contractEndDate,
            nextBillingDate,
            domainName,
            isMember,
            statusInfo,
            manageable,
          }) => (
            <div key={`accounts-${chargifyId}`} className="dashboard-accounts-card">
              <div className="app-card disabled flex flex-align-center rel-flex-gap-24 rel-p-x-24 rel-p-y-16">
                <div className="app-card__icon-wrapper"><WorkIcon /></div>
                <div className="app-card__content">
                  <div className="app-card__name flex flex-align-center rel-flex-gap-20">
                    {companyName}
                    {isMember && <div className="tag">Member</div>}
                    {isMember === false && <div className="tag secondary">Primary</div>}
                    <div></div>
                    {
                      manageable && (
                        <div
                          className="app-card__action flex flex-align-center rel-flex-gap-12"
                          onClick={() => navigate(`${id}`)}
                        >
                          {cartData?.subscriptionId === null || id === cartData?.subscriptionId
                            ? <><EditIcon /> Make Account Changes</>
                            : <><ShowIcon /> Review Subscription</>
                          }
                        </div>
                      )
                    }
                  </div>
                  <div className="app-card__id">ID: {chargifyId}</div>
                </div>
              </div>
              {statusInfo?.hasPaymentErrors && (
                <Alert
                  classes="rel-m-x-24 rel-m-b-16"
                  CustomIcon={InfoCircleOutlineIcon}
                  type="error"
                  theme="light"
                  textFontWeight={400}
                  message={
                    <div className="flex flex-align-center flex-justify-between full-width">
                      <span>There is an issue with your payment method. Please update your payment information</span>
                      <span className="flex flex-align-center rel-flex-gap-4" onClick={() => navigate(`/user-portal/billing/${id}`)}>
                        Go to Billing Details
                        <ArrowRightFullIcon />
                      </span>
                    </div>
                  }
                  fullWidth
                />
              )}
              {!manageable && (
                <Alert
                  classes="rel-m-x-24 rel-m-b-16"
                  CustomIcon={InfoCircleOutlineIcon}
                  type="error"
                  theme="light"
                  textFontWeight={400}
                  message={
                    <div>
                      <span className="fw-500">Your subscription is non-manageable and read-only.</span> Please contact our customer support team if you have any questions or need assistance.
                    </div>
                  }
                  fullWidth
                />
              )}
              <div className="dashboard-accounts-card__info rel-p-24">
                {domainName ? (
                  <p>
                    Company domain name:&nbsp;
                    <span className="link relative-units disabled" >{domainName}</span>
                  </p>
                ) : (
                  <div
                    className="dashboard-accounts-card__add-domain flex flex-align-center rel-flex-gap-8"
                    onClick={() => openDomainNameModal(id)}
                  >
                    <WarningIcon />
                    Add Company Domain Name
                    <Tooltip
                      classes="dashboard-accounts-card__add-domain-tooltip"
                      content="Please provide your domain name to display your subscription data"
                      placement="top-start"
                    >
                      <InfoCircleIcon className="flex" />
                    </Tooltip>
                  </div>
                )}
                <p>Contract End Date: <span>{contractEndDate ? formatDate(contractEndDate) : '-'}</span></p>
                <p>Active Users: <span className="fw-600">{activeUsers}</span></p>
                <p>Customer since: <span>{clientSinceDate ? formatDate(clientSinceDate) : '-'}</span></p>
                <p>Next Billing Date: <span>{nextBillingDate ? formatDate(nextBillingDate) : '-'}</span></p>
              </div>
            </div>
          ))
        }
        <Modal
          classes="dashboard-accounts__add-domain-name-modal"
          title="Add Company Domain Name"
          visible={isShowDomainNameModal}
          okText="Save"
          isOkDisabled={!isValidNewDomainName}
          isOkLoading={isUpdatingDomainName || isLoading}
          onOk={updateDomainNameHandler}
          onCancel={hideDomainNameModal}
          width={535}
        >
          <InputText
            label="Enter your Email (not link)"
            placeholder="Please enter email"
            value={newDomainName}
            isRequired
            onChange={setNewDomainName}
            isInvalid={isNewDomainNameInputTouched && !isValidNewDomainName}
            onBlur={() => setIsNewDomainNameInputTouched(true)}
          />
        </Modal>
      </section>
      <Pagination
        totalPages={totalPages}
        onChange={fetchData}
        onChangeCurrentPage={setCurrentPage}
        onChangeEntriesCount={setEntriesCount}
      />
    </div>
  );
};

export default DashboardAccount;
