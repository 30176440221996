import { DropdownButton, RadioButton } from 'components';
import React, { FC, Fragment, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { setApplicationComponentId, addAdminNewApplication } from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import ApplicationFormFooter from '../ApplicationFormFooter/ApplicationFormFooter';
import { notify, useFormFieldErrorNotification } from 'helpers';
import './AdditionalServices.scss';
import { useParams } from 'react-router-dom';
import { ThreeCubesIcon } from 'assets/svg';

const RETENSION_GROUP_PREFIX = 'Backup Retention';
const RETENSION_GROUP_HEADER = 'Extends Backup Retention for up to 100 GB of data';

interface Props {
  onCancel: (reset?: boolean) => void;
  fetchProducts: () => void;
  changeCurrentStep: React.Dispatch<React.SetStateAction<any>>;
}

const AdditionalServices: FC<Props> = ({ onCancel, fetchProducts }) => {
  const { subscriptionId } = useParams();
  const additionalServices: any = useAppSelector(state => state.products.adminAdditionalServices || []);
  const { isLoading: isAddLoading } = useAppSelector(state => state.products.addAdminNewApplicationRequest);

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: {
      service: null,
    },
  });

  const groupedAdditionalServices = useMemo(() => {
    // group backup retension
    const { retension, other } = additionalServices.reduce(
      (acc: any, cur: any) => {
        cur.version.startsWith(RETENSION_GROUP_PREFIX)
          ? acc.retension.push(cur)
          : acc.other.push(cur);
        return acc;
      },
      { retension: [], other: []},
    );
    return [...(retension && retension.length ? [retension] : []), ...other];
  }, [additionalServices]);
  useFormFieldErrorNotification(formState, ['componentId']);

  const onSubmit: SubmitHandler<any> = async (data) => {
    dispatch(setApplicationComponentId(data?.service?.componentId));
    if (typeof subscriptionId === 'string') {
      await dispatch(addAdminNewApplication({ subscriptionId })).unwrap();
      notify.success('Success', 'Product successfully added');
      onCancel(true);
      fetchProducts();
    }
    
  };

  return (
    <div>
      <form className="other-application" onSubmit={handleSubmit(onSubmit)}>
        <div className="other-application__title">
          Which version of Additional Services do you currently own?
        </div>
        <Controller
          name="service"
          control={control}
          rules={{ required: true }}
          render={({
            field: { onChange, value },
          }) => (
            <>
              {groupedAdditionalServices.map((addSer, index) => {
                const isGroup = Array.isArray(addSer);
                return (
                  <Fragment key={index}>
                    {!isGroup && (
                      <RadioButton
                        isChecked={value?.id === addSer.id}
                        label={addSer.version}
                        classes="rel-m-b-16"
                        onChange={() => onChange(addSer)}
                      />
                    )}
                    {isGroup && (
                      <DropdownButton
                        defaultState
                        icon={<ThreeCubesIcon />}
                        label={RETENSION_GROUP_HEADER}
                        source={<>{addSer.map((pr) => (
                          <RadioButton
                            value={pr.id}
                            key={pr.id}
                            label={pr.version}
                            isChecked={value?.id === pr.id}
                            onChange={() => onChange(pr)}
                          />
                        ))}</>}
                      />
                    )}

                  </Fragment>
                );
              })}
            </>
          )}
        />
        <ApplicationFormFooter
          onCancel={onCancel}
          submitForm
          isOkDisabled={!isValid}
          isOkLoading={isAddLoading}
          cancelText="Back"
          okText="Add"
        />
      </form>
    </div>
  );
};

export default AdditionalServices;
