import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { usePriceHandler } from 'helpers';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getBillingSla } from 'store';
import { BILLING_STATUS_CLASSES } from 'consts';
import { CountryCode, BillingStatus, PaymentMethod } from 'enums';

import {
  DocumentIcon,
  DotsIcon,
  // ImportExportIcon,
  InfoCircleOutlineIcon,
  PaperDownloadIcon,
  ShowOutlineIcon,
  WorkIcon,
} from 'assets/svg';

import { Menu, PaymentMethodBlock, Tooltip } from 'components';

interface Props {
  id: string;
  chargifyId: string;
  chargifyPaymentUpdateUrl: string;
  companyName: string;
  clientSinceAt: string;
  creditCardType: string;
  isExpired: boolean;
  isMember: boolean | null;
  fromChargify: boolean;
  nextBillingDate: string;
  paymentMethod: PaymentMethod;
  paymentNumber: string;
  status: BillingStatus;
  totalMonthlyFee: number;
  submittedBy: string;
  submittedByEmail: string;
}
const formatDate = (value: string): string => dayjs(value).format('MMM D, YYYY');

const BillingsTableBodyRow: FC<Props> = ({
  id,
  chargifyId,
  chargifyPaymentUpdateUrl,
  companyName,
  clientSinceAt,
  creditCardType,
  isExpired,
  isMember,
  fromChargify,
  nextBillingDate,
  paymentMethod,
  paymentNumber,
  status,
  totalMonthlyFee,
  submittedBy,
  submittedByEmail,
}) => {
  const dispatch = useAppDispatch();
  const currentCountry = useAppSelector((state) => state.ussp.user?.country || CountryCode.USA);
  const { formatPrice } = usePriceHandler();
  const navigate = useNavigate();

  const clientSinceAtFormated = useMemo(() => formatDate(clientSinceAt), [clientSinceAt]);
  const nextBillingDateFormated = useMemo(() => formatDate(nextBillingDate), [nextBillingDate]);
  const submittedByFormated = useMemo(() => formatDate(submittedBy), [submittedBy]);
  const totalMonthlyFeeFormated = useMemo(() => formatPrice(totalMonthlyFee, currentCountry), [
    totalMonthlyFee,
    currentCountry,
  ]);

  const statusFormated = useMemo(() => status
    .split('_')
    .map(el => `${el.charAt(0).toUpperCase()}${el.slice(1)}`)
    .join(' '), [status]);

  const onDownloadSlaClick = async () => {
    const data = await dispatch(getBillingSla({ subscriptionId: id })).unwrap();
    const url = URL.createObjectURL(new Blob([data]));
    const alink = document.createElement('a');
    alink.href = url;
    alink.download = `${id}_sla.pdf`;
    alink.click();
  };

  return (
    <tr className="billings-table-body-row">
      <td>
        <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
          <div className="app-card__icon-wrapper"><WorkIcon /></div>
          <div className="app-card__content">
            <div className="app-card__name">
              <span>{companyName}</span>
              {isMember && (
                <Tooltip
                  classes="app-card__info-tooltip-icon"
                  content="This subscription is paid by the Primary account"
                  placement="bottom-start">
                  <InfoCircleOutlineIcon />
                </Tooltip>
              )}
            </div>
            <div className="app-card__id">ID: {chargifyId}</div>
          </div>
        </div>
      </td>

      <td>{clientSinceAtFormated}</td>

      <td>
        <p>{nextBillingDateFormated}</p>
        <span>{totalMonthlyFeeFormated}/mo</span>
      </td>

      <td>
        <p>{submittedByFormated}</p>
        <span>{submittedByEmail}</span>
      </td>

      <td>
        <p className={BILLING_STATUS_CLASSES[status]}>{statusFormated}</p>
      </td>

      <td>
        <PaymentMethodBlock
          creditCardType={creditCardType}
          editCardUrl={chargifyPaymentUpdateUrl}
          isCardExpired={isExpired}
          paymentMethod={paymentMethod}
          paymentNumber={paymentNumber}
        />
      </td>

      <td>
        <Menu
          trigger={<DotsIcon />}
          items={[
            <div onClick={() => navigate(`${id}/invoices`)}><DocumentIcon className="menu-item-icon" />Invoices</div>,
            <div onClick={() => navigate(`${id}`)}><ShowOutlineIcon className="menu-item-icon" />Billing Details</div>,
            ...(fromChargify
              ? []
              : [<div onClick={onDownloadSlaClick}><PaperDownloadIcon className="menu-item-icon" />Download SLA</div>]
            ),
            // <div><ImportExportIcon className="menu-item-icon" />Activate</div>,
          ]}
          placement="bottom-end"
        />
      </td>
    </tr>
  );
};

export default BillingsTableBodyRow;
