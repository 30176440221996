import './Error.scss';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Button from '../Button/Button';

interface Props {
  absoluteStyles?: boolean;
  code: number | string;
  buttonLabel?: string;
  xVersion?: boolean;
  buttonRedirectPath?: string;
  description?: string;
  isShowButton?: boolean;
  title?: string;
  onButtonClick?: () => void;
}

const DEFAULT_TITLES_BY_CODE: { [key: string]: string } = {
  '404': 'Page not found',
  '500': 'There\'s been a glitch…',
};

const DEFAULT_DESCRIPTION_BY_CODE: { [key: string]: string } = {
  '404': 'Sorry, the page you are looking for doesn\'t exist or has been moved.',
  '500': 'We\'re not quite sure what went wrong. You can come back or try again later.',
};

const Error: FC<Props> = ({
  code,
  buttonLabel = 'Go to Home Page',
  buttonRedirectPath = '/',
  description,
  isShowButton = false,
  xVersion = false,
  absoluteStyles = false,
  title,
  onButtonClick,
}) => {
  const navigate = useNavigate();

  const onButtonClickHandler = () => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      navigate(buttonRedirectPath);
    }
  };

  return (
    <div
      className={cn("error-component", {
        'relative-units': !absoluteStyles,
      })}
    >
      <p className="error-component__code">{code}</p>
      <p
        className={cn("error-component__title", {
          'rel-m-b-16': !absoluteStyles,
          'm-b-16': absoluteStyles
        })}
      >{title || DEFAULT_TITLES_BY_CODE[`${code}`]}</p>
      <p
        className={cn("error-component__description", {
          'rel-m-b-32': !absoluteStyles,
          'm-b-32': absoluteStyles
        })}
      >{description || DEFAULT_DESCRIPTION_BY_CODE[`${code}`]}</p>
      {isShowButton && (
        <Button
          type={xVersion ? "primary" : "secondary"}
          size="medium"
          dataTestId="error-page.redirect-button"
          xVersion={xVersion}
          absoluteStyles={absoluteStyles}
          onClick={onButtonClickHandler}
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

export default Error;