import { Button, Modal, ProgressBar, SignUpBackButton } from 'components';
import './PaymentStep.scss';
import { getEnvCountryCode, notify, useGetProductsListWithInfo, useGetProductsListWithPrices, useGetSignUpTotalPriceByStep, useGetTotalPriceByStep, useSignUpFormNavigate } from 'helpers';
import { CountryCode, SignUpNavigation } from 'enums';
import ReactGA from 'react-ga4';
import ChargifyForm from 'pages/simple-form/Payment/components/ChargifyForm/ChargifyForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { FC, useMemo, useState } from 'react';
import { BankIcon, CreditCardIcon, MessageOutlineIcon } from 'assets/svg';
import { SerializedError } from '@reduxjs/toolkit';
import { completeOrder, resetOrder } from 'store';
import doneAnimation from 'assets/lotties/done.json';
import paymentUnsuccesful from 'assets/lotties/payment-unsuccesful.json';
import Lottie from 'lottie-react';
import cn from 'classnames';


enum PaymentMethod {
  CreditCard = 'card',
  BankAccount = 'bank',
}

interface IPaymentMethod {
  type: PaymentMethod;
  title: string;
  IconComponent: FC;
}

const PAYMENT_METHODS_BY_COUNTRY: { [key: string]: IPaymentMethod[] } = {
  [CountryCode.Canada]: [
    {
      type: PaymentMethod.CreditCard,
      title: 'Credit Card',
      IconComponent: CreditCardIcon,
    },
  ],
  [CountryCode.USA]: [
    {
      type: PaymentMethod.CreditCard,
      title: 'Credit Card',
      IconComponent: CreditCardIcon,
    },
    {
      type: PaymentMethod.BankAccount,
      title: 'Bank Account',
      IconComponent: BankIcon,
    },
  ],
};

const doneAnimationStyles = {
  height: '108px',
  marginLeft: '50px',
  marginRight: '50px',
};
const paymentUnsuccesfulAnimationStyles = {
  height: '160px',
  marginLeft: '50px',
  marginRight: '50px',
};

const PAYMENT_TYPES_MAP = {
  [PaymentMethod.CreditCard]: 'credit_card',
  [PaymentMethod.BankAccount]: 'bank_account',
};

const PaymentStep = () => {
  const simpleFormNavigate = useSignUpFormNavigate();
  const dispatch = useAppDispatch();
  const currentCountryCode = useAppSelector(state => state.order.country?.code ?? CountryCode.USA);
  const termsId = useAppSelector(state => state.order.termsId);
  const envCountryCode = useMemo(() => getEnvCountryCode(currentCountryCode), [currentCountryCode]);
  const couponState = useAppSelector(state => state.order.coupon);
  const orderId = useAppSelector(state => state.order.orderId);
  const [
    currentPaymentMethod,
    setCurrentPaymentMethod,
  ] = useState<PaymentMethod>(PAYMENT_METHODS_BY_COUNTRY[envCountryCode][0].type);
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [isShowErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessPayment, setIsSuccessPayment] = useState<boolean>(false);
  const products = useGetProductsListWithInfo();
  const purchaseProducts = useGetProductsListWithPrices();
  const { totalPrice } = useGetSignUpTotalPriceByStep({ currentStep: SignUpNavigation.Payment });

  const showModal = () => {
    setShowModal(true);
  };
  const showErrorModal = () => {
    setShowErrorModal(true);
  };

  const sendPurchaseGtagEvent = () => {
    ReactGA.event('purchase', {
      transaction_id: orderId,
      value: totalPrice,
      currency: currentCountryCode === CountryCode.Canada ? ' CAD' : 'USD',
      items: purchaseProducts.map(i => ({
        item_id: i.id,
        item_name: i.name,
        quantity: Number(i.quantity),
        price: i.price,
      })),
      coupon: couponState && couponState.name || '',
    });
  };

  const submit = async (token: string) => {
    setIsLoading(true);
    try {
      await dispatch(completeOrder({
        token: token,
        products,
        paymentType: PAYMENT_TYPES_MAP[currentPaymentMethod],
        termsId,
        _disableErrorHandler: true,
      })).unwrap();
      showModal();
      sendPurchaseGtagEvent();
      setIsSuccessPayment(true);
      dispatch(resetOrder());
    } catch (err) {
      if ((err as SerializedError).code === '502') {
        showErrorModal();
        sendPurchaseGtagEvent();
        dispatch(resetOrder());
      } else if (typeof (err as SerializedError).message === 'string') {
        notify.error(
          'Error',
          (err as SerializedError).message,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  const next = () => {
    setShowModal(false);
    window.location.href = 'https://www.summithosting.com';
  };

  return (
    <>
      <Modal
        absoluteStyles
        xVersion
        title={null}
        footer={null}
        closable={false}
        width={376}
        visible={isShowModal}
      >
        <div className="thanks-payment-modal-body p-t-44 sm-p-t-32">
          {isShowModal && (
            <Lottie
              style={doneAnimationStyles}
              animationData={doneAnimation}
              initialSegment={[0, 129]} />
          )}
          <p className="m-b-24 m-t-44 sm-m-t-32">Thank You for Choosing Summit Hosting!</p>
          <Button
            absoluteStyles
            xVersion
            isNewStyle
            onClick={next}
          >
            Next Steps and More Information
          </Button>
        </div>
      </Modal>
      <Modal
        absoluteStyles
        xVersion
        title={null}
        footer={null}
        closable={false}
        width={538}
        visible={isShowErrorModal}
      >
        <div className="error-payment-modal-body p-x-20 p-t-20 sm-p-t-16">
          {isShowErrorModal && (
            <Lottie
              style={paymentUnsuccesfulAnimationStyles}
              animationData={paymentUnsuccesful}
              initialSegment={[0, 79]} />
          )}
          <p className="title m-b-8">Opps...</p>
          <p className="description m-b-48 sm-m-b-36">
            Your order has been received; however, we are currently facing challenges
            processing it through our payment provider. To ensure the completion of the
            processing, a team member will reach out to you at the earliest convenience.
            Thank you for your understanding and patience.
          </p>
          <p className="info m-b-8">
            For immediate assistance or to inquire about your order status,
            please reach out to our Client Experience team.
          </p>
          <div className="flex flex-justify-center m-b-52 sm-m-b-40">
            <a
              href={`mailto:am@summithosting.com`}
              className="link relative-units without-decorations fw-400 flex flex-align-center gap-4 mail-link"
            >
              <MessageOutlineIcon />
              am@summithosting.com
            </a>
          </div>
          <button type="button" className="btn primary new-style thanks-button" onClick={next}>Thank you for choosing Summit Hosting</button>
        </div>
      </Modal>
      <div className="container-sm">
        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-8">Payment</div>
          <div className="sign-up-form-step-description m-b-24">
            Please enter your payment information below.
          </div>
          <div className="payment-methods m-b-32">
            {PAYMENT_METHODS_BY_COUNTRY[envCountryCode].map(({ type, title, IconComponent }) => (
              <button
                key={`payment-type-btn-${type}`}
                type="button"
                className={cn('btn btn-tab with-icon new-style', { active: currentPaymentMethod === type })}
                onClick={() => setCurrentPaymentMethod(type)}><IconComponent /> {title}</button>
            ))}
          </div>
          <ChargifyForm
            xVersion
            paymentType={currentPaymentMethod}
            submit={submit}
            isLoading={isLoading}
            isDisabled={isSuccessPayment}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentStep;