import { useEffect, useMemo } from 'react';
import { usePrevious } from './hooks';
import { notify } from './notification';
import { FormState, FieldErrors } from 'react-hook-form';
import flatten from 'flat';
import _ from 'lodash';

export const useFormFieldErrorNotification = (formState: FormState<any>, touchedArr?: string[]) => {
  const { errors, touchedFields }: { errors: FieldErrors; touchedFields: Record<string, any> } = formState;
  // for handling changes in these fields
  const memoizedErrorsTouchedStringified = useMemo(() => JSON.stringify([
    Object.keys(errors).map(key => ({ message: errors[key]?.message, type: errors[key]?.type })),
    Object.keys(touchedFields).map(key => ({ key, value: touchedFields[key] })),
  ]), [formState]);
  
  const getKeysWithDeep = (obj: Record<string, any>): Array<string> => {
    return Object.keys(flatten(obj) as Record<string, any>).reduce((acc, key) => {
      const keyArr = key.split('.');
      if (keyArr.length > 1) {
        keyArr.pop();
      }
      const newKey = keyArr.join('.');
      if (!acc.includes(newKey) && (!touchedArr?.includes(newKey) || _.get(touchedFields, newKey))) {
        acc.push(newKey);
      }
      return acc;
    }, [] as string[]);
  };

  const prevErrorsKeys = usePrevious<Array<string>>(getKeysWithDeep(errors));

  useEffect(() => {
    const newErrors: Array<string> = getKeysWithDeep(errors)
      .filter(
        (key) => !prevErrorsKeys?.includes(key),
      );
    newErrors.forEach((key) => {
      const newErrObj: { message?: string; name?: string } = _.get(errors, key) || {};
      if (!!newErrObj.message) {
        notify.error(
          newErrObj.message,
        );
      }
    });
  }, [memoizedErrorsTouchedStringified]);
};
