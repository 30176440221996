import './EmptyStateContainer.scss';
import { FC } from 'react';
import cn from 'classnames';
import { EmptySearchIcon } from 'assets/svg';

interface Props {
  title: string;
  description: string;
  classes?: string;
  fullHeight?: boolean;
}
const EmptyStateContainer: FC<Props> = ({
  title,
  description,
  classes,
  fullHeight = false,
}) => (
  <div className={cn('empty-state-container', classes, { 'full-height': fullHeight })}>
    <EmptySearchIcon />
    <div className="empty-state-container__title">{title}</div>
    <div className="empty-state-container__description">{description}</div>
  </div>
);

export default EmptyStateContainer;