import { FC, useEffect, useMemo } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { notify } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAdminCustomField, addUserPhoneNumber, editAdminCustomField } from 'store';

import { PHONE_PATTERN_SIMPLE } from 'consts';

import {
  InputText,
  Modal,
  Textarea,
} from 'components';

type CustomFieldForm = {
  name: string;
  value: string;
};

const initialForm: CustomFieldForm = {
  name: '',
  value: '',
};

interface Props {
  visible: boolean;
  customFieldData: any | null;
  subscriptionId?: string;
  onCancel: () => void;
  onSuccess: () => void;
}

const schema = yup.object({
  name: yup.string().required(),
  value: yup.string().required()
});

const AddEditCustomFieldModal: FC<Props> = ({
  visible,
  customFieldData,
  subscriptionId,
  onCancel,
  onSuccess,
}) => {
  const {
    control,
    watch,
    getValues,
    resetField,
    register,
    handleSubmit,
    formState,
    formState: {
      touchedFields,
    },
  } = useForm<CustomFieldForm>({ mode: 'all', defaultValues: initialForm, resolver: yupResolver(schema) });
  const { errors, isValid } = formState;

  const resetModal = (customFieldData?: any | null) => {
    const {
      name = '',
      value = '',
    } = customFieldData || {} as any;

    resetField('name', { defaultValue: name });
    resetField('value', { defaultValue: value });
  };

  const isFormNotChanged = useMemo(() => {
    const formValues = getValues();
    const {
      name = '',
      value = '',
    } = customFieldData || {} as any;

    return (
      (formValues.name ?? '') === (name ?? '') &&
      (formValues.value ?? '') === (value ?? '')
    );
  }, [watch()]);

  useEffect(() => {
    if (visible) {
      resetModal(customFieldData);
    }
  }, [visible]);

  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(state => state.ussp.addAdminCustomFieldRequest);
  const onNextHandler: SubmitHandler<CustomFieldForm> = async ({ name, value }) => {
    if (typeof subscriptionId === 'string') {
      if (customFieldData) {
        await dispatch(editAdminCustomField({
          subscriptionId,
          id: customFieldData?.id,
          name,
          value,
        })).unwrap();
  
        notify.success(
          'Success',
          'Custom field was saved successfully!',
        );
      } else {
        await dispatch(addAdminCustomField({
          subscriptionId,
          name,
          value,
        })).unwrap();
  
        notify.success(
          'Success',
          'Custom field was added successfully!',
        );
      }
      onSuccess();
      onCancel();
    }
  };

  return (
    <Modal
      classes="custom-field-add-edit-modal"
      title={customFieldData ? 'Edit Custom Field' : 'Add Custom Field'}
      visible={visible}
      width={535}
      okText={customFieldData ? 'Save' : 'Add'}
      isOkDisabled={!isValid || isFormNotChanged}
      isOkLoading={isLoading}
      onOk={handleSubmit(onNextHandler)}
      onCancel={onCancel}
    >
      <form>
        <InputText
          classes="rel-m-b-20"
          label="Name"
          placeholder="Custom field name"
          name="name"
          register={register}
          shouldUnregister={false}
          isRequired
          isDisabled={customFieldData}
          isInvalid={!!(errors.name && touchedFields.name)}
        />
        <Controller
          name="value"
          render={({ field: { onChange, onBlur, value } }) => (
            <Textarea
              isRequired
              rows={7}
              label="Details"
              placeholder="Custom field details..."
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!(errors.value && touchedFields.value)}
            />
          )}
          control={control}
        />
      </form>
    </Modal>
  );
};

export default AddEditCustomFieldModal;
