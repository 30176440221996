import './SignUpFooter.scss';

const SignUpFooter = () => {
  return (
    <footer className="sign-up-footer">
      <div className="left-text"><a href="tel:+1-888-244-6559">For sales inquiries, call 1-888-244-6559 (toll-free)</a></div>
      <div className="right-text">Copyright © 2024 Summit. All rights reserved.</div>
    </footer>
  );
};

export default SignUpFooter;