export const encodeCeasar = (text: string): string => {
  const fullAlphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const fullAlphabetLength: number = fullAlphabet.length;
  const k: number = fullAlphabet.indexOf(text[0]);
  const iterationNumber: number = Math.min(text.length, 6);
  let retVal: string = '';
  for (let i = 0; i < iterationNumber; i++) {
    const c: string = text[i];
    const index: number = fullAlphabet.indexOf(c);
    if (index < 0) {
      retVal += c;
    } else {
      const codeIndex: number = (fullAlphabetLength + index + k) % fullAlphabetLength;
      retVal += fullAlphabet[codeIndex];
    }
  }
  retVal += 'A!1a';
  return retVal;
};
