import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, UsaSmallIcon, CanadaSmallIcon } from 'assets/svg';
import './Navigation.scss';
import { useAppSelector } from 'store/hooks';
import { CountryCode } from 'enums';

interface Props {
  currentStep: number;
  hideCountry?: boolean;
}

const COUNTRY_FLAG_BY_COUNTRY_CODE = {
  [CountryCode.USA]: UsaSmallIcon,
  [CountryCode.Canada]: CanadaSmallIcon,
  [CountryCode.Other]: UsaSmallIcon,
};

const Navigation: FC<Props> = ({ currentStep, hideCountry = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const countryCode = useAppSelector(state => state.order.country?.code);
  const countryName = useAppSelector(state => state.order.country?.name?.toUpperCase() ?? 'USA');
  const CountryFlag = useMemo(
    () => countryCode && COUNTRY_FLAG_BY_COUNTRY_CODE[countryCode as CountryCode],
    [countryCode],
  );
  const orderBreadCrumbs = useAppSelector(state => state.order.breadCrumbs);
  const currentRouteInex = useMemo(() => {
    const { pathname } = location;
    return orderBreadCrumbs.findIndex(el => el === pathname);
  }, []);
  const goToPreviousRoute = () => {
    if (currentRouteInex > 0) {
      navigate(orderBreadCrumbs[currentRouteInex - 1]);
    }
  };

  return (
    <div className="navigation">
      <div className="navigation-links">
        {
          currentRouteInex > 0 ? (<span
            className="link-icon"
            onClick={goToPreviousRoute}
          >
            <ArrowLeftIcon className="navigation-links__back-icon" /> Back
          </span>) : <div/>
        }

        {!hideCountry && countryCode && (
          <span className="navigation-links_icon">
            {CountryFlag && <CountryFlag />} {countryName}
          </span>
        )}

      </div>
      <div className="navigation-steps">
        {Array.from(Array(10).keys()).map(i => {
          const classes = i + 1 <= currentStep ? 'active' : '';

          return (
            <div
              key={i}
              className={classes}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default Navigation;