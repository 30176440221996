import { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { useIsOverflow } from 'helpers';

import {
  DotsIcon,
  DeleteOutlineIcon,
  EditOutlineIcon,
  MinusInSquareIcon,
  PlusInSquareIcon,
} from 'assets/svg';

import { Menu, Tooltip } from 'components';

interface IApplication {
  id: string;
  name: string;
  isReadonly?: boolean;
}

interface Props {
  id: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  dateAdded: string;
  applications: IApplication[];
  isAdmin: boolean;
  hasMfa: boolean;
  openRemoveApplicationModal: (id: string, userName: string, applications: IApplication[]) => void;
  onAssignApplication: (id: string) => void;
  onEdit: () => void;
  onRemove: (userName: string, id: string) => void;
}

const formatDate = (value: string): string => dayjs(value).format('MMMM D, YYYY');

const UsersTableBodyRow: FC<Props> = ({
  id,
  username,
  firstName,
  lastName,
  phone,
  email,
  dateAdded,
  applications,
  isAdmin,
  hasMfa,
  openRemoveApplicationModal,
  onAssignApplication,
  onEdit,
  onRemove,
}) => {
  const { ref, isOverflowX } = useIsOverflow();
  const createdAtFormated = useMemo(() => formatDate(dateAdded), [dateAdded]);
  const changableApplications = useMemo(
    () => applications.filter(a => !a.isReadonly),
    [applications],
  );
  const applicationsNames = useMemo(() => applications
    .map(({ name }) => name)
    .join(', '), [applications]);

  const userName = username || `${firstName} ${lastName}`;

  return (
    <tr className="users-table-body-row">
      <td>
        <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
          {/* <img
            className="app-card__icon-wrapper"
            src={avatarUrl || DefaultAvatarImage}
            alt={`${username} avatar`}
          /> */}
          <div className="app-card__content">
            <div className="app-card__name flex align-items-center rel-flex-gap-4">
              {firstName} {lastName}
              {isAdmin && (
                <div className="user-role-label admin">Admin</div>
              )}
              {hasMfa && (
                <div className="user-role-label mfa">MFA</div>
              )}
            </div>
            <div className="app-card__id">{email}</div>
          </div>
        </div>
      </td>

      <td>{phone || '-'}</td>
      <td>{createdAtFormated}</td>
      <td>
        <Tooltip
          isDisabled={!isOverflowX}
          content={applicationsNames}
          placement="top"
        >
          <div className="text-overflow-ellipsis" ref={ref}>{applicationsNames}</div>
        </Tooltip>
      </td>

      <td>
        <Menu
          trigger={<DotsIcon />}
          items={[
            <div onClick={() => onAssignApplication(id)}><PlusInSquareIcon className="menu-item-icon" />Assign Application</div>,
            <div className={changableApplications.length < 1 ? 'hidden' : ''} onClick={() => openRemoveApplicationModal(id, userName, applications)}><MinusInSquareIcon className="menu-item-icon" />Remove Application(s)</div>,
            <div onClick={onEdit}><EditOutlineIcon className="menu-item-icon" />Edit Profile</div>,
            <div onClick={() => onRemove(userName, id)}><DeleteOutlineIcon className="menu-item-icon" />Remove</div>,
          ]}
          placement="bottom-end"
        />
      </td>
    </tr>
  );
};

export default UsersTableBodyRow;
