import { InfoCircleOutlineIcon } from 'assets/svg';
import { Modal } from 'components';
import { FC } from 'react';

interface Props {
  isShowConfirmationModal: boolean;
  toggleConfirmationModal: () => void;
}

const ConfirmationModal: FC<Props> = ({ isShowConfirmationModal, toggleConfirmationModal }) => (
  <Modal
    visible={isShowConfirmationModal}
    onOk={toggleConfirmationModal}
    okText="Okay"
    cancelText={null}
    title={<>Thank you!<br />Your request was successfully sent.</>}
  >
    <div className="block-info relative-units rel-p-16 rel-m-b-24">
      <InfoCircleOutlineIcon />
      <div className="block-info__content">
        We will review your request and our Sales team will get in touch with you.
      </div>
    </div>
  </Modal>
);

export default ConfirmationModal;