import './Summary.scss';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import cn from 'classnames';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { addProductsToOrder, addUserAssignment, checkCoupon, resetCoupon, setCoupon, setSignature, setTermsId } from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  SignUpNavigation,
  MsOfficeLicenseType,
  ProductChargify,
  CountryCode,
  UserRole,
} from 'enums';
import { PRODUCT_CHARGIFY_TITLES, QUICKBOOK_BUY_MAP_BY_COUNTRY } from 'consts';
import { ICoupon } from 'interfaces';
import {
  usePriceHandler,
  notify,
  getEnvCountryCode,
  useSignUpFormNavigate,
  useGetSignUpTotalPriceByStep,
} from 'helpers';

import { PromocodeIcon, InfoCircleIcon, TaxIcon, ArrowTopIcon, ArrowDownIcon } from 'assets/svg';
import {
  InputText,
  Alert,
  Checkbox,
  Tooltip,
  ProgressBar,
  SignUpBackButton,
  Signature,
  Select,
  Button,
} from 'components';

dayjs.extend(advancedFormat);

enum PromocodeState {
  Default = 'default',
  Error = 'error',
  Success = 'success',
}

interface IPromocodeConfigItem {
  btnLabel: string;
  alertType?: 'info' | 'error' | 'success';
  alertMessage?: string;
}
interface IPromocodeConfig {
  [PromocodeState.Default]: IPromocodeConfigItem;
  [PromocodeState.Error]: IPromocodeConfigItem;
  [PromocodeState.Success]: IPromocodeConfigItem;
}

const DEPOSIT_DESCRIPTION = 'Your deposit is equivalent to one month of service.  Should you choose to terminate your subscription with Summit, your deposit is fully refundable upon request within 30 days of cancellation.';

const SLA_LINK = 'https://www.summithosting.com/services-agreement/';

const PROMOCODE_CONFIG: IPromocodeConfig = {
  [PromocodeState.Default]: {
    btnLabel: 'Apply',
  },
  [PromocodeState.Error]: {
    btnLabel: 'Try Again',
    alertType: 'error',
    alertMessage: 'The coupon code is invalid.',
  },
  [PromocodeState.Success]: {
    btnLabel: 'Remove Code',
    alertType: 'success',
    alertMessage: 'Your coupon was successfuly applied.',
  },
};
// const CONTRACT_TYPE_LABELS: { [key: string]: string } = {
//   [ContractType.Anual]: 'Annual agreement, billed monthly',
//   [ContractType.Monthly]: 'Monthly agreement',
// };
// const CONTRACT_TYPES_MAP: { label: string; value: ContractType }[] = [
//   {
//     label: CONTRACT_TYPE_LABELS[ContractType.Anual],
//     value: ContractType.Anual,
//   },
//   {
//     label: CONTRACT_TYPE_LABELS[ContractType.Monthly],
//     value: ContractType.Monthly,
//   },
// ];

const GET_CUSTOM_PROMOCODE_TEXTS: {
  [key: string]: ({ formatedTotalPrice, formatedDeposit }: { formatedTotalPrice: string; formatedDeposit: string }) => string;
} = {
  'summit15': () => {
    const startingDateToPay = dayjs().add(15, 'day').format('MMMM D, YYYY');
    return `Summit Hosting is pleased to offer you 15 days of free service as a new customer.
      Starting from ${startingDateToPay}, your recurring monthly hosting invoice will be paid with the payment method on file.
      Also, you will pay a one month nontaxable Summit Hosting deposit`;
  },
};

const Summary = () => {
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const simpleFormNavigate = useSignUpFormNavigate();
  const dispatch = useAppDispatch();
  const currentCountryCode = useAppSelector(state => state.order.country?.code || CountryCode.USA);
  const hostProduct = useAppSelector(state => state.order.hostProduct);
  const quickbooksState = useAppSelector(state => state.order.quickbooks);
  const sageState = useAppSelector(state => state.order.sage);
  const msOfficeState = useAppSelector(state => state.order.msOffice);
  const otherProductsState = useAppSelector(state => state.order.otherProducts);
  const additionalServicesState = useAppSelector(state => state.order.additionalServices);
  const couponState = useAppSelector(state => state.order.coupon);
  const signatureState = useAppSelector(state => state.order.signature);
  const termsIdState: number | undefined = useAppSelector(state => state.order.termsId);
  const { data: terms = []} = useAppSelector(state => state.order.getSubscriptionTermsRequest);
  const assignProductsState = useAppSelector(state => state.order.assignProducts);
  const [signatureValue, setSignatureValue] = useState<string>('');
  
  const getAssignedUsersByProduct = useCallback((productName: ProductChargify) => {
    return assignProductsState
      .filter(user => user.products.includes(productName))
      .map(user => user.userInfo);
  }, [assignProductsState]);
  
  const { formatPrice, getProductPrice } = usePriceHandler();
  const { totalPrice, deposit, discount, discountDeposit } = useGetSignUpTotalPriceByStep({ currentStep: SignUpNavigation.Summary });
  const formatedTotalPrice = useMemo(() => {
    return formatPrice(totalPrice, currentCountryCode);
  }, [totalPrice]);
  const formatedTotalPriceWithDeposit = useMemo(() => {
    return formatPrice(totalPrice + deposit, currentCountryCode);
  }, [totalPrice, deposit]);
  const formatedTotalPriceWithDiscount = useMemo(() => {
    return formatPrice(totalPrice - discount, currentCountryCode);
  }, [totalPrice, discount]);
  const formatedDeposit = useMemo(() => {
    return formatPrice(deposit, currentCountryCode);
  }, [deposit]);
  const formatedDepositWithDiscount = useMemo(() => {
    return formatPrice(deposit - discountDeposit, currentCountryCode);
  }, [deposit, discountDeposit]);
  const discountedPriceWithDeposit = useMemo(() => {
    return formatPrice(totalPrice - discount + deposit - discountDeposit, currentCountryCode);
  }, [totalPrice, discount, deposit, discountDeposit]);

  const freeMonthsQuantity = useMemo(() => {
    return (couponState?.duration_period_count ?? 0) + 1;
  }, [couponState]);

  const nextPaymentDate = useMemo(() => {
    return dayjs().add(freeMonthsQuantity, 'month').format('MMMM D, YYYY');
  }, [freeMonthsQuantity]);

  const contractStartDateText = useMemo(() => {
    return ` Your contract starts on ${dayjs().format('MMMM	D, YYYY')}`;
  }, []);

  const promocodeChargeText = useMemo(() => {
    const customText = !!couponState?.code
      && GET_CUSTOM_PROMOCODE_TEXTS?.[couponState.code.toLowerCase()]?.({ formatedTotalPrice, formatedDeposit });
    const monthText = freeMonthsQuantity === 1 ? 'month' : 'months';
    const defaultText = `
      Summit Hosting is pleased to offer you ${freeMonthsQuantity} ${monthText}
      of free service as a new customer. Starting ${nextPaymentDate}, your recurring
      monthly hosting invoice will be paid with the payment method on file.
    `;
    return customText || defaultText;
  }, [freeMonthsQuantity, couponState]);

  const [promocode, setPromocode] = useState<string>('');
  const [promocodeState, setPromocodeState] = useState<PromocodeState>(PromocodeState.Default);
  const [isContractChecked, setIsContractChecked] = useState<boolean>(false);
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState<boolean>(false);
  const [isPromocodeLoading, setIsPromocodeLoading] = useState<boolean>(false);
  const [selectedTermsId, setSelectedTermsId] = useState<number | undefined>(termsIdState);
  const [openedProductsDetails, setOpenedProductsDetails] = useState<ProductChargify[]>([]);
  const [openedProductsUserDetails, setOpenedProductsUserDetails] = useState<ProductChargify[]>([]);

  const getTermNameById = useMemo(() => terms.find((t: any) => t.id === selectedTermsId)?.name, [selectedTermsId]);

  useEffect(() => {
    if (couponState !== null) {
      setPromocode(couponState.code);
      setPromocodeState(PromocodeState.Success);
    }
    setSignatureValue(signatureState ?? '');
    setIsTermsAndConditionsChecked(Boolean(signatureState));
    setIsContractChecked(Boolean(signatureState));
  }, []);

  const getUsersQuickTotals = useCallback((users) => {
    return {
      [UserRole.Admin]: users.filter((u: any) => u.userRole === UserRole.Admin).length,
      [UserRole.Standard]: users.filter((u: any) => u.userRole === UserRole.Standard).length,
    };
  }, []);

  const applyPromocode = async () => {
    setIsPromocodeLoading(true);
    try {
      const couponPayload: ICoupon = await dispatch(checkCoupon({ code: promocode, _disableErrorHandler: true })).unwrap();
      dispatch(setCoupon(couponPayload));
      setPromocodeState(PromocodeState.Success);
    } catch (err) {
      if ((err as SerializedError).code === '400') {
        setPromocodeState(PromocodeState.Error);
      } else if (typeof (err as SerializedError).message === 'string') {
        notify.error(
          'Error',
          (err as SerializedError).message,
        );
      }
    } finally {
      setIsPromocodeLoading(false);
    }
  };

  const removePromocode = async () => {
    setIsPromocodeLoading(true);
    try {
      await dispatch(resetCoupon()).unwrap();
      dispatch(setCoupon(null));
      setPromocode('');
      setPromocodeState(PromocodeState.Default);
    } finally {
      setIsPromocodeLoading(false);
    }
  };

  const PROMOCODE_BTN_HANDLERS = {
    [PromocodeState.Default]: applyPromocode,
    [PromocodeState.Error]: applyPromocode,
    [PromocodeState.Success]: removePromocode,
  };

  const CHECKOUT_PRODUCTS_MAP: { name: ProductChargify; quantity: number; price: string, productDetails: any[], assignedUsers: any[], }[] = useMemo(() => {
    return [
      {
        name: ProductChargify.SHServerUserHosting,
        quantity: hostProduct.quantity,
        price: getProductPrice(ProductChargify.SHServerUserHosting, hostProduct.quantity),
        productDetails: [],
        assignedUsers: getAssignedUsersByProduct(ProductChargify.SHServerUserHosting),
      },
      ...(quickbooksState.data.name !== null ? [{
        name: quickbooksState.data.name,
        quantity: quickbooksState.data.quantity,
        price: getProductPrice(quickbooksState.data.name, quickbooksState.data.quantity),
        productDetails: [
          ...(quickbooksState.data.licenseCode ? [{ label: 'License code', value: quickbooksState.data.licenseCode }] : []),
          ...(quickbooksState.data.productCode ? [{ label: 'Product code', value: quickbooksState.data.productCode }] : []),
          ...(quickbooksState.data.year ? [{ label: 'Edition', value: quickbooksState.data.year }] : []),
        ],
        assignedUsers: getAssignedUsersByProduct(quickbooksState.data.name),
      }] : []),
      ...(sageState.name !== null ? [{
        name: sageState.name,
        quantity: sageState.quantity,
        price: getProductPrice(sageState.name, sageState.quantity),
        productDetails: [
          ...(sageState.serialNumber ? [{ label: 'Serial number', value: sageState.serialNumber }] : []),
          ...(sageState.year ? [{ label: 'Edition', value: sageState.year }] : []),
        ],
        assignedUsers: getAssignedUsersByProduct(sageState.name),
      }] : []),
      ...(msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense ? [{
        name: ProductChargify.MSOfficeOwn,
        quantity: hostProduct.quantity,
        price: getProductPrice(ProductChargify.MSOfficeOwn, hostProduct.quantity),
        productDetails: [],
        assignedUsers: getAssignedUsersByProduct(ProductChargify.MSOfficeOwn),
      }] : []),
      ...msOfficeState.products.reduce((acc, { isChecked, name, quantity }) => {
        if (isChecked) acc.push({
          name,
          quantity,
          price: getProductPrice(name, quantity),
          productDetails: [],
          assignedUsers: getAssignedUsersByProduct(name),
        });
        return acc;
      }, [] as any[]),
      ...otherProductsState.reduce((acc, { isChecked, name, quantity, price, serialNumber, productCode, licenseCode }) => {
        if (isChecked) acc.push({
          name,
          quantity,
          price: formatPrice(price * +quantity, currentCountryCode),
          productDetails: [
            ...(licenseCode ? [{ label: 'License code', value: licenseCode }] : []),
            ...(productCode ? [{ label: 'Product code', value: productCode }] : []),
            ...(serialNumber ? [{ label: 'Serial number', value: serialNumber }] : []),
          ],
          assignedUsers: getAssignedUsersByProduct(name as ProductChargify),
        });
        return acc;
      }, [] as any[]),
      ...additionalServicesState.map(({ name, quantity }) => ({
        name,
        quantity,
        productDetails: [],
        assignedUsers: [],
        price: getProductPrice(name as ProductChargify),
      })),
      {
        name: ProductChargify.SSW,
        quantity: 1,
        productDetails: [],
        assignedUsers: [],
        price: getProductPrice(ProductChargify.SSW),
      },
    ];
  }, [hostProduct, quickbooksState, sageState, msOfficeState, assignProductsState, otherProductsState, additionalServicesState]);

  const toggleProductDetails = useCallback((checkoutProduct: ProductChargify) => {
    setOpenedProductsDetails((state) => {
      if (state.includes(checkoutProduct)) {
        return state.filter(e => e !== checkoutProduct);
      }
      return [...state, checkoutProduct];
    });
  }, []);
  const toggleProductUserDetails = useCallback((checkoutProduct: ProductChargify) => {
    setOpenedProductsUserDetails((state) => {
      if (state.includes(checkoutProduct)) {
        return state.filter(e => e !== checkoutProduct);
      }
      return [...state, checkoutProduct];
    });
  }, []);

  const isShowPromocodeAlert = useMemo(() =>
    promocodeState === PromocodeState.Error || promocodeState === PromocodeState.Success, [promocodeState]);

  const isShowContractCheckbox = useMemo(() => {
    return couponState === null || couponState.code.toLocaleLowerCase() !== 'summit15';
  }, [couponState]);

  const isValid = useMemo(() => {
    const isContractCheckboxValid = isShowContractCheckbox ? isContractChecked : true;
    return isTermsAndConditionsChecked && !!selectedTermsId && isContractCheckboxValid && !!signatureValue;
  }, [isTermsAndConditionsChecked, selectedTermsId, isContractChecked, signatureValue, isShowContractCheckbox]);

  const nextHandler = async () => {
    setIsNextLoading(true);
    try {
      dispatch(setSignature(signatureValue));
      dispatch(setTermsId(selectedTermsId));
      const productsToAdd: any = [
        {
          name: ProductChargify.SHServerUserHosting,
          quantity: Number(hostProduct.quantity),
        },
        {
          name: ProductChargify.SSW, 
          quantity: 1
        },
        ...(quickbooksState.data.name !== null ? [{
          name: quickbooksState.data.name,
          quantity: Number(quickbooksState.data.quantity),
          customInfo: {
            ...(quickbooksState.data.licenseCode ? { licenseCode: quickbooksState.data.licenseCode } : {}),
            ...(quickbooksState.data.productCode ? { productCode: quickbooksState.data.productCode } : {}),
            ...(quickbooksState.data.year ? { year: quickbooksState.data.year } : {}),
            provideMetafields: 'true',
          },
        }] : []),
        ...(sageState.name !== null ? [{
          name: sageState.name,
          quantity: Number(sageState.quantity),
          customInfo: {
            ...(sageState.serialNumber ? { serialNumber: sageState.serialNumber } : {}),
            ...(sageState.year ? { year: sageState.year } : {}),
            provideMetafields: 'true',
          },
        }] : []),
        ...(msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense ? [{
          name: ProductChargify.MSOfficeOwn,
          quantity: Number(hostProduct.quantity),
          customInfo: {
            provideMetafields: 'true',
          },
        }] : []),
        ...msOfficeState.products.reduce((acc, { isChecked, name, quantity }) => {
          if (isChecked) acc.push({
            name,
            quantity: Number(quantity),
            customInfo: {
              provideMetafields: 'true',
            },
          });
          return acc;
        }, [] as any[]),
        ...otherProductsState.reduce((acc, { isChecked, name, quantity, price, serialNumber, productCode, licenseCode }) => {
          if (isChecked) acc.push({
            name,
            quantity: Number(quantity),
            customInfo: {
              serialNumber,
              productCode,
              licenseCode,
              provideMetafields: 'true',
            },
          });
          return acc;
        }, [] as any[]),
        ...additionalServicesState.map(({ name, quantity }) => ({
          name,
          quantity: Number(quantity),
        })),
        {
          name: ProductChargify.Deposit,
          quantity: Math.floor(totalPrice)
        },
      ];
      await dispatch(addProductsToOrder(productsToAdd)).unwrap(),
      await dispatch(addUserAssignment(assignProductsState)).unwrap();
    } finally {
      setIsNextLoading(false);
      simpleFormNavigate(SignUpNavigation.Payment);
    }
  };

  const backHandler = () => {
    simpleFormNavigate(SignUpNavigation.SetupUsers);
  };
  return (
    <>
      <SignUpBackButton onClick={backHandler} />
      <div className="container-sm">
        <ProgressBar currentStep={7} totalSteps={7} />
        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-8">Summary</div>
          <div className="summary-table m-b-24">
            <div className="table-row header-row">
              <div className="table-cell">Products</div>
              <div className="table-cell">Quantity</div>
              <div className="table-cell">Price</div>
            </div>
            <div>
              {CHECKOUT_PRODUCTS_MAP.map(checkoutProduct => (
                <>
                  <div className="table-row" key={checkoutProduct.name}>
                    <div className="table-cell">
                      <div className="summary-product-name">{checkoutProduct.name}</div>
                      {checkoutProduct.assignedUsers.length
                        || checkoutProduct.productDetails.length
                        ? <div className="summary-show-more" onClick={() => toggleProductDetails(checkoutProduct.name)}>
                          {openedProductsDetails.includes(checkoutProduct.name)
                            ? <>Hide details <ArrowTopIcon /></>
                            : <>More details <ArrowDownIcon /></>
                          }
                        </div>
                        : null}
                    </div>
                    <div className="table-cell">x{checkoutProduct.quantity}</div>
                    <div className="table-cell">{
                      QUICKBOOK_BUY_MAP_BY_COUNTRY[getEnvCountryCode(currentCountryCode)].some(
                        ({ name }) => checkoutProduct.name === name,
                      )
                        ? 'TBD'
                        : checkoutProduct.price
                    }</div>
                  </div>
                  {openedProductsDetails.includes(checkoutProduct.name) && (checkoutProduct.assignedUsers.length || checkoutProduct.productDetails.length)
                    ? (<div className="summary-product-details-block p-x-24 sm-p-x-16 p-y-16">
                      {checkoutProduct.productDetails.length ? (
                        <div className={cn(
                          "summary-product-details-info",
                          { 'show-divider': checkoutProduct.assignedUsers.length && checkoutProduct.productDetails.length }
                        )}>
                          {checkoutProduct.productDetails.map(detail => (
                            <div className="summary-product-details-info__item">
                              <div className="summary-product-details-info__item__label">
                                {detail.label}
                              </div>
                              <div className="summary-product-details-info__item__value">
                                {detail.value}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {checkoutProduct.assignedUsers.length ? (
                        <div className="summary-product-users">
                          <div className="summary-product-users__total-block">
                            <div className="summary-product-users-totals">
                              <div className="summary-product-users-totals__item">
                                Admin users: <span>{getUsersQuickTotals(checkoutProduct.assignedUsers)[UserRole.Admin]}</span>
                              </div>
                              <div className="summary-product-users-totals__item">
                                Standard users: <span>{getUsersQuickTotals(checkoutProduct.assignedUsers)[UserRole.Standard]}</span>
                              </div>
                            </div>
                            <div className="summary-show-more" onClick={() => toggleProductUserDetails(checkoutProduct.name)} >
                              {openedProductsUserDetails.includes(checkoutProduct.name)
                                ? <>Hide user details <ArrowTopIcon /></>
                                : <>More user details <ArrowDownIcon /></>
                              }
                            </div>
                          </div>
                          {openedProductsUserDetails.includes(checkoutProduct.name) ? (
                            <div className="summary-product-users__details">
                              {checkoutProduct.assignedUsers.map(user => (
                                <div className="summary-product-users__details__row">
                                  <div className="summary-product-users__details__row__name">{user.firstName}</div>
                                  <div className="summary-product-users__details__row__role">{user.userRole}</div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    ) : null}
                </>
              ))}
            </div>
          </div>
          <div className="summary-price deposit m-b-24">
            Subtotal
            <span>
              {promocodeState === PromocodeState.Success && discountDeposit > 0
                ? <del>{formatedTotalPrice}</del>
                : formatedTotalPrice}
            </span>
          </div>
          <div className="summary-price deposit m-b-12">
            {PRODUCT_CHARGIFY_TITLES[ProductChargify.Deposit]}
            <span>
              {promocodeState === PromocodeState.Success && discountDeposit > 0
                ? <del>{formatedDeposit}</del>
                : formatedDeposit}
            </span>
          </div>
          <div className="summary-deposit-desciption m-b-24">The deposit matches your first invoice amount and is refundable upon request within 30 days of cancellation.</div>
          <div
            className="summary-price m-b-16">
            Total
            <span>
              {promocodeState === PromocodeState.Success
                ? <del>{formatedTotalPriceWithDeposit}</del>
                : formatedTotalPriceWithDeposit}
            </span>
          </div>
          {
            promocodeState === PromocodeState.Success ? (
              <div className="summary-price m-b-16">
                Discounted Price
                <span>{discountedPriceWithDeposit}</span>
              </div>
            ) : null
          }
          {/* <div className={cn('block-info m-b-20')}>
            <InfoCircleIcon />
            <div className="block-info__content pay-info">
              <div>
                Today you will pay <b>{formatedTotalPriceWithDiscount}</b> for monthly hosting.
              </div>
              <div>
                Today you will pay <b>{formatedDepositWithDiscount}</b> for your nontaxable Summit Hosting &#x200B;
                <Tooltip
                  absoluteStyles
                  content={DEPOSIT_DESCRIPTION}
                  placement="bottom-end"
                  isInline
                ><span className="link summary-link font-weight-400">deposit</span></Tooltip>
                .
              </div>
            </div>
          </div> */}
          {promocodeState === PromocodeState.Success && (
            <>
              <p className={cn('summary-coupon-charge m-b-20')}>
                {promocodeChargeText}
              </p>
            </>
          )}
          {currentCountryCode === CountryCode.Canada && (
            <div className="block-info m-b-20">
              <TaxIcon />
              Taxes will be applied after
            </div>
          )}
          <div className="promotion-block m-t-32">
            <InputText
              absoluteStyles
              xVersion
              classes={promocodeState === PromocodeState.Default ? 'm-b-32' : 'm-b-12'}
              label="Promotion code"
              placeholder="Enter code"
              icon={PromocodeIcon}
              readOnly={promocodeState === PromocodeState.Success}
              isWithButton
              value={promocode}
              onChange={setPromocode}
              buttonText={PROMOCODE_CONFIG[promocodeState].btnLabel}
              buttonDisabled={promocode.length === 0}
              buttonAction={PROMOCODE_BTN_HANDLERS[promocodeState]}
              isButtonLoading={isPromocodeLoading}
              isNewStyle
            />
            {isShowPromocodeAlert && (
              <Alert
                absoluteStyles
                classes="m-b-32"
                type={PROMOCODE_CONFIG[promocodeState]?.alertType}
                message={PROMOCODE_CONFIG[promocodeState]?.alertMessage}
                fullWidth
              />
            )}
            <Select
              xVersion
              absoluteStyles
              options={terms.map(({ id, name }: any) => ({ label: name, value: id }))}
              value={selectedTermsId}
              classes="m-b-24 m-t-32 sm-m-t-24"
              label="Contract Term"
              placeholder=""
              isRequired
              onChange={setSelectedTermsId}
            />
            {isShowContractCheckbox && (
              <Checkbox
                absoluteStyles
                classes="m-b-24 summary-agree-checkbox"
                isChecked={isContractChecked}
                onChange={(e) => setIsContractChecked((e.target as HTMLInputElement).checked)}
              >
                You are agreeing to a {getTermNameById} contract term that will be billed monthly. &#x200B;
                <span>{contractStartDateText}</span>
              </Checkbox>
            )}
            <Checkbox
              absoluteStyles
              isChecked={isTermsAndConditionsChecked}
              classes="m-b-32 summary-agree-checkbox"
              onChange={(e) => setIsTermsAndConditionsChecked((e.target as HTMLInputElement).checked)}
            >
              <span>I agree with the terms and conditions under the</span> &#x200B;
              <a className="link font-weight-400 summary-link" href={SLA_LINK} target="_blank">Service Level Agreement (SLA)</a>
            </Checkbox>
            <Signature
              absoluteStyles
              xVersion
              value={signatureValue}
              isNewStyle
              onChange={setSignatureValue}
            />
          </div>
          <div className="flex flex-justify-center m-t-40 sm-m-t-32">
            <Button
              dataTestId="summary.next-button"
              xVersion
              absoluteStyles
              isLoading={isNextLoading}
              isDisabled={!isValid}
              onClick={nextHandler}
            >
              Proceed to Payment
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;