import { useRef } from 'react';
import { useUpdateEffect } from 'helpers';
import { useAppSelector } from 'store/hooks';

export function useCartCompleteSuccess(callback: () => void) {
  const prevIsSuccess = useRef<boolean>(false);
  const { isSuccess } = useAppSelector(state => state.ussp.completeUsspCartRequest);
  useUpdateEffect(() => {
    if (prevIsSuccess.current !== isSuccess && isSuccess) {
      callback();
    }
    prevIsSuccess.current = isSuccess;
  }, [isSuccess]);
}
