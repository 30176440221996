export const makeVh = (px: string | number, windowHeight: number = 760) => {
  return 100 * (Number(px) / windowHeight) + 'vh';
};
export const makeVw = (px: string | number, windowWidth: number = 1440) => {
  return 100 * (Number(px) / windowWidth) + 'vw';
};

export const makePxWindowUnit = (px: string | number, currentWindowProp: string | number, unit: 'height' | 'width') => {
  return Number(px) / (unit === 'width' ? 1440 : 760) * Number(currentWindowProp);
};

export const makePxDependOnWindowHeight = (px: string | number) => {
  const vhValue = makePxWindowUnit(px, 100, 'height');
  return vhValue * window.innerHeight / 100;
};
export const makePxDependOnWindowWidth = (px: string | number) => {
  const vwValue = makePxWindowUnit(px, 100, 'width');
  return vwValue * window.innerWidth / 100;
};
