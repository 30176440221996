import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { DotsIcon } from 'assets/svg';

const ApplicationsTableBodyRowLoader: FC = () => (
  <tr>
    <td>
      <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
        {/* <Skeleton containerClassName="app-card__icon-skeleton" height="100%" /> */}
        <div className="app-card__content">
          <Skeleton containerClassName="app-card__name" height="100%" width="70%" />
          <Skeleton containerClassName="app-card__id" height="100%" />
        </div>
      </div>
    </td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><DotsIcon /></td>
  </tr>
);

export default ApplicationsTableBodyRowLoader;
