import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks';
import { canUserMfaBeRemoved, removeUserMfa } from 'store';

import { notify } from 'helpers';

import { InfoCircleIcon } from 'assets/svg';

import { SidebarModal } from 'components';

interface Props {
  visible: boolean;
  userData: { id: string; userName: string; email: string };
  onCancel: () => void;
  onSuccess: () => void;
}

const RemoveMfaSidebarModal: FC<Props> = ({
  visible,
  userData,
  onCancel,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();

  const { subscriptionId } = useParams();

  const submitHandler = async () => {
    if (typeof subscriptionId === 'string' && typeof userData?.id === 'string') {
      const payload = {
        subscriptionId,
        userIds: [userData.id],
      };
      const canBeRemoved = await dispatch(canUserMfaBeRemoved(payload)).unwrap();
      if (canBeRemoved) {
        await dispatch(removeUserMfa(payload)).unwrap();
        notify.success('Success', 'MFA successfully removed from user');
        onSuccess();
      } else {
        notify.error('Error', 'MFA can\'t be removed from user');
      }
      onCancel();
    }
  };

  return (
    <SidebarModal
      title="Remove MFA"
      visible={visible}
      onCancel={onCancel}
      onOk={submitHandler}
      okText="Yes, Remove"
      cancelText="Cancel"
      // isOkLoading={isLoading}
      contentClasses="flex-column"
    >
      <div className="block-info relative-units rel-m-b-16 app-card">
        <div className="app-card__content">
          <div className="app-card__name app-card__name--small">{userData?.userName}</div>
          <div className="app-card__id">{userData?.email}</div>
        </div>
      </div>
      <div className="block-info relative-units">
        <InfoCircleIcon />
        <p className="block-info__content">
          By removing MFA, you are removing admin rights to the server for this user.&nbsp;
          <strong>Are you sure want to continue?</strong>
        </p>
      </div>
    </SidebarModal>
  );
};

export default RemoveMfaSidebarModal;