import { FC, SyntheticEvent } from 'react';
import cn from 'classnames';
import { Checkbox } from 'components';
import './CheckboxButton.scss';

interface Props {
  xVersion?: boolean;
  isChecked: boolean;
  isIndeterminate?: boolean;
  label: string;
  rightLabel?: string;
  isDisabled?: boolean;
  isDisabledOpacity?: boolean;
  classes?: string;
  dataTestId?: string;
  absoluteStyles?: boolean;
  noCheckedBorderColor?: boolean;
  whiteReadOnly?: boolean;
  size?: string;
  onChange: (e: SyntheticEvent) => void;
}

const CheckboxButton: FC<Props> = ({
  isChecked = false,
  isIndeterminate = false,
  label,
  rightLabel,
  isDisabled = false,
  isDisabledOpacity = false,
  absoluteStyles = false,
  noCheckedBorderColor = false,
  whiteReadOnly = false,
  classes = '',
  xVersion,
  dataTestId,
  size,
  onChange,
}) => {
  const handleKeyDown = (event: any) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      onChange({ ...event, target: { ...event.target, checked: !isChecked } });
    }
  };

  return (
    <label
      data-test-id={dataTestId}
      tabIndex={isDisabled ? -1 : 0}
      className={cn('checkbox-button new-style', classes, size, {
        checked: isChecked,
        'with-rl': rightLabel,
        disabled: isDisabled,
        'disabled-opacity': isDisabledOpacity,
        'relative-units': !absoluteStyles,
        xVersion,
        noCheckedBorderColor,
        whiteReadOnly
      })}
      onKeyDown={handleKeyDown}
    >
      <Checkbox
        keyEventDisabled
        size={size}
        absoluteStyles={absoluteStyles}
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
        label={label}
        isDisabled={isDisabled}
        onChange={onChange}
      />
      {rightLabel && <div className="right-label">{rightLabel}</div>}
    </label>
  );
};

export default CheckboxButton;