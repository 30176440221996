import React, { FC } from 'react';
import { ITableFilterOrdering } from 'interfaces';
import { TableFilter, TableOrder, TableSearch } from 'components';

interface Props {
  initialSearchQuery?: string;
  productsForFilter: any[];
  applicationsIdsValue: string[];
  orderState?: ITableFilterOrdering;
  onSearch: (searchQuery: string) => void;
  onOrderChange: (data: ITableFilterOrdering) => void;
  onFilterChange: (data: string[]) => void;
}

const UsersTableHeadRow: FC<Props> = ({
  productsForFilter = [],
  initialSearchQuery = '',
  applicationsIdsValue = [],
  orderState,
  onSearch,
  onOrderChange,
  onFilterChange,
}) => (
  <tr className="users-table-head-row">
    <th>
      {/* <TableFilter
        options={[]}
        onChange={() => undefined}
        // onChange={onFilterByStatusesChange}
      >
        <TableOrder
          label="USERNAMES"
          fieldName="FirstName"
          currentOrderState={orderState}
          onChange={onOrderChange}
        />
      </TableFilter> */}
      <TableSearch
        initialValue={initialSearchQuery}
        label="USERNAMES"
        searchBy="Search by Email or Name..."
        onSearch={onSearch}
      />
    </th>
    <th>PHONE NUMBER</th>
    <th>
      <TableOrder
        label="DATE ADDED"
        fieldName="DateAdded"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>
      <TableFilter
        values={applicationsIdsValue}
        showAlways
        options={productsForFilter.map(({ id, name }) => ({ label: name, value: id }))}
        onChange={onFilterChange}
        label="APPLICATIONS"
      />
    </th>
    <th>ACTIONS</th>
  </tr>
);

export default UsersTableHeadRow;
