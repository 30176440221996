import { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AccessTokenType } from 'enums';
import { INTENDED_URL } from 'consts';

interface Props {
  additionalRedirect?: boolean;
  children: ReactNode;
}

const PrivateRoute: FC<Props> = ({
  additionalRedirect,
  children,
}) => {
  const location = useLocation();
  const accessToken = localStorage.getItem(AccessTokenType.AccessTokenUssp);
  if (!accessToken) {
    sessionStorage.setItem(INTENDED_URL, location.pathname + location.search);
    // not logged in so redirect to login page
    return <Navigate to="/login"/>;
  }
  if (additionalRedirect) {
    return <Navigate to="/login"/>;
  }
  // authorized so return child components
  return <>{children}</>;
};

export default PrivateRoute;