import './TableOrder.scss';
import { FC, useMemo, useCallback } from 'react';
import cn from 'classnames';
import _debounce from 'lodash/debounce';

import { SortingState } from 'enums';
import { ITableFilterOrdering } from 'interfaces';

import { SortArrowDownIcon, SortArrowUpIcon } from 'assets/svg';

interface Props {
  children?: JSX.Element | string;
  label?: string;
  fieldName: string;
  currentOrderState?: ITableFilterOrdering;
  onChange: (data: ITableFilterOrdering) => void;
}

const TableSort: FC<Props> = ({ children, label, fieldName, currentOrderState, onChange }) => {
  const isCurrentFieldSelected = useMemo(
    () => fieldName === currentOrderState?.orderBy,
    [fieldName, currentOrderState?.orderBy],
  );
  const debouncedOnChange = useCallback(_debounce(onChange, 500), [onChange]);
  const onClickHandler = () => {
    debouncedOnChange({
      orderBy: fieldName,
      order: isCurrentFieldSelected && currentOrderState?.order === SortingState.ASC
        ? SortingState.DESC
        : SortingState.ASC,
    });
  };

  return (
    <div className="table-order">
      {children || label}
      <div
        className="table-order__icons-wrapper"
        onClick={onClickHandler}
      >
        <SortArrowUpIcon className={cn('table-order__icon', {
          active: isCurrentFieldSelected && currentOrderState?.order === SortingState.ASC,
        })} />
        <SortArrowDownIcon className={cn('table-order__icon', {
          active: isCurrentFieldSelected && currentOrderState?.order === SortingState.DESC,
        })} />
      </div>
    </div>
  );
};

export default TableSort;