import { FC } from 'react';
import { ITableFilterOrdering } from 'interfaces';
import { TableSearch, TableOrder } from 'components';

interface Props {
  initialSearchQuery?: string;
  orderState?: ITableFilterOrdering | undefined;
  onSearch: (searchQuery: string) => void;
  onOrderChange: (data: ITableFilterOrdering) => void;
}

const ApplicationsTableHeadRow: FC<Props> = ({
  initialSearchQuery = '',
  orderState,
  onSearch,
  onOrderChange,
}) => (
  <tr className="applications-table-head-row">
    <th>
      <TableSearch
        initialValue={initialSearchQuery}
        label="APP TITLE"
        searchBy="Search by Title..."
        onSearch={onSearch}
      />
    </th>
    <th>
      <TableOrder
        label="QTY OF LICENSE"
        fieldName="Quantity"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>APP DETAILS</th>
    <th>ACTIONS</th>
  </tr>
);

export default ApplicationsTableHeadRow;
