import { FC, useMemo } from 'react';
import './SignUpBackButton.scss';
import { ArrowLeftFullIcon } from 'assets/svg';

interface Props {
  onClick: () => void;
}
const SignUpBackButton: FC<Props> = ({ onClick }) => {
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClick();
    }
  };
  return (
    <div
      data-test-id="sign-up-back-button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className="sign-up-back"
      onClick={onClick}
    >
      <ArrowLeftFullIcon className="sign-up-back__back-icon" /> Back
    </div>
  );
};

export default SignUpBackButton;