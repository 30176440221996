import './CartSectionItem.scss';
import { FC } from 'react';
import cn from 'classnames';

import { CloseIcon } from 'assets/svg';

interface Props {
  title: string;
  price: string;
  isDisabled?: boolean;
  onRemove: () => void;
}

const CartSectionItem: FC<Props> = ({
  title,
  price,
  isDisabled = false,
  onRemove,
}) => {
  const onRemoveHandler = () => {
    if (!isDisabled) {
      onRemove();
    }
  };

  return (
    <div className={cn(
      'cart-section-item flex flex-justify-between flex-align-center rel-flex-gap-16',
      { 'cart-section-item--disabled': isDisabled },
    )}>
      <CloseIcon className="rel-m-r-4" onClick={onRemoveHandler} />
      <span className="flex-1">{title}</span>
      <span className="font-weight-600">{price}</span>
    </div>
  );
};

export default CartSectionItem;