import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { usePriceHandler } from 'helpers';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getBillingInvoiceFile } from 'store';

import { INVOICE_STATUS_CLASSES } from 'consts';
import { CountryCode, InvoiceStatus } from 'enums';

import { DotsIcon, ShowOutlineIcon, PaperDownloadIcon } from 'assets/svg';

import { Menu } from 'components';

interface Props {
  subscriptionId: string;
  id: string;
  number: string;
  isManageable: boolean;
  issuedDate: string;
  dueDate: string;
  amountTotal: number;
  dueAmount: number;
  status: InvoiceStatus;
  onViewInvoices: () => void;
}

const formatDate = (value: string): string => dayjs(value).format('MMM D, YYYY');

const BillingInvoicesTableBodyRow: FC<Props> = ({
  subscriptionId,
  id,
  number,
  issuedDate,
  dueDate,
  amountTotal,
  isManageable,
  dueAmount,
  status,
  onViewInvoices,
}) => {
  const dispatch = useAppDispatch();
  const currentCountry = useAppSelector((state) => state.ussp.user?.country || CountryCode.USA);
  const { formatPrice } = usePriceHandler();

  const issuedDateFormated = useMemo(() => issuedDate === null ? '' : formatDate(issuedDate || ''), [issuedDate]);
  const dueDateFormated = useMemo(() => dueDate === null ? '' : formatDate(dueDate || ''), [dueDate]);
  const amountTotalFormated = useMemo(() => formatPrice(amountTotal || 0, currentCountry), [
    amountTotal,
    currentCountry,
  ]);
  const dueAmountFormated = useMemo(() => formatPrice(dueAmount || 0, currentCountry), [
    dueAmount,
    currentCountry,
  ]);

  const onPreviewInvoiceClick = async () => {
    const data = await dispatch(getBillingInvoiceFile({
      invoiceId: id,
      subscriptionId,
    })).unwrap();
    const url = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    window.open(url, '_blank');
  };
  const onDownloadInvoiceClick = async () => {
    const data = await dispatch(getBillingInvoiceFile({
      invoiceId: id,
      subscriptionId,
    })).unwrap();
    const url = URL.createObjectURL(new Blob([data]));
    const alink = document.createElement('a');
    alink.href = url;
    alink.download = `invoice_${id}.pdf`;
    alink.click();
  };

  return (
    <tr className="billings-table-body-row">
      <td>{number}</td>
      <td>{issuedDateFormated}</td>
      <td>{dueDateFormated}</td>
      <td>{amountTotalFormated}</td>
      <td>{dueAmountFormated}</td>
      <td><p className={INVOICE_STATUS_CLASSES[status]}>{status}</p></td>
      {isManageable ? null : (<td>
        {status === 'paid'
          ? (<span className="btn btn-text" onClick={onViewInvoices}>
            View
          </span>)
          : '-'
        }
      </td>)}
      <td>
        <Menu
          trigger={<DotsIcon />}
          items={[
            <div onClick={onPreviewInvoiceClick}><ShowOutlineIcon className="menu-item-icon" />View Invoice</div>,
            <div onClick={onDownloadInvoiceClick}><PaperDownloadIcon className="menu-item-icon" />Download PDF</div>,
          ]}
          placement="bottom-end"
        />
      </td>
    </tr>
  );
};

export default BillingInvoicesTableBodyRow;
