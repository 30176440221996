import './CustomFieldsModalFooter.scss';
import { FC, ReactElement } from 'react';
import cn from 'classnames';

interface Props {
  showDownload?: boolean;
  onSuccess: () => void;
  onDownload?: () => void;
}

const CustomFieldsModalFooter: FC<Props> = ({
  showDownload,
  onSuccess,
  onDownload,
}) => (
  <div className="custom-fields-modal-footer">
    {
      showDownload 
        ? (
          <button
            type="button"
            className="btn relative-units secondary outline new-style"
            onClick={onDownload}
          >Download PDF</button>
        )
        : null
    }
    <button
      type="button"
      className={cn('btn relative-units primary new-style')}
      onClick={onSuccess}
    >Okay</button>
  </div>
);

export default CustomFieldsModalFooter;