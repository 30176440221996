import React, { FC } from 'react';
import { inputFieldStyles, inputFieldStylesXVersion, selectFieldStyles, selectFieldStylesXVersion } from './styleConfig';

const getFields = ({ xVersion }: { xVersion?: boolean }) => ({
  firstName: {
    selector: '#chargify1',
    label: 'First Name on Account',
    placeholder: xVersion ? ' ' : 'John',
    required: true,
    message: 'Incorrect first name.',
    maxlength: '30',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  lastName: {
    selector: '#chargify2',
    label: 'Last Name on Account',
    placeholder: xVersion ? ' ' : 'Doe',
    required: true,
    message: 'Incorrect last name.',
    maxlength: '30',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  bankName: {
    selector: '#chargify3',
    label: 'Bank Name',
    placeholder: xVersion ? ' ' : 'My Bank',
    message: 'Incorrect bank name.',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  accountHolderType: {
    selector: '#chargify4',
    label: 'Account Holder Type',
    placeholder: xVersion ? ' ' : 'Select One...',
    required: true,
    message: 'Incorrect account holder type.',
    style: xVersion ? selectFieldStylesXVersion : selectFieldStyles,
  },
  routingNumber: {
    selector: '#chargify5',
    label: 'Routing Number',
    placeholder: xVersion ? ' ' : '123412341234',
    required: true,
    message: 'Incorrect routing number.',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  accountNumber: {
    selector: '#chargify6',
    label: 'Account Number',
    placeholder: xVersion ? ' ' : '123412341234',
    required: true,
    message: 'Incorrect account number.',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  address: {
    selector: '#chargify7',
    label: 'Address',
    placeholder: xVersion ? ' ' : '1234 Hill St',
    required: true,
    message: 'Incorrect valid address.',
    maxlength: '70',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
});

interface Props {
  hide: boolean;
  xVersion?: boolean;
}

const template: FC<Props> = ({ hide, xVersion }) => (
  <div style={hide ? { display: 'none' } : {}}>
    <div className="row-items">
      <div id="chargify1"></div>
      <div id="chargify2"></div>
    </div>
    <div id="chargify3"></div>
    <div id="chargify4"></div>
    <div id="chargify5"></div>
    <div id="chargify6"></div>
    <div id="chargify7"></div>
  </div>
);

export {
  getFields,
  template,
};
