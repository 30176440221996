import { useNavigate } from 'react-router-dom';

import { OrderNavigation, SignUpNavigation } from 'enums';

import { useAppDispatch } from 'store/hooks';
import { setBreadCrumb, setSignUpBreadCrumb } from 'store';

export function useSimpleFormNavigate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (orderNavigationPath: OrderNavigation): void => {
    dispatch(setBreadCrumb(orderNavigationPath));
    navigate(orderNavigationPath);
  };
}
export function useSignUpFormNavigate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (orderNavigationPath: SignUpNavigation, state: any = {}): void => {
    dispatch(setSignUpBreadCrumb(orderNavigationPath));
    navigate(`/sign-up${orderNavigationPath}`, { state });
  };
}
