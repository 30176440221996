import './RemoveApplicationFromUserModal.scss';
import { InfoCircleIcon } from 'assets/svg';
import { Checkbox, StepModal } from 'components';
import { notify } from 'helpers';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { removeApplicationFromUser } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IApplication {
  id: string;
  userName: string;
  applications: string[];
}

interface Props {
  visible: boolean;
  onCancel: () => void;
  removeApplicationsData: IApplication[];
  onSuccess: () => void;
  isAdminPortal?: boolean;
}

const RemoveApplicationFromUserModal: FC<Props> = ({
  visible,
  onCancel,
  removeApplicationsData,
  onSuccess,
  isAdminPortal = false,
}) => {
  const { subscriptionId } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.ussp.removeApplicationFromUserRequest);

  const { id, userName, applications }: any = removeApplicationsData;
  
  const changableApplications = useMemo(
    () => applications.filter((a: any) => !a.isReadonly),
    [applications],
  );

  const [applicationsToRemove, setApplicationsToRemove] = useState<string[]>(() => {
    if (changableApplications.length > 1) {
      return [];
    }
    return [changableApplications[0].id];
  });

  const toggleApplications = (value: boolean, id: string): void => {
    const ids: string[] = value ? [...applicationsToRemove, id] : applicationsToRemove.filter(el => el !== id);
    setApplicationsToRemove(ids);
  };

  const onRemoveApplications = async () => {
    if (typeof subscriptionId === 'string') {
      await dispatch(removeApplicationFromUser({
        subscriptionId,
        userId: id,
        isAdminPortal,
        applicationIds: applicationsToRemove,
      })).unwrap();
      onSuccess();
      onCancel();
      notify.success('This product has been removed', 'You cannot return this product');
    }
  };

  const modalTitle = changableApplications.length > 1 ? 'Remove Applications' : 'Remove Application';

  return (
    <StepModal
      visible={visible}
      closable
      config={[{
        content: (
          <div className="dashboard-accounts-remove-application">
            <div className="info">
              {changableApplications.length > 1
                ? 'Choose the application from the list below that should be removed from the user\'s list'
                : 'Application listed below will be removed from the User\'s list'
              }
            </div>
            {changableApplications.length > 1 ? (
              <ul className="list-unstyled">
                {changableApplications.map(({ id, name }: { id: string; name: string }) => (
                  <li key={id}>
                    <Checkbox
                      label={name}
                      isChecked={applicationsToRemove.includes(id)}
                      onChange={(e) => toggleApplications((e.target as HTMLInputElement).checked, id)}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <ul className="list-unstyled">
                <li>{changableApplications[0].name}</li>
              </ul>
            )}
            <div className="info">{isAdminPortal ? "The client's account will be updated with the latest changes." : 'Your billing will be updated based on your recent changes.'}</div>
            <div className="block-info relative-units remove-application-info">
              <InfoCircleIcon />
              <div className="block-info__content remove-application-info__content">
                If you click <span>Remove</span>, the user <span>{userName}</span> will no longer have access to selected application(s)
              </div>
            </div>
          </div>
        ),
        title: modalTitle,
        width: 535,
        okText: 'Yes, Remove',
        isOkDisabled: !applicationsToRemove.length,
      }, {
        title: 'Are you sure?',
        description: 'All Related Data will be removed.',
        okText: 'Yes',
        isOkLoading: isLoading,
      }]}
      onFinish={onRemoveApplications}
      onCancel={onCancel}
    />
  );
};

export default RemoveApplicationFromUserModal;