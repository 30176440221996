import React, { FC } from 'react';
import { ITableFilterOrdering } from 'interfaces';
import { TableFilter, TableSearch, TableOrder } from 'components';

interface Props {
  initialSearchQuery?: string;
  applicationsForFilter: any[];
  applicationsIdsValue: string[];
  orderState?: ITableFilterOrdering;
  onSearch: (searchQuery: string) => void;
  onOrderChange: (data: ITableFilterOrdering) => void;
  onFilterByApplicationsChange: (filters: string[]) => void;
}

const UsersTableHeadRow: FC<Props> = ({
  applicationsForFilter = [],
  initialSearchQuery = '',
  applicationsIdsValue = [],
  orderState,
  onSearch,
  onOrderChange,
  onFilterByApplicationsChange,
}) => (
  <tr className="users-table-head-row">
    <th>
      <TableSearch
        initialValue={initialSearchQuery}
        label="USERNAMES"
        searchBy="Search by Email or Name..."
        onSearch={onSearch}
      />
    </th>
    <th>EMAIL</th>
    <th>
      <TableOrder
        label="DATE ADDED"
        fieldName="CreatedAt"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>
      <TableFilter
        values={applicationsIdsValue}
        label="APPLICATIONS"
        showAlways
        options={applicationsForFilter.map(({ id, name }) => ({ label: name, value: id }))}
        onChange={onFilterByApplicationsChange}
      />
    </th>
    <th>ACTIONS</th>
  </tr>
);

export default UsersTableHeadRow;
