import React, { FC } from 'react';
import { ITableFilterOrdering } from 'interfaces';
import { TableOrder } from 'components';

interface Props {
  orderState?: ITableFilterOrdering;
  onOrderChange: (data: ITableFilterOrdering) => void;
}

const InvoicesTableHeadRow: FC<Props> = ({
  orderState,
  onOrderChange,
}) => (
  <tr className="users-table-head-row">
    <th>
      <TableOrder
        label="NAME"
        fieldName="Name"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>DETAILS</th>
    <th>
      <TableOrder
        label="LAST UPDATED"
        fieldName="UpdatedAt"
        currentOrderState={orderState}
        onChange={onOrderChange}
      />
    </th>
    <th>ACTIONS</th>
  </tr>
);

export default InvoicesTableHeadRow;
