import { FC, useCallback, useMemo, useState } from 'react';
import './OtherProductsChoose.scss';
import { Button, CheckboxTag, InputText, Modal, Select, StepPriceDetails } from 'components';
import { IOtherProduct } from 'interfaces';
import { useAppSelector } from 'store/hooks';
import { getEnvCountryCode, usePriceHandler } from 'helpers';
import { CountryCode, SignUpNavigation } from 'enums';
import { ArrowRightFullIcon, CloseIcon, InfoCircleIcon, SearchRightIcon } from 'assets/svg';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as _ from 'lodash';
import { watch } from 'fs';

interface IOtherProductsForm {
  quantity: string;
  licenseCode?: string;
  productCode?: string;
  serialNumber?: string;
}

interface Props {
  initData?: IOtherProduct[];
  onAdd: (data: any) => void;
  currentStepProducts: any;
}

const OtherProductsChoose: FC<Props> = ({ onAdd, initData = [], currentStepProducts }) => {
  const otherProductsInfo: { [key: string]: IOtherProduct } = useAppSelector(
    state => state.products.otherProductsInfo,
  );
  const [showModal, setShowModal] = useState(false);
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState<IOtherProduct | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [otherProductsCopy, setOtherProductsCopy] = useState(initData);
  const hostTotalQuantity = useAppSelector(state => state.order.hostProduct.quantity);
  const numbersRange = useMemo(() => _.range(1, +(hostTotalQuantity || 0) + 1).map(el => el.toString()), [hostTotalQuantity]);
  const [initialFormData, setInitialFormData] = useState<any>(null);

  const filteredOtherProducts = useMemo(() => Object.values(otherProductsInfo).filter(({ name }: any) => {
    const formatedSearchQuery = searchQuery.trim().toLowerCase();
    const formatedName = name?.trim().toLowerCase();
    const isMatchName = formatedName.includes(formatedSearchQuery);
    return isMatchName;
  }), [searchQuery, otherProductsInfo]);

  const initCheckedProducts = useMemo(() => {
    return initData.filter(el => el.isChecked);
  }, [initData]);

  const hasInitChecked = useMemo(() => {
    return initCheckedProducts.length > 0;
  }, [initCheckedProducts]);

  const currentCountryCode = useAppSelector(state => state.order.country?.code ?? CountryCode.USA);
  const envCountryCode = useMemo(() => getEnvCountryCode(currentCountryCode), [currentCountryCode]);

  const {
    control,
    getValues,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = useForm<IOtherProductsForm>({ mode: 'all' });
  const { formatPrice } = usePriceHandler();

  const onTagClick = (id: string) => {
    const currentProductData = otherProductsCopy.find(el => el.id === id);
    if (!currentProductData) {
      return;
    }
    const productInfo = otherProductsInfo[currentProductData?.name];
    reset({
      quantity: currentProductData?.quantity?.toString() || '1',
      licenseCode: currentProductData?.licenseCode,
      productCode: currentProductData?.productCode,
      serialNumber: currentProductData?.serialNumber,
    });
    setInitialFormData({
      quantity: currentProductData?.quantity?.toString() || '1',
      licenseCode: currentProductData?.licenseCode,
      productCode: currentProductData?.productCode,
      serialNumber: currentProductData?.serialNumber,
    });
    setCurrentSelectedProduct(productInfo);
    setShowModal(true);
  };

  const confirmAddEditProduct: SubmitHandler<IOtherProductsForm> = (data) => {
    const currentProductDataIndex = otherProductsCopy.findIndex(el => el.id === currentSelectedProduct?.id);
    if (currentProductDataIndex === -1) {
      return;
    }
    const copyProducts: any = _.cloneDeep(otherProductsCopy);
    copyProducts[currentProductDataIndex] = {
      ...otherProductsCopy[currentProductDataIndex],
      ...data,
      isChecked: true
    };
    setOtherProductsCopy(copyProducts);
    setShowModal(false);
    setInitialFormData(null);
    reset();
  };

  const isProductChecked = useCallback((id) => {
    const currentProductData = otherProductsCopy.find(el => el.id === id);
    return Boolean(currentProductData?.isChecked);
  }, [otherProductsCopy]);

  const checkedProducts = useMemo(() => {
    return otherProductsCopy.filter(el => el.isChecked);
  }, [otherProductsCopy]);

  const isAddEqual = useMemo(() => {
    return _.isEqual(checkedProducts, initCheckedProducts);
  }, [checkedProducts, initCheckedProducts]);

  const isUpdateModalEqual = useMemo(() => {
    const data = getValues();
    return _.isEqual(initialFormData, data);
  }, [watch(), initialFormData]);

  const unCheckProduct = useCallback((id) => {
    const currentProductDataIndex = otherProductsCopy.findIndex(el => el.id === id);
    if (currentProductDataIndex === -1) {
      return;
    }
    const copyProducts = _.cloneDeep(otherProductsCopy);
    copyProducts[currentProductDataIndex] = {
      ...otherProductsCopy[currentProductDataIndex],
      isChecked: false,
      quantity: "",
      serialNumber: "",
      productCode: "",
      licenseCode: "",
    };
    setOtherProductsCopy(copyProducts);
    setShowModal(false);
  }, [otherProductsCopy]);

  const onRemoveCheckedIconKeyDown = (event: any, id: string) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      unCheckProduct(id);
    }
  };

  const handleAdd = () => {
    onAdd(otherProductsCopy);
  };

  const STEP_PRODUCTS = useMemo(() => {
    return [
      ...currentStepProducts.quickbooks,
      ...currentStepProducts.sage,
      ...currentStepProducts.msOwn,
      ...currentStepProducts.msLease,
      ...checkedProducts.map(({ name, quantity, price }) => ({ name, quantity, price })),
    ];
  }, [currentStepProducts, checkedProducts]);


  return (
    <>
      <Modal
        xVersion
        absoluteStyles
        title={`${currentSelectedProduct?.name} - ${formatPrice(currentSelectedProduct?.price || 0, envCountryCode)}`}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        isOkDisabled={isProductChecked(currentSelectedProduct?.id) ? (isUpdateModalEqual || !isValid) : !isValid}
        showRemoveButton={isProductChecked(currentSelectedProduct?.id)}
        onOk={handleSubmit(confirmAddEditProduct)}
        okText={isProductChecked(currentSelectedProduct?.id) ? 'Update' : 'Add'}
        onRemove={() => unCheckProduct(currentSelectedProduct?.id)}
      >
        <div className="other-products-info-modal">
          {currentSelectedProduct?.isSubscriptionProduct ? (
            <div className="block-info xVersion m-b-16 sm-m-b-12">
              <InfoCircleIcon />
              <p className="block-info__content server-product-info">
                This product could be assigned to server only.
              </p>
            </div>
          ) : null}
          <form>
            {currentSelectedProduct?.isSubscriptionProduct ? null : (
              <Controller
                name="quantity"
                control={control}
                defaultValue="1"
                rules={{ required: true }}
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <Select
                    dataTestId="product-list.other.quantity-select"
                    xVersion
                    absoluteStyles
                    value={value}
                    label="Number of users"
                    placeholder=""
                    classes="m-b-20 sm-m-b-12"
                    options={numbersRange}
                    isInvalid={invalid}
                    isRequired
                    onChange={onChange}
                    onClose={onBlur}
                  />
                )}
              />)
            }
            <Controller
              name="licenseCode"
              control={control}
              defaultValue=""
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched, error },
              }) => (
                <InputText
                  dataTestId="product-list.other.license-code-input"
                  classes="m-b-20 sm-m-b-12"
                  xVersion
                  absoluteStyles
                  label="License code"
                  placeholder=""
                  value={value}
                  isInvalid={isTouched && invalid}
                  errorText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="productCode"
              control={control}
              defaultValue=""
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched, error },
              }) => (
                <InputText
                  dataTestId="product-list.other.product-code-input"
                  classes="m-b-20 sm-m-b-12"
                  label="Product code"
                  xVersion
                  absoluteStyles
                  placeholder=""
                  value={value}
                  isInvalid={isTouched && invalid}
                  errorText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="serialNumber"
              control={control}
              defaultValue=""
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched, error },
              }) => (
                <InputText
                  dataTestId="product-list.other.serial-number-input"
                  label="Serial number"
                  xVersion
                  absoluteStyles
                  placeholder=""
                  value={value}
                  isInvalid={isTouched && invalid}
                  errorText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </form>
        </div>
      </Modal>
      <div className="container-lg">
        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-32">More Applications</div>
          {checkedProducts.length > 0 ? (<div className="sign-up-selected-products-list">
            {checkedProducts.map(({ id, name, quantity, isSubscriptionProduct }) => (
              <div className="selected-product-wrapper" key={`checked-products-${id}`}>
                <CloseIcon
                  data-test-id={`product-list.other.selected-product-remove-icon-${name}`}
                  tabIndex={0}
                  onKeyDown={(event) => onRemoveCheckedIconKeyDown(event, id)}
                  className="close-icon"
                  onClick={() => unCheckProduct(id)}
                />
                <div className="product-title">{name}</div>
                <div className="flex flex-justify-between">
                  <div className="product-quantity">{isSubscriptionProduct ? 'Qty' : 'Users'}: {quantity}</div>
                  <Button
                    dataTestId={`product-list.other.selected-product-change-button-${name}`}
                    absoluteStyles
                    xVersion
                    type="textable"
                    onClick={() => onTagClick(id)}
                  >
                    Change
                  </Button>
                </div>
              </div>
            ))}
          </div>) : null}
          <div className="sign-up-other-products-search-wrapper">
            <div className="search-input">
              <InputText
                dataTestId="product-list.other.search-input"
                xVersion
                absoluteStyles
                value={searchQuery}
                label=""
                placeholder="Search by application name"
                icon={SearchRightIcon}
                onChange={setSearchQuery}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-md">
        <div className="sign-up-form-content p-t-0">
          <div className="sign-up-other-products-list">
            {filteredOtherProducts
              .map(({ id, name, price, isSubscriptionProduct }) => {
                return (
                  <CheckboxTag
                    dataTestId={`product-list.other.product-tag-${name}`}
                    key={`other-product-${id}`}
                    onlyClicked
                    iconRight={ArrowRightFullIcon}
                    iconRightSmOnly
                    isChecked={isProductChecked(id)}
                    onChange={() => onTagClick(id)}
                  >
                    <div className="other-product-info">
                      <div className="other-product-info-name">{name}</div>
                      <div className="other-product-info-price">{formatPrice(price, envCountryCode)}{isSubscriptionProduct ? '' : '/user'}</div>
                    </div>
                  </CheckboxTag>);
              })
            }
          </div>
          <div className="flex flex-justify-center m-t-40 sm-m-t-32">
            <Button
              dataTestId="product-list.other.add-edit-button"
              xVersion
              absoluteStyles
              isDisabled={isAddEqual}
              onClick={handleAdd}
            >
              {hasInitChecked ? 'Update products' : 'Add to product list'}
            </Button>
          </div>
        </div>
      </div>
      <StepPriceDetails currentStep={SignUpNavigation.ProductsList} stepProducts={STEP_PRODUCTS} />
    </>
  );
};

export default OtherProductsChoose;