import './SidebarModal.scss';
import { FC, ReactElement, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { CloseIcon } from 'assets/svg';
import { makeVw } from 'helpers';
import Button from '../Button/Button';

interface Props {
  visible: boolean;
  children?: ReactNode;
  closable?: boolean;
  title?: string | null;
  description?: string;
  footer?: ReactElement | null;
  footerAdditonal?: ReactElement | null;
  hideFooter?: boolean;
  classes?: string;
  contentClasses?: string;
  width?: string | number;
  isOkDisabled?: boolean;
  isOkLoading?: boolean;
  okText?: string | null;
  okWidth?: number;
  cancelText?: string | null;
  onCancel?: () => void;
  onOk?: () => void;
  onClose?: () => void;
}

const SidebarModal: FC<Props> = ({
  visible,
  children,
  title,
  description,
  footer,
  footerAdditonal = null,
  hideFooter,
  classes,
  contentClasses,
  width = 495,
  closable = true,
  isOkDisabled = false,
  isOkLoading = false,
  okText = 'Confirm',
  okWidth,
  cancelText = 'Cancel',
  onCancel,
  onOk,
  onClose,
}) => {
  const onCloseButtonClick = () => {
    const handler = onClose || onCancel;
    if (handler) {
      handler();
    }
  };

  const okBtnWidthStyle = useMemo(
    () => okWidth ? { width: makeVw(okWidth) } : { width: 'auto' },
    [okWidth],
  );

  return (
    <div
      className={cn('sidebar-modal-wrapper', { visible }, classes)}
      onClick={() => closable && onCancel}
    >
      <div className="sidebar-modal" style={{ width: makeVw(width) }}>
        {closable && <CloseIcon className="close-icon" onClick={onCloseButtonClick} />}
        {title !== null && <div className="sidebar-modal-title">
          {title}
          {description && <p className="rel-m-t-8">{description}</p>}
        </div>}
        {children && (
          <div className={cn('sidebar-modal-main', contentClasses)}>
            {children}
          </div>
        )}
        {(footer !== null && !hideFooter) && (
          <div className="sidebar-modal-footer flex flex-justify-end flex-align-center">
            {footer === undefined
              ? (
                <>
                  {footerAdditonal}
                  {cancelText !== null && (
                    <button
                      type="button"
                      className="btn relative-units default new-style"
                      onClick={onCancel}
                    >{cancelText}</button>
                  )}
                  {okText !== null && (
                    <Button
                      isLoading={isOkLoading}
                      style={okBtnWidthStyle}
                      isNewStyle
                      isDisabled={isOkDisabled}
                      onClick={onOk}
                    >
                      {okText}
                    </Button>
                  )}
                </>
              )
              : footer
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarModal;