import './AddUserSidebarModal.scss';
import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _debounce from 'lodash/debounce';

import { notify, useFormFieldErrorNotification, usePriceHandler } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAllAssigningApplications,
  addNewUserToCart,
  checkIsAllowedUserEmail,
  getUsspCartData,
  adminAddUser,
} from 'store';

import { CountryCode, UserRole, UsspRole } from 'enums';
import { IDashboardUser, IProductPriceData } from 'interfaces';
import { EMAIL_PATTERN, PHONE_PATTERN_SIMPLE } from 'consts';

import { InfoCircleOutlineIcon } from 'assets/svg';

import {
  CheckboxButton,
  InputText,
  Select,
  SidebarModal,
  TotalPriceModalFooter,
} from 'components';

interface IApplication {
  id: string;
  name: string;
  price: number;
}

type UserForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: UserRole;
  hasMfa?: boolean;
  applications: boolean[];
};

const initialUserForm: UserForm = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: UserRole.Standard,
  hasMfa: false,
  applications: [],
};

interface Props {
  visible: boolean;
  isAdminPortal?: boolean;
  hidePrice?: boolean;
  onCancel: () => void;
  onSuccess?: () => void;
}

interface IModalProp {
  okText: string;
  cancelText: string;
}

const AddUserSidebarModal: FC<Props> = ({
  visible,
  isAdminPortal = false,
  hidePrice = false,
  onCancel,
  onSuccess,
}) => {
  const { subscriptionId } = useParams();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const schema = useMemo(() => currentStep === 0 ? yup.object({
    firstName: yup.string().trim().required('First name is required').min(2, 'First name must be at least 2 characters'),
    lastName: yup.string().trim().required('Last name is required').min(2, 'Last name must be at least 2 characters'),
    email: yup.string().required('Email is required').matches(EMAIL_PATTERN, 'Please enter a valid email'),
    phone: yup.string()
      .matches(PHONE_PATTERN_SIMPLE, { message: 'Please enter a valid phone', excludeEmptyString: true })
      // .min(4, 'Phone number can\'t be shorter than 4 digits') // @todo
      .max(15, 'Phone number can\'t be longer than 15 digits')
      .when('role', {
        is: UserRole.Admin,
        then: yup.string().required('Phone number is required'),
      })
      .when('hasMfa', {
        is: true,
        then: yup.string().required('Phone number is required'),
      }),
    role: yup.string().required('Role is required'),
  }) : yup.object({
    applications: yup.array().test(
      'oneRequired',
      'Please chose at least one application',
      (value?: boolean[]): boolean => Boolean(value?.some(Boolean)),
    ),
  }), [currentStep]);

  const {
    register,
    resetField,
    control,
    handleSubmit,
    formState,
    getValues,
    setValue,
    setError,
    watch,
  } = useForm<UserForm>({ mode: 'all', resolver: yupResolver(schema), defaultValues: initialUserForm });
  useFormFieldErrorNotification(formState, ['firstName', 'lastName', 'email']);
  const { errors, isValid, touchedFields } = formState;

  // Additional validator for email field
  const [isEmailExist, setIsEmailExist] = useState<boolean>(false);
  const [isValidateInProgress, setIsValidateInProgress] = useState<boolean>(false);
  const emailAsyncValidation = async (subscriptionId: string, email: string) => {
    try {
      await dispatch(checkIsAllowedUserEmail({
        subscriptionId,
        email,
        isAdminPortal,
        _disableErrorHandler: true,
      })).unwrap();
      setIsEmailExist(false);
    } catch {
      setIsEmailExist(true);
      setError('email', { type: 'unique-email', message: 'Email should be unique' });
    }
    setIsValidateInProgress(false);
  };
  const debouncedEmailAsyncValidation = useCallback(_debounce(emailAsyncValidation, 500), []);

  const resetModal = () => {
    onCancel();
    setCurrentStep(0);
    resetField('firstName', { defaultValue: '' });
    resetField('lastName', { defaultValue: '' });
    resetField('email', { defaultValue: '' });
    resetField('phone', { defaultValue: '' });
    resetField('role', { defaultValue: UserRole.Standard });
    resetField('hasMfa', { defaultValue: false });
  };

  const user = useAppSelector<IDashboardUser | null>(state => state.ussp.user);

  const { formatPrice } = usePriceHandler();

  const FALLBACK_PRODUCT_PRICE_DATA: IProductPriceData = useMemo(() => ({
    price: null,
    country: user?.country || CountryCode.USA,
  }), [user]);

  const {
    price: userHostingPrice,
    country: userHostingPriceCountry,
  } = useAppSelector<IProductPriceData>(state => state.products.userHosting || FALLBACK_PRODUCT_PRICE_DATA);

  const formatedUserHostingPrice = useMemo(
    () => formatPrice(userHostingPrice, userHostingPriceCountry),
    [userHostingPrice, userHostingPriceCountry],
  );

  const {
    data: applications = [],
    isLoading: isAssigningApplicationsLoading
  } = useAppSelector<any>(
    state => state.ussp.getAllAssigningApplicationsRequest,
  );

  const isAdmin = useMemo(() => getValues('role') === UserRole.Admin, [watch()]);
  const hasMfaValue = useMemo(() => getValues('hasMfa'), [watch()]);
  const phoneValue = useMemo(() => getValues('phone'), [watch()]);

  useEffect(() => {
    setValue('phone', phoneValue, {
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [hasMfaValue]);

  const [applicationsPrice, setApplicationsPrice] = useState<number>(0);
  const calculateApplicationsPrice = (price: number, shouldAdd: boolean): void => {
    const newPrice = shouldAdd ? applicationsPrice + price : applicationsPrice - price;
    setApplicationsPrice(newPrice);
  };

  const totalPrice = useMemo(
    () => (userHostingPrice ?? 0) + applicationsPrice,
    [userHostingPrice, applicationsPrice],
  );

  const dispatch = useAppDispatch();

  const backHandler = () => {
    if (currentStep === 1) {
      setCurrentStep(0);
    } else {
      resetModal();
    }
  };

  const navigate = useNavigate();
  const navigateToAppsHandler = () => {
    if (isAdminPortal) {
      navigate(`/admin-portal/subscriptions/${subscriptionId}/applications`);
    } else {
      navigate('');
    }
    resetModal();
  };

  const { isLoading } = useAppSelector(state => isAdminPortal ? state.ussp.adminAddUserRequest : state.ussp.addNewUserToCartRequest);

  const addUserHandler: SubmitHandler<UserForm> = async data => {
    if (typeof subscriptionId === 'string') {
      const {
        applications: formApplications,
        firstName,
        lastName,
        email,
        phone,
        role,
        hasMfa,
      } = data;
      const productIds = (applications as IApplication[]).reduce((acc, cur, index) => {
        return [...acc, ...(formApplications[index] ? [cur.id] : [])];
      }, [] as string[]);
      const userFormData = new FormData();
      userFormData.append('FirstName', firstName);
      userFormData.append('LastName', lastName);
      userFormData.append('Email', email);
      userFormData.append('Phone', phone);
      userFormData.append('Role', role);
      userFormData.append('HasMFA', hasMfa ? 'true' : 'false');
      productIds.forEach((id) => userFormData.append('ProductIds[]', id));
      userFormData.append('Avatar', '');
      if (isAdminPortal) {
        await dispatch(adminAddUser({
          subscriptionId,
          formData: userFormData,
        })).unwrap();
        notify.success(
          'Success',
          'User was successfully added',
        );
      } else {
        await dispatch(addNewUserToCart({
          subscriptionId,
          formData: userFormData,
        })).unwrap();
        notify.success(
          'Your changes have been made',
          'Go to cart to checkout. If you do not go to your cart and checkout your changes will not be applied',
        );
        dispatch(getUsspCartData({ _isBackground: true })).unwrap();
      }
      onSuccess?.();
      resetModal();
    }
  };

  const MODAL_PROPS_BY_STEPS: { [key: string]: IModalProp } = useMemo(() => ({
    0: {
      okText: isAdminPortal
        ? (applications && applications.length
          ? 'Next'
          : isAssigningApplicationsLoading ? 'Next' : 'Add')
        : 'Next',
      cancelText: 'Cancel',
    },
    1: {
      okText: isAdminPortal ? 'Add' : 'Add to cart',
      cancelText: 'Back',
    },
  }), [isAdminPortal, applications]);

  const nextHandler = () => {
    if ((!isAdminPortal && currentStep === 0) || (currentStep === 0 && applications && applications.length)) {
      setCurrentStep(1);
    } else {
      const handler = handleSubmit(addUserHandler);
      handler();
    }
  };

  useEffect(() => {
    if (visible && typeof subscriptionId === 'string') {
      dispatch(getAllAssigningApplications({ subscriptionId, isAdminPortal })).unwrap();
    }
  }, [visible]);

  return (
    <SidebarModal
      classes="dashboard-accounts-add-user"
      title="Add User"
      visible={visible}
      onOk={nextHandler}
      isOkDisabled={!isValid || isValidateInProgress || isEmailExist}
      isOkLoading={isLoading}
      footerAdditonal={
        hidePrice ? null : (
          <TotalPriceModalFooter
            price={totalPrice}
            withInfo={currentStep === 0}
            infoContent={(
              <p>Service User Hosting - <span className="font-weight-600">{formatedUserHostingPrice}</span></p>
            )}
          />
        )
      }
      onCancel={backHandler}
      onClose={resetModal}
      {...MODAL_PROPS_BY_STEPS[`${currentStep}`]}
    >
      <form>
        {currentStep === 0 ? (
          <>
            <div className="row-items rel-m-b-20">
              <InputText
                label="First name"
                placeholder="e.g. Tom"
                name="firstName"
                register={register}
                shouldUnregister={false}
                isRequired
                isInvalid={!!(errors.firstName && touchedFields.firstName)}
              />
              <InputText
                label="Last name"
                placeholder="e.g. Riddle"
                name="lastName"
                register={register}
                shouldUnregister={false}
                isRequired
                isInvalid={!!(errors.lastName && touchedFields.lastName)}
              />
            </div>
            <Controller
              name="email"
              control={control}
              defaultValue={''}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { invalid, isTouched },
              }) => (
                <InputText
                  type="email"
                  classes="rel-m-b-20"
                  label="Email address"
                  placeholder="name@summit.com"
                  value={value}
                  isRequired
                  isInvalid={isTouched && invalid}
                  onChange={(e: string) => {
                    onChange(e.trim());
                    if (new RegExp(EMAIL_PATTERN).test(e.trim())) {
                      setIsValidateInProgress(true);
                      debouncedEmailAsyncValidation(subscriptionId || '', e.trim());
                    }
                  }}
                  onBlur={() => {
                    if (value === '') {
                      onBlur();
                    } else if (!isTouched) {
                      setValue('email', value, { shouldTouch: true });
                    }
                  }}
                />
              )}
            />
            <InputText
              classes="rel-m-b-20"
              label="Phone number"
              placeholder="(555) 555-5555"
              name="phone"
              register={register}
              shouldUnregister={false}
              isRequired={isAdmin || hasMfaValue}
              withInfo
              infoContent="Please provide your phone number you want to be able to use SMS password recovery"
              isInvalid={!!(errors.phone && touchedFields.phone)}
            />
            <Controller
              name="role"
              control={control}
              defaultValue={UserRole.Standard}
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid },
              }) => (
                <Select
                  value={value}
                  classes="rel-m-b-20"
                  options={Object.values(UserRole)}
                  label="Role"
                  placeholder="Please choose role"
                  isRequired
                  // withInfo
                  // infoContent={<>
                  //   If you change role to&nbsp;
                  //   <span className="font-weight-600">Admin</span>,&nbsp;
                  //   you will have to pay for&nbsp;
                  //   <span className="font-weight-600">MFA</span>
                  // </>}
                  isInvalid={invalid}
                  onChange={(e) => {
                    if (e === UsspRole.Admin) {
                      setValue('hasMfa', true);
                    }
                    onChange(e);
                  }}
                  onClose={onBlur}
                />
              )}
            />
            {isAdmin && (
              <div className="block-info relative-units rel-p-16 rel-m-b-24">
                <InfoCircleOutlineIcon />
                <div className="block-info__content">
                  <p>
                    By choosing the role of an <span>Admin</span>,
                    an MFA was automatically added for them
                  </p>
                </div>
              </div>
            )}
            <Controller
              name="hasMfa"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <CheckboxButton
                  isChecked={Boolean(value)}
                  onChange={onChange}
                  label="MFA Status"
                  isDisabled={isAdmin}
                />
              )}
            />
          </>
        ) : (
          <>
            <div className="block-info relative-units rel-p-16 rel-m-b-24">
              <InfoCircleOutlineIcon />
              <div className="block-info__content">
                <p className="rel-m-b-8">
                  If you want to add a <span>new application</span>, that is not installed
                  in your server, add the user first, then go into the&nbsp;
                  <span
                    className="link relative-units without-decorations"
                    onClick={navigateToAppsHandler}
                  >Applications tab</span> to add the new application
                </p>
                <p>Within there you can assign the application to this new user.</p>
              </div>
            </div>
            {(applications as IApplication[])?.map(({ id, name, price = 0 }, index) => (
              <Controller
                key={id}
                name={`applications.${index}`}
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    isChecked={value}
                    onChange={e => {
                      onChange(e);
                      calculateApplicationsPrice(price, (e.target as HTMLInputElement).checked);
                    }}
                    label={name}
                    rightLabel={isAdminPortal ? undefined : formatPrice(price, user?.country)}
                  />
                )}
              />
            ))}
          </>
        )}
      </form>
    </SidebarModal>
  );
};

export default AddUserSidebarModal;