import './AdminSubscriptions.scss';
import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import _debounce from 'lodash/debounce';

import { getAdminSubscriptions, getAdminSubscriptionStatuses, getAdminSubscriptionTerms } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
// import { setupSignalR } from 'helpers';

import { ITableFilterOrdering } from 'interfaces';

import { SearchIcon } from 'assets/svg';

import SubscriptionsTableHeadRow from './components/SubscriptionsTableHeadRow';
import SubscriptionsTableBodyRow from './components/SubscriptionsTableBodyRow';
import SubscriptionsTableBodyRowLoader from './components/SubscriptionsTableBodyRowLoader';
import { Error, InputText, EmptyStateContainer, Pagination, Select, FilterTags } from 'components';
import { COUNTRY_FILTER_OPTIONS, MANAGABLE_TYPES_LIST } from 'consts';
import { CountryCode } from 'enums';
import { useSearchParams } from 'react-router-dom';
import { capitalizeFirstLetter } from 'helpers';

const AdminSubscriptions: FC = () => {
  const dispatch = useAppDispatch();

  const {
    data: statusesInfosData,
  } = useAppSelector(state => state.ussp.getAdminSubscriptionStatusesRequest);
  const {
    data: termsInfosData,
  } = useAppSelector(state => state.ussp.getAdminSubscriptionTermsRequest);

  const {
    data: { subscriptions = []} = {},
    isLoading,
    error,
  } = useAppSelector(state => state.ussp.getAdminSubscriptionsRequest);
  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const isShowLoader = useMemo(
    () => isLoading && !isBackgroundUpdate,
    [isLoading, isBackgroundUpdate],
  );

  const [totalPages, setTotalPages] = useState<number>(1);

  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    filter: '',
    orderings: '[]',
    statuses: '[]',
    country: 'All',
    terms: '[]',
    manageables: '[]',
  });
  
  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryFilter = useMemo(() => {
    return query.get('filter') || '';
  }, [query]);

  const queryOrderings = useMemo(() => {
    return JSON.parse(query.get('orderings') || '[]');
  }, [query]);

  const queryStatuses = useMemo(() => {
    return JSON.parse(query.get('statuses') || '[]');
  }, [query]);

  const queryCountry = useMemo(() => {
    return (query.get('country') || 'All') as CountryCode | 'All';
  }, [query]);

  const queryTerms = useMemo(() => {
    return JSON.parse(query.get('terms') || '[]');
  }, [query]);

  const queryManageables = useMemo(() => {
    return JSON.parse(query.get('manageables') || '[]');
  }, [query]);

  const [searchQuery, setSearchQuery] = useState<string>(queryFilter || '');

  useEffect(() => {
    fetchData();
  }, [query]);

  const fetchData = async (
    isBackground?: boolean,
  ) => {
    const data = await dispatch(getAdminSubscriptions({
      page: queryPage,
      pageSize: queryPageSize,
      filter: queryFilter,
      orderings: queryOrderings,
      statuses: queryStatuses,
      terms: queryTerms,
      manageables: queryManageables,
      country: queryCountry,
      _disableErrorHandler: true,
      _isBackground: isBackground,
    })).unwrap();
    setTotalPages((data?.totalPages as number) || 1);
  };

  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(true);
    setIsBackgroundUpdate(false);
  };

  const onSearch = useCallback((searchQuery: string) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('filter', searchQuery);
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  }, [query]);

  const onOrderChange = useCallback((data: ITableFilterOrdering) => {
    const newOrderings = data === undefined ? [] : [data];
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('orderings', JSON.stringify(newOrderings));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  }, [query]);

  const onFilterByStatusesChange = useCallback((filters: string[]) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('statuses', JSON.stringify(filters));
    updatedSearchParams.set('terms', '[]');
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  }, [query]);

  const onFilterByCountryChange = useCallback((country: CountryCode | 'All') => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('country', country);
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  }, [query]);

  const onFilterByTermsChange = useCallback((filters: string[]) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('terms', JSON.stringify(filters));
    updatedSearchParams.set('statuses', '[]');
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  }, [query]);

  const onFilterByManageableChange = useCallback((filters: string[]) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('manageables', JSON.stringify(filters));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  }, [query]);

  const onPaginationChange = useCallback((page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  }, [query]);

  const debounceSearch = useCallback(_debounce((value: string) => onSearch(value), 1000), [onSearch]);

  const handleChangeSearch = (value: string) => {
    setSearchQuery(value);
    debounceSearch(value);
  };

  const filterTagsData = useMemo(
    () => {
      const statusesFilter = queryStatuses.map((status: string) => {
        const newProductsAfterDelete = queryStatuses.filter((el: string) => el !== status);
        return {
          label: `${capitalizeFirstLetter(status.replaceAll('_', ' '))}`,
          onDelete: () => {
            onFilterByStatusesChange(newProductsAfterDelete);
          }
        };
      });
      const manageablesFilter = queryManageables.map((manageable: string) => {
        const currentManageableType = MANAGABLE_TYPES_LIST.find(e => e.value === manageable);
        const newManageableAfterDelete = queryManageables.filter((el: string) => el !== manageable);
        return {
          label: currentManageableType?.label,
          onDelete: () => {
            onFilterByManageableChange(newManageableAfterDelete);
          }
        };
      });
      const termsFilter = queryTerms.map((id: string) => {
        const currentTerm = termsInfosData.find((e: any) => e.id.toString() === id);
        const newTermsAfterDelete = queryTerms.filter((el: string) => el !== id);
        return {
          label: currentTerm?.name,
          onDelete: () => {
            onFilterByTermsChange(newTermsAfterDelete);
          }
        };
      });
      return [
        ...manageablesFilter,
        ...statusesFilter,
        ...termsFilter,
      ];
    },
    [queryStatuses, queryManageables, queryTerms]
  );

  const onClearAll = () => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('manageables', '[]');
    updatedSearchParams.set('statuses', '[]');
    updatedSearchParams.set('terms', '[]');
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  useEffect(() => {
    // const connection = setupSignalR('/dashboard');
    // if (connection) {
    //   connection.start()
    //     .then(() => {
    //       connection.on('Signal', async (message: any) => {
    //         setIsBackgroundUpdate(true);
    //         await fetchData();
    //         setIsBackgroundUpdate(false);
    //         console.log('DASHBOARD:SIGNAL', message);
    //       });
    //     })
    //     .catch((e: any) => console.log('Connection failed [dashboard]: ', e));
    // }

    dispatch(getAdminSubscriptionStatuses()).unwrap(); // @todo replace
    dispatch(getAdminSubscriptionTerms()).unwrap(); // @todo replace

    return () => {
      // if (connection) {
      //   connection.stop();
      // }
    };
  }, []);

  if (error) {
    return <Error code={500} />;
  }

  return (
    <div className="admin-subscriptions ussp-page">
      <section className="admin-subscriptions__header ussp-page__header">
        <h1>Subscriptions</h1>
      </section>
      <section className="admin-subscriptions__content relative-content">
        <div className="subsciptions-header-filters">
          <InputText
            classes="admin-subscriptions__search"
            value={searchQuery}
            icon={SearchIcon}
            placeholder="Search by subscription, subscriber"
            theme="grey"
            maxLength={58}
            onChange={handleChangeSearch}
            withClear
          />
          <Select
            value={queryCountry}
            options={COUNTRY_FILTER_OPTIONS}
            onChange={onFilterByCountryChange}
            size="small"
            dropdownWidthDifferent
            dropdownPlacement="bottom-end"
            optionsWithIcons
          />
        </div>
        <FilterTags tags={filterTagsData} onClearAll={onClearAll}/>
        <div className="subscriptions-table-wrapper">
          <table className="subscriptions-table">
            <thead>
              <SubscriptionsTableHeadRow
                orderState={queryOrderings[0]}
                statuses={statusesInfosData}
                selectedStatuses={queryStatuses}
                terms={termsInfosData}
                managableTypes={MANAGABLE_TYPES_LIST}
                selectedSubscriptionManageable={queryManageables}
                selectedTerms={queryTerms}
                onOrderChange={onOrderChange}
                onFilterByStatusesChange={onFilterByStatusesChange}
                onFilterByTermsChange={onFilterByTermsChange}
                onFilterByManageableChange={onFilterByManageableChange}
              />
            </thead>
            <tbody>
              {isShowLoader
                ? <SubscriptionsTableBodyRowLoader />
                : subscriptions.map((el: any, index: number) => (
                  <SubscriptionsTableBodyRow
                    key={`Subscriptions-${index}`}
                    {...el}
                    navigationState={{ adminSubscriptionQuery: query.toString() }}
                    onEdit={() => undefined}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
        {
          !isShowLoader && subscriptions.length === 0
            ? (
              <EmptyStateContainer
                title="No subscriptions found"
                description="No subscriptions found with specified parameters"
                classes="empty-state-container-table"
              />
            ) : null
        }
      </section>
      {
        subscriptions.length > 0
          ? (
            <Pagination
              current={queryPage}
              entries={queryPageSize}
              totalPages={totalPages}
              onChange={onPaginationChange}
            />
          ) : null
      }
    </div>
  );
};

export default AdminSubscriptions;
