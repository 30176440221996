import { FC, useEffect, useMemo, useState } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import {
  Country,
  Products,
  Quickbooks,
  Sage,
  MsOffice,
  MsOfficeLease,
  OtherProducts,
  AdditionalServices,
  SecureWorkspace,
  Checkout,
  Payment,
  Login,
} from 'pages';
import {
  AdminRouting,
  DashboardRouting,
  Error,
  PrivateRoute,
  PublicRoute,
  SignUpFormRouting
} from 'components';
import { CloseIcon } from 'assets/svg';
import { ToastContainer } from 'react-toastify';
import { OrderNavigation, AccessTokenType, RefreshTokenType, AuthRole, LogType } from 'enums';
import { IdleTimer, addLog, usePrevious } from 'helpers';
import { EXPIRED_TIME, INACTIVE_IDLE, INTENDED_URL, SESSION_EXPIRED } from 'consts';
import { useAppSelector } from 'store/hooks';
import jwtDecode from "jwt-decode";
import ReactGA from 'react-ga4';

// const SIMPLE_FORM_PAGES = [
//   { path: '/sign-up', Element: Country },
//   { path: OrderNavigation.Products, Element: Products },
//   { path: OrderNavigation.Quickbooks, Element: Quickbooks },
//   { path: OrderNavigation.Sage, Element: Sage },
//   { path: OrderNavigation.MsOffice, Element: MsOffice },
//   { path: OrderNavigation.MsOfficeLease, Element: MsOfficeLease },
//   { path: OrderNavigation.OtherProducts, Element: OtherProducts },
//   { path: OrderNavigation.AdditionalServices, Element: AdditionalServices },
//   { path: OrderNavigation.SecureWorkspace, Element: SecureWorkspace },
//   { path: OrderNavigation.Checkout, Element: Checkout },
//   { path: OrderNavigation.Payment, Element: Payment },
// ];

const App: FC = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  const dashboardUser = useAppSelector((state) => state.ussp.user);
  // const orderBreadCrumbs = useAppSelector(state => state.order.breadCrumbs);

  const previousDashboardUser = usePrevious(dashboardUser);

  const [timer, setTimer] = useState<IdleTimer | null>(null);

  const getTokenDetails = () => {
    const token = localStorage.getItem(AccessTokenType.AccessTokenUssp);
    if (!token) {
      return null;
    }
    return jwtDecode(token);
  };

  const getTokenRole = () => {
    const tokenDetails: any = getTokenDetails();
    return tokenDetails?.role;
  };

  // useEffect(() => {
  //   const { pathname } = location;
  //   // check if user can access step based on fill steps
  //   if ((Object.values(OrderNavigation) as string[]).includes(pathname)
  //     && !orderBreadCrumbs.includes((pathname as OrderNavigation))) {
  //     const newPathname = orderBreadCrumbs.length === 0
  //       ? '/sign-up'
  //       : orderBreadCrumbs[orderBreadCrumbs.length - 1];
  //     navigate(newPathname);
  //   }
  // }, []);

  useEffect(() => {
    // to do make subscibre only when token is exsist (maybe add mark isLogged in redux)
    if (!previousDashboardUser && dashboardUser) {
      setTimer(new IdleTimer({
        tokenName: AccessTokenType.AccessTokenUssp,
        timeout: INACTIVE_IDLE,
        onTimeout: (data?: any) => {
          console.log('--TIMEOUT--');
          // remove localstorage tokens
          localStorage.removeItem(AccessTokenType.AccessTokenUssp);
          localStorage.removeItem(RefreshTokenType.RefreshTokenUssp);
          localStorage.removeItem(EXPIRED_TIME);
          localStorage.setItem(SESSION_EXPIRED, 'true');
          // remove session storage state
          sessionStorage.removeItem('applicationState');
          sessionStorage.setItem(INTENDED_URL, location.pathname + location.search);
          window.location.href = '/login';
          addLog(LogType.Logout, `idle timer timeout, ${JSON.stringify(data)}`);
        },
        onExpired: (data?: any) => {
          console.log('--EXPIRED--');
          // remove localstorage tokens
          localStorage.removeItem(AccessTokenType.AccessTokenUssp);
          localStorage.removeItem(RefreshTokenType.RefreshTokenUssp);
          localStorage.removeItem(EXPIRED_TIME);
          localStorage.setItem(SESSION_EXPIRED, 'true');
          // remove session storage state
          sessionStorage.removeItem('applicationState');
          sessionStorage.setItem(INTENDED_URL, location.pathname + location.search);
          window.location.href = '/login';
          addLog(LogType.Logout, `idle timer expired, ${JSON.stringify(data)}`);
        },
      }));
    } else if (timer && !dashboardUser) {
      timer.cleanUp();
      setTimer(null);
    }
  }, [dashboardUser]);

  // const isSimpleFormPage = useMemo(() => {
  //   const { pathname } = location;
  //   return SIMPLE_FORM_PAGES.some(page => pathname.includes(page.path));
  // }, [location]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      <div className="full-block">
        <Routes>
          <Route
            path={'/'}
            element={<Navigate replace to={'/login'} />}
          />
          {/* {SIMPLE_FORM_PAGES.map(({ path, Element }) =>
            <Route
              key={path}
              path={path}
              element={<Element />}
            />,
          )}
          <Route path="/sign-up/*" element={(
            <Error
              code={404}
              isShowButton
              buttonLabel="Go to Sign Up Page"
              buttonRedirectPath={orderBreadCrumbs[orderBreadCrumbs.length - 1] || '/sign-up'} />
          )}/> */}
          <Route
            path="/sign-up/*"
            element={
              <SignUpFormRouting />
            }
          />
          {
            process.env.REACT_APP_DISABLE_USSP !== 'true' && <>
              <Route
                path="/login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="/admin-portal/*"
                element={
                  <PrivateRoute additionalRedirect={getTokenRole() !== AuthRole.Admin}>
                    <AdminRouting />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user-portal/*"
                element={
                  <PrivateRoute additionalRedirect={getTokenRole() !== AuthRole.Customer}>
                    <DashboardRouting />
                  </PrivateRoute>
                }
              />
            </>
          }
          <Route path="*" element={<Error code={404} absoluteStyles xVersion isShowButton />} />
        </Routes>
      </div>
      <ToastContainer
        autoClose={7000}
        closeOnClick
        hideProgressBar
        theme="dark"
        closeButton={({ closeToast }) => (
          <CloseIcon className="Toastify__close-icon " onClick={closeToast}/>
        )}
      />
    </>
  );
};

export default App;