import { FC } from 'react';
import { ITableFilterOrdering } from 'interfaces';
import { TableOrder } from 'components';

interface Props {
  orderings?: ITableFilterOrdering[];
  onOrderChange: (data: ITableFilterOrdering) => void;
}

const UserProfileTableHeadRow: FC<Props> = ({
  orderings,
  onOrderChange,
}) => (
  <tr className="user-profile-table-head-row">
    <th>
      <TableOrder
        label="APPLICATIONS"
        fieldName="Name"
        currentOrderState={orderings?.find(({ orderBy }) => orderBy === 'Name')}
        onChange={onOrderChange}
      />
    </th>
    <th>
      <TableOrder
        label="DATE ADDED"
        fieldName="CreatedAt"
        currentOrderState={orderings?.find(({ orderBy }) => orderBy === 'CreatedAt')}
        onChange={onOrderChange}
      />
    </th>
    <th>PRODUCT DETAILS</th>
    <th>ACTIONS</th>
  </tr>
);

export default UserProfileTableHeadRow;
