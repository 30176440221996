import './EditUserModal.scss';
import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _debounce from 'lodash/debounce';

import { notify, useFormFieldErrorNotification } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { editUser, checkIsAllowedUserEmail, removeUserAvatar, isUserLastAdmin } from 'store';

import { UserRole, UsspRole } from 'enums';
import { EMAIL_PATTERN, PHONE_PATTERN_SIMPLE } from 'consts';

import {
  InputText,
  Select,
  Modal,
  CheckboxButton,
  // UploadUserAvatar,
} from 'components';

type UserForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: UserRole;
  hasMfa: boolean;
  avatar?: string;
};

interface IUserData extends UserForm {
  id: string;
  phoneNumber: string;
  isAdmin: boolean;
  hasMfa: boolean;
}

const initialUserForm: UserForm = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: UserRole.Standard,
  hasMfa: false,
};

interface Props {
  visible: boolean;
  userData?: IUserData | null;
  subscriptionId?: string;
  isAdminPortal?: boolean;
  onCancel: () => void;
  onSuccessEdit: () => void;
}

const schema = yup.object({
  firstName: yup.string().trim().required('First name is required').min(2, 'First name must be at least 2 characters'),
  lastName: yup.string().trim().required('Last name is required').min(2, 'Last name must be at least 2 characters'),
  email: yup.string().required('Email is required')
    .matches(EMAIL_PATTERN, 'Please enter a valid email'),
  phone: yup.string().nullable()
    .matches(PHONE_PATTERN_SIMPLE, { message: 'Please enter a valid phone', excludeEmptyString: true })
    .max(15, 'Phone number can\'t be longer than 15 digits')
    .when('role', {
      is: UserRole.Admin,
      then: yup.string().nullable().required('Phone number is required'),
    })
    .when('hasMfa', {
      is: true,
      then: yup.string().nullable().required('Phone number is required'),
    }),
  role: yup.string().required('Role is required'),
  hasMfa: yup.boolean().optional(),
});

const EditUserModal: FC<Props> = ({
  visible,
  userData,
  subscriptionId,
  isAdminPortal,
  onCancel,
  onSuccessEdit,
}) => {
  const {
    getValues,
    register,
    resetField,
    control,
    handleSubmit,
    setError,
    setValue,
    formState,
    watch,
  } = useForm<UserForm>({ mode: 'all', resolver: yupResolver(schema), defaultValues: initialUserForm });
  useFormFieldErrorNotification(formState, ['email']);
  const { errors, isValid } = formState;

  // Additional validator for email field - is unique
  const [isEmailExist, setIsEmailExist] = useState<boolean>(false);
  const [isValidateInProgress, setIsValidateInProgress] = useState<boolean>(false);
  const emailAsyncValidation = async (subscriptionId: string, email: string) => {
    try {
      await dispatch(checkIsAllowedUserEmail({
        subscriptionId,
        email,
        isAdminPortal,
        _disableErrorHandler: true,
      })).unwrap();
      setIsEmailExist(false);
    } catch {
      setIsEmailExist(true);
      setError('email', { type: 'unique-email', message: 'Email should be unique' });
    }
    setIsValidateInProgress(false);
  };
  const debouncedEmailAsyncValidation = useCallback(_debounce(emailAsyncValidation, 500), []);

  const isFormNotChanged = useMemo(() => {
    const formValues = getValues();
    const {
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
      phoneNumber = phone as string,
      isAdmin = false,
      role = (isAdmin ? UserRole.Admin : UserRole.Standard) as UserRole,
      hasMfa = false,
    } = userData || {};

    return formValues.firstName === firstName
      && formValues.lastName === lastName
      && formValues.email === email
      && formValues.phone === phoneNumber
      && formValues.role === role
      && formValues.hasMfa === hasMfa;
  }, [watch()]);

  const isAdmin = useMemo(() => getValues('role') === UserRole.Admin, [watch()]);
  const hasMfaValue = useMemo(() => getValues('hasMfa'), [watch()]);
  const phoneValue = useMemo(() => getValues('phone'), [watch()]);

  useEffect(() => {
    setValue('phone', phoneValue, {
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [hasMfaValue]);

  const resetModal = (userData?: IUserData | null) => {
    const {
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
      phoneNumber = phone as string,
      isAdmin = false,
      role = (isAdmin ? UserRole.Admin : UserRole.Standard) as UserRole,
      hasMfa = false,
    } = userData || {};

    resetField('firstName', { defaultValue: firstName });
    resetField('lastName', { defaultValue: lastName });
    resetField('email', { defaultValue: email });
    resetField('phone', { defaultValue: phoneNumber });
    resetField('role', { defaultValue: role });
    resetField('hasMfa', { defaultValue: hasMfa });
  };

  useEffect(() => {
    if (visible) {
      if (userData && typeof subscriptionId === 'string') {
        dispatch(isUserLastAdmin({ subscriptionId, userId: userData.id, isAdminPortal }));
      }
      resetModal(userData);
    }
  }, [visible]);

  const [userAvatarFile, setUserAvatarFile] = useState<undefined | null | Blob>(undefined);

  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(state => state.ussp.editUserRequest);
  const { data: isCurrentUserLastAdmin } = useAppSelector(state => state.ussp.isUserLastAdminRequest);
  const onNextHandler: SubmitHandler<UserForm> = async data => {
    if (typeof subscriptionId === 'string' && typeof userData?.id === 'string') {
      const userFormData = new FormData();
      userFormData.append('UserId', userData.id);
      userFormData.append('FirstName', data.firstName);
      userFormData.append('LastName', data.lastName);
      userFormData.append('Email', data.email);
      userFormData.append('Phone', data.phone || '');
      userFormData.append('Role', data.role);
      userFormData.append(isAdmin ? 'HasMFA' : 'HasMfa', data.hasMfa ? 'true' : 'false');
      userFormData.append('Avatar', userAvatarFile ?? '');

      if (isAdminPortal) {
        if (!isFormNotChanged) {
          await dispatch(editUser({ subscriptionId, isAdminPortal, formData: userFormData })).unwrap();
        }
      } else {
        await Promise.all([
          ...(isFormNotChanged && userAvatarFile === undefined ? [] : [dispatch(editUser({
            subscriptionId,
            formData: userFormData,
          })).unwrap()]),
          ...(userAvatarFile === null ? [dispatch(removeUserAvatar({
            userId: userData.id,
            subscriptionId,
          })).unwrap()] : []),
        ]);
      }

      notify.success(
        'Success',
        'User was successfully edited',
      );
      onSuccessEdit();
      onCancel();
    }
  };

  const isOkDisabled = useMemo(() => {
    const isFormAndAvatarNotChanged = isFormNotChanged && userAvatarFile === undefined;
    return !isValid || isFormAndAvatarNotChanged || isValidateInProgress || isEmailExist;
  }, [isValid, isFormNotChanged, isValidateInProgress, isEmailExist, userAvatarFile]);
  
  return (
    <Modal
      classes="dashboard-accounts-edit-user"
      title="Edit Profile"
      visible={visible}
      width={535}
      okText="Save"
      isOkDisabled={isOkDisabled}
      isOkLoading={isLoading}
      onOk={handleSubmit(onNextHandler)}
      onCancel={onCancel}
    >
      <form>
        {/* <UploadUserAvatar
          currentUserAvatar={userData?.avatar}
          onUpload={setUserAvatarFile}
        /> */}
        <div className="row-items rel-m-b-20">
          <InputText
            label="First name"
            placeholder="e.g. Tom"
            name="firstName"
            register={register}
            shouldUnregister={false}
            isRequired
            isInvalid={!!errors.firstName}
          />
          <InputText
            label="Last name"
            placeholder="e.g. Riddle"
            name="lastName"
            register={register}
            shouldUnregister={false}
            isRequired
            isInvalid={!!errors.lastName}
          />
        </div>
        <Controller
          name="email"
          control={control}
          defaultValue={''}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, isTouched },
          }) => (
            <InputText
              type="email"
              classes="rel-m-b-20"
              label="Email address"
              placeholder="name@summit.com"
              value={value}
              isRequired
              isInvalid={isTouched && invalid}
              onChange={(e: string) => {
                const trimedValue = e.trim();
                onChange(trimedValue);
                if (trimedValue !== userData?.email && (new RegExp(EMAIL_PATTERN)).test(trimedValue)) {
                  setIsValidateInProgress(true);
                  debouncedEmailAsyncValidation(subscriptionId || '', trimedValue);
                }
              }}
              onBlur={() => {
                if (value === '') {
                  onBlur();
                } else if (!isTouched) {
                  setValue('email', value, { shouldTouch: true });
                }
              }}
            />
          )}
        />
        <InputText
          classes="rel-m-b-20"
          label="Phone number"
          placeholder="(555) 555-5555"
          name="phone"
          register={register}
          shouldUnregister={false}
          isRequired={isAdmin || hasMfaValue}
          isInvalid={!!errors.phone}
        />
        <Controller
          name="role"
          control={control}
          defaultValue={UserRole.Standard}
          rules={{ required: true }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { invalid },
          }) => (
            <Select
              value={value}
              classes="rel-m-b-20"
              options={Object.values(UserRole)}
              label="Role"
              placeholder="Please choose role"
              isRequired
              isDisabled={isCurrentUserLastAdmin}
              // withInfo
              // infoContent={<>
              //   If you change role to&nbsp;
              //   <span className="font-weight-600">Admin</span>,&nbsp;
              //   you will have to pay for&nbsp;
              //   <span className="font-weight-600">MFA</span>
              // </>}
              isInvalid={invalid}
              onChange={(e) => {
                if (e === UsspRole.Admin) {
                  setValue('hasMfa', true);
                }
                onChange(e);
              }}
              onClose={onBlur}
            />
          )}
        />
        <Controller
          name="hasMfa"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <CheckboxButton
              isChecked={Boolean(value)}
              onChange={onChange}
              label="MFA Status"
              isDisabled={isAdmin}
            />
          )}
        />
      </form>
    </Modal>
  );
};

export default EditUserModal;