import { FC, useMemo } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { usePriceHandler } from 'helpers';

import { CountryCode } from 'enums';

interface Props {
  classes: string;
  isLoading: boolean;
  country: CountryCode;
  balanceInCents: null | number;
  createdAt: string;
  totalRevenueInCents: number;
  currentPeriodEndsAt: string;
}

const formatDate = (value: string): string => dayjs(value).format('MM/DD/YYYY');

const AdditionalInfo: FC<Props> = ({
  classes,
  isLoading,
  country,
  balanceInCents,
  createdAt,
  totalRevenueInCents,
  currentPeriodEndsAt,
}) => {
  const { formatPrice } = usePriceHandler();
  const formatedBalance = useMemo(
    () => formatPrice((balanceInCents ?? 0) / 100, country || CountryCode.USA),
    [balanceInCents],
  );
  const formatedTotalRevenue = useMemo(
    () => formatPrice((totalRevenueInCents ?? 0) / 100, country || CountryCode.USA),
    [totalRevenueInCents],
  );
  const formatedCreatedAt = useMemo(() => createdAt ? formatDate(createdAt) : '-', [createdAt]);
  const formatedCurrentPeriodEndsAt = useMemo(() => currentPeriodEndsAt ? formatDate(currentPeriodEndsAt) : '-', [currentPeriodEndsAt]);

  return (
    <div className={cn('flex rel-gap-64 rel-p-x-32 rel-p-t-24', classes)}>
      <div className="flex-column rel-gap-12">
        <span>Total Payments</span>
        {isLoading ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          formatedTotalRevenue
        )}
      </div>
      <div className="flex-column rel-gap-12">
        <span>Current Balance</span>
        {isLoading ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          formatedBalance
        )}
      </div>
      <div className="flex-column rel-gap-12">
        <span>Subscriber Since</span>
        {isLoading ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          formatedCreatedAt
        )}
      </div>
      <div className="flex-column rel-gap-12">
        <span>Period Ends</span>
        {isLoading ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          formatedCurrentPeriodEndsAt
        )}
      </div>
    </div>
  );
};

export default AdditionalInfo;
