import './Tooltip.scss';
import { FC, ReactElement, ReactNode, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { usePopper } from 'react-popper';
import { makePxDependOnWindowHeight } from 'helpers';

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

interface Props {
  absoluteStyles?: boolean;
  children: ReactNode;
  content: string | ReactElement;
  classes?: string;
  placement?: Placement;
  isDisabled?: boolean;
  isInline?: boolean;
  isFlex?: boolean;
}

const Tooltip: FC<Props> = ({
  children,
  content,
  classes,
  placement = 'auto',
  isDisabled = false,
  isInline = false,
  isFlex = false,
  absoluteStyles = false,
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [rootElement, setRootElement] = useState<Element | null>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, makePxDependOnWindowHeight(8)],
        },
      },
    ],
  });
  const showTooltip = () => {
    if (!isDisabled && !isShow) {
      setIsShow(true);
    }
  };
  const hideTooltip = () => {
    setIsShow(false);
  };

  useEffect(() => {
    setRootElement(document.querySelector('#root'));
  }, []);

  return (
    <div className={cn('tooltip-wrapper', classes, { 'd-inline-block': isInline })}>
      <div
        ref={setReferenceElement}
        className={cn('tooltip-trigger', { 'd-inline-block': isInline, flex: isFlex })}
        onMouseOverCapture={showTooltip}
        onMouseLeave={hideTooltip}>
        {children}
      </div>
      {rootElement !== null && isShow && (ReactDOM.createPortal(
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={cn("tooltip-content", { 'relative-units': !absoluteStyles })}
        >{content}</div>,
        rootElement,
      ))}
    </div>
  );
};

export default Tooltip;