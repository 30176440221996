import React, { FC, useState, useMemo } from 'react';
import {
  Navigation,
  RadioButton,
  TotalPrice,
  InputText,
  OrderNextButton,
  Logo,
} from 'components';
import { QbLogoNewIcon, SageLogoIcon } from 'assets/svg';
import './Products.scss';
import cn from 'classnames';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setHostProduct,
  resetBreadCrumbsFrom,
  resetProductsWithQuantity,
  resetQuickbooks,
  resetSage,
  addProductsToOrder,
  deleteProductsFromOrder,
} from 'store';
import { HostProduct, ProductChargify, MsOfficeLicenseType, OrderNavigation } from 'enums';
import { NUMBER_PATTERN, PRODUCTS_PAGES_NAV } from 'consts';
import { IHostProduct } from 'interfaces';
import { usePriceHandler, useSimpleFormNavigate } from 'helpers';

const ADDITIONAL_PRODUCTS_KEYS = {
  OTHER: 'OTHER',
};

const NAV_FOR_RESET_BY_ADDITIONAL_PRODUCTS = {
  [ADDITIONAL_PRODUCTS_KEYS.OTHER]: OrderNavigation.MsOffice,
};

const Products: FC = () => {
  const dispatch = useAppDispatch();
  const hostProduct = useAppSelector(state => state.order.hostProduct);
  const quickbooksState = useAppSelector(state => state.order.quickbooks);
  const sageState = useAppSelector(state => state.order.sage);
  const msOfficeState = useAppSelector(state => state.order.msOffice);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, touchedFields, isValid },
  } = useForm<IHostProduct>({ mode: 'all', defaultValues: { ...hostProduct } });
  const { getProductPrice } = usePriceHandler();
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);

  const PRODUCTS_MAP = useMemo(() => [
    { title: 'QuickBooks', LogoComponent: QbLogoNewIcon, key: HostProduct.Quickbooks },
    { title: 'Sage', LogoComponent: SageLogoIcon, key: HostProduct.Sage },
    { title: 'Other', LogoComponent: null, key: ADDITIONAL_PRODUCTS_KEYS.OTHER },
  ], []);

  const STEP_PRODUCTS = useMemo(() => {
    return [{ name: ProductChargify.SHServerUserHosting, quantity: (getValues())?.quantity }];
  }, [watch('quantity')]);

  const simpleFormNavigate = useSimpleFormNavigate();
  const nextHandler: SubmitHandler<IHostProduct> = async data => {
    setIsNextLoading(true);
    try {
      // clear breadcrumbs
      if (hostProduct.name && (+hostProduct.quantity < +data.quantity || hostProduct.name !== data.name)) {
        const navForReset = NAV_FOR_RESET_BY_ADDITIONAL_PRODUCTS[hostProduct.name]
          || PRODUCTS_PAGES_NAV[(hostProduct.name)];
        dispatch(resetBreadCrumbsFrom(navForReset));
      }

      //update quantity
      if (+hostProduct.quantity !== +data.quantity) {
        const productsToUpdate = [
          ...(msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense ? [{
            name: ProductChargify.MSOfficeOwn,
            quantity: Number(data.quantity),
            customInfo: {
              provideMetafields: 'true',
            },
          }] : []),
        ];
        if (productsToUpdate.length) {
          await dispatch(addProductsToOrder(productsToUpdate)).unwrap();
        }
      }
      //reset products
      let productsToDelete: (string | null)[] = [];
      if (+hostProduct.quantity > +data.quantity) {
        dispatch(resetProductsWithQuantity());
        productsToDelete.push(
          quickbooksState.data.name,
          sageState.name,
          ...(msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense ? [ProductChargify.MSOfficeOwn] : []),
          ...msOfficeState.products.reduce((acc, { isChecked, name }) => {
            if (isChecked) acc.push(name);
            return acc;
          }, [] as string[]),
        );
      } else if ((hostProduct.name !== null && hostProduct.name !== data.name)) {
        if (hostProduct.name === HostProduct.Quickbooks && quickbooksState.data.name !== null) {
          dispatch(resetQuickbooks);
          productsToDelete.push(quickbooksState.data.name);
        } else if (hostProduct.name === HostProduct.Sage && sageState.name !== null) {
          dispatch(resetSage);
          productsToDelete.push(sageState.name);
        }
      }
      productsToDelete = productsToDelete.filter(Boolean);
      if (productsToDelete.length) {
        await dispatch(deleteProductsFromOrder(productsToDelete)).unwrap();
      }
      await dispatch(addProductsToOrder([{
        name: ProductChargify.SHServerUserHosting,
        quantity: Number(data.quantity),
      }])).unwrap();

      dispatch(setHostProduct({ ...data, quantity: `${Number(data.quantity)}` }));

      if (data.name === ADDITIONAL_PRODUCTS_KEYS.OTHER) {
        simpleFormNavigate(OrderNavigation.MsOffice);
      } else {
        simpleFormNavigate(PRODUCTS_PAGES_NAV[(data.name as HostProduct)]);
      }
    } finally {
      setIsNextLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <div className="products page">
        <div className="page-left">
          <div className="products-icons">
            {PRODUCTS_MAP.map(({ title, key, LogoComponent }, index) => (
              LogoComponent && (
                <LogoComponent
                  key={`product-logo-${title}-${index}`}
                  className={cn({ active: watch('name') === key })}
                />
              )
            ))}
          </div>
          <TotalPrice currentStep={OrderNavigation.Products} stepProducts={STEP_PRODUCTS} />
        </div>
        <div className="page-right">
          <Navigation currentStep={2} />

          <div className="page-right_content">
            <h1>Server User Hosting</h1>
            <form onSubmit={handleSubmit(nextHandler)}>
              <div className="price-per-user flex flex-justify-between flex-align-center rel-m-b-16">
                Price per user
                <span className="price-per-user__value">{getProductPrice(ProductChargify.SHServerUserHosting)}</span>
              </div>
              <InputText
                classes="rel-m-b-40"
                label="How many users would you like sign up today?"
                placeholder="Enter number of user(s)"
                name="quantity"
                register={register}
                isRequired
                validationRules={{ pattern: NUMBER_PATTERN, min: 1, max: 100 }}
                isInvalid={!!touchedFields?.quantity && !!errors?.quantity}
              />
              <h2>Select the Applications You Would Like to Host</h2>
              <p>The applications you select on this page will be installed on your new Summit Hosting server.</p>
              <Controller
                name="name"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                }) => (
                  <>
                    {PRODUCTS_MAP.map((product, index) => (
                      <RadioButton
                        key={`product-${product.title}-${index}`}
                        label={product.title}
                        value={value}
                        isChecked={value === product.key}
                        onChange={() => onChange(product.key)}
                      />
                    ))}
                  </>
                )}
              />
              <OrderNextButton
                isDisabled={!isValid}
                isLoading={isNextLoading}
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;