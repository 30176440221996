import './AssignApplicationSidebarModal.scss';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { assignApplicationToUser } from 'store';

import { notify } from 'helpers';

import { InfoCircleIcon } from 'assets/svg';

import {
  CustomRouterLink,
  Select,
  SidebarModal,
  EmptyStateContainer,
  TotalPriceModalFooter,
} from 'components';

interface Props {
  visible: boolean;
  onCancel: () => void;
  userId: string;
  onSuccess: () => void;
  isAdminPortal?: boolean;
}

interface IApplication {
  id: string;
  componentId: number;
  name: string;
  price: number;
  quantity: number;
  year: number;
}

const AssignApplicationSidebarModal: FC<Props> = ({
  visible,
  onCancel,
  userId,
  onSuccess,
  isAdminPortal = false,
}) => {
  const dispatch = useAppDispatch();
  const { data, isLoading: isLoadingApplications } = useAppSelector(state => state.ussp.getUserAvailableAssigningAppsRequest);
  const { isLoading } = useAppSelector(state => state.ussp.assignApplicationToUserRequest);
  const [productIds, setProductIds] = useState<string[]>([]);

  const { subscriptionId } = useParams();

  const submitHandler = async () => {
    if (typeof subscriptionId === 'string') {
      await dispatch(assignApplicationToUser({ subscriptionId, userId, isAdminPortal, productIds })).unwrap();
      if (isAdminPortal) {
        notify.success('Success', 'Product successfully added to user');
      } else {
        notify.success('Product successfully added to cart', 'Now you can proceed to payment');
      }
      onCancel();
      onSuccess();
    }
  };

  const formattedPrice = useMemo(() => {
    const price: number = data?.filter(({ id }: { id: string }) => productIds.includes(id)).reduce((acc: number, cur: IApplication): number => acc + cur.price, 0);
    return price;
  }, [productIds]);

  const applicationPageRoutePath = isAdminPortal
    ? `/admin-portal/subscriptions/${subscriptionId}/applications`
    : `/user-portal/account-overview/${subscriptionId}`;

  return (
    <SidebarModal
      classes="dashboard-accounts-assign-application"
      title="Assign Application"
      visible={visible}
      onCancel={onCancel}
      onOk={submitHandler}
      okText={isAdminPortal ? 'Yes, Assign' : 'Add to Cart'}
      cancelText="Cancel"
      isOkDisabled={!productIds.length}
      isOkLoading={isLoading}
      contentClasses="flex-column"
      footerAdditonal={!isAdminPortal ? <TotalPriceModalFooter price={formattedPrice} /> : null}
    >
      <div className="block-info relative-units assign-application-info">
        <InfoCircleIcon />
        <div className="block-info__content assign-application-info__content">
          <p>If you want to add a <strong>new application</strong>, that is not installed in your server,
            add the user first, then go into the <CustomRouterLink to={applicationPageRoutePath}>Applications tab</CustomRouterLink> to add the new application</p>
          <p>You can assign the application to this new user there.</p>
        </div>
      </div>
      <div className="flex-1 flex-column">
        <h4 className="assign-application-title">Add Applications</h4>
        {
          (isLoadingApplications && (
            <Skeleton containerClassName="assign-application-load" height="100%" />
          )) || (data?.length <= 0 && (
            <EmptyStateContainer
              title="There are no applications"
              description="All applications have already assigned to users"
              classes="flex-1"
            />
          )) || (
            <Select
              value={productIds}
              classes="rel-m-b-20"
              placeholder="Select products"
              options={data.map((item: any) => ({ label: item.name, value: item.id }))}
              onChange={setProductIds}
              isMulti
              isRequired
              theme="dark"
            />
          )
        }
      </div>
    </SidebarModal>
  );
};

export default AssignApplicationSidebarModal;