import { throttle } from 'lodash';
import { EXPIRED_TIME } from 'consts';

export class IdleTimer {
  timeout: number;
  onTimeout: any;
  onExpired: any;
  eventHandler: any;
  interval: any;
  timeoutTracker: any;
  tokenName: string;

  constructor({
    timeout,
    onTimeout,
    onExpired,
    tokenName,
  }: {
    timeout: number;
    onTimeout: any;
    onExpired: any;
    tokenName: string;
  }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.tokenName = tokenName;

    const expiredTime: number = parseInt(localStorage.getItem(EXPIRED_TIME) || '0', 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired({
        expiredTime: {
          value: expiredTime,
          date: new Date(expiredTime)
        },
        now: new Date()
      });
      return;
    }

    this.eventHandler = throttle(this.updateExpiredTime.bind(this), 1000);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const token = localStorage.getItem(this.tokenName);
      const expiredTime = parseInt(
        localStorage.getItem(EXPIRED_TIME) || '0',
        10,
      );
      if (token && expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout({ expiredTime: new Date(expiredTime), now: new Date() });
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    const token = localStorage.getItem(this.tokenName);
    if (token) {
      this.timeoutTracker = setTimeout(() => {
        localStorage.setItem(EXPIRED_TIME, (Date.now() + this.timeout * 1000).toString());
      }, 300);
    }
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem(EXPIRED_TIME);
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}