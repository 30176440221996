import { FC, ReactNode, useEffect, useState } from 'react';
import Modal from '../Modal/Modal';

interface Config {
  content?: ReactNode;
  title?: string | null;
  description?: string;
  width?: string | number;
  isOkDisabled?: boolean;
  isOkLoading?: boolean;
  okText?: string | null;
  cancelText?: string | null;
  onCancel?: () => void;
  onOk?: () => void;
}

interface Props {
  visible: boolean;
  config: Config[];
  closable?: boolean;
  savePreviousSteps?: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

const INITIAL_STEP = 0;

const StepModal: FC<Props> = ({
  visible,
  config,
  closable = false,
  savePreviousSteps = false,
  onCancel,
  onFinish,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(INITIAL_STEP);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const reset = () => {
    setCurrentStep(INITIAL_STEP);
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    config[currentStep].onCancel?.();
    if (currentStep === INITIAL_STEP || !savePreviousSteps) {
      onCancel();
    } else {
      setIsOpen(false);
      setCurrentStep(currentStep - 1);
      setIsOpen(true);
    }
  };

  const onOkHandler = () => {
    config[currentStep].onOk?.();
    if (currentStep === config.length - 1) {
      onFinish();
    } else {
      setIsOpen(false);
      setCurrentStep(currentStep + 1);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (visible) {
      setIsOpen(true);
    } else {
      reset();
    }
  }, [visible]);

  return (
    <Modal
      width={535}
      title={config[currentStep].title}
      description={config[currentStep].description}
      visible={isOpen}
      isOkDisabled={config[currentStep].isOkDisabled}
      isOkLoading={config[currentStep].isOkLoading}
      okText={config[currentStep].okText}
      cancelText={config[currentStep].cancelText}
      closable={closable}
      onCancel={onCancelHandler}
      onOk={onOkHandler}
    >
      {config[currentStep].content}
    </Modal>
  );
};

export default StepModal;