import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Textarea } from 'components';

import './QuickbooksPurchaseLicense.scss';
import ApplicationFormFooter from '../ApplicationFormFooter/ApplicationFormFooter';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { sendQuickbooksPurchase } from 'store';

interface QuickbooksPurchaseLicenseForm {
  additionalDetails: string;
}

interface Props {
  onCancel: () => void;
  toggleConfirmationModal: () => void;
}

const QuickbooksPurchaseLicense: FC<Props> = ({ onCancel, toggleConfirmationModal }) => {
  const { subscriptionId } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.ussp.sendQuickbooksPurchaseRequest);
  const { control, handleSubmit, getValues } = useForm<QuickbooksPurchaseLicenseForm>({
    defaultValues: {
      additionalDetails: '',
    },
  });

  const sendQuickbooksPurchaseHandler = async () => {
    if (typeof subscriptionId === 'string') {
      await dispatch(sendQuickbooksPurchase({
        subscriptionId,
        message: getValues('additionalDetails'),
      })).unwrap();
      onCancel();
      toggleConfirmationModal();
    }
  };

  return (
    <div className="quickbooks-purchase-license">
      <form className="quickbooks-purchase-license__form" onSubmit={handleSubmit(sendQuickbooksPurchaseHandler)}>
        <div className="quickbooks-purchase-license__title">Select the applications you would like to host</div>
        <div>
          <label className="quickbooks-purchase-license__label">Additional details</label>
          <Controller
            name="additionalDetails"
            render={({ field: { onChange, value } }) => (
              <Textarea
                placeholder="Provide additional information in here"
                value={value}
                onChange={onChange}
              />
            )}
            control={control}
          />
          <div className="quickbooks-purchase-license__tips">
            <span>Example:</span>
            <ul>
              <li>QuickBooks version, year etc</li>
              <li>Number of users</li>
            </ul>
          </div>
        </div>
      </form>
      <ApplicationFormFooter
        onCancel={onCancel}
        onOk={sendQuickbooksPurchaseHandler}
        okText="Send Request"
        okWidth={90}
        cancelText="Back"
        isOkLoading={isLoading}
      />
    </div>
  );
};

export default QuickbooksPurchaseLicense;
