import { FC } from 'react';
import { Link, useMatch, useResolvedPath, LinkProps } from 'react-router-dom';
import cn from 'classnames';

interface CustomLinkProps extends LinkProps {
  matchPattern?: string;
}

const CustomRouterLink: FC<CustomLinkProps> = ({ children, to, className, matchPattern, ...props }) => {
  const { pathname } = useResolvedPath(to);
  const match = useMatch({ path: matchPattern || pathname, end: true });

  return (
    <Link className={cn(className, { active: match })} to={to} {...props}>{children}</Link>
  );
};

export default CustomRouterLink;