import './SubscriptionsDetailsInvoices.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

import { setupSignalR, useCartCompleteSuccess, copyTextToClipboard, notify } from 'helpers';
import { getAdminSubscriptionInvoices } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ITableFilterOrdering } from 'interfaces';

import { CopyIcon } from 'assets/svg';

import {
  Error,
  Pagination,
  EmptyStateContainer,
  Modal,
} from 'components';
import InvoicesTableHeadRow from './components/InvoicesTableHeadRow';
import InvoicesTableBodyRow from './components/InvoicesTableBodyRow';
import InvoicesTableBodyRowLoader from './components/InvoicesTableBodyRowLoader';

const SubscriptionsDetailsInvoices: FC = () => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getAdminSubscriptionInvoicesRequest);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    orderings: '[]',
  });

  useEffect(() => {
    fetchData();
  }, [subscriptionId, query]);

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryOrderings = useMemo(() => {
    return JSON.parse(query.get('orderings') || '[]');
  }, [query]);

  const fetchData = async (
    isBackgroundUpdate?: boolean,
  ) => {
    if (typeof subscriptionId === 'string') {
      const data = await dispatch(getAdminSubscriptionInvoices({
        subscriptionId,
        page: queryPage,
        pageSize: queryPageSize,
        orderings: queryOrderings,
        _disableErrorHandler: true,
        _isBackground: isBackgroundUpdate,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);
  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(true);
    setIsBackgroundUpdate(false);
  };

  const onOrderChange = (data: ITableFilterOrdering) => {
    const newOrderings = data === undefined ? [] : [data];
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('orderings', JSON.stringify(newOrderings));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  };

  useCartCompleteSuccess(async () => {
    await backgroundFetch();
  });

  const onPaginationChange = (page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  };

  // useEffect(() => {
  //   const connection = setupSignalR('/accountOverviewUsers');
  //   if (connection) {
  //     connection.start()
  //       .then(() => {
  //         connection.on('Signal', async (message: any) => {
  //           await backgroundFetch();
  //           console.log('ACCOUNT_OVERVIEW_USERS:SIGNAL', message);
  //         });
  //       })
  //       .catch((e: any) => console.log('Connection failed [accountOverviewUsers]: ', e));
  //   }

  //   return () => {
  //     if (connection) {
  //       connection.stop();
  //     }
  //   };
  // }, []);

  const [isShowPublicUrlModal, setIsShowPublicUrlModal] = useState<boolean>(false);
  const [currentInvoicePublicUrl, setCurrentInvoicePublicUrl] = useState<string | null>(null);
  const showInvoicePublicUrlModal = (publicUrl: string): void => {
    setCurrentInvoicePublicUrl(publicUrl);
    setIsShowPublicUrlModal(true);
  };
  const hideInvoicePublicUrlModal = (): void => {
    setIsShowPublicUrlModal(false);
    setCurrentInvoicePublicUrl(null);
  };
  const copyPublicUrlToClipboard = async (value: string) => {
    await copyTextToClipboard(value);
    notify.success('Success', 'URL successfully copied to clipboard');
  };

  if (error) {
    return <section className="dashboard-account-details__content"><Error code={500} /></section>;
  }

  return (
    <>
      <section className="dashboard-account-details__content">
        <table className="admin-subscription-invoices-table">
          <thead>
            <InvoicesTableHeadRow
              orderState={queryOrderings?.[0]}
              onOrderChange={onOrderChange}
            />
          </thead>
          <tbody>
            {isShowLoader
              ? <InvoicesTableBodyRowLoader />
              : data?.items?.map((el: any, index: number) => (
                <InvoicesTableBodyRow
                  key={`subscription-invoices-details-${index}`}
                  {...el}
                  subscriptionId={subscriptionId}
                  onShowPublicUrl={showInvoicePublicUrlModal}
                />
              ))
            }
          </tbody>
        </table>
        {
          data?.items.length === 0
            ? <EmptyStateContainer
              title="No invoices found"
              description="No invoices found with specified parameters"
              classes="empty-state-container-table"
            />
            : null
        }
      </section>
      {
        data?.items.length > 0
          ? <Pagination
            current={queryPage}
            entries={queryPageSize}
            totalPages={totalPages}
            onChange={onPaginationChange}
          />
          : null
      }
      {isShowPublicUrlModal && (
        <Modal
          title="Invoice Public URL"
          visible={isShowPublicUrlModal}
          onCancel={hideInvoicePublicUrlModal}
          cancelText={null}
          footer={null}
          width={535}
          classes="invoice-public-url-modal"
        >
          <>
            <p className="invoice-public-url-modal__field-label rel-m-b-4">
              View or copy your invoice at the following URL:
            </p>
            <div className="flex flex-align-center rel-gap-24 invoice-public-url-modal__field">
              <span>{currentInvoicePublicUrl}</span>
              <CopyIcon onClick={() => copyPublicUrlToClipboard(currentInvoicePublicUrl ?? '')}/>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default SubscriptionsDetailsInvoices;
