import './Header.scss';
import React, { FC, useCallback, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';

import { addLog, useOnClickOutside } from 'helpers';
import { useAppSelector } from 'store/hooks';

import { AccessTokenType, LogType, RefreshTokenType } from 'enums';
import { EXPIRED_TIME } from 'consts';

import DefaultAvatarImage from 'assets/images/default-avatar.png';
import {
  ArrowDownIcon,
  LoginIcon,
  // QuestionCircleIcon,
  // NotificationIcon,
} from 'assets/svg';
import { Menu } from 'components';

interface Props {
  gridArea?: string;
  isLoading?: boolean;
}

const Header: FC<Props> = ({
  gridArea,
  isLoading,
}) => {
  const user = useAppSelector((state) => state.ussp.user);
  const userMenuNode = useRef<HTMLDivElement | null>(null);
  const [isOpenUserMenu, setIsOpenUserMenu] = useState<boolean>(false);

  const closeUserMenu = (): void => {
    setIsOpenUserMenu(false);
  };

  useOnClickOutside<HTMLDivElement>(userMenuNode, () => {
    if (isOpenUserMenu) {
      setIsOpenUserMenu(false);
    }
  });

  const logout = useCallback((e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    closeUserMenu();
    // remove localstorage tokens
    localStorage.removeItem(AccessTokenType.AccessTokenUssp);
    localStorage.removeItem(RefreshTokenType.RefreshTokenUssp);
    // remove IDLE expired time
    localStorage.removeItem(EXPIRED_TIME);
    // remove session storage state
    sessionStorage.removeItem('applicationState');
    window.location.href = '/login';
    addLog(LogType.Logout, 'logout button click');
  }, []);

  return (
    <header className="app-header flex flex-align-center flex-justify-end" style={{ gridArea }}>
      {/* <QuestionCircleIcon className="app-header__question-icon rel-m-r-28"/>
      <div className="app-header__notification-wrapper rel-m-r-36">
        <NotificationIcon className="app-header__notification-icon" />
        <div className="app-header__notification-indicator"></div>
      </div> */}
      {isLoading && user !== null ? (
        <div
          className="user-card flex flex-align-center flex-justify-between skeleton">
          <Skeleton containerClassName="user-card__avatar rel-m-r-16" height="100%" width="100%" circle />
          <div className="user-card__description skeleton__text rel-m-r-28">
            <Skeleton containerClassName="user-card__name" height="100%" width="70%" />
            <Skeleton containerClassName="user-card__email" height="100%" />
          </div>
          <ArrowDownIcon className="user-card__dropdown-icon" />
        </div>
      ) : (
        <Menu
          trigger={
            <div
              className="user-card flex flex-align-center flex-justify-between">
              <img className="user-card__avatar rel-m-r-16" src={DefaultAvatarImage} alt="User avatar" />
              <div className="user-card__description rel-m-r-28">
                <div className="user-card__name">{user?.firstName} {user?.lastName}</div>
                <div className="user-card__email">{user?.email}</div>
              </div>
              <ArrowDownIcon className={cn('user-card__dropdown-icon', { rotated: isOpenUserMenu })} />
            </div>
          }
          items={[
            <div onClick={logout}><LoginIcon className="menu-item-icon" /> Log out</div>,
          ]}
          placement="bottom-end"
          onStateChange={setIsOpenUserMenu}
        />
      )}
    </header>
  );
};

export default Header;
