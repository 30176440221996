import React, { FC, useMemo } from 'react';
import { cardInputFieldStylesXVersion, inputFieldStyles, inputFieldStylesXVersion, selectFieldStyles, selectFieldStylesXVersion } from './styleConfig';
import { CardTypeChargify, CountryCode } from 'enums';
import { MasterCardIcon, VisaIcon, DiscoverIcon, DinersClubIcon, JcbIcon, MaestroIcon, CreditCardIcon, AmericanExpressWhiteIcon } from 'assets/svg';

export const PAYMENT_CARD_ICONS: { [key: string]: FC } = {
  [CardTypeChargify.MasterCard]: MasterCardIcon,
  [CardTypeChargify.Visa]: VisaIcon,
  [CardTypeChargify.AmericanExpress]: AmericanExpressWhiteIcon,
  [CardTypeChargify.Discover]: DiscoverIcon,
  [CardTypeChargify.Diners]: DinersClubIcon,
  [CardTypeChargify.DinersClub]: DinersClubIcon,
  [CardTypeChargify.Jcb]: JcbIcon,
  [CardTypeChargify.Maestro]: MaestroIcon,
};

const getBaseFields = ({ xVersion }: { xVersion?: boolean }) => console.log(5, xVersion) as any || ({
  firstName: {
    selector: '#chargify5',
    label: 'First Name',
    placeholder: xVersion ? ' ' : 'John',
    required: true,
    message: 'Incorrect first name.',
    maxlength: '30',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  lastName: {
    selector: '#chargify6',
    label: 'Last Name',
    placeholder: xVersion ? ' ' : 'Doe',
    required: true,
    message: 'Incorrect last name.',
    maxlength: '30',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  number: {
    selector: '#chargify1',
    label: 'Card number',
    placeholder: xVersion ? ' ' : 'xxxx xxxx xxxx xxxx',
    message: 'Incorrect card number.',
    style: xVersion ? cardInputFieldStylesXVersion : inputFieldStyles,
  },
  month: {
    selector: '#chargify2',
    label: 'Month',
    placeholder: xVersion ? ' ' : 'mm',
    message: 'Incorrect month.',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
    next_focus: 'year'
  },
  year: {
    selector: '#chargify3',
    label: 'Year',
    placeholder: xVersion ? ' ' : 'yyyy',
    message: 'Incorrect year.',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  cvv: {
    selector: '#chargify4',
    label: 'CVV',
    placeholder: xVersion ? ' ' : '123',
    required: true,
    message: 'Incorrect CVV code.',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  address: {
    selector: '#chargify7',
    label: 'Address',
    placeholder: xVersion ? '' : '1234 Hill St',
    required: true,
    message: 'Incorrect address.',
    maxlength: '70',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  address2: {
    selector: '#chargify8',
    label: 'Address 2',
    placeholder: xVersion ? '' : '1234 Hill St',
    required: false,
    message: 'Incorrect address 2.',
    maxlength: '70',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  city: {
    selector: '#chargify9',
    label: 'City',
    placeholder: xVersion ? ' ' : 'Austin',
    required: true,
    message: 'Incorrect city.',
    maxlength: '30',
    style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
  },
  country: {
    selector: '#chargify10',
    label: 'Country',
    placeholder: xVersion ? ' ' : 'Select...',
    required: true,
    message: 'Incorrect country',
    maxlength: '2',
    style: xVersion ? selectFieldStylesXVersion : selectFieldStyles,
  },
  state: {
    selector: '#chargify11',
    label: 'State / Province',
    placeholder: xVersion ? ' ' : 'Select...',
    required: true,
    message: 'Incorrect state',
    maxlength: '2',
    style: xVersion ? selectFieldStylesXVersion : selectFieldStyles,
  },
});

const getFields = ({ xVersion }: { xVersion?: boolean }) => ({
  [CountryCode.USA]: {
    ...getBaseFields({ xVersion }),
    zip: {
      selector: '#chargify12',
      label: 'Zip Code',
      placeholder: xVersion ? ' ' : '10001',
      required: true,
      message: 'Incorrect zip code.',
      maxlength: '7',
      style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
    },
  },
  [CountryCode.Canada]: {
    ...getBaseFields({ xVersion }),
    zip: {
      selector: '#chargify12',
      label: 'Post Code',
      placeholder: xVersion ? ' ' : 'A1A 1A1',
      required: true,
      message: 'Incorrect post code.',
      maxlength: '7',
      style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
    },
  },
  [CountryCode.Other]: {
    ...getBaseFields({ xVersion }),
    zip: {
      selector: '#chargify12',
      label: 'Zip Code',
      placeholder: xVersion ? ' ' : '10001',
      required: true,
      message: 'Incorrect zip code.',
      maxlength: '7',
      style: xVersion ? inputFieldStylesXVersion : inputFieldStyles,
    },
  },
});

interface Props {
  hide: boolean;
  cardType?: CardTypeChargify;
  xVersion?: boolean;
}

const template: FC<Props> = ({ hide, cardType, xVersion }) => {

  const CardImage = useMemo(() => cardType ? PAYMENT_CARD_ICONS[cardType] : CreditCardIcon, [cardType]);
  return (
    <div style={{ display: hide ? 'none' : '' }}>
      <div id="number-row">
        <div id="card-type">{CardImage && <CardImage />}</div>
        <div id="chargify1"></div>
      </div>
      <div className="row-items">
        <div id="chargify2"></div>
        <div id="chargify3"></div>
        <div id="chargify4"></div>
      </div>
      <div className="row-items">
        <div id="chargify5"></div>
        <div id="chargify6"></div>
      </div>
      <div id="chargify7"></div>
      <div id="chargify8"></div>
      <div className="row-items">
        <div id="chargify9"></div>
        <div id="chargify10"></div>
      </div>
      <div className="row-items">
        <div id="chargify11"></div>
        <div id="chargify12"></div>
      </div>
    </div>
  );
};

export {
  getFields,
  template,
};
