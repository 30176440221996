import { FC, useMemo } from 'react';
import './SignUpHeader.scss';
import { Button, Logo } from 'components';

const SignUpHeader = () => {
  return (
    <header className="sign-up-header">
      <div className="sign-up-logo">
        <Logo absoluteStyles />
      </div>
      <Button
        xVersion
        absoluteStyles
        size="medium"
        type="default"
        classes="request-quote-button"
        onClick={() => window.open('https://www.summithosting.com/about-us/contact-us/', '_blank')}
      >
        Request a quote
      </Button>
    </header>
  );
};

export default SignUpHeader;