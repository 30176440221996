import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import querystring from 'query-string';
import { CountryCode, HostProduct, MsOfficeLicenseType, OrderNavigation, SignUpNavigation } from 'enums';
import {
  IHttpRequestResult,
  IHostProduct,
  IQuickbooks,
  ISage,
  IMsOffice,
  IMsOfficeProduct,
  IOtherProduct,
  IAssignProductItem,
  ICoupon,
  ICompanyInfo,
  ICountry,
  IAdditionalServices,
} from 'interfaces';
import { MS_OFFICE_PRODUCTS, ADDITIONAL_SERVICES_PRODUCTS } from 'consts';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers';

interface IRequestOptions {
  _disableErrorHandler?: boolean;
}

export const createOrder = createAsyncThunk<any, any>(
  'order/createOrder',
  async ({ country, userId }, { getState }) => {
    const { companyInfoId } = (getState() as any).order;
    const response = await http.post('/bff/orders/create', {
      userId,
      country,
      companyInfoId,
    });
    return response;
  },
);

export const addProductsToOrder = createAsyncThunk<any, any>(
  'order/addProductsToOrder',
  async (products, { getState }) => {
    const { orderId, country } = (getState() as any).order;
    const response = await http.post('/bff/orders/order-products/create', {
      orderId,
      country: country?.code,
      products,
    });
    return response;
  },
);

export const deleteProductsFromOrder = createAsyncThunk<any, any>(
  'order/deleteProductsFromOrder',
  async (products, { getState }) => {
    const { orderId, country } = (getState() as any).order;
    const response = await http.delete('/bff/orders/order-products/delete', { data: {
      orderId,
      country: country?.code,
      products,
    } });
    return response;
  },
);

export const addUserAssignment = createAsyncThunk<any, any>(
  'order/addUserAssignment',
  async (assignProducts, { getState }) => {
    const { orderId, country } = (getState() as any).order;
    const response = await http.post('/bff/orders/user-assignments/create', {
      orderId,
      country: country?.code,
      assignProducts,
    });
    return response;
  },
);

export const deleteUserAssignment = createAsyncThunk<any, any>(
  'order/deleteUserAssignment',
  async (_, { getState }) => {
    const { orderId, country } = (getState() as any).order;
    const response = await http.delete('/bff/orders/user-assignments/delete', { data: {
      orderId,
      country: country?.code,
    } });
    return response;
  },
);

export const checkCoupon = createAsyncThunk<any, any>(
  'order/checkCoupon',
  async ({ code, _disableErrorHandler }, { getState }) => {
    const { orderId, userDetails, country } = (getState() as any).order;
    const response = await http.post('/bff/orders/coupon', {
      orderId,
      userId: userDetails?.id,
      code,
      country: country?.code,
    }, { _disableErrorHandler });
    return response;
  },
);

export const resetCoupon = createAsyncThunk<any, IRequestOptions | undefined>(
  'order/resetCoupon',
  async ({ _disableErrorHandler = false } = {}, { getState }) => {
    const { orderId, userDetails, country } = (getState() as any).order;
    const query = querystring.stringify({ OrderId: orderId, UserId: userDetails?.id, Country: country?.code });
    const response = await http.delete(`/bff/orders/coupon?${query}`, { _disableErrorHandler });
    return response;
  },
);

export const completeOrder = createAsyncThunk<any, any>(
  'order/completeOrder',
  async ({ token, products, paymentType, termsId, _disableErrorHandler }, { getState }) => {
    const {
      companyName,
      orderId,
      country,
      signature,
      companyInfoId,
      userDetails,
    } = (getState() as any).order;

    const { firstName, lastName } = userDetails || {};

    const response = await http.put('/bff/orders/complete', {
      orderId,
      companyName,
      country: country?.code,
      products,
      companyInfoId,
      chargifyToken: token,
      paymentType,
      signature,
      billingDetails: {
        firstName,
        lastName,
      },
      termsId,
    }, { _disableErrorHandler });
    return response;
  },
);

export const getSubscriptionTerms = createAsyncThunk<any>(
  'ussp/getSubscriptionTerms',
  async () => {
    const response = await http.get('/bff/subscription/terms');
    return response;
  },
);

interface IUserDetailsFields {
  country: CountryCode;
  firstName: string;
  lastName: string;
  email: string;
}
interface IUserDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  companyInfo: ICompanyInfo;
}

interface OrderState {
  createOrderRequest: IHttpRequestResult<any>;
  addProductsToOrderRequrest: IHttpRequestResult<any>;
  deleteProductsFromOrderRequest: IHttpRequestResult<any>;
  addUserAssignmentRequest: IHttpRequestResult<any>;
  deleteUserAssignmentRequest: IHttpRequestResult<any>;
  checkCouponRequest: IHttpRequestResult<any>;
  completeOrderRequest: IHttpRequestResult<any>;
  getSubscriptionTermsRequest: IHttpRequestResult<any>;
  companyName: string | null;
  orderId: string | null;
  companyInfoId: string | null;
  breadCrumbs: OrderNavigation[];
  signUpBreadCrumbs: SignUpNavigation[];
  country: null | ICountry;
  hostProduct: IHostProduct;
  quickbooks: IQuickbooks;
  sage: ISage;
  msOffice: IMsOffice;
  otherProducts: IOtherProduct[];
  additionalServices: IAdditionalServices[];
  assignProducts: IAssignProductItem[];
  coupon: null | ICoupon;
  signature: null | string;
  termsId: undefined | number;
  userDetails: null | IUserDetails;
  userDetailsFields: null | IUserDetailsFields;
}

const getDefaultQuickbooksData = () => ({
  option: null,
  data: {
    name: null,
    quantity: '',
  },
});
const getDefaultSageData = () => ({
  name: null,
  year: '',
  quantity: '',
  serialNumber: '',
});
const getDefaultMsOfficeProductsData = () => MS_OFFICE_PRODUCTS.map(el => ({
  name: el,
  isChecked: false,
  quantity: '',
}));

const initialState: OrderState = {
  createOrderRequest: createHttpRequestInitResult(),
  addProductsToOrderRequrest: createHttpRequestInitResult(),
  deleteProductsFromOrderRequest: createHttpRequestInitResult(),
  addUserAssignmentRequest: createHttpRequestInitResult(),
  deleteUserAssignmentRequest: createHttpRequestInitResult(),
  checkCouponRequest: createHttpRequestInitResult(),
  completeOrderRequest: createHttpRequestInitResult(),
  getSubscriptionTermsRequest: createHttpRequestInitResult(),
  companyName: null,
  orderId: null,
  companyInfoId: null,
  breadCrumbs: [],
  signUpBreadCrumbs: [],
  country: null,
  hostProduct: {
    name: null,
    quantity: '',
  },
  quickbooks: getDefaultQuickbooksData(),
  sage: getDefaultSageData(),
  msOffice: {
    licenseType: null,
    products: getDefaultMsOfficeProductsData(),
  },
  otherProducts: [],
  additionalServices: [],
  assignProducts: [],
  coupon: null,
  signature: null,
  termsId: undefined,
  userDetails: null,
  userDetailsFields: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    setOrderId: (state, action: PayloadAction<string>) => {
      state.orderId = action.payload;
    },
    setCompanyInfoId: (state, action: PayloadAction<string>) => {
      state.companyInfoId = action.payload;
    },
    setBreadCrumb: (state, action: PayloadAction<OrderNavigation>) => {
      if (!state.breadCrumbs.includes(action.payload)) {
        state.breadCrumbs.push(action.payload);
      }
    },
    setSignUpBreadCrumb: (state, action: PayloadAction<SignUpNavigation>) => {
      if (!state.signUpBreadCrumbs.includes(action.payload)) {
        state.signUpBreadCrumbs.push(action.payload);
      }
    },
    setCountry: (state, action: PayloadAction<ICountry>) => {
      state.country = { ...action.payload };
    },
    setUserDetailsFields: (state, action: PayloadAction<IUserDetailsFields>) => {
      state.userDetailsFields = { ...action.payload };
    },
    setHostProduct: (state, action: PayloadAction<IHostProduct>) => {
      // set default state
      if (+state.hostProduct.quantity < +action.payload.quantity) {
        state.quickbooks = getDefaultQuickbooksData();
        state.sage = getDefaultSageData();
        state.msOffice = { licenseType: null, products: getDefaultMsOfficeProductsData() };
      } else if ((state.hostProduct.name !== null && state.hostProduct.name !== action.payload.name)) {
        if (state.hostProduct.name === HostProduct.Quickbooks) {
          state.quickbooks = getDefaultQuickbooksData();
        } else if (state.hostProduct.name === HostProduct.Sage) {
          state.sage = getDefaultSageData();
        }
      }

      state.hostProduct = { ...action.payload };
    },
    setQuickbooks: (state, action: PayloadAction<IQuickbooks>) => {
      state.quickbooks = {
        ...action.payload,
        data: { ...action.payload.data },
      };
    },
    resetQuickbooks: state => {
      state.quickbooks = getDefaultQuickbooksData();
    },
    setSage: (state, action: PayloadAction<ISage>) => {
      state.sage = { ...action.payload };
    },
    resetSage: state => {
      state.sage = getDefaultSageData();
    },
    setMsOfficeLicenseType: (state, action: PayloadAction<null | MsOfficeLicenseType>) => {
      if (state.msOffice.licenseType !== action.payload) {
        const msOfficeIndex = state.breadCrumbs.findIndex(el => el === OrderNavigation.MsOffice);
        state.breadCrumbs.splice(msOfficeIndex + 1);
      }
      state.msOffice.licenseType = action.payload;
      if (action.payload === MsOfficeLicenseType.OwnLicense) {
        state.msOffice.products = getDefaultMsOfficeProductsData();
      }
    },
    setOtherProducts: (state, action: PayloadAction<IOtherProduct[]>) => {
      state.otherProducts = action.payload.map(el => ({ ...el }));
    },
    setMsOfficeProducts: (state, action: PayloadAction<IMsOfficeProduct[]>) => {
      state.msOffice.products = action.payload.map(el => ({ ...el }));
    },
    resetMsOffice: state => {
      state.msOffice.licenseType = null;
      state.msOffice.products = getDefaultMsOfficeProductsData();
      const msOfficeIndex = state.breadCrumbs.findIndex(el => el === OrderNavigation.MsOffice);
      if (msOfficeIndex >= 0) {
        state.breadCrumbs.splice(msOfficeIndex);
      }
    },
    setAdditionalServices: (state, action: PayloadAction<typeof ADDITIONAL_SERVICES_PRODUCTS[number][] | []>) => {
      state.additionalServices = action.payload.map(name => ({ name, quantity: '1' }));
    },
    setAssignProducts: (state, action: PayloadAction<IAssignProductItem[]>) => {
      state.assignProducts = action.payload.map(({ userInfo, products }) => ({
        userInfo: { ...userInfo },
        products: [...products],
      }));
    },
    setCoupon: (state, action: PayloadAction<ICoupon | null>) => {
      state.coupon = action.payload;
    },
    setSignature: (state, action: PayloadAction<string | null>) => {
      state.signature = action.payload;
    },
    setTermsId: (state, action: PayloadAction<number | undefined>) => {
      state.termsId = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<IUserDetails | null>) => {
      state.userDetails = action.payload !== null ? {
        ...action.payload,
        companyInfo: {
          ...action.payload.companyInfo,
        },
      } : null;
    },
    resetBreadCrumbsFrom(state, action: PayloadAction<OrderNavigation>) {
      const itemIndex = state.breadCrumbs.findIndex(el => el === action.payload);
      if (itemIndex >= 0) state.breadCrumbs.splice(itemIndex);
    },
    resetSignUpBreadCrumbsFrom(state, action: PayloadAction<SignUpNavigation>) {
      const itemIndex = state.signUpBreadCrumbs.findIndex(el => el === action.payload);
      if (itemIndex >= 0) state.signUpBreadCrumbs.splice(itemIndex);
    },
    resetProductsWithQuantity(state) {
      state.quickbooks = getDefaultQuickbooksData();
      state.sage = getDefaultSageData();
      state.msOffice = { licenseType: null, products: getDefaultMsOfficeProductsData() };
      state.otherProducts = state.otherProducts.map(e => ({
        ...e, isChecked: false, licenseCode: '', productCode: '', serialNumber: '',
      }));
      state.assignProducts = [];
      state.additionalServices = [];
    },
    resetOrder: () => initialState,
  },
  extraReducers: {
    ...createExtraReducersForResponses(createOrder, 'createOrderRequest'),
    ...createExtraReducersForResponses(addProductsToOrder, 'addProductsToOrderRequrest'),
    ...createExtraReducersForResponses(deleteProductsFromOrder, 'deleteProductsFromOrderRequest'),
    ...createExtraReducersForResponses(addUserAssignment, 'addUserAssignmentRequest'),
    ...createExtraReducersForResponses(deleteUserAssignment, 'deleteUserAssignmentRequest'),
    ...createExtraReducersForResponses(checkCoupon, 'checkCouponRequest'),
    ...createExtraReducersForResponses(completeOrder, 'completeOrderRequest'),
    ...createExtraReducersForResponses(getSubscriptionTerms, 'getSubscriptionTermsRequest'),
  },
});

export const {
  setCompanyName,
  setOrderId,
  setCompanyInfoId,
  setBreadCrumb,
  setSignUpBreadCrumb,
  setCountry,
  setHostProduct,
  setQuickbooks,
  resetQuickbooks,
  setSage,
  resetSage,
  setMsOfficeLicenseType,
  setMsOfficeProducts,
  setOtherProducts,
  resetMsOffice,
  setAdditionalServices,
  setAssignProducts,
  setCoupon,
  setSignature,
  setTermsId,
  setUserDetails,
  setUserDetailsFields,
  resetBreadCrumbsFrom,
  resetProductsWithQuantity,
  resetOrder,
} = orderSlice.actions;

export default orderSlice.reducer;