import './Signature.scss';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import cn from 'classnames';
import { QuillWriteIcon } from 'assets/svg';
import { Button, Modal } from 'components';
import { makePxWindowUnit, useWindowDimensions } from 'helpers';
import SignatureModalFooter from '../SignatureModalFooter/SignatureModalFooter';
import _debounce from 'lodash/debounce';
interface Props {
  xVersion?: boolean;
  absoluteStyles?: boolean;
  bodyClasses?: string;
  value: string;
  isNewStyle?: boolean;
  onChange: (value: string) => void;
}

const Signature: FC<Props> = ({
  bodyClasses,
  value,
  absoluteStyles = false,
  xVersion,
  onChange
}) => {
  const [isEmpty, setIsEmpty] = useState<any>(true);
  const sigCanvas = useRef<any>();
  const [canvasContainerExist, setCanvasContainerExist] = useState(false);
  const { height, width }: { height: number; width: number } = useWindowDimensions();
  const [isShowModal, setShowModal] = useState<boolean>(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    clearCanvas();
  };

  const checkIsEmpty = () => {
    setIsEmpty(sigCanvas.current?.isEmpty());
  };

  const clearCanvas = () => {
    sigCanvas.current.clear();
    checkIsEmpty();
  };
  const onConfirm = () => {
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    onChange(dataUrl);
    closeModal();
  };

  const redo = () => {
    onChange('');
  };
  
  const resizeCanvas = useCallback(() => {
    if (sigCanvas.current) {
      const canvas = sigCanvas.current.getCanvas();
      const container = canvas.parentElement;
      clearCanvas();
      canvas.width = 0;
      canvas.height = 0;
      setTimeout(() => {
        canvas.width = container.offsetWidth;
        canvas.height = container.offsetHeight;
      }, 0);
    }
  }, []);
  
  const debounceResize = useCallback(_debounce(resizeCanvas, 300), [resizeCanvas]);

  useEffect(() => {
    resizeCanvas();
    window.addEventListener('resize', debounceResize);
    return () => {
      window.removeEventListener('resize', debounceResize);
    };
  }, [canvasContainerExist]);

  return (
    <>
      <Modal
        xVersion={xVersion}
        absoluteStyles={absoluteStyles}
        width={535}
        title="Add your eSignature"
        classes="signature-modal"
        okText="Sign"
        isOkDisabled={isEmpty}
        visible={isShowModal}
        onOk={onConfirm}
        onCancel={closeModal}
      >
        {xVersion ? null: <p className="signature-modal-description">Sign your signature below</p>}
        <div className={cn(
          'flex flex-align-center flex-justify-between signature-modal-body', {
            'rel-m-b-12': !absoluteStyles,
            'm-b-12': absoluteStyles,
          })}
        >
          <h2 className="signature-modal-subtitle">Signature</h2>
          {isEmpty ? null : (
            <Button
              dataTestId="signature.modal.clear-button"
              type="textable"
              xVersion
              absoluteStyles
              onClick={clearCanvas}
            >
              Clear
            </Button>
          )}
        </div>
        <div ref={(ref) => {
          setCanvasContainerExist(Boolean(ref?.offsetWidth));
        }} className="sign-wrapper">
          <SignatureCanvas
            ref={sigCanvas}
            onEnd={checkIsEmpty}
            canvasProps={{
              ...(absoluteStyles ? {} : { width: makePxWindowUnit(471, width, 'width') }),
              ...(absoluteStyles ? {} : { height: makePxWindowUnit(250, height, 'height') }),
              className: 'sign-body'
            }}
            clearOnResize={false}
          />
          <div className="sign-line"></div>
        </div>
      </Modal>
      <div className={cn('signature-wrapper', { 'relative-units': !absoluteStyles, xVersion })}>
        <div className="signature-title flex flex-align-center flex-justify-between">
          Signature
          {value && (
            <Button
              dataTestId="signature.redo-button"
              type="textable"
              xVersion
              absoluteStyles
              onClick={redo}
            >
              Redo
            </Button>
          )}
        </div>
        <div
          className={cn('signature-body flex flex-align-center flex-justify-center', bodyClasses)}
          style={{ ...(value ? {
            background: `url(${value})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            border: 'none',
          } : {}) }}>
          {!value && <>
            <QuillWriteIcon />
            <div>
              <a className={cn('link dark', { 'relative-units': !absoluteStyles })} onClick={openModal}>Create signature</a>
            </div>
          </>}
        </div>
      </div>
    </>
  );
};

export default Signature;