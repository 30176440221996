import { FC, useEffect, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';

import { Error, SignUpFooter, SignUpHeader } from 'components';
import {
  AdditionalServicesStep,
  CompanyDetails,
  Payment,
  PaymentStep,
  ProductsList,
  ServerUserHosting,
  SetupUsers,
  Summary,
  UserDetails
} from 'pages';
import { SignUpNavigation } from 'enums';

interface IRoute {
  path: string;
  Element?: FC;
  children?: IRoute[];
}

const SignUpFormRouting: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const orderBreadCrumbs = useAppSelector(state => state.order.signUpBreadCrumbs);

  useEffect(() => {
    const { pathname } = location;
    const relevantPath = pathname.replace('/sign-up', '');
    // check if user can access step based on fill steps
    if ((Object.values(SignUpNavigation) as string[]).includes(relevantPath)
      && !orderBreadCrumbs.includes((pathname as SignUpNavigation))) {
      const newPathname = orderBreadCrumbs.length === 0
        ? '/sign-up'
        : `/sign-up${orderBreadCrumbs[orderBreadCrumbs.length - 1]}`;
      navigate(newPathname);
    }
  }, []);

  const SIGN_UP_FORM_ROUTES: IRoute[] = useMemo(() => [
    { path: SignUpNavigation.UserDetails, Element: UserDetails },
    { path: SignUpNavigation.CompanyDetails, Element: CompanyDetails },
    { path: SignUpNavigation.ServerUserHosting, Element: ServerUserHosting },
    { path: SignUpNavigation.ProductsList, Element: ProductsList },
    { path: SignUpNavigation.AdditionalServices, Element: AdditionalServicesStep },
    { path: SignUpNavigation.SetupUsers, Element: SetupUsers },
    { path: SignUpNavigation.Summary, Element: Summary },
    { path: SignUpNavigation.Payment, Element: PaymentStep },
  ], []);

  return (
    <div className="sign-up-form">
      <SignUpHeader/>
      <Routes>
        {SIGN_UP_FORM_ROUTES.map(({ path, Element }) =>
          <Route
            key={`sign-up-route-${path}`}
            path={path}
            element={Element && <Element />}
          />,
        )}
        <Route path="*" element={(
          <Error
            xVersion
            absoluteStyles
            code={404}
            isShowButton
            buttonLabel="Go to Sign Up Page"
            buttonRedirectPath={`/sign-up${orderBreadCrumbs[orderBreadCrumbs.length - 1]}` || '/sign-up'} />
        )}/>
      </Routes>
      <SignUpFooter/>
    </div>
  );
};

export default SignUpFormRouting;
