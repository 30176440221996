import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { usePriceHandler } from './priceHandler';
import { ProductChargify, MsOfficeLicenseType } from 'enums';
import { IProductWithPrice } from 'interfaces';
// import { ADDITIONAL_SERVICES_PRODUCTS } from 'consts';

interface Arguments {
  excluded?: (ProductChargify | string)[];
}

export const useGetProductsList = ({ excluded = []}: Arguments = {}) => {
  const allProductsInfo = useAppSelector(state => state.products.products);
  const hostProduct = useAppSelector(state => state.order.hostProduct);
  const quickbooksState = useAppSelector(state => state.order.quickbooks);
  const sageState = useAppSelector(state => state.order.sage);
  const msOfficeState = useAppSelector(state => state.order.msOffice);
  const otherProductsState = useAppSelector(state => state.order.otherProducts);
  const additionalServicesState = useAppSelector(state => state.order.additionalServices);

  const PRODUCTS_MAP: IProductWithPrice[] = useMemo(() => {
    return [
      {
        id: allProductsInfo[ProductChargify.SHServerUserHosting]?.component_id?.toString() || '0',
        name: ProductChargify.SHServerUserHosting,
        quantity: hostProduct.quantity,
      },
      ...(quickbooksState.data.name !== null ? [{
        id: allProductsInfo[quickbooksState.data.name]?.component_id?.toString() || '',
        name: quickbooksState.data.name,
        quantity: quickbooksState.data.quantity,
      }] : []),
      ...(sageState.name !== null ? [{
        id: allProductsInfo[sageState.name]?.component_id?.toString() || '',
        name: sageState.name,
        quantity: sageState.quantity,
      }] : []),
      ...(msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense ? [{
        id: allProductsInfo[ProductChargify.MSOfficeOwn]?.component_id?.toString() || '',
        name: ProductChargify.MSOfficeOwn,
        quantity: hostProduct.quantity,
      }] : []),
      ...msOfficeState.products.reduce((acc, { isChecked, name, quantity }) => {
        if (isChecked) acc.push({
          id: allProductsInfo[name]?.component_id?.toString() || '',
          name,
          quantity,
        });
        return acc;
      }, [] as any[]),
      ...otherProductsState.reduce((acc, { id, isChecked, name, quantity, price }) => {
        if (isChecked) acc.push({
          id,
          name,
          quantity,
          price,
        });
        return acc;
      }, [] as any),
      ...additionalServicesState.map(({ name, quantity }) => ({
        id: name && allProductsInfo[name]?.component_id?.toString() || '',
        name,
        quantity,
      })),
      {
        id: allProductsInfo[ProductChargify.SSW]?.component_id?.toString() || '',
        name: ProductChargify.SSW,
        quantity: 1,
      },
    ];
  }, [hostProduct, quickbooksState, sageState, msOfficeState, additionalServicesState]);

  return PRODUCTS_MAP.filter(product => !excluded.includes(product.name));
};

export const useGetProductsListWithPricesFormated = ({ excluded = []}: Arguments = {}) => {
  const { getProductPrice } = usePriceHandler();
  const productList = useGetProductsList({ excluded });

  return productList.map(({ name, quantity, price }) => ({
    name,
    quantity,
    price: price || getProductPrice(name, quantity),
  }));
};

export const useGetProductsListWithPrices = ({ excluded = []}: Arguments = {}) => {
  const { getSinglePriceAmountInDollars } = usePriceHandler();
  const productList = useGetProductsList({ excluded });

  return productList.map(({ id, name, quantity, price }) => ({
    id,
    name,
    quantity: quantity,
    price: price || getSinglePriceAmountInDollars(name),
  }));
};

export const useGetProductsListWithInfo = () => {
  const allProductsInfo = useAppSelector(state => state.products.products);
  const allOtherProductsInfo = useAppSelector(
    state => state.products.otherProductsInfo,
  );

  // const productList = useGetProductsList({ excluded });

  // return productList.map(({ name }) => {
  //   const { name: product_name, ...rest } = allProductsInfo[name];
  //   return { product_name, ...rest };
  // });

  const formatedAllOtherProductsInfo = Object.values(allOtherProductsInfo).map(({
    name: productName,
    componentId,
    pricePointId,
    price,
  }) => ({ productName, componentId, pricePointId, price }));
  const formatedAllProductsInfo = Object.values(allProductsInfo).map(
    ({
      name: productName,
      component_id: componentId,
      price_point_id: pricePointId,
      ...rest
    }: any) => ({
      productName,
      componentId,
      pricePointId,
      ...rest,
    }),
  );

  return [
    ...formatedAllProductsInfo, ...formatedAllOtherProductsInfo];
};