import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import {
  getAdminAvailableCoupons,
  addAdminCoupon,
  removeAdminCoupon,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { DeleteOutlineIcon } from 'assets/svg';

import { Button, Modal, Select, Tooltip } from 'components';
import { notify } from 'helpers';

interface ICoupon {
  name: string;
  description: string;
  code: string;
  isStackable: boolean;
}

interface Props {
  classes: string;
  isLoading: boolean;
  coupons: ICoupon[];
  onCouponsChange: () => void;
}

const DepositInfo: FC<Props> = ({
  classes,
  isLoading,
  coupons,
  onCouponsChange,
}) => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();

  const {
    data: availableCoupons = [],
    isLoading: isAvailableCouponsLoading,
  } = useAppSelector(
    (state) => state.ussp.getAdminAvailableCouponsRequest || {},
  );

  const isThereUnstackableCoupon = () => {
    return coupons?.some(c => !c.isStackable);
  };

  const filteredAvailableCoupons = useMemo(() => {
    return availableCoupons
      .filter(({ code }: ICoupon) => !coupons?.some(coupon => coupon.code === code))
      .filter(({ isStackable }: ICoupon) => {
        if (coupons?.length) {
          if (isThereUnstackableCoupon()) {
            return false;
          }
          return isStackable;
        }
        return true;
      });
  }, [coupons, availableCoupons]);

  const [selectedPromocode, setSelectedPromocode] = useState<string>('');
  const [isLoadingApplyPromocode, setIsLoadingApplyPromocode] = useState<boolean>(false);
  const [isShowPromocodeModal, setIsShowPromocodeModal] = useState<boolean>(false);
  const openPromocodeModal = async () => {
    if (typeof subscriptionId === 'string') {
      await dispatch(getAdminAvailableCoupons({ subscriptionId })).unwrap();
      setIsShowPromocodeModal(true);
    }
  };
  const hidePromocodeModal = () => {
    setIsShowPromocodeModal(false);
    setSelectedPromocode('');
  };

  const applyPromocode = async () => {
    if (typeof subscriptionId === 'string') {
      setIsLoadingApplyPromocode(true);
      await dispatch(addAdminCoupon({
        subscriptionId,
        code: selectedPromocode,
      })).unwrap();
      notify.success(
        'Success',
        'Coupon was successfully added',
      );
      onCouponsChange();
      hidePromocodeModal();
      setIsLoadingApplyPromocode(false);
    }
  };

  const isAppliedCouponNonStackable = useMemo(() => {
    return coupons?.some(c => !c.isStackable);
  }, [coupons]);

  const removePromocode = async (code: string) => {
    if (typeof subscriptionId === 'string') {
      await dispatch(removeAdminCoupon({
        subscriptionId,
        code: encodeURI(code),
      })).unwrap();
      notify.success(
        'Success',
        'Coupon was successfully deleted',
      );
      onCouponsChange();
    }
  };

  return (
    <>
      <div className={classes}>
        <div
          className="rel-p-x-24 rel-p-y-16"
        >
          Coupon
        </div>
        <div
          className="rel-p-x-24 rel-p-y-16 flex flex-justify-between"
        >
          <span>Discount/Promotion ID</span>

          <Tooltip
            isDisabled={!isAppliedCouponNonStackable}
            content="Applied coupon is not stackable"
            placement="top"
          >
            <Button
              isLoading={isAvailableCouponsLoading}
              isDisabled={isAvailableCouponsLoading || isAppliedCouponNonStackable}
              isNewStyle
              size="medium"
              onClick={openPromocodeModal}
            >
              Apply Coupon
            </Button>
          </Tooltip>
        </div>
        {isLoading ? (
          <div
            className="admin-subscription-coupon rel-p-x-20 rel-p-t-16 rel-p-b-24 flex flex-justify-between"
          >
            <div className="flex-1">
              <p className="admin-subscription-coupon__name rel-m-b-8">
                <Skeleton height="100%" width="60%" />
              </p>
              <p className="admin-subscription-coupon__description">
                <Skeleton height="100%" width="90%" />
              </p>
            </div>
            <DeleteOutlineIcon />
          </div>
        ) : (coupons || []).map(({ name, description, code }: any) => (
          <div
            key={code}
            className="admin-subscription-coupon rel-p-x-20 rel-p-t-16 rel-p-b-24 flex flex-justify-between"
          >
            <div>
              <p className="admin-subscription-coupon__name rel-m-b-8">{name}</p>
              <p className="admin-subscription-coupon__description">{description}</p>
            </div>
            <DeleteOutlineIcon className="cursor-pointer" onClick={() => removePromocode(code as string)} />
          </div>
        ))}
      </div>
      {isShowPromocodeModal && (
        <Modal
          title="Apply coupon"
          visible={isShowPromocodeModal}
          onOk={applyPromocode}
          isOkDisabled={!selectedPromocode}
          isOkLoading={isLoadingApplyPromocode}
          okText="Apply"
          onCancel={hidePromocodeModal}
          width={535}
        >
          <Select
            value={selectedPromocode}
            classes="rel-m-b-20"
            options={filteredAvailableCoupons.map(({ name, code }: any = {}) => ({
              label: name,
              value: code,
            }))}
            label="Select discount"
            placeholder="Please select discount"
            isRequired
            onChange={setSelectedPromocode}
          />
        </Modal>
      )}
    </>
  );
};

export default DepositInfo;
