import './AdminRouting.scss';
import { FC, useEffect, useMemo, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import cn from 'classnames';

import { getAdminUser } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  ActivityIcon,
  ActivityOutlineIcon,
  CategoryIcon,
  CategoryOutlineIcon,
  CustomersIcon,
  CustomersOutlineIcon,
  ReportsIcon,
  ReportsOutlineIcon,
  StarIcon,
  StarOutlineIcon,
  UsersIcon,
  UsersOutlineIcon,
  WorkIcon,
  WorkOutlineIcon,
} from 'assets/svg';

import { Error, Header, Sidebar } from 'components';
import {
  AdminHome,
  AdminSubscriptions,
  AdminSubscriptionsDetails,
} from 'pages';

interface INavigation {
  name: string;
  path: string;
  Icon: FC;
  ActiveIcon: FC;
  matchPattern?: string;
  inDevelopment?: boolean,
}
interface IRoute {
  path: string;
  Element?: FC;
  children?: IRoute[];
}

const AdminRouting: FC = () => {
  const isUserLoading = useAppSelector((state) => state.ussp.getAdminUserRequest?.isLoading || false);

  const NAVIGATIONS: INavigation[] = [
    {
      name: 'Dashboard',
      path: '',
      Icon: CategoryOutlineIcon,
      ActiveIcon: CategoryIcon,
      inDevelopment: true,
    },
    {
      name: 'Team',
      path: 'team',
      Icon: UsersOutlineIcon,
      ActiveIcon: UsersIcon,
      matchPattern: 'admin-portal/team/*',
      inDevelopment: true,
    },
    {
      name: 'Subscriptions',
      path: 'subscriptions',
      Icon: StarOutlineIcon,
      ActiveIcon: StarIcon,
      matchPattern: 'admin-portal/subscriptions/*',
    },
    {
      name: 'Customers',
      path: 'customers',
      Icon: CustomersOutlineIcon,
      ActiveIcon: CustomersIcon,
      matchPattern: 'admin-portal/customers/*',
      inDevelopment: true,
    },
    {
      name: 'Reseller',
      path: 'reseller',
      Icon: WorkOutlineIcon,
      ActiveIcon: WorkIcon,
      matchPattern: 'admin-portal/reseller/*',
      inDevelopment: true,
    },
    {
      name: 'Reports',
      path: 'reports',
      Icon: ReportsOutlineIcon,
      ActiveIcon: ReportsIcon,
      matchPattern: 'admin-portal/reports/*',
      inDevelopment: true,
    },
    {
      name: 'Activity logs',
      path: 'activity-logs',
      Icon: ActivityOutlineIcon,
      ActiveIcon: ActivityIcon,
      matchPattern: 'admin-portal/activity-logs/*',
      inDevelopment: true,
    },
  ];

  const ROUTES: IRoute[] = useMemo(() => [
    {
      path: '',
      Element: AdminHome,
    },
    {
      path: 'subscriptions',
      children: [
        {
          path: '',
          Element: AdminSubscriptions,
        },
        {
          path: ':subscriptionId/*',
          Element: AdminSubscriptionsDetails,
        },
      ],
    },
  ], []);
  const dispatch = useAppDispatch();

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);

  const getAdminUserHandler = () => {
    dispatch(
      getAdminUser({ _isBackground: isBackgroundUpdate }),
    ).unwrap();
  };

  useEffect(() => {
  //   const connection = setupSignalR('/userInfo');
  //   if (connection) {
  //     connection.start()
  //       .then(() => {
  //         connection.on('Signal', async (message: any) => {
  //           setIsBackgroundUpdate(true);
  //           await getDashboardUserHandler();
  //           setIsBackgroundUpdate(false);
  //           console.log('USER_INFO:SIGNAL', message);
  //         });
  //         connection.on('PaymentExpirationChanged', async (message: any) => {
  //           setIsBackgroundUpdate(true);
  //           await getDashboardUserHandler();
  //           setIsBackgroundUpdate(false);
  //           console.log('USER_INFO:PAYMENT_EXPIRIATION_CHANGED', message);
  //         });
  //       })
  //       .catch((e: any) => console.log('Connection failed [userInfo]: ', e));
  //   }

    getAdminUserHandler();

    return () => {
      // if (connection) {
      //   connection.stop();
      // }
    };
  }, []);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className={cn('dashboard-layout', { 'collapsed-sidebar': isSidebarCollapsed })}>
      <Sidebar
        gridArea="app-sidebar"
        isCollapsed={isSidebarCollapsed}
        navigationMap={NAVIGATIONS}
        isAdmin
        onToggleMenu={toggleSidebar}
      />
      <Header gridArea="app-header" isLoading={isUserLoading && !isBackgroundUpdate} />
      <main>
        <Routes>
          {ROUTES.map(({ path, Element, children }) => (
            <Route key={`admin-route-${path}`} path={path} element={Element && <Element />}>
              {children?.map(({ path, Element: ChildElement }) => (
                <Route key={`admin-child-route-${path}`} path={path} element={ChildElement && <ChildElement />} />
              ))}
            </Route>
          ))}
          <Route path="*" element={(
            <Error
              code={404}
              buttonLabel="Go to Homepage"
              buttonRedirectPath="/admin-portal"
              isShowButton
            />
          )} />
        </Routes>
      </main>
    </div>
  );
};

export default AdminRouting;