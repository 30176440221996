import './SubscriptionsDetailsCustomFields.scss';
import { FC, useState, useEffect, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import _debounce from 'lodash/debounce';

import { setupSignalR, useCartCompleteSuccess, copyTextToClipboard, notify } from 'helpers';
import { getAdminSubscriptionCustomFields, removeCustomField } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ITableFilterOrdering } from 'interfaces';

import { CopyIcon, SearchIcon } from 'assets/svg';

import {
  Error,
  Pagination,
  EmptyStateContainer,
  Modal,
  InputText,
} from 'components';
import InvoicesTableHeadRow from './components/CustomFieldsTableHeadRow';
import InvoicesTableBodyRow from './components/CustomFieldsTableBodyRow';
import InvoicesTableBodyRowLoader from './components/CustomFieldsTableBodyRowLoader';
import AddEditCustomFieldModal from './components/AddEditCustomFieldModal';

const SubscriptionsDetailsInvoices = forwardRef((props, ref) => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  useImperativeHandle(ref, () => ({ backgroundFetch }));
  const { data, isLoading, error } = useAppSelector(state => state.ussp.getAdminSubscriptionCustomFieldsRequest);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [query, setQuery]: any = useSearchParams({
    page: '1',
    pageSize: '10',
    filter: '',
    orderings: '[]',
  });

  useEffect(() => {
    fetchData();
  }, [subscriptionId, query]);

  // get values from query
  const queryPage = useMemo(() => {
    return Number(query.get('page')) || 1;
  }, [query]);

  const queryPageSize = useMemo(() => {
    return Number(query.get('pageSize')) || 10;
  }, [query]);

  const queryFilter = useMemo(() => {
    return query.get('filter') || '';
  }, [query]);

  const queryOrderings = useMemo(() => {
    return JSON.parse(query.get('orderings') || '[]');
  }, [query]);

  const [searchQuery, setSearchQuery] = useState<string>(queryFilter || '');

  const fetchData = async (
    isBackgroundUpdate?: boolean,
  ) => {
    if (typeof subscriptionId === 'string') {
      const data = await dispatch(getAdminSubscriptionCustomFields({
        subscriptionId,
        page: queryPage,
        pageSize: queryPageSize,
        filter: queryFilter,
        orderings: queryOrderings,
        _disableErrorHandler: true,
        _isBackground: isBackgroundUpdate,
      })).unwrap();
      setTotalPages((data?.totalPages as number) || 1);
    }
  };

  const [isShowRemoveModal, setIsShowRemoveModal] = useState<boolean>(false);
  const showRemoveModal = (customField: any) => {
    setCurrentCustomField(customField);
    setIsShowRemoveModal(true);
  };
  const hideRemoveModal = () => {
    setCurrentCustomField(null);
    setIsShowRemoveModal(false);
  };
  const removeAppHandler = async () => {
    //maybe add loading to buttons
    if (currentCustomField && typeof subscriptionId === 'string') {
      await dispatch(removeCustomField({
        id: currentCustomField.id,
        subscriptionId,
      })).unwrap();
      backgroundFetch();
      notify.success('This custom field has been deleted', 'You cannot return this custom field');
    }
    hideRemoveModal();
  };

  const [isBackgroundUpdate, setIsBackgroundUpdate] = useState<boolean>(false);
  const isShowLoader = useMemo(() => isLoading && !isBackgroundUpdate, [isLoading, isBackgroundUpdate]);
  const backgroundFetch = async () => {
    setIsBackgroundUpdate(true);
    await fetchData(true);
    setIsBackgroundUpdate(false);
  };

  const onOrderChange = useCallback((data: ITableFilterOrdering) => {
    const newOrderings = data === undefined ? [] : [data];
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('orderings', JSON.stringify(newOrderings));
    updatedSearchParams.set('page', '1');
    setQuery(updatedSearchParams);
  }, [query]);

  const onSearch = useCallback((searchQuery: string) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('filter', searchQuery);
    setQuery(updatedSearchParams);
  }, [query]);

  useCartCompleteSuccess(async () => {
    await backgroundFetch();
  });

  const onPaginationChange = useCallback((page: number, entries: number) => {
    const updatedSearchParams = new URLSearchParams(query.toString());
    updatedSearchParams.set('page', page.toString());
    updatedSearchParams.set('pageSize', entries.toString());
    setQuery(updatedSearchParams);
  }, [query]);

  const debounceSearch = useCallback(_debounce((value: string) => onSearch(value), 1000), [onSearch]);

  const handleChangeSearch = (value: string) => {
    setSearchQuery(value);
    debounceSearch(value);
  };

  // useEffect(() => {
  //   const connection = setupSignalR('/accountOverviewUsers');
  //   if (connection) {
  //     connection.start()
  //       .then(() => {
  //         connection.on('Signal', async (message: any) => {
  //           await backgroundFetch();
  //           console.log('ACCOUNT_OVERVIEW_USERS:SIGNAL', message);
  //         });
  //       })
  //       .catch((e: any) => console.log('Connection failed [accountOverviewUsers]: ', e));
  //   }

  //   return () => {
  //     if (connection) {
  //       connection.stop();
  //     }
  //   };
  // }, []);

  const [isShowAddEditCustomFieldModal, setIsShowAddEditCustomFieldModal] = useState<boolean>(false);
  const [currentCustomField, setCurrentCustomField] = useState<any | null>(null);
  const showAddEditCustomFieldModal = (customField: any): void => {
    setIsShowAddEditCustomFieldModal(true);
    setCurrentCustomField(customField);
  };
  const hideAddEditCustomFieldModal = (): void => {
    setIsShowAddEditCustomFieldModal(false);
    setCurrentCustomField(null);
  };

  if (error) {
    return <section className="dashboard-account-details__content"><Error code={500} /></section>;
  }

  return (
    <>
      <section className="dashboard-account-details__content">
        <div className="custom-fields-header-filters">
          <InputText
            classes="admin-subscriptions__search"
            value={searchQuery}
            icon={SearchIcon}
            placeholder="Search..."
            theme="grey"
            onChange={handleChangeSearch}
            withClear
          />
        </div>
        <table className="admin-subscription-custom-fields-table">
          <thead>
            <InvoicesTableHeadRow
              orderState={queryOrderings?.[0]}
              onOrderChange={onOrderChange}
            />
          </thead>
          <tbody>
            {isShowLoader
              ? <InvoicesTableBodyRowLoader />
              : data?.items?.map((el: any) => (
                <InvoicesTableBodyRow
                  key={`subscription-invoices-details-${el.id}`}
                  {...el}
                  onEdit={() => showAddEditCustomFieldModal(el)}
                  onRemove={() => showRemoveModal(el)}
                />
              ))
            }
          </tbody>
        </table>
        {
          data?.items.length === 0
            ? <EmptyStateContainer
              title="No custom fields found"
              description="No custom fields found with specified parameters"
              classes="empty-state-container-table"
            />
            : null
        }
      </section>
      {
        data?.items.length > 0
          ? <Pagination
            current={queryPage}
            entries={queryPageSize}
            totalPages={totalPages}
            onChange={onPaginationChange}
          />
          : null
      }
      {isShowAddEditCustomFieldModal && (
        <AddEditCustomFieldModal
          subscriptionId={subscriptionId}
          customFieldData={currentCustomField}
          visible={isShowAddEditCustomFieldModal}
          onCancel={hideAddEditCustomFieldModal}
          onSuccess={backgroundFetch}
        />
      )}
      {isShowRemoveModal && (
        <Modal
          title="Do you want to remove this custom field?"
          visible={isShowRemoveModal}
          onOk={removeAppHandler}
          okText="Yes, Remove"
          cancelText="No"
          onCancel={hideRemoveModal}
          width={535}
        />
      )}
    </>
  );
});

export default SubscriptionsDetailsInvoices;
