import './UpgradeAppModalContent.scss';
import { FC } from 'react';

import { useAppSelector } from 'store/hooks';

import { InfoCircleOutlineIcon } from 'assets/svg';

import { Checkbox, Select } from 'components';

interface Props {
  title: string;
  productVersion: string;
  confirmation: boolean;
  onConfirm: (value: boolean) => void;
  openAddNewAppModal: () => void;
}

const UpgradeAppModalContent: FC<Props> = ({
  title,
  productVersion,
  confirmation,
  onConfirm,
  openAddNewAppModal,
}) => {
  const { data: newestVersion } = useAppSelector(state => state.ussp.getProductNewestVersionRequest || {});

  return (
    <>
      <div className="block-info relative-units rel-p-16 rel-m-b-24">
        <div className="block-info__content app-card disabled flex flex-align-center rel-flex-gap-16">
          <div className="app-card__content">
            <div className="app-card__name">
              {title}
            </div>
            <div className="app-card__id">{productVersion}</div>
          </div>
        </div>
      </div>
      <div className="block-info relative-units rel-p-16 rel-m-b-24">
        <InfoCircleOutlineIcon />
        <div className="block-info__content">
          <p className="rel-m-b-8">
            Upgrading this application&nbsp;
            <span>will remove the current version</span>&nbsp;
            and&nbsp;
            <span>replace it with your new version</span>&nbsp;
            and licensing.
          </p>
          <p className="rel-m-b-8">
            If you would like to keep your current
            application and add the new one, select the&nbsp;
            <span className="link relative-units without-decorations" onClick={openAddNewAppModal}>
              Add New Application
            </span>&nbsp;
            option instead.
          </p>
          <p>
            Upgrading the application will not delete your current company data
          </p>
        </div>
      </div>
      <p className="rel-m-b-8 upgrade-app-content__select-label">
        The application will be automatically upgraded to the newest version
      </p>
      <Select
        value={newestVersion}
        classes="rel-m-b-32"
        size="small"
        options={[newestVersion]}
        readOnly
      />
      <Checkbox
        isChecked={confirmation}
        label="Please note that all users accessing company files must
        be on the latest version available. Earlier versions of
        the application are not compatible with newer versions."
        onChange={(e) => onConfirm((e.target as HTMLInputElement).checked)}
      />
    </>
  );
};

export default UpgradeAppModalContent;
