import './Pagination.scss';
import { FC, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { useUpdateEffect } from 'helpers';
import { ArrowLeftSmallIcon, ArrowRightSmallIcon, DotsIcon } from 'assets/svg';
import { Select } from 'components';

const PAGINATION_ENTRIES_COUNT_OPTION: number[] = [10, 20, 30, 40, 50];

interface Props {
  current?: number;
  initialCurrent?: number;
  entries?: number;
  initialEntries?: number;
  totalPages: number;
  onChange: (currentPage: number, entriesCount: number) => void;
  onChangeCurrentPage?: (currentPage: number) => void;
  onChangeEntriesCount?: (entriesCount: number) => void;
}

const Pagination: FC<Props> = ({
  current,
  initialCurrent,
  entries,
  initialEntries,
  totalPages,
  onChange,
  onChangeCurrentPage,
  onChangeEntriesCount,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(initialCurrent || current || 1);
  useEffect(() => {
    if (typeof current === 'number') {
      setCurrentPage(current);
    }
  }, [current]);

  const [entriesCount, setEntriesCount] = useState<number>(
    initialEntries || entries || PAGINATION_ENTRIES_COUNT_OPTION[0],
  );
  useEffect(() => {
    if (typeof entries === 'number') {
      setEntriesCount(entries);
    }
  }, [entries]);

  const getPaginationRange = useMemo(() => {
    const minValue = 1;
    const maxValue = totalPages;

    const lowerRange = Math.max(Math.min(maxValue - 4, currentPage - 2), minValue);
    const upperRange = Math.min(Math.max(minValue + 4, currentPage + 2), maxValue);

    return _.range(lowerRange, upperRange + 1);
  }, [currentPage, totalPages]);

  const changeEntriesCountHandler = (value: number): void => {
    setEntriesCount(value);
    setCurrentPage(1);
  };

  const changeCurrentPageHandler = (value: number): void => {
    if (value === currentPage) return;

    setCurrentPage(value);
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      changeCurrentPageHandler(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      changeCurrentPageHandler(currentPage + 1);
    }
  };

  useUpdateEffect(() => {
    onChange(currentPage, entriesCount);
  }, [currentPage, entriesCount]);

  // useEffect(() => {
  //   if (currentPage > totalPages) {
  //     changeCurrentPageHandler(1);
  //   }
  // }, [totalPages]);

  useEffect(() => {
    if (onChangeCurrentPage) {
      onChangeCurrentPage(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (onChangeEntriesCount) {
      onChangeEntriesCount(entriesCount);
    }
  }, [entriesCount]);

  return (
    <section className="pagination">
      <div className="pagination__left">
        <Select
          fullWidth={false}
          value={entriesCount}
          options={PAGINATION_ENTRIES_COUNT_OPTION}
          onChange={changeEntriesCountHandler}
          size="small"
          centeredOptions
        />
        per page
      </div>
      <div className="pagination__right">
        <button disabled={currentPage <= 1} onClick={goToPrevPage}><ArrowLeftSmallIcon /></button>
        {!getPaginationRange.includes(1) && (
          <div
            className={cn('pagination__item', { active: currentPage === 1 })}
            onClick={() => changeCurrentPageHandler(1)}
          >1</div>
        )}
        {getPaginationRange.length > 1 && !getPaginationRange.includes(2) && <DotsIcon className="pagination__dots" />}
        {getPaginationRange.map((el, index) => (
          <div
            key={`pagination-items-${index}`}
            className={cn('pagination__item', { active: currentPage === el })}
            onClick={() => changeCurrentPageHandler(el)}
          >{el}</div>
        ))}
        {getPaginationRange.length > 1 && !getPaginationRange.includes(totalPages - 1) && <DotsIcon className="pagination__dots" />}
        {!getPaginationRange.includes(totalPages) && (
          <div
            className={cn('pagination__item', { active: currentPage === totalPages })}
            onClick={() => changeCurrentPageHandler(totalPages)}
          >{totalPages}</div>
        )}
        <button disabled={currentPage >= totalPages} onClick={goToNextPage}><ArrowRightSmallIcon /></button>
      </div>
    </section>
  );
};

export default Pagination;