import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { DotsIcon, DeleteOutlineIcon } from 'assets/svg';

import { Menu } from 'components';

interface IAplicationDetails {
  productVersion: string;
  licenceCode: string;
  productCode: string;
  serialNumber: string;
}

interface Props {
  name: string;
  isReadonly: boolean;
  createdAt: string;
  details: IAplicationDetails | null;
  onReview: () => void;
  onRemove: () => void;
}
const formatDate = (value: string): string => dayjs(value).format('MMM D, YYYY');

const UserProfileTableBodyRowLoader: FC<Props> = ({
  name,
  isReadonly,
  createdAt,
  details,
  onReview,
  onRemove,
}) => {

  const createdAtFormated = useMemo(() => formatDate(createdAt), [createdAt]);

  return (
    <tr className="user-profile-table-body-row">
      <td>
        <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
          <div className="app-card__content">
            <div className="app-card__name">{name}</div>
            <div className="app-card__id">{details?.productVersion}</div>
          </div>
        </div>
      </td>

      <td>{createdAtFormated}</td>

      <td>
        {details === null ? '-' : (
          <span className="btn btn-text" onClick={onReview}>
            Review
          </span>
        )}
      </td>

      <td>
        <Menu
          isDisabled={isReadonly}
          trigger={<DotsIcon />}
          items={[
            <div onClick={onRemove}><DeleteOutlineIcon className="menu-item-icon" />Remove</div>,
          ]}
          placement="bottom-end"
        />
      </td>
    </tr>
  );
};

export default UserProfileTableBodyRowLoader;
