import { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { usePriceHandler } from 'helpers';
import { BILLING_STATUS_CLASSES, COUNTRY_FLAG_BY_COUNTRY_CODE, COUNTRY_NAMES_BY_CODE } from 'consts';
import { CountryCode, BillingStatus, PaymentMethod } from 'enums';

import {
  NonManageableIcon,
} from 'assets/svg';

import { CustomRouterLink, Tooltip } from 'components';

interface IconProps {
  className?: string;
}
interface Props {
  id: string;
  manageable: boolean;
  chargifyId: string;
  companyName: string;
  nextBillingDate: string;
  paymentMethod: PaymentMethod;
  paymentNumber: string;
  status: BillingStatus;
  totalMonthly: number;
  country: CountryCode;
  countryCode: CountryCode;
  countryName: string;
  nextBillingAmountInCents: number;
  ownerFirstName: string;
  ownerLastName: string;
  subTerms: string;
  navigationState: any;
  onEdit: () => void;
}

const renderIcon = (IconComponent: FC<IconProps> | undefined) => {
  return IconComponent && <IconComponent />;
};

const formatDate = (value: string): string => dayjs(value).format('MMM D, YYYY');

const SubscriptionsTableBodyRow: FC<Props> = ({
  id,
  manageable,
  chargifyId,
  companyName,
  nextBillingDate,
  nextBillingAmountInCents,
  status,
  totalMonthly,
  country,
  countryCode,
  countryName,
  ownerFirstName,
  ownerLastName,
  subTerms,
  navigationState,
}) => {
  const { formatPrice } = usePriceHandler();

  const nextBillingDateFormated = useMemo(() => nextBillingDate ? formatDate(nextBillingDate) : '-', [nextBillingDate]);
  const formatedNextBillingAmountInCents = useMemo(
    () => formatPrice((nextBillingAmountInCents ?? 0) / 100, country || CountryCode.USA),
    [nextBillingAmountInCents],
  );
  const statusFormated = useMemo(() => status
    .split('_')
    .map(el => `${el.charAt(0).toUpperCase()}${el.slice(1)}`)
    .join(' ')
  , [status]);

  return (
    <tr className="subscriptions-table-body-row">
      <td>
        {!manageable && (
          <Tooltip
            content="This subscription has not been updated. Please, review and update the details."
            placement="top"
          >
            <NonManageableIcon/>
          </Tooltip>
        )}
      </td>
      <td>
        <div className="app-card disabled flex flex-align-center rel-flex-gap-16">
          <div className="app-card__content">
            <div className="app-card__name"><CustomRouterLink to={`${id}`} state={navigationState}>{companyName}</CustomRouterLink></div>
            <div className="app-card__id">ID: {chargifyId}</div>
          </div>
        </div>
      </td>

      <td>{ownerFirstName} {ownerLastName}</td>

      <td>
        <p className={BILLING_STATUS_CLASSES[status]}>
          {statusFormated}
        </p>
      </td>

      <td>{subTerms || '-'}</td>

      <td>{formatedNextBillingAmountInCents}</td>
      <td>{nextBillingDateFormated}</td>
      <td>
        <div className="country-with-icon">
          <div className="option-icon">{renderIcon(COUNTRY_FLAG_BY_COUNTRY_CODE[countryCode] || COUNTRY_FLAG_BY_COUNTRY_CODE.OTHER)}</div>
          <div>{COUNTRY_NAMES_BY_CODE[countryCode] || countryName}</div>
        </div>
      </td>

    </tr>
  );
};

export default SubscriptionsTableBodyRow;
