import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { DotsIcon } from 'assets/svg';

const InvoicesTableBodyRowLoader: FC = () => (
  <tr>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><Skeleton /></td>
    <td><DotsIcon /></td>
  </tr>
);

export default InvoicesTableBodyRowLoader;
