import { Button, CheckboxButton, ProgressBar, RadioButton, RadioButtonGroup, SignUpBackButton, StepPriceDetails } from 'components';
import './AdditionalServicesStep.scss';
import { usePriceHandler, useSignUpFormNavigate } from 'helpers';
import { ProductChargify, SignUpNavigation } from 'enums';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { PRODUCT_CHARGIFY_TITLES } from 'consts';
import { setAdditionalServices } from 'store';

const BACKUP_RETENTION_PRODUCTS: ProductChargify[] = [
  ProductChargify.BackupRetentionIncrease1Year,
  ProductChargify.BackupRetentionIncrease2Year,
  ProductChargify.BackupRetentionIncrease3Year,
];

const GROUPED_ADDITIONAL_SERVICES = [
  BACKUP_RETENTION_PRODUCTS,
  ProductChargify.VPN,
  ProductChargify.ExternalStaticIPAddress,
];

const getGroupTitle = (productNames: ProductChargify[]): string => {
  if (productNames.some(name => BACKUP_RETENTION_PRODUCTS.includes(name as ProductChargify))) {
    return 'Extends Backup Retention for up to 100 GB of data';
  }

  return 'Group of products';
};

const AdditionalServicesStep = () => {
  const simpleFormNavigate = useSignUpFormNavigate();
  const dispatch = useAppDispatch();
  const { getProductPrice } = usePriceHandler();
  const additionalServicesState = useAppSelector(state => state.order.additionalServices);
  const additionalServicesStateNames = useMemo(
    () => additionalServicesState.map(({ name }) => name as ProductChargify),
    [additionalServicesState],
  );
  const [selectedServices, setSelectedServices] = useState<ProductChargify[]>(additionalServicesStateNames);

  const toggleServices = (newValue: ProductChargify | null, valuesToRemove: ProductChargify[]): void => {
    if (valuesToRemove.length) {
      setSelectedServices(selectedServices.filter(name => !valuesToRemove.includes(name)));
    }

    if (newValue !== null) {
      setSelectedServices(prevValue => [...prevValue, newValue]);
    }
  };

  const getSelectedRadio = useCallback((groupOptions) => {
    return selectedServices.find(e => groupOptions.includes(e));
  }, [selectedServices]);

  const STEP_PRODUCTS = useMemo(
    () => selectedServices.map(name => ({ name, quantity: '1' })),
    [selectedServices],
  );

  const onNextHandler = () => {
    dispatch(setAdditionalServices(selectedServices));
    // check session
    simpleFormNavigate(SignUpNavigation.SetupUsers);
  };

  const skipHandler = () => {
    simpleFormNavigate(SignUpNavigation.SetupUsers);
  };

  const backHandler = () => {
    simpleFormNavigate(SignUpNavigation.ProductsList);
  };
  return (
    <>
      <SignUpBackButton onClick={backHandler} />
      <div className="container-sm">
        <ProgressBar currentStep={5} totalSteps={7} />
        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-8">Additional Services</div>
          <div className="sign-up-form-step-description m-b-32">
            In addition to your chosen applications, you can select one or more of the following additions to ensure you meet your compliance and data requirements.
          </div>
          <form>
            {GROUPED_ADDITIONAL_SERVICES.map((service, index) => {
              const isGroup = Array.isArray(service);
              const isChecked = selectedServices.some(
                name => isGroup ? service.includes(name) : service === name,
              );
              const label = isGroup
                ? getGroupTitle(service)
                : `${PRODUCT_CHARGIFY_TITLES[service]} (${getProductPrice(service)}/month)`;
              return (
                <Fragment key={index}>
                  <CheckboxButton
                    dataTestId="additional-services.service-checkbox"
                    xVersion
                    absoluteStyles
                    isChecked={isChecked}
                    label={label}
                    classes="m-b-16"
                    onChange={() => toggleServices(
                      isChecked ? null : isGroup ? service[0] : service,
                      isGroup ? service : [service],
                    )}
                  />
                  {isGroup && isChecked && (
                    <RadioButtonGroup
                      xVersion
                      absoluteStyles
                      value={getSelectedRadio(service)}
                      onChange={({ option }) => toggleServices(option.value, service)}
                      options={service.map((name, index) => ({
                        dataTestId: "additional-services.service-checkbox",
                        label: `${PRODUCT_CHARGIFY_TITLES[name]} (${getProductPrice(name)}/month)`,
                        value: name,
                        classes: index + 1 === service.length ? 'm-b-16' : 'm-b-12',
                      }))}
                    />
                  )}
                </Fragment>
              );
            })}
          </form>
          <div className="flex flex-justify-center m-t-40 sm-m-t-32">
            {selectedServices.length ? (
              <Button
                dataTestId="additional-services.next-step-button"
                xVersion
                absoluteStyles
                // isLoading={isNextLoading}
                onClick={onNextHandler}
              >
                Next Step
              </Button>
            ) : (
              <Button
                dataTestId="additional-services.skip-button"
                type="default"
                xVersion
                absoluteStyles
                onClick={skipHandler}
              >
                Skip
              </Button>
            )}
          </div>
        </div>
      </div>
      <StepPriceDetails currentStep={SignUpNavigation.AdditionalServices} stepProducts={STEP_PRODUCTS} />
    </>
  );
};

export default AdditionalServicesStep;