import './Checkbox.scss';
import { FC, SyntheticEvent, FormEvent, ReactNode, useMemo } from 'react';
import {
  CheckIcon,
  MinusIcon,
} from 'assets/svg';
import cn from 'classnames';
import { isBoolean } from 'lodash';

interface Props {
  absoluteStyles?: boolean;
  isChecked: boolean;
  isIndeterminate?: boolean;
  children?: ReactNode;
  classes?: string;
  label?: string;
  size?: string;
  isRadio?: boolean;
  dataTestId?: string;
  isDisabled?: boolean;
  keyEventDisabled?: boolean;
  theme?: 'light' | 'dark';
  onChange?: (e: SyntheticEvent) => void;
}

const Checkbox: FC<Props> = ({
  isChecked,
  isIndeterminate,
  children,
  classes,
  label,
  size,
  dataTestId,
  isDisabled = false,
  keyEventDisabled = false,
  theme = 'light',
  absoluteStyles = false,
  onChange,
}) => {
  const handleKeyDown = (event: any) => {
    if (!keyEventDisabled && (event.key === ' ' || event.key === 'Enter')) {
      event.preventDefault();
      onChange?.({ ...event, target: { ...event.target, checked: !isChecked } });
    }
  };
  const onChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    onChange?.(e);
  };
  const checkedValue = useMemo(() => {
    const indeterminateValue = isBoolean(isIndeterminate) ? isIndeterminate : false;
    return isChecked || indeterminateValue;
  }, [isChecked, isIndeterminate]);

  return (
    <label
      data-test-id={dataTestId}
      className={cn('checkbox', theme, classes, {
        checked: checkedValue,
        disabled: isDisabled,
        'relative-units': !absoluteStyles
      })}
    >
      <input
        type="checkbox"
        aria-checked={checkedValue}
        checked={checkedValue}
        disabled={isDisabled}
        onChange={onChangeHandler}
      />
      <div
        tabIndex={keyEventDisabled ? -1 : 0}
        className={cn('checkbox-icon new-style', {
          checked: checkedValue,
          'rel-m-r-12': !absoluteStyles && (children || label) && size !== 'small',
          'm-r-12': absoluteStyles && (children || label) && size !== 'small',
          'rel-m-r-8': !absoluteStyles && (children || label) && size === 'small',
          'm-r-8': absoluteStyles && (children || label) && size === 'small',
        })}
        onKeyDown={handleKeyDown}
      >
        {isIndeterminate ? <MinusIcon /> : <CheckIcon />}
      </div>
      {(children || label) && (
        <div className="checkbox-label">{children || label}</div>
      )}
    </label>
  );
};

export default Checkbox;