import { FC, useCallback, useMemo, useState } from 'react';
import './MsOfficeChoose.scss';
import { Button, CheckboxTag, InputText, Modal, Select, StepPriceDetails } from 'components';
import { IMsOfficeProduct } from 'interfaces';
import { useAppSelector } from 'store/hooks';
import { usePriceHandler } from 'helpers';
import { CountryCode, SignUpNavigation } from 'enums';
import { ArrowRightFullIcon, CloseIcon, InfoCircleIcon, MsOfficeLogo, SearchRightIcon } from 'assets/svg';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as _ from 'lodash';
import { MS_OFFICE_PRODUCTS, PRODUCT_CHARGIFY_TITLES } from 'consts';

interface MsOfficeForm {
  quantity: string;
}

interface Props {
  initData?: IMsOfficeProduct[];
  onAdd: (data: any) => void;
  currentStepProducts: any;
}

const MsOfficeChoose: FC<Props> = ({ onAdd, initData = [], currentStepProducts }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState<any | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [msProductsCopy, setMsProductsCopy] = useState(initData);
  const hostTotalQuantity = useAppSelector(state => state.order.hostProduct.quantity);
  const numbersRange = useMemo(() => _.range(1, +(hostTotalQuantity || 0) + 1).map(el => el.toString()), [hostTotalQuantity]);
  const [initialFormData, setInitialFormData] = useState<any>(null);
  const { getProductPrice } = usePriceHandler();
  
  const msProductsInfo = useMemo(() => {
    return MS_OFFICE_PRODUCTS.map(((item) => ({
      name: item,
      shownName: PRODUCT_CHARGIFY_TITLES[item],
      price: getProductPrice(item),
    })));
  }, []);

  const filteredMsProductsInfo = useMemo(() => msProductsInfo.filter(({ shownName }: any) => {
    const formatedSearchQuery = searchQuery.trim().toLowerCase();
    const formatedName = shownName?.trim().toLowerCase();
    const isMatchName = formatedName.includes(formatedSearchQuery);
    return isMatchName;
  }), [searchQuery, msProductsInfo]);

  const initCheckedProducts = useMemo(() => {
    return initData.filter(el => el.isChecked);
  }, [initData]);

  const hasInitChecked = useMemo(() => {
    return initCheckedProducts.length > 0;
  }, [initCheckedProducts]);

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isValid },
  } = useForm<MsOfficeForm>({ mode: 'all' });

  const onTagClick = (name: string) => {
    const currentProductData = msProductsCopy.find(el => el.name === name);
    if (!currentProductData) {
      return;
    }
    reset({
      quantity: (Number(currentProductData?.quantity) || '1').toString(),
    });
    setInitialFormData({
      quantity: (Number(currentProductData?.quantity) || '1').toString(),
    });
    const productInfo = msProductsInfo.find(el => el.name === name);
    setCurrentSelectedProduct(productInfo);
    setShowModal(true);
  };

  const confirmAddEditProduct: SubmitHandler<MsOfficeForm> = (data) => {
    const currentProductDataIndex = msProductsCopy.findIndex(el => el.name === currentSelectedProduct?.name);
    if (currentProductDataIndex === -1) {
      return;
    }
    const copyProducts: any = _.cloneDeep(msProductsCopy);
    copyProducts[currentProductDataIndex] = {
      ...msProductsCopy[currentProductDataIndex],
      ...data,
      isChecked: true
    };
    setMsProductsCopy(copyProducts);
    setShowModal(false);
    setInitialFormData(null);
    reset();
  };

  const isProductChecked = useCallback((name) => {
    const currentProductData = msProductsCopy.find(el => el.name === name);
    return Boolean(currentProductData?.isChecked);
  }, [msProductsCopy]);

  const checkedProducts = useMemo(() => {
    return msProductsCopy.filter(el => el.isChecked);
  }, [msProductsCopy]);

  const isAddEqual = useMemo(() => {
    return _.isEqual(checkedProducts, initCheckedProducts);
  }, [checkedProducts, initCheckedProducts]);

  const isAddEqualisUpdateModalEqual = useMemo(() => {
    const data = getValues();
    return _.isEqual(initialFormData, data);
  }, [watch(), initialFormData]);

  const unCheckProduct = useCallback((name) => {
    const currentProductDataIndex = msProductsCopy.findIndex(el => el.name === name);
    if (currentProductDataIndex === -1) {
      return;
    }
    const copyProducts = _.cloneDeep(msProductsCopy);
    copyProducts[currentProductDataIndex] = {
      ...msProductsCopy[currentProductDataIndex],
      isChecked: false,
      quantity: "",
    };
    setMsProductsCopy(copyProducts);
    setShowModal(false);
  }, [msProductsCopy]);

  const onRemoveCheckedIconKeyDown = (event: any, id: string) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      unCheckProduct(id);
    }
  };

  const handleAdd = () => {
    onAdd(msProductsCopy);
  };

  const STEP_PRODUCTS = useMemo(() => {
    return [
      ...currentStepProducts.quickbooks,
      ...currentStepProducts.sage,
      ...currentStepProducts.msOwn,
      ...checkedProducts.map(({ name, quantity }) => ({ name, quantity })),
      ...currentStepProducts.other,
    ];
  }, [currentStepProducts, checkedProducts]);

  return (
    <>
      <Modal
        xVersion
        absoluteStyles
        title={`${currentSelectedProduct?.name} - ${currentSelectedProduct?.price}`}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        isOkDisabled={isProductChecked(currentSelectedProduct?.name) ? (isAddEqualisUpdateModalEqual || !isValid) : !isValid}
        showRemoveButton={isProductChecked(currentSelectedProduct?.name)}
        onOk={handleSubmit(confirmAddEditProduct)}
        okText={isProductChecked(currentSelectedProduct?.name) ? 'Update' : 'Add'}
        onRemove={() => unCheckProduct(currentSelectedProduct?.name)}
      >
        <div className="other-products-info-modal">
          {currentSelectedProduct?.isSubscriptionProduct ? (
            <div className="block-info xVersion m-b-16 sm-m-b-12">
              <InfoCircleIcon />
              <p className="block-info__content server-product-info">
                This product could be assigned to server only.
              </p>
            </div>
          ) : null}
          <form>
            {currentSelectedProduct?.isSubscriptionProduct ? null : (
              <Controller
                name="quantity"
                control={control}
                defaultValue="1"
                rules={{ required: true }}
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <Select
                    dataTestId="product-list.ms-lease.quantity-select"
                    xVersion
                    absoluteStyles
                    value={value}
                    label="Number of users"
                    placeholder=""
                    classes="m-b-20 sm-m-b-12"
                    options={numbersRange}
                    isInvalid={invalid}
                    isRequired
                    onChange={onChange}
                    onClose={onBlur}
                  />
                )}
              />)
            }
          </form>
        </div>
      </Modal>
      <div className="container-lg">
        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-32"><MsOfficeLogo className="step-heading-logo"/><div className="text">Microsoft Office</div></div>
          {checkedProducts.length > 0 ? (<div className="sign-up-selected-products-list">
            {checkedProducts.map(({ name, quantity }) => (
              <div className="selected-product-wrapper" key={`checked-products-${name}`}>
                <CloseIcon
                  data-test-id={`product-list.ms-lease.selected-product-remove-icon-${name}`}
                  tabIndex={0}
                  onKeyDown={(event) => onRemoveCheckedIconKeyDown(event, name)}
                  className="close-icon" onClick={() => unCheckProduct(name)}
                />
                <div className="product-title">{name}</div>
                <div className="flex flex-justify-between">
                  <div className="product-quantity">Users: {quantity}</div>
                  <Button
                    dataTestId={`product-list.ms-lease.selected-product-change-button-${name}`}
                    absoluteStyles
                    xVersion
                    type="textable"
                    onClick={() => onTagClick(name)}
                  >
                    Change
                  </Button>
                </div>
              </div>
            ))}
          </div>) : null}
          <div className="sign-up-other-products-search-wrapper">
            <div className="search-input">
              <InputText
                dataTestId="product-list.ms-lease.search-input"
                xVersion
                absoluteStyles
                withClear
                value={searchQuery}
                label=""
                placeholder="Search by application name"
                icon={SearchRightIcon}
                onChange={setSearchQuery}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-md">
        <div className="sign-up-form-content p-t-0">
          <div className="sign-up-other-products-list">
            {filteredMsProductsInfo
              .map(({ name, shownName, price }) => {
                return (
                  <CheckboxTag
                    dataTestId={`product-list.other.product-tag-${name}`}
                    key={`other-product-${name}`}
                    onlyClicked
                    iconRight={ArrowRightFullIcon}
                    iconRightSmOnly
                    isChecked={isProductChecked(name)}
                    onChange={() => onTagClick(name)}
                  >
                    <div className="other-product-info">
                      <div className="other-product-info-name">{shownName}</div>
                      <div className="other-product-info-price">{price}</div>
                    </div>
                  </CheckboxTag>);
              })
            }
          </div>
          <div className="flex flex-justify-center m-t-40 sm-m-t-32">
            <Button
              dataTestId="product-list.ms-lease.add-edit-button"
              xVersion
              absoluteStyles
              isDisabled={isAddEqual}
              onClick={handleAdd}
            >
              {hasInitChecked ? 'Update products' : 'Add to product list'}
            </Button>
          </div>
        </div>
      </div>
      <StepPriceDetails currentStep={SignUpNavigation.ProductsList} stepProducts={STEP_PRODUCTS} />
    </>
  );
};

export default MsOfficeChoose;