import './OtherProducts.scss';
import { FC, useMemo, useState, useEffect } from 'react';
import cn from 'classnames';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';

import AcctivateImage from 'assets/images/acctivate.png';
import ActImage from 'assets/images/act.png';
import BillcomImage from 'assets/images/billcom.png';
import DrakeImage from 'assets/images/drake-software.png';
import LacerteImage from 'assets/images/lacerte.png';
import ProSeriesImage from 'assets/images/pro-series.png';
import WebGilityImage from 'assets/images/web-gility.png';
import ServiceFusionImage from 'assets/images/service-fusion.png';
import TruCommerceImage from 'assets/images/tru-commerce.png';
import MethodCRMImage from 'assets/images/method-crm.png';
import TelpayImage from 'assets/images/telpay.png';
import SQLExpressImage from 'assets/images/sql-server.png';
import MiSysImage from 'assets/images/misys.png';
import FieldEdgeImage from 'assets/images/field-edge.png';

import { CountryCode, OrderNavigation } from 'enums';
import { IOtherProduct } from 'interfaces';
import { useSimpleFormNavigate, usePriceHandler, notify } from 'helpers';

import {
  addProductsToOrder,
  deleteProductsFromOrder,
  setOtherProducts,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  Navigation,
  TotalPrice,
  Logo,
  OrderNextButton,
  ProductCard,
} from 'components';
// import OtherProductsOtherDetails from './components/OtherProductsOtherDetails/OtherProductsOtherDetails';
import OtherProductsDetails from './components/OtherProductsDetails/OtherProductsDetails';

interface IOtherProductImage {
  id: string;
  name: string;
  imgUrl: string;
  imgOrder: number;
}

const IMAGES_MAP = new Map([
  ['Drake', { imgUrl: DrakeImage, imgOrder: 1 }],
  ['Lacerte', { imgUrl: LacerteImage, imgOrder: 2 }],
  ['ProSeries', { imgUrl: ProSeriesImage, imgOrder: 3 }],
  ['Webgility', { imgUrl: WebGilityImage, imgOrder: 4 }],
  ['MiSYS', { imgUrl: MiSysImage, imgOrder: 5 }],
  ['TruCommerce', { imgUrl: TruCommerceImage, imgOrder: 6 }],
  ['Method CRM', { imgUrl: MethodCRMImage, imgOrder: 7 }],
  ['TelePay', { imgUrl: TelpayImage, imgOrder: 8 }],
  ['SQL Express', { imgUrl: SQLExpressImage, imgOrder: 9 }],
  ['FieldEdge', { imgUrl: FieldEdgeImage, imgOrder: 10 }],
  ['Service Fusion', { imgUrl: ServiceFusionImage, imgOrder: 11 }],
  ['Bill.com', { imgUrl: BillcomImage, imgOrder: 12 }],
  ['ACT!', { imgUrl: ActImage, imgOrder: 13 }],
  ['Acctivate', { imgUrl: AcctivateImage, imgOrder: 14 }],
]);

const OtherProducts: FC = () => {
  const otherProductsInfo: { [key: string]: IOtherProduct } = useAppSelector(
    state => state.products.otherProductsInfo,
  );
  const otherProducts = useAppSelector(state => state.order.otherProducts);
  const hostTotalQuantity = useAppSelector(state => state.order.hostProduct.quantity);
  const {
    control,
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IOtherProduct[]>({
    mode: 'all',
    defaultValues: {
      ...(otherProducts.map(el => ({
        ...el,
        ...(otherProductsInfo[el.name].isSubscriptionProduct ? { quantity: '1' } : {}),
      }))),
    },
  });

  const [limitUserErrorsCount, setLimitUserErrorsCount] = useState<number>(0);
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);

  const invalidQuantityProductIndexes = useMemo(() => {
    return Object.entries(getValues()).reduce((acc, [key, el]) => {
      if (+el.quantity > +hostTotalQuantity) {
        acc.push(+key);
      }
      return acc;
    }, [] as number[]);
  }, [watch()]);

  useEffect(() => {
    if (invalidQuantityProductIndexes.length > limitUserErrorsCount) {
      notify.error('Sorry', `You can sign up to ${hostTotalQuantity} users`);
    }
    setLimitUserErrorsCount(invalidQuantityProductIndexes.length);
  }, [invalidQuantityProductIndexes]);

  const checkedOtherProductsNames = useMemo<string[]>(() => {
    const values = getValues();
    return Object.values(values).reduce(
      (acc, cur: IOtherProduct) => cur.isChecked ? [...acc, cur.name] : acc,
      [] as string[],
    );
  }, [watch()]);

  const STEP_PRODUCTS = useMemo(() => {
    return Object.values(getValues()).reduce((acc, { isChecked, name, quantity, price }) => {
      return isChecked ? acc.concat([{ isChecked, name, quantity, price }]) : acc;
    }, [] as any[]);
  }, [watch()]);

  const imagesToShow = useMemo<IOtherProductImage[]>(() => {
    return Object.values(otherProductsInfo).reduce((acc, { id, name }) => {
      const newImage = IMAGES_MAP.get(name);
      return [...acc, ...(newImage ? [{ id, name, ...newImage }] : [])];
    }, [] as any[]);
  }, [otherProductsInfo]);

  const compareByOrder = (firstOrder: number = 0, secondOrder: number = 0): number => firstOrder - secondOrder;

  const { formatPrice } = usePriceHandler();
  const currentCountryCode = useAppSelector(state => state.order.country?.code ?? CountryCode.USA);

  const simpleFormNavigate = useSimpleFormNavigate();
  const dispatch = useAppDispatch();
  const nextHandler: SubmitHandler<IOtherProduct[]> = async data => {
    setIsNextLoading(true);
    try {
      const productsToAdd = Object.values(data).reduce(
        (acc, { isChecked, name, quantity, serialNumber, productCode, licenseCode }) => {
          if (isChecked) {
            acc.push({
              name,
              quantity: Number(quantity),
              customInfo: {
                serialNumber,
                productCode,
                licenseCode,
                provideMetafields: 'true',
              },
            });
          }
          return acc;
        },
        [] as any[],
      ); // @todo add interface for product object
      const productsToDelete = otherProducts.reduce((acc, cur) => {
        if (cur.isChecked && !productsToAdd.some(newEl => newEl.name === cur.name)) {
          acc.push(cur.name);
        }
        return acc;
      }, [] as string[]);
      if (productsToDelete.length) {
        await dispatch(deleteProductsFromOrder(productsToDelete)).unwrap();
      }
      await dispatch(addProductsToOrder(productsToAdd)).unwrap();
      dispatch(setOtherProducts(Object.values(data).map(({ quantity, isChecked, ...restData }) => ({
        ...restData,
        isChecked,
        quantity: isChecked && quantity ? `${Number(quantity)}` : '',
      }))));
      simpleFormNavigate(OrderNavigation.AdditionalServices);
    } finally {
      setIsNextLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <div className="other-products page">
        <div className="page-left">
          <div className="products-icons">
            {imagesToShow
              .sort((firstEl, secondEl) => compareByOrder(firstEl?.imgOrder, secondEl?.imgOrder))
              .map(item => (
                <img
                  key={`other-product-logo-${item.id}`}
                  className={cn({ active: checkedOtherProductsNames.includes(item.name) })}
                  src={item.imgUrl}
                  alt={item.name}
                />
              ))}
          </div>
          <TotalPrice currentStep={OrderNavigation.OtherProducts} stepProducts={STEP_PRODUCTS} />
        </div>
        <div className="page-right">
          <Navigation currentStep={6} />

          <div className="page-right_content">
            <h1>Would You Like to Add Any of Your Existing Applications to Your Summit Hosting Server?</h1>
            <form onSubmit={handleSubmit(nextHandler)}>
              {Object.values(otherProductsInfo)
                .map(({ id, name, price, isSubscriptionProduct }, index) => {
                  const productData = {
                    title: name,
                    price: formatPrice(price, currentCountryCode),
                    additionalContent: (
                      <OtherProductsDetails
                        isSubscriptionProduct={isSubscriptionProduct}
                        register={register}
                        errors={errors}
                        index={index}
                        invalidQuantity={invalidQuantityProductIndexes.includes(index)}
                      />
                    ),
                  };
                  return (
                    <Controller
                      key={`other-product-${id}`}
                      name={`${index}.isChecked`}
                      control={control}
                      defaultValue={false}
                      shouldUnregister
                      render={({ field: { onChange, value } }) => (
                        <ProductCard
                          item={productData}
                          isChecked={value}
                          onToggleCheck={onChange}
                        />
                      )}
                    />
                  );
                })
              }
              <OrderNextButton
                isDisabled={!isValid || !!invalidQuantityProductIndexes.length}
                isLoading={isNextLoading}
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherProducts;