import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IHttpRequestResult } from 'interfaces';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers';

export const sendEmailCode = createAsyncThunk<any, { email: string; captcha: string; _disableErrorHandler?: boolean }>(
  'auth/sendEmailCode',
  async ({ email, captcha, _disableErrorHandler }) => {
    const response = await http.post('/bff-ussp/auth/validateEmail', { email, captcha }, { _disableErrorHandler });
    return response;
  },
);
export const validateOneTimeCode = createAsyncThunk<any, { email: string; code: string; _disableErrorHandler?: boolean }>(
  'auth/validateOneTimeCode',
  async ({ email, code, _disableErrorHandler }) => {
    const response = await http.post('/bff-ussp/auth/login', { email, code }, { _disableErrorHandler });
    return response;
  },
);

interface AuthState {
  sendEmailCodeRequest: IHttpRequestResult<any>;
  validateOneTimeCodeRequest: IHttpRequestResult<any>;
}

const initialState: AuthState = {
  sendEmailCodeRequest: createHttpRequestInitResult(),
  validateOneTimeCodeRequest: createHttpRequestInitResult(),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
  },
  extraReducers: {
    ...createExtraReducersForResponses(sendEmailCode, 'sendEmailCodeRequest'),
    ...createExtraReducersForResponses(validateOneTimeCode, 'validateOneTimeCodeRequest'),
  },
});

// export const { } = authSlice.actions;

export default authSlice.reducer;