import './DashboardAccountDetails.scss';
import { FC, useEffect, useMemo, useState } from 'react';
import { Routes, Route, useNavigate, useLocation, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getOtherProducts,
  getQuickbooksProducts,
  getSageProducts,
  getUserHostingPrice,
} from 'store';

import { ArrowLeftSmallIcon, ArrowRightFullIcon, PlusInSquareIcon, InfoCircleOutlineIcon } from 'assets/svg';

import { Alert, CustomRouterLink, Error, AddUserSidebarModal } from 'components';
import AddApplicationSidebarModal from './components/AddApplicationSidebarModal/AddApplicationSidebarModal';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';

import AccountDetailsApplications from './pages/AccountDetailsApplications/AccountDetailsApplications';
import AccountDetailsUsers from './pages/AccountDetailsUsers/AccountDetailsUsers';
import AccountDetailsUsersMfa from './pages/AccountDetailsUsersMfa/AccountDetailsUsersMfa';

enum EPageRouteName {
  Applications = 'applications',
  Users = 'users',
  MFA = 'mfa',
}

const getRouteName = (fullpath: string): EPageRouteName => {
  const fullpathParts = fullpath.split('/');
  const routeName = Object.values(EPageRouteName).find(el => el === fullpathParts[fullpathParts.length - 1]);
  return routeName || EPageRouteName.Applications;
};

const ADD_BUTTON_LABELS = {
  [EPageRouteName.Applications]: 'Add Application',
  [EPageRouteName.Users]: 'Add User',
  [EPageRouteName.MFA]: undefined,
};

const DashboardAccountDetails: FC = () => {
  const { subscriptionId } = useParams();
  const { pathname } = useLocation();
  const routeName = useMemo(() => getRouteName(pathname), [pathname]);
  const navigate = useNavigate();
  const {
    data: cartData,
    isLoading: isLoadingCartData,
  } = useAppSelector(state => state.ussp.getUsspCartDataRequest);
  const [viewModeAlertState, setViewModeAlertState] = useState<boolean>(true);
  const isViewMode = useMemo(
    () => !isLoadingCartData
      && cartData?.subscriptionId !== null
      && cartData?.subscriptionId !== subscriptionId,
    [subscriptionId, cartData?.subscriptionId, isLoadingCartData]
  );
  const isShowViewModeAlert = useMemo(
    () => viewModeAlertState
      && !isLoadingCartData
      && cartData?.subscriptionId !== null
      && cartData?.subscriptionId !== subscriptionId,
    [subscriptionId, cartData?.subscriptionId, isLoadingCartData, viewModeAlertState],
  );
  const {
    data: accountApplicationsData,
    isLoading: isAccountApplicationsDataLoading,
  } = useAppSelector(state => state.ussp.getAccountApplicationsDataRequest);
  const {
    data: accountUsersData,
    isLoading: isAccountUsersDataLoading,
  } = useAppSelector(state => state.ussp.getAccountUsersDataRequest);
  const {
    data: mfaData,
    isLoading: isMfaDataLoading,
  } = useAppSelector(state => state.ussp.getAccountUsersMfaDataRequest);

  const dispatch = useAppDispatch();

  const getProductsInformation = async () => {
    if (typeof subscriptionId === 'string') {
      await dispatch(getSageProducts({ subscriptionId })).unwrap();
      await dispatch(getOtherProducts({ subscriptionId })).unwrap();
      await dispatch(getQuickbooksProducts({ IsOwn: true, IsPurchase: false, subscriptionId })).unwrap();
      await dispatch(getUserHostingPrice({ subscriptionId })).unwrap();
    }
  };

  useEffect(() => {
    getProductsInformation();
  }, []);

  useEffect(() => {
    if ((accountApplicationsData && !accountApplicationsData.manageable)
     || (accountApplicationsData && !accountApplicationsData.manageable)
     || (mfaData && !mfaData.manageable)
    ) {
      navigate('/');
    }
  }, [accountApplicationsData, accountUsersData, mfaData]);

  const companyName = useMemo(
    () => accountApplicationsData?.companyName
      || accountUsersData?.companyName
      || mfaData?.companyName
      || '',
    [accountApplicationsData, accountUsersData, mfaData],
  );
  const isNameLoading = useMemo(
    () => isAccountApplicationsDataLoading
      || isAccountUsersDataLoading
      || isMfaDataLoading,
    [isAccountApplicationsDataLoading, isAccountUsersDataLoading, isMfaDataLoading],
  );

  const [isShowSidebarModal, setIsShowSidebarModal] = useState<boolean>(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState<boolean>(false);
  const showSidebarModal = () => {
    setIsShowSidebarModal(true);
  };
  const hideSidebarModal = () => {
    setIsShowSidebarModal(false);
  };
  const toggleConfirmationModal = () => {
    setIsShowConfirmationModal(!isShowConfirmationModal);
  };

  return (
    <div className="dashboard-account-details ussp-page">
      <section className="ussp-page__header rel-flex-gap-12">
        <button
          className="btn relative-units default dashboard-billing-details__back-btn"
          onClick={() => navigate('/user-portal/account-overview')}
        >
          <ArrowLeftSmallIcon />
        </button>
        <h1 className="flex-1">
          Summit Account Overview for {isNameLoading
            ? <Skeleton containerClassName="h1-skeleton" height="100%" width="200px" />
            : companyName}
        </h1>
        {ADD_BUTTON_LABELS[routeName] && (
          <button
            className="btn relative-units primary medium with-icon new-style"
            onClick={showSidebarModal}
            disabled={isViewMode}
          >
            <PlusInSquareIcon />
            {ADD_BUTTON_LABELS[routeName]}
          </button>
        )}
      </section>
      {isShowViewModeAlert && (
        <Alert
          classes="rel-m-x-24 rel-m-b-16"
          CustomIcon={InfoCircleOutlineIcon}
          iconPosition="top"
          type="info"
          theme="light"
          message={
            <>
              <p>View mode</p>
              <p className="dashboard-account-details__alert-description rel-m-b-12">
                You will not be able to upgrade or purchase anything in this subscription
                until you complete the action in the subscription&nbsp;
                <span className="fw-600">
                  {cartData?.subscriptionName}
                </span>
              </p>
              <div className="dashboard-account-details__alert-description flex flex-align-center rel-flex-gap-12">
                <span>Review</span>
                <CustomRouterLink
                  to={`/user-portal/account-overview/${cartData?.subscriptionId}`}
                  className="link without-decorations flex flex-align-center rel-flex-gap-4"
                >
                  Go To Subscription
                  <ArrowRightFullIcon />
                </CustomRouterLink>
              </div>
            </>
          }
          fullWidth
          onClose={() => setViewModeAlertState(false)}
        />
      )}
      <nav className="flex">
        <CustomRouterLink to="">Applications</CustomRouterLink>
        <CustomRouterLink to="mfa">MFA</CustomRouterLink>
        <CustomRouterLink to="users">Users</CustomRouterLink>
      </nav>
      <Routes>
        <Route path="" element={<AccountDetailsApplications isViewMode={isViewMode} openAddNewAppModal={showSidebarModal} />} />
        <Route path="users" element={<AccountDetailsUsers isViewMode={isViewMode} />} />
        <Route path="mfa" element={<AccountDetailsUsersMfa isViewMode={isViewMode} />} />
        <Route path="*" element={(
          <section className="dashboard-account-details__content">
            <Error
              code={404}
              buttonLabel="Go to Account Overview Page"
              buttonRedirectPath="/user-portal/account-overview"
              isShowButton
            />
          </section>
        )} />
      </Routes>
      {isShowSidebarModal && (
        routeName === 'applications' ? (
          <AddApplicationSidebarModal
            visible={isShowSidebarModal}
            onCancel={hideSidebarModal}
            toggleConfirmationModal={toggleConfirmationModal}
          />
        ) : (
          <AddUserSidebarModal
            visible={isShowSidebarModal}
            onCancel={hideSidebarModal}
          />
        )
      )}
      {isShowConfirmationModal && (
        <ConfirmationModal
          isShowConfirmationModal={isShowConfirmationModal}
          toggleConfirmationModal={toggleConfirmationModal}
        />
      )}
    </div>
  );
};

export default DashboardAccountDetails;
